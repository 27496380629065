
class Strings {
    constructor() {
        this.About_App_Name = 1 //寻简思维导图
        this.TEMPLATE = 2 //模板
        this.Mind_Edit_Main_Topic = 3 //分支主题
        this.Global_Add_Free_Node_Tips = 4 //点击屏幕选择添加位置
        this.Global_Add_Free_Chart_Node_Tips = 5//双击屏幕选择添加位置
        this.Global_Add_Relationship_Tips = 6//选择需要关联的目标
        this.Global_Add_Target_Tips = 7//请选择一个节点添加对象
        this.Mind_Mindmap_ConnectMap_Exist_Tips = 8//该对象已经关联其他导图
        this.Mind_Edit_Main_Idea = 9//中心主题
        this.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips = 10 //关联导图不存在
        this.Mind_Style_Add_Icon_Priority = 11//优先级
        this.Mind_Style_Add_Icon_Progress = 12//进度
        this.Mind_Style_Add_Icon_Stars = 13//星星
        this.Mind_Style_Add_Icon_Week = 14//星期
        this.Mind_Style_Add_Icon_Head_Portrait = 15//头像
        this.Establish = 16 //创建
        this.Create_From_Template = 17 //从模板创建
        this.Common = 18 //通用
        this.Function_List = 19 //功能列表
        this.Recent_Updates = 20 //最近更新
        this.The_Latest_To_Delete = 21 //最近删除
        this.Setting_Up = 22 //联系我们
        this.Usinghelp = 23 //使用帮助
        this.The_Download_Client = 24 //下载客户端/手机版
        this.Global_Purchase = 25 //购买
        this.Global_Login = 26  //登录
        this.My_map = 27 //我的导图
        this.Storm = 28 //风暴
        this.Mind_Double_Bubble_Edit_Main_Common_Characteristic = 29  //共同\n特点
        this.Mind_Double_Bubble_Edit_Main_Characteristic = 30  //特点
        this.Mind_Organization_Edit_Main_Idea = 31  // 组织中枢
        this.Mind_Edit_Free_Topic = 33  // 自由主题
        this.Mind_Edit_Sub_Topic = 34  // 子主题
        this.Mind_Organization_Main_Topic = 35  // 部门
        this.Mind_Edit_Main_Topic = 36  // 分支主题
        this.Mind_Edit_Summary = 37  // 摘要
        this.Mind_Time_Edit_Main_Idea = 38  // 计划安排
        this.Mind_Time_Edit_Head = 39  // 时间
        this.Mind_Time_Edit_Title = 40  // 计划
        this.Mind_Edit_Details = 41  // 详细描述
        this.Checking_Data = 42 //个人信息
        this.Individual_Account = '' //个人账户
        this.Setting_Information = 43  //设置信息
        this.Sign_Out = 44  //退出登录


        //功能列表
        this.Create_a_Mind_Map = 45  //创建思维导图
        this.Create_Mind_Map_1 = 46  //【1】创建十三种布局的导图（双向/右侧/左侧/组织架构/单气泡图/....
        this.Create_Mind_Map_2 = 47  //【2】可使用模板快速创建导图（大量模板内容引用）
        this.Create_Mind_Map_3 = 48  //【3】对导图进行八种类型的线条样式编辑
        this.Create_Mind_Map_4 = 49  //【4】对导图进行八种布局的变更编辑
        this.Create_Mind_Map_5 = 50  //【5】对目标节点添加子节点
        this.Create_Mind_Map_6 = 51  //【6】可对目标节点添加同级节点（上、下）
        this.Create_Mind_Map_7 = 52  //【7】摘要：对导图文本框添加[摘要]进行说明
        this.Create_Mind_Map_8 = 53  //【8】成块：对导图文本框添加[成块]，进行模块化</
        this.Create_Mind_Map_9 = 54  //【9】添加自由节点
        this.Create_Mind_Map_10 = 55  //【10】创建任意节点之间四种线条样式的关联
        this.Create_Mind_Map_11 = 56  //【11】给节点插入图片、图标、备注、链接
        this.Create_Mind_Map_12 = 57  //【12】复制/粘贴节点，拷贝/粘贴样式
        this.Create_Mind_Map_13 = 58  //【13】撤销，恢复撤销

        this.Beautify_The_Mind_Map = 59  //美化思维导图
        this.Beautify_The_Mind_Map_1 = 60  //【1】设置思维导图背景色
        this.Beautify_The_Mind_Map_2 = 61  //【2】设置文字颜色，文字大小，加粗、斜体、删除样式
        this.Beautify_The_Mind_Map_3 = 62  //【3】设置文本填充色、边框颜色、边框13种形状、边框宽度、阴影效果
        this.Beautify_The_Mind_Map_4 = 63  //【4】设置导图所有或部分线条颜色、宽度
        this.Beautify_The_Mind_Map_5 = 64  //【5】设置图片大小、位置、形状样式
        this.Beautify_The_Mind_Map_6 = 65  //【6】添加七种类别的图标（常用/3D/学习/工作/人物/动物/食物）并设置大小/位置
        this.Beautify_The_Mind_Map_7 = 66  //【7】添加图片并设置图片形状/大小/位置
        this.Beautify_The_Mind_Map_8 = 67  //【8】添加对整个导图一键风格样式引用
        this.Beautify_The_Mind_Map_9 = 68  //【9】分组管理导图
        this.Beautify_The_Mind_Map_10 = 69  //【10】快捷键

        this.Map_Output = 70  //导图输出
        this.Map_Output_1 = 71  //【1】保存图片
        this.Map_Output_2 = 72  //【2】保存PDF
        this.Map_Output_3 = 73  //【3】打印
        this.Map_Output_4 = 74  //【4】分享给同事、同学、好友

        //最近更新
        this.Recently_Updated_Title_1 = 75  //建立个人账号
        this.Recently_Updated_Title_2 = 76  //海量模板知识库
        this.Recently_Updated_Title_3 = 77  //支持多平台
        this.Recently_Updated_Title_4 = 78  //定制每一处细节
        this.Recently_Updated_Title_5 = 79  //个性风格样式库
        this.Recently_Updated_Title_6 = 80  //导图输出
        this.Recently_Updated_Title_7 = 81  //云存储
        this.Recently_Updated_Title_8 = 82  //十五种类型导图

        this.Recently_Updated_Content_1 = 83  //创建个人账号，所制作导图跟随账号保存
        this.Recently_Updated_Content_2 = 84  //海量模板知识库直接引用，提高学习工作效率
        this.Recently_Updated_Content_3 = 85  //支持网页版在线制作、IOS手机端、Mac端、安卓手机端、安卓平板多平台使用
        this.Recently_Updated_Content_4 = 86  //可根据个人喜好美化文本、背景、线条、图片图标等设计
        this.Recently_Updated_Content_5 = 87  //个性风格样式库直接引用，省去配色烦恼
        this.Recently_Updated_Content_6 = 88  //可保存图片、PDF格式，可直接分享好友
        this.Recently_Updated_Content_7 = 89  //强力云端存储支持，再也不用担心资料丢失
        this.Recently_Updated_Content_8 = 90  //双向、右侧、左侧、组织架构、单气泡图、双气泡图、左侧树形图、右侧树形图、双向树形图、表格、横向时间线、竖向时间线、S时间线，八种布局随意变更

        //最近删除
        this.Mind_Mindmap_Deleted_Title = 91 // 最近删除
        this.Mind_Mindmap_Deleted_Recovery = 92  // 恢复
        this.Mind_Mindmap_Deleted_Forever = 93  // 删除
        this.Mind_Mindmap_Deleted_Filename = 94  //文件名
        this.Mind_Mindmap_Deleted_Tips = 95  //在此处删除之后，将永久移除
        this.Mind_Mindmap_Deleted_Operating = 96  //操作
        this.Mind_Mindmap_Deleted_Selected = 97  //搜索最近删除导图
        this.Mind_Mindmap_Deleted_Forever_Time = 98 //删除日期

        //联系我们
        this.Contact_Us_No_Public = 99  // 公众号
        this.Contact_Us_QQ_Group = 100  // QQ群
        this.Contact_Us_Message_Board = 101  // 留言板
        this.Contact_Us_Username = 102  //用户名
        this.Contact_Us_Email = 103  //邮箱
        this.Contact_Us_Voicemail = 104  //请留下宝贵的意见
        this.Contact_Us_Send_Email = 105  //发送邮箱

        //验证信息
        this.Input_Username_isNull = 106  //姓名不能为空
        this.Input_Email_isNull = 107  //请输入正确的邮箱
        this.Input_Voicemail_isNull = 108  //请输入发送内容
        this.Input_Introduction_isNull = 108  //简介不能为空
        this.Input_Title_isNull = '' //标题不能为空

        this.Input_Please_Enter_A_Valid_Phone_Number = 109  // 请输入正确的手机号!
        this.Input_Please_Enter_Verification_Code = 110  // 请输入验证码！
        this.Input_Please_Enter_The_Password = 111  // 请输入密码！
        this.Input_The_Password_Format_Is_Incorrect = 112  // 密码格式不正确！
        this.Input_Please_Enter_A_New_Password = 113  // 请输入新密码！
        this.Input_Confirm_The_New_Password = 114  // 确认新密码！
        this.Input_The_Passwords_Are_Inconsistent = 115  // 密码不一致！
        this.Input_The_Please_Enter_The_Correct_Verification_Code = 116  // 请输入正确验证码！
        this.Input_The_Verification_Code_Error = 117  // 验证码错误！
        // this.Input_ Title_isNull = 116  // 标题不能为空
        // this.Input_ Title_isNull = 117  // 标题不能为空

        //使用帮助
        this.Mind_Usinghelp_1 = 120 //创建思维导图
        this.Mind_Usinghelp_2 = 121 //如何新增节点
        this.Mind_Usinghelp_3 = 122 //创建自由节点
        this.Mind_Usinghelp_4 = 123 //建立关联
        this.Mind_Usinghelp_5 = 124 //美化画布
        this.Mind_Usinghelp_6 = 125 //美化文本
        this.Mind_Usinghelp_7 = 126 //插入备注、链接
        this.Mind_Usinghelp_8 = 127 //插入图标
        this.Mind_Usinghelp_9 = 128 //插入图片
        this.Mind_Usinghelp_10 = 129 //如何恢复删除
        this.Mind_Usinghelp_11 = 130 //导出思维导图
        this.Mind_Usinghelp_12 = 131 //会员升级
        this.Mind_Usinghelp_13 = 408 //快捷键
        this.Mind_Usinghelp_Understood = 409 //知道了

        //风暴
        this.Mind_Storm_Recommend = 132  //推荐
        this.Mind_Storm_Boilingpoint = 133  //沸点
        this.Mind_Storm_Unfold = 134  //展开
        this.Mind_Storm_Putaway = 135  //收起
        this.Mind_Storm_Myclassification = 136  //我的分类
        this.Mind_Storm_Recommended_Classification = 137  //推荐分类
        this.Mind_Storm_Click_Add_Category = 138  //点击添加分类
        this.Mind_Storm_No_Classification = 139  //暂无分类
        this.Mind_Storm_No_Tinterested = 140  //不感兴趣
        this.Mind_Storm_Report = 141  //举报
        this.Mind_Storm_Views = 142  //浏览次数

        this.Mind_Storm_Pornographic = 143  //色情低俗
        this.Mind_Storm_Advertising_Harassment = 144  //广告骚扰
        this.Mind_Storm_Political_Religion = 145  //政治宗教
        this.Mind_Storm_Infringement = 146  //侵权（肖像、诽谤、抄袭、冒用）
        this.Mind_Storm_Prohibited_Content = 147  //违禁内容
        this.Mind_Storm_Spread_Rumors = 148  //造谣传谣
        this.Mind_Storm_Report_Successful = 149  //举报成功

        //修改资料
        this.Global_Edit = 150  //编辑
        this.Global_Cancel = 151  //取消
        this.Global_Ok = 152  //确定
        this.Global_Name = 153  //昵称
        this.Global_Introduction = 154  //简介
        this.Global_Change_Avatar = 155  //更换头像
        this.Global_Local_Upload = 156  //本地上传

        //个人资料
        this.User_Personal_information = 157  //个人资料
        this.User_Member_Validity_Period = 158  //会员有效期
        this.User_My_Share = 159  //我的分享
        this.User_My_Collection = 160  //我的收藏
        this.User_More_Message = 161  //更多
        this.User_Deleted_Sotrm = 162  //删除风暴
        this.User_Folder_Rename = 163 //重命名
        this.User_Folder_Rename_Root = 164 //修改权限
        this.User_Folder_Rename_Root_Title = 165 //文件夹类型
        this.User_Folder_Root_Public = 166 //完全公开
        this.User_Folder_Root_Viewonly = 167 //文件夹仅允许查看
        this.User_Folder_Root_Private = 168 //私密
        this.User_Folder_Root_Public_Introduction = 169 //文件夹完全公开简介
        this.User_Message_Link = 170  //链接
        this.User_New_Group = 171  //新建分组
        this.User_New_Group_Title = 172  //新建分组标题
        this.User_New_Group_Style_Title = 173  //新建分组类型标题
        this.User_Share_Is_Null = 174  //暂无分享！
        this.User_Deleted_Storm_Tips = 175  //确定删除该风暴吗？
        this.User_Remove_From_List = 176  //从列表删除
        this.User_Move_To = 177  //移动到
        this.User_Deleted_Link = 178  //删除连接
        this.User_Collction_Is_Null = 179  //暂无收藏！
        this.User_Collection = 180  //收藏

        //登录框
        this.User_Lolgin_Welcome_To_Login = 181  //欢迎登陆Mind+
        this.User_Lolgin_Free_Registration = 182  //免费注册
        this.User_Lolgin_Please_Enter_Phone_Number = 183  //请输入手机号
        this.User_Lolgin_Please_Enter_Verification_Code = 184  //请输入验证码
        this.User_Lolgin_Obtain = 185  //获取
        this.User_Lolgin_Password_Login = 186  //密码登录
        this.User_Lolgin_Login = 187  //登录
        this.User_Lolgin_Register_An_Account = 188  //账号注册
        this.User_Lolgin_Already_Have_An_Account = 189  //已有账号
        this.User_Lolgin_To_Log_In = 190  //去登陆
        this.User_Lolgin_Please_Enter_Password = 191  //请输入密码
        this.User_Lolgin_Confirm_Password = 192  //确认密码
        this.User_Lolgin_Registered = 193  //注册
        this.User_Lolgin_Account_Login = 194  //账号登录
        this.User_Lolgin_Scan_Code_Login = 195  //扫码登录
        this.User_Lolgin_Forgot_Password = 196  //忘记密码
        this.User_Lolgin_Verification_Code_Login = 197  //验证码登录
        this.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface = 198  //打开Mind+在设置界面顶部扫一扫
        this.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click = 199  //此二维码五分钟过期，过期请点击
        this.User_Lolgin_Refresh = 200  //刷新
        this.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone = 201  //扫描成功，请在手机上确认
        this.User_Lolgin_Whether_Yo_Authorize_Login = 202  //是否授权登录
        this.User_Lolgin_Please_Enter_New_Password = 203  //请输入新密码
        this.User_Lolgin_Reset = 204  //重置

        //我的导图
        this.My_Mind_Search_Template_Type_Or_Template = 205 //搜索模板类型或模板
        this.My_Mind_Date = 206 //日期
        this.My_Mind_Create_Folder = 207 //创建文件夹
        this.My_Mind_Please_Enter_The_Folder_Name = 208 //请输入文件夹名
        this.My_Mind_Please_Enter_A_New_Folder_Name = 209 //请输入新文件夹名
        this.My_Mind__No_Map_Yet_Go = 210 //暂无导图 快去
        this.My_Mind_Create = 211 //创建
        this.My_Mind_Right = 212 //吧!
        this.My_Mind_Open = 213 //打开
        this.My_Mind_Type_Left_Right = 214// 双向导图
        this.My_Mind_Type_Unilateral = 215 // 单向导图
        this.My_Mind_Type_Bottom = 216 // 组织架构图
        this.My_Mind_Type_Bubble = 217 // 气泡图
        this.My_Mind_Type_Time_Vertical = 218 // 日程安排图
        this.My_Mind_Blank_Mind_Map = 219 // 空白思维导图
        this.My_Mind_All = 220 // 全部
        this.My_Mind_Views = 221 // 浏览次数

        //风暴搜索
        this.Storm_Search_How_Title = 222 //热门标签
        this.Storm_History_Search = 223 //历史搜索

        //公共 header
        this.Public_Header_Mind = 224 //导图
        this.Public_Header_Outline = 225 //大纲
        this.Public_Header_Download_Apps = 226 //下载客户端
        this.Public_Header_Mobile_Version = 227 //手机版
        this.Public_Header_Creator = 228 //创作者
        this.Public_Header_View_Frequency = 229 //查看次数
        this.Public_Header_Save = 230 //保存

        this.Header_Mind_Tips_Saved = 405 //已保存
        this.Header_Mind_Tips_Edit = 406 //已编辑
        this.Header_Mind_Tips_Update_Saved = 407 //更新已保存

        //导图编辑菜单
        this.Mind_Edit_Revoke = 231 //撤销
        this.Mind_Edit_Restore = 232 //恢复
        this.Mind_Edit_Subtopic = 233 //子主题
        this.Mind_Edit_Top_Subtopic = 234 //上子主题
        this.Mind_Edit_Bottom_Subtopic = 235 //下子主题
        this.Mind_Edit_Free_Node = 236 //自由节点
        this.Mind_Edit_Block = 237 //成块
        this.Mind_Edit_Insert = 238 //插入
        this.Mind_Edit_Related = 239 //关联
        this.Mind_Edit_Style = 240 //风格
        this.Mind_Edit_Recording = 241 //录音
        this.Mind_Edit_Insert_Picture = 242 //插入图片
        this.Mind_Edit_Insert_Icon = 243 //插入图标 Insert icon
        this.Mind_Edit_Insert_Remarks = 244 //插入备注
        this.Mind_Edit_Insert_Link = 245 //插入链接
        this.Mind_Edit_Insert_Summary_Graph = 246 //插入统计图
        this.Mind_Edit_Upload = 247 //上传
        this.Mind_Edit_Right_Icon = 248 //图标
        this.Mind_Edit_Right_Style = 249 //样式
        this.Mind_Edit_Audio_Decoding = 250 //解码中...
        this.Mind_Edit_Audio_Duration = 251 //时长
        this.Mind_Edit_Delete_Audio_Tips = 252 //您确定删除录制的音频吗？
        this.Mind_Edit_Join_Membership = 253 //开通会员
        this.Mind_Edit_Upgrade = 254 //升级
        this.Mind_Edit_Join_Membership_Tips = 255 //会员可以有更多的录制时长，现在去升级成会员？

        this.Mind_Edit_Choose_Material = 256 // 选择素材
        this.Mind_Edit_Drag_And_Drop_The_Picture_Here = 257 // 拖放图片至此
        this.Mind_Edit_Select_locally = 258 // 从本地选择
        this.Mind_Edit_Choose_From = 259 // 从从素材库选择
        this.Mind_Edit_Mine = 260 // 我的
        this.Mind_Edit_My_Material = 261 // 我的素材
        this.Mind_Edit_Recently_Used = 262 // 最近使用
        this.Mind_Edit_Use = 263 // 使用
        this.Mind_Edit_No_Material_Temporarily_Go_Add_It = 264 // 暂无素材 快去添加吧！
        this.Mind_Edit_Select_Chart_Type = 265 // 选择统计图类型
        this.Mind_Edit_Remarks = 266 //备注
        this.Mind_Edit_Remarks_Tips = 267 //请输入备注内容
        this.Mind_Edit_Add = 268 //添加
        this.Mind_Edit_Please_Enter_The_Link = 269 //请输入链接

        //编辑页左侧菜单
        this.Mind_Edit_Left_Menu_Save_Picture = 270 //保存图片
        this.Mind_Edit_Left_Menu_Export_Pdf = 271 //导出PDF
        this.Mind_Edit_Left_Menu_Share_Links = 272 //分享链接
        this.Mind_Edit_Left_Menu_Share_The_Storm = 273 //分享到风暴
        this.Mind_Edit_Left_Menu_Printing = 274 //打印
        this.Mind_Edit_Left_Menu_Unlock_Full_Version = 275 //解锁完整版本
        this.Mind_Edit_Left_Menu_Help = 276 //帮助
        this.Mind_Edit_Left_Menu_Save_Picture_Format = 277 //保存格式
        this.Mind_Edit_Left_Menu_Save_Picture_Png = 278 //png格式
        this.Mind_Edit_Left_Menu_Save_Picture_Jpg = 279 //jpg格式
        this.Mind_Edit_Left_Menu_Save_Picture_Webp = 280 //webp格式
        this.Mind_Edit_Left_Menu_Share_Map = 281 //分享导图
        this.Mind_Edit_Left_Menu_Share_Switch = 282//分享开关
        this.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent = 283//可将链接复制并发送
        this.Mind_Edit_Left_Menu_Copy_Link = 284//复制链接
        this.Mind_Edit_Left_Menu_Follow_The_Link = 285//如果你想跟换链接，请点击
        this.Mind_Edit_Left_Menu_Regenerate = 286//换一换
        this.Mind_Edit_Left_Menu_Link_Password = 287//链接密码：
        this.Mind_Edit_Left_Menu_Allow_Save = 288//开启保存后，查看者可将其保存为自己的导图
        this.Mind_Edit_Left_Menu_Maximum_Number_Of_Views = 289//最多可查看次数
        this.Mind_Edit_Left_Menu_Infinite = 290//无限
        this.Mind_Edit_Left_Menu_Limit = 291 //限制
        this.Mind_Edit_Left_Menu_Second = 292 //次
        this.Mind_Edit_Left_Menu_Link_Validity = 293 //链接有效期
        this.Mind_Edit_Left_Menu_1_Day = 294 //1天
        this.Mind_Edit_Left_Menu_3_Days = 295 //3天
        this.Mind_Edit_Left_Menu_7_Days = 296 //7天
        this.Mind_Edit_Left_Menu_30_Days = '' //30天
        this.Mind_Edit_Left_Menu_Permanent = 297 //永久
        this.Scan_The_Code_To_Get_The_file = '' //微信扫码即可获取文件
        this.Mind_Edit_Left_Menu_Copy_Content_And_Link = 298 //复制内容&链接
        this.Mind_Edit_Left_Menu_Regenerate_Tips = 299 //您确定要重新生成？之前的链接将会失效！
        this.Mind_Edit_Left_Menu_Share = 300 //分享
        this.Mind_Edit_Left_Menu_Custom_Category = 301 //自定义分类：
        this.Mind_Edit_Left_Menu_Allow_Comments = 302 //是否允许评论
        this.Mind_Edit_Left_Menu_Edit_Add_Category = 303 //编辑添加分类
        this.Mind_Edit_Left_Menu_Membership_Rights = 304 //会员权益
        this.Mind_Edit_Left_Menu_Support_Platform = 305 //支持平台
        this.Mind_Edit_Left_Menu_Open_Membership = 306 //开通会员
        this.Mind_Edit_Left_Menu_Activation_Code_Exchange = 307 //激活码兑换
        this.Mind_Edit_Left_Menu_purchase_Tips = 308 //购买完即可会员权益
        this.Mind_Edit_Left_Menu_Payment_Type = 309 //选择支付方式：
        this.Mind_Edit_Left_Menu_Activity_Price = 310 //活动价
        this.Mind_Edit_Left_Menu_Amount_Actually_Paid = 311 //实付金额
        this.Mind_Edit_Left_Menu_Immediate_Renewal = 312 //立即续费
        this.Mind_Edit_Left_Menu_Terms_Of_Service = 313 //服务条款
        this.Mind_Edit_Left_Menu_Privacy_Policy = 314 //隐私政策
        this.Mind_Edit_Left_Menu_Activation_Code = 315 //激活码
        this.Mind_Edit_Left_Menu_Please_Input_Activation_Code = 316 //请输入激活码
        this.Mind_Edit_Left_Menu_Verify_Activation_Code = 317 //验证激活码
        this.Mind_Edit_Left_Menu_Select_Categories = 318 // 选择分类
        this.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty = 319 // 主标题不能为空
        this.Mind_Edit_Left_Menu_Coupon = 320 // 优惠券：
        this.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code = 321 // 请输入优惠券码
        this.Mind_Edit_Left_Menu_Verification = 322 // 验证
        this.Mind_Edit_Left_Menu_Preferential_Amount = 323 // 优惠金额
        this.Mind_Edit_Left_Menu_Pay_Immediately = 324 // 立即支付
        this.Mind_Edit_Left_Menu_Wx_Pay = 325 // 微信支付
        this.Mind_Edit_Left_Menu_Confirm_Exchange_Information = 326 // 确认兑换信息
        this.Mind_Edit_Left_Menu_Exchange_Code = 327 //兑换码：
        this.Mind_Edit_Left_Menu_Confirm_Exchange = 328 //兑换

        //编辑页右侧菜单
        this.Mind_Edit_Right_Menu_canvas = 329 //画布
        this.Mind_Edit_Right_Menu_background_Color = 330 //背景颜色
        this.Mind_Edit_Right_Menu_layout = 331 //布局
        this.Mind_Edit_Right_Menu_Line_Style = 332 //线条样式
        this.Mind_Edit_Right_Menu_line_Color = 333 //线条颜色
        this.Mind_Edit_Right_Menu_Line_Width = 334 //线条宽度
        this.Mind_Edit_Right_Menu_Line_Tapering = 335 //线条渐细
        this.Mind_Edit_Right_Menu_Theme_Font = 336 //主题字体
        this.Mind_Edit_Right_Menu_Child_Font = 337 //子级字体
        this.Mind_Edit_Right_Menu_text_Color = 338 //文本颜色
        this.Mind_Edit_Right_Menu_font_Size = 339 //字体大小
        this.Mind_Edit_Right_Menu_Font_Style = 340 //字体样式
        this.Mind_Edit_Right_Menu_Border_Color = 341 //边框颜色
        this.Mind_Edit_Right_Menu_Fill_Color = 342 //填充色
        this.Mind_Edit_Right_Menu_Border_Shape = 343 //边框形状
        this.Mind_Edit_Right_Menu_Border_Width = 344 //边框宽度
        this.Mind_Edit_Right_Menu_Show_Shadow_Effect = 345 //显示阴影效果（订阅功能）
        this.Mind_Edit_Right_Menu_Line_Color = 346 //线条颜色
        this.Mind_Edit_Right_Menu_Dotted_Line = 347 //虚线
        this.Mind_Edit_Right_Menu_Picture_Size = 348 //图片大小
        this.Mind_Edit_Right_Menu_Picture_Location = 349 //图片位置
        this.Mind_Edit_Right_Menu_Picture_Shape = 350 //图片形状
        this.Mind_Edit_Right_Menu_Icon_Size = 351 //图标大小
        this.Mind_Edit_Right_Menu_Icon_Position = 352 //图标位置
        this.Mind_Edit_Right_Menu_routine = 353 //常规
        this.Mind_Edit_Right_Menu_priority = 354 //优先级
        this.Mind_Edit_Right_Menu_Progress = 355 //进度
        this.Mind_Edit_Right_Menu_Stars = 356 //星星
        this.Mind_Edit_Right_Menu_Week = 357 //星期
        this.Mind_Edit_Right_Menu_Head_Portrait = 358 //头像
        this.Mind_Edit_Right_Menu_3D = 359 //3D
        this.Mind_Edit_Right_Menu_Learning = 360 //学习
        this.Mind_Edit_Right_Menu_Work = 361 //工作
        this.Mind_Edit_Right_Menu_Characters = 362 //人物
        this.Mind_Edit_Right_Menu_Animals = 363 //动物
        this.Mind_Edit_Right_Menu_Food = 364 //食物

        //编辑节点菜单
        this.Mind_Edit_Node_Menu_Copy_Style = 365 //拷贝样式
        this.Mind_Edit_Node_Menu_Sticking_Style = 366 //黏贴样式
        this.Mind_Edit_Node_Menu_Modify_Shape = 367 //修改形状
        this.Mind_Edit_Node_Menu_View_Picture = 368 //查看图片
        this.Mind_Edit_Node_Menu_Delete_Picture = 369 //删除图片
        this.Mind_Edit_Node_Menu_Copy = 370 //复制
        this.Mind_Edit_Node_Menu_Paste = 371 //粘贴
        this.Mind_Edit_Node_Menu_Delete_Line = 372 //删除线条
        this.Mind_Edit_Node_Menu_Delete_Wrapping_Line = 373 //删除包裹线
        this.Mind_Edit_Node_Menu_Edit_Remarks = 374 //编辑备注
        this.Mind_Edit_Node_Menu_Open_Link = 375 //打开链接
        this.Mind_Edit_Node_Menu_Delete_Remarks = 376 //删除备注
        this.Mind_Edit_Node_Menu_Delete_Link = 377 //删除链接
        this.Mind_Edit_Node_Menu_Delete_Icon = 378 //删除图标
        this.Mind_Edit_Node_Menu_Icon_Style = 379 //图标样式

        //统计图编辑面板
        this.Echart_Edit_Statistical = 380 //统计
        this.Echart_Edit_Value = 381 // 值
        this.Echart_Edit_Color = 382 // 颜色
        this.Echart_Edit_Title = 383 // 标题
        this.Echart_Edit_Detailed_Description = 384 // 详细描述
        this.Echart_Edit_Value_Sort = 385 // 排序
        this.Echart_Edit_Value_Display_Type = 386 // 展示类型
        this.Echart_Edit_Value_Color_Scheme = 387 // 配色方案
        this.Echart_Edit_Value_Display_Size = 388 // 展示大小
        this.Echart_Edit_Value_Title_Size = 389 // 标题大小
        this.Echart_Edit_Value_Explain_Size = 390 // 解释大小
        this.Echart_Edit_Value_Subscribe = 391 // 订阅

        //风暴标签页
        this.StormBookTag_Subscribed = 392 // 已订阅
        this.StormBookTag_List = 393 //列表

        //风暴分享页
        this.Storm_Share_Comment = 394 // 评论
        this.Storm_Share_Reply = 395 // 回复
        this.Storm_Share_Let_Me_Comment_Too = 396 // 我也来评论一下吧~
        this.Storm_Share_Release = 397 // 发布
        this.Storm_Share_Main_Title = 398 // 主标题
        this.Storm_Share_Subtitle = 399 // 副标题
        this.Storm_Share_Author = 400 // 作者
        this.Storm_Share_Replies = 401 // 条回复
        this.Storm_Share_Comment_Is_Null_Tips = 402 //暂无评论，快来抢沙发吧
        this.Storm_Share_Comment_Input_Is_Null_Tips = 403 //请输入评论呢~
        this.Storm_Share_Delete_Comment_Tips = 404 //确定删除该评论吗？

        //导图内部使用帮助
        this.Mind_Usinghelp_Btn_1 = 410 //快捷键(windows)
        this.Mind_Usinghelp_Btn_2 = 411 //快捷键(mac)
        this.Mind_Usinghelp_Btn_3 = 412 //描述
        this.Mind_Usinghelp_Btn_4 = 413 //[Ctrl] + [D]
        this.Mind_Usinghelp_Btn_5 = 414 //[Command] + [D]
        this.Mind_Usinghelp_Btn_6 = 415 //添加子节点
        this.Mind_Usinghelp_Btn_7 = 416 //[Delet]
        this.Mind_Usinghelp_Btn_8 = 417 // 删除当前节点（注意，如果是编辑状态就不可以删除了）
        this.Mind_Usinghelp_Btn_9 = 418 //[Ctrl] + [E]
        this.Mind_Usinghelp_Btn_10 = 419 //[Command] + [E]
        this.Mind_Usinghelp_Btn_11 = 420 //修改当前节点
        this.Mind_Usinghelp_Btn_12 = 421 //[Ctrl] + [↑] [↓] [←] [→]
        this.Mind_Usinghelp_Btn_13 = 422 //[Command] + [↑] [↓] [←] [→]
        this.Mind_Usinghelp_Btn_14 = 423 //[Ctrl] + [+]
        this.Mind_Usinghelp_Btn_15 = 424 //[Command] + [+]
        this.Mind_Usinghelp_Btn_16 = 425 //放大导图
        this.Mind_Usinghelp_Btn_17 = 426 //[Ctrl] + [-]
        this.Mind_Usinghelp_Btn_18 = 427 //[Command] + [-]
        this.Mind_Usinghelp_Btn_19 = 428 //缩小导图
        this.Mind_Usinghelp_Btn_20 = 429 //[Ctrl] + [S]
        this.Mind_Usinghelp_Btn_21 = 430 //[Command] + [S]
        this.Mind_Usinghelp_Btn_22 = 431 //[Ctrl] + [Z]
        this.Mind_Usinghelp_Btn_23 = 432 //[Command] + [Z]
        this.Mind_Usinghelp_Btn_24 = 433 //[Ctrl] + [Shift] + [Z]
        this.Mind_Usinghelp_Btn_25 = 434 //[Command] + [Shift] + [Z]
        this.Mind_Usinghelp_Btn_26 = 435 //[Ctrl] + [B]
        this.Mind_Usinghelp_Btn_27 = 436 //[Command] + [B]
        this.Mind_Usinghelp_Btn_28 = 437 //退出编辑

        //提示文字
        this.Message_Tips_Login_Success = 438 //登录成功
        this.Message_Tips_Dlt_Success = 439 //删除成功
        this.Message_Tips_Report_Success = 440 //举报成功
        this.Message_Tips_Copy_Success = 441 //复制成功
        this.Message_Tips_Collction_Success = 442 //收藏成功
        this.Message_Tips_Avatar_Modification_Success = 443 //头像修改成功
        this.Message_Tips_Signature_Modification_Success = 444 //签名修改成功
        this.Message_Tips_Name_Modification_Success = 445 //昵称修改成功
        this.Message_Tips_Modification_Success = 446 //修改成功
        this.Message_Tips_New_Profile_Success = 447 //新建文件夹成功
        this.Message_Tips_Mobile_Success = 448 //移动成功
        this.Message_Tips_Submit_Success = 449 //提交成功
        this.Message_Tips_Exchange_Success = 450 //兑换成功
        this.Message_Tips_Please_Code = 451 //请输入激活码
        this.Message_Tips_Agree_To_Terms = 452 //请同意隐私条款
        this.Message_Tips_Please_Sign_In = 453 //请登录
        this.Message_Tips_Share_Success = 454 //分享成功
        this.Message_Tips_Create_Success = 455 //创建成功
        this.Message_Tips_Profile_Name_No_Null = 456 //文件夹名不能为空
        this.Message_Tips_Selecte_Success = 457 //搜索完成
        this.Message_Tips_Subscription_Success = 458 //订阅成功
        this.Message_Tips_Unsubscribe_Success = 459 //取消订阅成功
        this.Message_Tips_Related_Content_Not_Found = 460 //未找到相关内容
        this.Message_Tips_Max_Add_Class = 461 // 最多只能添加
        this.Message_Tips_Registered_Success = 462 //注册成功
        this.Message_Tips_Scan_Code_Login_Failed = 463 //扫码登录失败
        this.Message_Tips_Share_Is_Null = 464 //分享链接不存在
        this.Message_Tips_Share_Link_Does_Not_Exist = 465 //上传文件不能超过3MB!
        this.Message_Tips_Get_Permission_Successfully = 466 //获取权限成功
        this.Message_Tips_Membership_Required = 467 //需要会员
        this.Message_Tips_Save_Success = 468 //保存成功
        this.Message_Tips_Scan_Code_Login_Success = 469 //扫码登录成功
        this.Message_Tips_Style = 470 // 个分类呢

        //大纲模式
        this.Outline_Menu_Indent = 471 // 缩进
        this.Outline_Menu_Decrease_Indent = 472 // 减少缩进


        //帮助提示
        this.Help_Background_Content = 473 // 线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,
        this.Help_Mind_Content = 474 // 可以根据喜好做不同布局的导图：双向导图、左侧导图、右侧导图、组织机构图、双向树、左侧树、右侧树、表格
        this.Help_Share_Link_Content = 475 // 分享链接可以以链接形式在微信、QQ、微博等渠道方式分享，好友可以查看内容


        this.Using_Templates = 476 //使用模板
        this.User_Lolgin_Please_Enter_Invitation_Code = 477  //请输入邀请码
        this.User_Lolgin_Please_Correct_Enter_Invitation_Code = 478  //请输入正确邀请码
        this.User_Lolgin_Optional = 479  //选填

        this.Cannot_Add_To_Node_Background = 480  // 不适用于节点背景
        this.Cannot_Add_To_Img_Background = 481  //不适用于文字背景
        this.Cannot_Add_To_Mind_Background = 482 //不适用于包裹线背景

        this.Mind_Usinghelp_14 = 483 //插入素材库
        this.Mind_Edit_Node_Menu_Add_Encircle_Background = 484 //添加包裹线背景
        this.Mind_Edit_Node_Menu_Delete_Encircle_Background = 485 //删除包裹线背景

        this.Mind_Add_Background_Image = 486 //插入节点背景图
        this.Mind_Delete_Background_Image = 487 //删除节点背景图
        this.Permanent_Member = 488 //永久会员

        this.Insufficient_Storage_Space = 489 //存储空间不足
        this.Used_Space = 490 //存储空间：
        this.Used_Space_Upgrade = '' //当前存储
        this.Current_Storage = '' //存储空间升级
        this.My_Mind_Is_Null_Tips = 491 // Mind+脑图&笔记 超级记忆术, 给思维插上翅膀 创作、展示、计划、笔记
        this.Insufficient_Storage_Space_Tempalte_Tips = 492 //存储空间不足，升级会员即可使用模板。


        this.Group_Management = 493 //群组
        this.Save_Img_Fill_Full = 494, //铺满
        this.Save_Img_Center_Tilt = 495, //居中倾斜
        this.Save_Img_File_Full_Dark_Color = 496 //铺满（深色）
        this.Save_Img_Mind_Map_Watermarking = 497//寻简思维导图水印
        this.Save_Img_Add_Watermark_To_Protect_Community_Creation = 498 //添加水印保护知识创作:
        this.Save_Img_Watermark_Text = 499//水印文字:
        this.Save_Img_Watermark_Logo = 500//水印LOGO:
        this.Save_Img_Watermark_Style = 501//水印样式:
        this.Save_Img_Please_Input = 502//请输入:
        this.Save_Img_Chop_Tips = 503//开通会员即可享移除默认水印、添加自定义水印
        this.Public_Header_Mindmap_Mind = 504 //导图模式
        this.Copy_Mind_Map = 505 //复制导图
        this.Font_Style_Bold = 506 //粗体
        this.Font_Style_Italics = 507 //斜体
        this.Font_Style_Delete_Line = 508 //删除线
        this.Remove_Any = 509 //移除

        this.Mindmap_Review = 510 //导图回顾
        this.Mindmap_Review_Recently_Updated_Content_10 = '' //加深印象增强记忆，高效梳理思路和信息||Deepen impression, enhance memory, and efficiently sort out ideas and information
        this.Mindmap_Review_Content = 511 //导图回顾内容
        this.Mindmap_Review_Speed = 512 //速度
        this.Mindmap_Review_Word_Speed = 513 //文字速度
        this.Mindmap_Review_Background_Ratio = 514 //背景比例
        this.Mindmap_Review_Current_Playback_Node_Magnification = 515 //当前播放节点放大倍数
        this.Mindmap_Review_Node_Playback_Order = 516 //节点播放顺序
        

        //使用帮助-2
        this.Mind_Usinghelp_15 = 517 //新建导图
        this.Mind_Usinghelp_16 = 518 //添加节点
        this.Mind_Usinghelp_17 = 519 //撤销操作、取消
        this.Mind_Usinghelp_18 = 520 //拖动节点换位
        this.Mind_Usinghelp_19 = 521 //节点对齐
        this.Mind_Usinghelp_20 = 522 //变更导图类型
        this.Mind_Usinghelp_21 = 523 //导图线条样式
        this.Mind_Usinghelp_22 = 524 //导图背景颜色
        this.Mind_Usinghelp_23 = 525 //设置节点形状
        this.Mind_Usinghelp_24 = 526 //节点线条样式
        this.Mind_Usinghelp_25 = 527 //节点图片设置
        this.Mind_Usinghelp_26 = 528 //节点图标设置;
        this.Mind_Usinghelp_27 = 529 //节点添加摘要;
        this.Mind_Usinghelp_28 = 530 //多节点框选;
        this.Mind_Usinghelp_29 = 531 //多节点成块;
        this.Mind_Usinghelp_30 = 532 //节点添加边框;
        this.Mind_Usinghelp_31 = 533 //添加统计图;
        this.Mind_Usinghelp_32 = 534 //隐藏节点及恢复;
        this.Mind_Usinghelp_33 = 535 //节点多个操作;
        this.Mind_Usinghelp_34 = 536 //更换风格;
        this.Mind_Usinghelp_35 = 537 //插入语音及解释;
        this.Mind_Usinghelp_36 = 538 //导图导成图片;
        this.Mind_Usinghelp_37 = 539 //导图PDF文档;
        this.Mind_Usinghelp_38 = 540 //打印导图;
        this.Mind_Usinghelp_39 = 541 //链接分享及查看;
        this.Mind_Usinghelp_40 = 542 //导图分享到风暴;
        this.Mind_Usinghelp_41 = 543 //文件夹的使用;
        this.Mind_Usinghelp_42 = 544 //删除及恢复导图;
        //使用帮助3
        this.Mind_Usinghelp_43 = ''//好友申请加入组
        this.Mind_Usinghelp_44 = '' //如何解散组;
        this.Mind_Usinghelp_45 = '' //上传文件资料;
        this.Mind_Usinghelp_46 = '' //同意加入申请;
        this.Mind_Usinghelp_47 = '' //协同编辑;
        this.Mind_Usinghelp_48 = '' //新建共享组;
        this.Mind_Usinghelp_49 = '' //邀请组成员

        this.Currently_Selected = 545 //当前选中
        this.LabelManagement = 546 //标签管理
        this.Mindmap_Review_Play = 547 //播放
        this.NoAttention = 548 //暂无关注
        this.Create_Map = 549 //创建导图
        this.Mind_Edit_Right_Menu_Node_Full_Shape = 550 // '填充样式'

        this.Mind_Group_Picture_Upload = ''   //头像上传
        this.Mind_Group_Upload_From_Local = '' //本地上传
        this.Mind_Group_Mind_Mapping  = ''//思维导图 mind mapping 
        this.Mind_Group_Information  = ''//资料 information

        this.Mind_Group_Invite_New_Members  = ''//邀请新成员  Invite New Members
        this.Mind_Group_Please_Enter_Your_Cell_Phone_Number = ''//请输入手机号 Please enter your cell phone number
        this.Mind_Group_Cancel = '' //取消 cancel
        this.Mind_Group_Confirm = ''//确定 confirm 
        this.Mind_Group_The_Phone_Number_Cannot_Be_Blank = ''//手机号不能为空 The phone number cannot be blank
        this.Mind_Group_Invite_The_Successful = ''//邀请成功 Invite the successful


        
        this.Mind_Group_Shared_Group_Membership = ""//共享组成员||Shared group membership
        this.Mind_Group_Maximum_Number_Of_Shared_Group_Members = ""//共享组成员数量上限||Maximum number of shared group members
        this.Mind_Group_Membership_Limit = "" //成员上限||Membership Limit
        this.Mind_Group_Current_Member_Limit = "" //当前成员上限||Current member limit 
        this.Mind_Group_Upgrade_Member_Limit = "" //升级成员上限||Upgrade member limit
        this.Mind_Group_Members_Of_The_Search = ""//搜索成员||Members of the search
        this.Mind_Group_Creator = ""//创建者||Creator
        this.Mind_Group_Administrator = ""//管理员||Administrator
        this.Mind_Group_Number_Of_People = ""//人||Number of people
        this.Mind_Group_Selected = ""//已选择||Selected
        this.Mind_Group_Member = ""//成员||Member
        this.Mind_Group_Check_All = ""//全选||Check all
        this.Mind_Group_Remove = ""//移除||Remove
        this.Mind_Group_Invite_New_Members = ""//邀请新成员||Invite New Members
        this.Mind_Group_Delete_Members = ""//删除成员||Delete members
        this.Mind_Group_Please_Select_The_Member_To_Delete = ""//请选择要删除的成员 ||Please select the member to delete
        this.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group = ""//确定要将用户从本组中移除？||Make sure you want to remove the user from the group
        this.Mind_Group_Add_As_Administrator = ""//添加为管理员||Add as administrator
        this.Mind_Group_Accomplish = ""//完成||accomplish
        this.Mind_Group_Compile = ""//编辑||compile
        this.Mind_Group_Group_Owner = ""//群主||group owner
        this.Mind_Group_Add_Admin = ""//添加管理员||add admin
        this.Mind_Group_Group_ID = ""//组id||group ID
        this.Mind_Group_Group_Number = ""//组号||group number
        this.Mind_Name = ""//名称||name
        this.Mind_Group_Please_Enter_A_Shared_Group_Name = ""//请输入共享组名称||Please enter a shared group name
        this.Mind_Group_Introduce = ""//介绍||introduce
        this.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups = ""//请简要介绍共享组||Please give a brief introduction to shared groups
        this.Mind_Group_Announcement = ""//公告||announcement
        this.Mind_Group_Shared_Group_Tag = ""//共享组标签||Shared group tag
        this.Mind_Group_Custom_Tag = ""//自定义标签||custom tag
        this.Mind_Group_Save = ""//保存||save
        this.Mind_Group_The_Group_Name_Cannot_Be_Empty = ""//组名不能为空||The group name cannot be empty
        this.Mind_Group_Establish = ""//创建||establish
        this.Mind_Group_Allows_Mapfolder_Creation = ""//允许创建导图/文件夹||Allows map/folder creation
        this.Mind_Group_Allows_Editing_Of_Maps = ""//允许编辑导图||Allows editing of maps
        this.Mind_Group_Holder = ""//所有人||holder
        this.Mind_Group_Only_The_Administrator = ""//仅管理员||Only the administrator
        this.Mind_Group_Not_Allow = ""//不允许||not allow
        this.Mind_Group_Allows_Map_Deletion = ""//允许删除导图||Allows map deletion
        this.Mind_Group_Allows_Discussion_Boards_To_Post_Messages = ""//允许讨论区发消息||Allows discussion boards to post messages
        this.Mind_Group_Export = ""//导出||export
        this.Mind_Group_Allows_You_To_Save_Images_PDF = ""//允许保存图片/PDF||Allows you to save images /PDF
        this.Mind_Group_Open_Group_Watermark = ""//开启组水印||Open group watermark
        this.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo = ""//开启后，思维导图上会印上组标识||When turned on, the mind map will be printed with a group logo
        this.Mind_Group_Allow_Saving_Maps = ""//允许保存导图||Allow saving maps
        this.Mind_Group_Save_It_To_My_Map = ""//保存到我的导图||Save it to my map
        this.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed = ""//是否允许分享到风暴||Whether sharing to storm is allowed
        this.Mind_Group_Join_The_Way = ""//加入方式||Join the way
        this.Mind_Group_Whether_To_Invite_New_Members = ""//是否允许邀请新成员||Whether to invite new members
        this.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added = ""//是否允许搜索组号加入||Whether to allow search group numbers to be added
        this.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining = ""//加入时是否需要验证消息||Whether the message needs to be validated when joining
        this.Mind_Group_Shared_Group_Data = ""//共享组资料||Shared Group Data
        this.Mind_Group_Authority_Management = ""//权限管理||authority management
        this.Mind_Group_The_Dissolution_Of_Group = ""//解散组||The dissolution of group
        this.Mind_Group_Quit_Sharing_Of_Group = ""//"退出共享组"
        this.Mind_Group_Confirm_To_Exit_The_Shared_Group = ""//"是否确认退出共享组？"
        this.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding = ""//解散组后文件将删除，确认解散？||The files will be deleted after disbanding the group. Confirm disbanding?
        this.Mind_Group_Return_To_Home_Page = ""//返回到首页||Return to Home Page
        this.Mind_Group_Join_The_Group = ""//加入组||Join the group
        this.Mind_Group_Please_Enter_The_Group_Number = ""//请输入组号||Please enter the group number
        this.Mind_Group_Apply_To_Join = ""//申请加入||apply to join
        this.Mind_Group_Join = ""//加入||join
        this.Mind_Group_Verification = ""//验证||verification
        this.Mind_Group_Please_Enter_A_Validation_Message = ""//请输入验证消息||Please enter a validation message
        this.Mind_Group_The_Content_Cannot_Be_Empty = ""//内容不能为空||The content cannot be empty
        this.Mind_Group_Name = ""//共享组名称||name
        this.Mind_Group_Please_Enter_A_Shared_Group_Name = ""//请输入共享组名称||Please enter a shared group name
        this.Mind_Group_Introduction = ""//共享组介绍||Introduction
        this.Mind_Group_Group_Indication = ""//组号||group indication
        this.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet = ""//创建者很懒，还没有介绍哦~||The creator is lazy and hasn't been introduced yet
        this.Mind_Group_Shared_Group_Announcement = ""//共享组公告||Shared group announcement
        this.Mind_Group_No_Announcement = ""//暂无公告||No announcement
        this.Mind_Group_Click_On = ""//点击展开||Click on
        this.Mind_Group_Click_On_The_Hidden = ""//点击隐藏||Click on the hidden
        this.Mind_Group_Altogether = ""//共||altogether
        this.Mind_Group_The_Group_Number_Was_Copied_Successfully = ""//复制组号成功||The group number was copied successfully
        this.Mind_Group_Stick = ""//置顶||stick
        this.Mind_Group_There_Is_A_New_Content = ""//有新内容||There is a new content
        this.Mind_Group_Cancel_The_Top = ""//取消置顶||Cancel the top
        this.Mind_Group_Setting_Up = ""//设置||setting up
        this.Mind_Group_Usinghelp = ""//使用帮助||usinghelp
        this.Mind_Group_Set_The_Message = ""//组消息||Set the message
        this.Mind_Group_Apply_To_Join = ""//申请加入||apply to join
        this.Mind_Group_Invite_To_Join = ""//邀请加入||invite to join
        this.Mind_Group_Consent = ""//同意||consent
        this.Mind_Group_Turn_Down = ""//拒绝||turn down
        this.Mind_Group_Label = ""//标签||label
        this.Mind_Group_Please_Enter_A_Shared_Group_Label_Name = ""//请输入共享组标签名称||Please enter a shared group label name
        this.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community = ""//邀请好友、同事共同创作、共享知识内容||Invite friends and colleagues to create and share knowledge
        this.Mind_Group_Immediately_Create = ""//立即创建||Immediately create
        this.Mind_Group_Immediately_To_Join = ""//立即加入||Immediately to join

        this.Mind_Group_Joined = ""//已加入||Joined
        this.Mind_Group_Download_App = ""//下载APP||Download app
        this.Mind_Group_No_Content = ""//暂无内容||No content
        this.Mind_Group_Individual = ""//个||individual
        this.Mind_Group_Message_Area = ""//留言区||Message area
        this.Mind_Group_No_Message = ""//暂无留言，快去||No message
        this.Mind_Group_Leave_Message = ""//留言||Leave a message
        this.Mind_Group_Bar = ""//吧||
        this.Mind_Group_See_More = ""//查看更多||See more
        this.Mind_Group_Enter_Invitation_Code = ""//输入邀请码||Enter invitation code
        this.Mind_Group_Apply_To_Join_Sharing_Group = ""//申请加入共享组||Apply to join sharing group
        this.Reasons_For_Application_0_30_Words = ""//申请理由（0-30字）||Reasons for application (0-30 words)
        this.Mind_Group_Send_Out = ""//发送||send out
        this.Mind_Group_Join_Successfully = ""//加入成功||Join successfully
        this.Mind_Group_Application_Sent_Successfully = ""//申请发送成功||Application sent successfully

        this.Mind_Group_Save_The_Mapping = ""//保存导图||Save the mapping
        this.Mind_Group_Update_Record = ""//修改记录||Update Record

        this.Mind_Edit_Page_Switch_Mapping = ""//切换导图||Switch mapping
        this.Mind_Edit_Page_Mind_Retrospect = ""//导图回顾||Mind retrospect
        this.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map = ""//已有用户在编辑导图||A user is already editing the map
        this.Mind_Edit_Page_Password = ""//密码||password

        this.Mind_Edit_Page_Sharing_Group = ""//共享组||Sharing group
        this.Mind_MBean = ""//M豆||M Beans
        this.Mind_My_MBean = ""//我的M豆||My M Beans
        this.Alipay = ""//支付宝||Alipay
        this.Alipay_Payment = ""//支付宝支付||Alipay payment
        this.Please_Pay_On_The_Payment_Page = ""//请到支付页面支付||Alipay Please pay on the payment page
        this.WeChat = ""//微信||WeChat
        
        this.Mind_Shooping_Total= ""//合计||total
        this.Mind_Shooping_Confirm_Payment= ""//确认支付||Confirm payment
        this.Mind_Shooping_Redeem_Now= ""//立即兑换||Redeem now
        this.Mind_Confirm_Use_Coupons = ""//确认使用优惠券||Confirm the use of coupons

        this.Mind_Whether_Confirm_Payment_Purchase = ""//是否确认支付购买||Are you sure you want to pay
        this.Mind_Tips = ""//提示||Tips
        this.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean = ""//您的M豆不足，是否去购买M豆||You don't have enough m beans. Do you want to buy m beans

        this.Mind_share_scan_code = ""//扫码||Scan code


        this.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played = ""//是否恢复到上次播放的地方||Do you want to go back to where it was last played?
        this.Mind_Play_Number_Of_Playable_Times_Remaining = ""//剩余可播放次数||Number of playable times remaining

        this.Group_Mind_Play_The_File_Will_Not_Exist = "" //个文件将不存在，确认解散吗||After dissolving the group, 35 files in the group will not exist. Are you sure you want to dissolve
        this.Group_Mind_Up_One_Level = ""//上一级||Up one level
        this.Group_Mind_No_Documents = ""//暂无文件||No documents
        this.Group_Mind_PaymentSuccessful = ""//支付成功||Payment successful

        this.Mind_Edit_Page_Sharing_Group_introduce = ""//人协同编辑，多端即时同步，共享文档，提升团队协作效率||Human collaborative editing, multi terminal real-time synchronization, document sharing, improve team collaboration efficiency
        this.Mind_Edit_Subscript = ""//下标||subscript
        this.Mind_Edit_Text_Location = ""//文本位置||Text location
        this.Mind_Mind_Import_Into_A_Shared_Group = ""//导入到共享组||Import into a shared group

        this.Standard_Colors = ""//标准颜色||Standard colors
        this.More_Colors = ""//更多颜色||More colors

        this.Reset_Password = ""//重置密码||reset password||パスワードをリセットします
        this.Account_Password_Login = ""//账号密码登录||Account password login||アカウントパスワードログイン
        this.Authentication_code_Login_registration = ""//验证码登录/注册||Authentication code login / registration||確認コードログイン/登録
        this.One_End_Creation_Multi_End_Synchronization = ""//一端创作 多端同步||One end creation, multi end synchronization||一方の端はマルチ端同期を作成します
        this.Rich_And_Diverse_Mapping_Structure = ""//丰富多样导图结构||Rich and diverse mapping structure||豊富で多様なガイド構造
        this.Massive_And_ExquisiteTemplateLibrary = ""//海量精美模板库||Massive and exquisite template library||美しいテンプレートのライブラリのボリューム
        this.Map_Review_Play = ""//导图回顾-播放||Map review - play||ガイドチャートのレビュー - 再生
        this.CommunityLearningAndSharingPlatform = ""//知识学习与分享交流平台||Community learning and sharing platform||知識学習と共有のためのプラットフォーム

        this.Mind_Mind_Upgrade_Storage_Space = ""//升级存储空间||Upgrade storage space||ストレージをアップグレードします
        this.Mind_Mind_Let_Me_Comment_Too = ""//我也来评论一下吧||Let me comment, too||私もコメントします
        this.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time = ""//暂无消息通知||No message notifications are announced at this time||現時点では、メッセージ通知はありません

        this.Search_Pagination = ""//搜索分页||Search Pagination ||ページを検索
        this.Please_Enter_A_Paging_Name = ""//请输入分页名||Please enter a paging name ||改ページ名を入力してください
        this.Failed_To_Get_Recording_Device = ""//获取录音设备失败||Failed to get recording device
        this.Operation_Settings = ""//操作设置||Operation settings
        this.Shortcut_key_Settings = ""//快捷键设置||Shortcut key settings
        this.Press_Key_Combination = ""//请按下需要的组合键||Please press the key combination you want
        this.Mind_Reduction = ""//还原||reduction
        this.Double_Click_Add_Free_Node = ""//双击空白处增加自由节点||Double click the blank space to add a free node
        this.Shortcut_Conflict = ""//快捷键冲突||Shortcut conflict
        this.Select_Main_Node = ""//选择主节点||Select the main node
        this.Telephone = ""//电话||Telephone
        this.Microblog = ""//微博||Microblog
        this.Address = ""//地址||Address
        this.Company_Address= ""//公司地址
        this.Mind_Archive= ""//归档||archive
        this.Mind_Bookshelf= ""//书架||bookshelf
        this.Search_Archive= ""//搜索书架||Search bookshelf
        this.Modify_Archive_Order= ""//修改书架顺序||Modify bookcase order
        this.Modification_Order= ""//修改顺序||Modification order
        this.Please_Enter_Archive_Name= ""//请输入书架名称||Please enter the bookshelf name
        this.Message_Tips_Archive_Name_No_Null= ""//书架名称不能为空||Bookshelf name cannot be empty
        this.Mind_Edit_Delete_Archive_Tips= ""//确认删除该书架？||Are you sure you want to delete the bookshelf?
        this.Mind_Edit_Delete_Bookcase_Tips= ""//确认删除该书夹？||Are you sure you want to delete the bookcase?
        this.Mind_Edit_Delete_Map_Tips= ""//确认删除该导图？||Are you sure you want to delete the map?
        this.Mind_Edit_Confirm= ""//确认||confirm
        this.Mind_Import_Map= ""//导入导图||Import map
        this.Mind_Bookcase= ""//书夹||Bookcase
        this.Added_Successfully= ""//添加成功||Added successfully
        this.Message_Tips_Bookcase_Name_No_Null= ""//书夹名称不能为空||Folder name cannot be empty
        this.Share_Group_To_Storm= ""//是否分享组到风暴||Share group to storm
        this.Apply_To_Join_Storm_Platform= ""//在风暴平台，其他人看到并申请加入，和有共同知识爱好者一起头脑风暴||On the storm platform, others see and apply to join, brainstorming with fans with common knowledge
        this.Pay_To_Goin= ""//付费加入||Pay to join
        this.Pay_To_Goin_Need_Mbeans= ""//加入时需要支付M豆||You need to pay m beans when you join
        this.Mbean_Can_Be_Realized= ""//M豆可变现||M Beans can be realized


        this.Mind_Visit= ""//访问||visit
        this.Mind_Details= ""//详情||details
        this.Mind_See= ""//查看||see
        this.Earn_Mbeans= ""//赚取M豆||Earn m beans
        this.Mind_Explain= ""//说明||explain
        this.Mind_Parenting= ""//亲子||Parenting
        this.Mind_Internet= ""//互联网||internet
        this.Mind_Psychology= ""//心理学||Psychology
        this.Mind_Literature= ""//文学||literature
        this.Mind_Technology= ""//科技||Technology
        this.Mind_Workplace= ""//职场||Workplace
        this.Mind_Life= ""//生活||life
        this.Mind_Subject= ""//学科||subject
        this.Hot_Group= ""//热门组||Hot group
        this.Open_Group= ""//公开组||Open group



        this.Mind_Association_Map= ""//关联导图||association map
        this.Mind_confirm_Association_Map= ""//确定关联该导图？||Are you sure to associate this map?
        this.Mind_Association_Map_See= ""//查看关联导图||View Association map
        this.Mind_Association_Map_Delete= ""//删除关联导图||Delete associated map


        
        this.Mind_Edit_Right_Menu_Layout_branch = ""//分支结构
        this.Mind_Edit_Right_Menu_Line_branch = ""//分支线条
        this.Mind_Edit_Right_Menu_Correlation_Line = ""//关联线
        this.Mind_Edit_Right_Menu_Correlation_Line_Start = ""//关联线起点
        this.Mind_Edit_Right_Menu_Correlation_Line_End = ""//关联线终点

        this.Mind_Group_Used = ""//已使用
        this.Mind_Click_Select = ""//单击选中
        this.Mind_Double_Click_Enter = ""//双击进入
        this.Search_Shared_Groups = ""//搜索共享组
        this.Search_Storm = ""//搜索风暴
        this.Members_Save_Free = ""//会员免费保存
        this.Bank_Card_Account_Name = ""//请输入您的银行卡户名
        this.Bank_Card_No = ""//银行卡号
        this.Bank_Card_Account_Name = ""//银行卡号
        this.Please_Enter_Your_Bank_Card_No = ""//请输入您的银行卡号
        this.Branch_Sub_Branch_Information = ""//分行支行信息
        this.Branch_Sub_Branch_Example = ""//例如：杭州分行城西支行
        this.Application_For_Withdrawal = ""//申请提现
        this.Withdrawal_Amount = ""//提现金额
        this.Withdrawal = ""//提现
        this.Current_Revenue = ""//当前收益
        this.Recharge = ""//充值
        this.Current_Total_MBeans = ""//当前M豆总数
        this.Withdrawal_Status = ""//提现状态
        this.Insufficient_Withdrawal_Amount = ""//提现金额不足
        this.Load_More = ""//加载更多
        this.Loading = ""//加载中
        this.It_Is_Over = ""//已经到底了~
        this.Default_Label = ""//默认标签
        this.Selected_Label = ""//已选标签
        this.Current_Location = ""//当前位置
        this.MGold_Introduction = ""
        this.MGold_Details = ""
        this.Cloud_Group_Introduction = ""
        this.Cloud_Group_Details = ""
        this.Share_Storm_Introduction = ""
        this.Share_Storm_Details = ""

        this.Linked_Successfully = ""//关联成功||Linked successfully
        this.Cancel_Archiving = ""//取消归档
        this.Please_Select_Archive_Location = ""//请选择归档位置
        this.The_Search_Result_Is_Empty = ""//搜索结果为空

        this.Customize_A_Style = ""//非会员，只能创建一个风格
        this.Adds_Current_Guide_Style_Library = ""
        this.Trial_Version = ""//试用版
        this.Not_Supported_At_The_Moment = ""//暂不支持
        this.Not_Supported_At_The_Moment_This_Type_Xmind_File = ""//暂不支持此版本，请导入最新版本xmind文件
        this.Load_File_Error = "" //很抱歉，文件解析出错！
        this.Mind_File = ""//文件
        this.Mind_Import = ""//导入
        this.Importing = ""//导入中

        this.Mind_Edit_Insert_Video = ""//插入视频||
        this.Mind_Edit_Drag_And_Drop_The_Video_Here = ""//拖动视频至此
        this.Message_Tips_Video_Upload_Time_Limit = ""//上传视频时长不能超过60s
        this.Mind_Edit_Node_Menu_View_Video = ""//查看视屏
        this.Mind_Edit_Node_Delete_View_Video = ""//删除视频
        this.Message_Tips_Share_Video_Does_Not_Exist = "" //上传文件不能超过10MB!
        this.Mind_Upload_Video_Restrictions = ""//仅支持上传MP4格式的视频,上限120s和10M大小
        this.Mind_Video_Size = ""//视频大小
        this.Mind_Edit_Left_Menu_Export_Word = ""//导出Word
        this.Mind_Edit_Left_Menu_Export_TXT = ""//导出TXT
        this.Export_Failed = ""//导出失败
        this.Add_File = ""//添加文件
        this.Drag_The_File_Directly_To_Upload = ""//直接拖入文件，即可上传
        this.Supported_Imported_File_Types = ""//目前支持导入的文件类型：Word(仅支持.docx)、XMind、.txt
        this.No_Permission_Do_This = ""//您没有权限执行此操作

        this.Upgrade_To_Professional = ""//升级到专业版
        this.Non_Members_Insert_Videos = ""//非会员注册24小时后才可插入视频
        this.Upload_Failed = ""//上传失败
        this.Upload_Failed_Title_Message = ""//上传失败请点击确认，删除上传失败的遗留图片
        this.Mind_Edit_Members_Use = ""//会员使用
        this.Invitation_Code = ""//邀请码
        this.Student_Certification = ""//学生认证
        this.I_Get_It = ""//我会了
        this.Welcome_To_Mind = ""//欢迎小主加入Mind+,让我们开启思维之旅吧！
        this.Mind_Before = ""//先
        this.Try_A_Map = ""//一个导图试试看
        this.Click_Create = ""//点击创建
        this.Click_Create_A_Mind_Map = ""//点击创建一张思维导图
        this.Mind_About = ""//关于
        this.See_Checking_Data = ""//查看个人信息
        this.Modify_Checking_Data = ""//修改个人信息
        this.Mind_Shear = ""//剪切

        this.Mind_Style_Empty = ""//未收藏或自定义风格
        this.Mind_Branch_Free_Layout = ""//分支自由布局
        this.Mind_Branch_Free_Layout_explain = ""//双向结构和气泡图分支可以自由拖动布局
        this.Mind_Free_Theme_Drag_Dnd_Snap = ""//自由主题拖动吸附
        this.Select_A_Node = ""//选中一个主题
        this.Create_Child_Node = ""//创建子主题
        this.Add_A_Nice_Image_To_The_Node = ""//接下来我们给子主题添加一个好看的
        this.Click_Icon_Panel = ""//点击图标面板
        this.Select_An_Icon_You_Like = ""//选择一个你喜欢的图标
        this.Replace_The_Mind_Map_With_A_New_One = ""//接下来我们给思维导图更换一个新的
        this.Select_The_Central_Theme = ""//选中中心主题
        this.Switch_To_Canvas_Panel = ""//切换到画布面板
        this.Click_Layout = ""//点击布局
        this.Choose_A_Layout_You_Like = ""//选择一个你喜欢的布局
        this.Mind_Map_Save_Share = ""//恭喜你已经成功做出了一张漂亮的思维导图！我们把它保存下来分享给小伙伴吧~
        this.Click_Save_Picture = ""//点击保存图片
        this.Click_OK = ""//点击确定
        this.Congratulations_On_Completing_The_Novice_Guide = ""//恭喜你完成了新手引导
        this.Upload_Succeeded = ""//上传成功

        this.Mind_Edit_Node_Menu_Quick_Style = ""//快速样式
        this.Mind_Extremely_Important = ""//极其重要
        this.Mind_Important = ""//重要
        this.Mind_Commonly = ""//一般
        this.Mind_Ordinary = ""//普通

        this.Mind_Association_Node = ""//关联节点
        this.Member_Exclusive = ""//该功能为会员专享，是否去升级会员？
        this.Member_Exclusive_Color_Scheme = ""//该配色方案为会员专享，是否去升级会员？
        this.Mind_Enlarge = ""//放大
        this.Mind_Narrow = ""//缩小
        this.Mind_Center = ""//居中
        this.Mind_Full_Screen = ""//全屏

        this.Mind_Version_Number = ""//版本号
        this.Mind_Copy_Rights = ""//Copyright ©2021上海玉数科技有限公司版权所有

        this.Global_Text_Find = ""//查找
        this.Global_Text_Replace = ""//替换
        this.Global_Text_Replace_All = ""//全部替换
        this.Global_Text_Find_Empty = ""//无内容
        this.Global_Text_Find_Result = ""//第&项, 共@项
        this.Global_Edit_Task = ""//编辑任务
        this.Global_Detele_Task = ""//删除任务
        this.Mind_Extremely_Urgent = ""//非常紧急
        this.Mind_Urgent = ""//紧急
        this.Mind_Lower = ""//较低
        this.Mind_Task_Information = ""//任务信息
        this.Mind_Start_Date = ""//开始日期
        this.Mind_End_Date = ""//结束日期
        this.Mind_person_Charge = ""//负责人
        this.Mind_colse = ""//关闭
        this.Mind_Enter_Names_Separated_By_Commas = ""//输入姓名,多个用逗号隔开
        this.Mind_Please_Select_An_Start_Date = ""//请选择开始日期
        this.Mind_Please_Select_An_End_Date = ""//请选择结束日期
        this.Mind_The_Task_Leader_Cannot_Be_Empty = ""//任务负责人不能为空
        this.Mind_The_End_Time_Less_Start_Time = ""//结束时间不能小于开始时间
        this.Mind_Are_You_Sure_To_Delete_This_Task = ""//是否确定删除本次任务
        this.Mind_Start_Later = ""//开始
        this.Mind_Over = ""//已结束
        this.Mind_In_Progress_Remaining = ""//进行中剩余
        this.Mind_In_Progress_Time = ""//进行中用时
        this.Mind_level = ""//完成度
        this.Mind_Completed = ""//已完成
        this.Display_Mode = ""//显示模式
        this.Default_Sort = ""//默认排序
        this.Edit_Time = ""//编辑时间

        this.Mind_Year = "" //年
        this.Mind_Month = ""//月
        this.Mind_Day = ""//日
        this.Mind_Day_Day = ""//天
        this.Mind_Hours = ""//小时
        this.Mind_Minutes = ""//分钟

        this.Mind_SubjectLine_Space = ""//分支间距"
        this.Mind_SonSubjectLine_Space = ""//子分支间距"
        this.Mind_SubjectLine_H = ""//分支横向间距
        this.Mind_SubjectLine_Vertical_Space = ""//分支竖向间距
        this.Mind_SonSubjectLine_H = ""//子分支横向间距
        this.Mind_SonSubjectLine_Vertical_Space = ""//子分支竖向间距

        this.Current_Paging = ""//当前分页
        this.Select_Paging = ""//选择分页
        this.Batch_Delete = ""//批量删除
        this.New_Version_Online = ""//新版本上线
        this.Upgrade_Now = ""//立即升级
        this.install_Later = ""//稍后安装

        this.Mind_Edit_Latex = ""//编辑Latex
        this.Mind_Detele_Latex = ""//删除Latex
        this.Mind_Are_You_Sure_To_Delete_This_LaTeX = ""//是否确定删除LaTeX公式
        this.Mind_LaTeX_Expression = ""//LaTeX表达式
        this.Mind_LaTeX_Formula_Display_Area = ""//公式展示区

        this.Mind_LaTeX_Add_Template = ""//添加到模板
        this.Mind_LaTeX_Common_Template = ""//常用模板
        this.Mind_LaTeX_Used_Help_1 = ""//LaTeX功能分为四大模块,分别为
        this.Mind_LaTeX_Used_Help_1_1 = ""//公式区域-根据表达式生成对应公式图形
        this.Mind_LaTeX_Used_Help_1_2 = ""//表达式区域-输入LaTex表达式
        this.Mind_LaTeX_Used_Help_1_3 = ""//符号区域-常用各领域符号对应的字符串值
        this.Mind_LaTeX_Used_Help_1_4 = ""//常用模板-一些默认公式,可以通过'添加到模板'自定义公式,鼠标移上可删除指定的自定义公式
        this.Mind_LaTeX_Used_Help_2 = ""//必须在正确的LaTeX的语法规则下才能生成公式图形以及保存使用
        this.Mind_LaTeX_Used_Help_3 = ""//如何修改颜色?
        this.Mind_LaTeX_Used_Help_3_1 = ""//选中指定的文字区域点击风格符中的颜色即可修改文字颜色,
        this.Mind_LaTeX_Used_Help_3_2 = ""//例如对 x=a+b中的a+b进行着色,只需要选中a+b点击风格符中的颜色块,
        this.Mind_LaTeX_Used_Help_3_3 = ""//此时表达式会变成x={\color{#FF0000}a}将a改为a+b着色
        this.Mind_LaTeX_Quick_Title_2 = ""//希腊字母
        this.Mind_LaTeX_Quick_Title_3 = ""//数学
        this.Mind_LaTeX_Quick_Title_4 = ""//风格符
        this.Mind_LaTeX_Quick_Title_5 = ""//分隔符
        this.Mind_LaTeX_Quick_Title_6 = ""//可变符
        this.Mind_LaTeX_Quick_Title_7 = ""//函数
        this.Mind_LaTeX_Quick_Title_8 = ""//关系符
        this.Mind_LaTeX_Quick_Title_9 = ""//箭头符
        this.Mind_LaTeX_Quick_Title_10 = ""//杂项符
        this.Mind_LaTeX_Quick_Title_11 = ""//重音符
        this.Mind_LaTeX_Error = ""//latex公式错误，请确保公式正确
        this.Line_Dashed_Line = ""//线条虚线
        this.Border_Dashed_Line = ""//边框虚线
        this.Rainbow_Color = ""//彩虹色
        this.Mind_Beginning = ""//始
        this.Mind_End = ""//末
        this.Mind_Arrow = ""//箭头
        this.Get_Verification_Code = ""//获取验证码
        this.Mailbox_Login = ""//邮箱登录
        this.Forget_Email_Password = ""//忘记邮箱密码
        this.Mind_No_Account = ""//没有账号
        this.Mind_To_Register = ""//去注册
        this.Reset_Mailbox_Password = ""//重置邮箱密码
        this.Mailbox_Registration = ""//邮箱注册
        this.Account_Security = ""//账号安全
        this.Cell_Phone_Number = ""//手机号
        this.Mind_Replace = ""//更换
        this.Mind_Unbound = ""//未绑定
        this.Mind_Debinding = ""//去绑定
        this.Change_Password = ""//修改密码
        this.Mind_Not_Set = ""//未设置
        this.Logged_Device = ""//已登录设备
        this.Current_Equipment = ""//当前设备
        this.Change_Mobile_Phone_Number = ""//更换手机号
        this.Bind_Mobile_Phone_Number = ""//绑定手机号
        this.Replace_Mailbox = ""//更换邮箱
        this.Bind_Mailbox = ""//绑定邮箱
        this.Bind_Mobile_Number_Or_Email_Address = ""//请绑定手机号或邮箱

        this.Mind_Resource_See= ""//查看附件
        this.Mind_Resource_Delete= ""//删除附件
        this.Mind_add_Resource= ""//添加附件
        this.Mind_Float_Explain = ""//注释
        this.Mind_Edit_Drag_And_Drop_The_explain_Here = ""//拖放文件至此
        this.Mind_Edit_choose_explain = ""//选择文件
        this.Message_Tips_Share_Explain_Does_Not_Exist = ""//上传文件不能超过10M
        this.Modification_Time = ""//修改时间
        this.Header_Mind_Tips_Saving = ""//保存中
        this.Mind_Not_Edited = ""//未编辑,请编辑之后保存

        this.Keep_Original_Style_Request = ""//是否保留原有样式?
        this.Keep_Original_Style = ""//保留原有
        this.Keep_FollowCurrent = ""//跟随当前
        this.Export_picture_margins_from_map = ""//导图中导出图片边距
        this.Mind_Edit_Right_Menu_text = ""//文本
        this.Title_Location = ""//标题位置
        this.Wrapping_Line_Padding = ""//内边距
        this.Mind_Edit_Menu_Custom_Width = ""//自定义宽度
        this.Mind_Node_Overlay_Paste = ""//覆盖粘贴
        this.Mind_Expression = ""//表情
        this.Mind_Flag = ""//旗帜
        this.Mind_Portrait = ""//人像
        this.Mind_Learning = ""//学术
        this.Mind_Social_Contact = ""//社交
        this.Mind_Symbol = ""//符号
        this.Mind_Month_Icon = ""//月份
        this.Mind_Skeleton = ""//骨架

        this.Mind_Confirm_Deleted = ""//确定要删除吗
        this.Mind_Folder_Has = ""//当前文件夹有
        this.Mind_Sub_File = ""//个子文件
        this.Not_Joined_Any_Group = ""//你还没有加入任何共享组
        this.Mind_Set_Password = ""//设置密码
        this.Mind_Stretching = ""//拉伸
        this.Mind_Equal_Ratio = ""//等比
        this.Mind_Insert_Background = ""//插入背景
        this.Mind_Size_Fit = ""//尺寸适配
        this.Mind_Watermark_Settings = ""//水印设置
        this.Mind_Simplification_Watermark = ""//寻简水印
        this.Mind_Custom_Watermark = ""//自定义水印
        this.keep_Custom_Part_Style_Unchanged = ""//是否保持自定义部分样式不改变？
        this.Mind_Cover = ""//覆盖
        this.Mind_Keep_Custom = ""//保留自定义
        this.Mind_Adding_Child_Nodes_Not_Allowed = ""//注释不允许添加子节点
        this.Mind_Information_Has_Expired = ""//信息已过期请重新登录
        this.Mind_Fail = ""//失败
        this.Mind_Scan_Creation = ""//扫描创建
        this.Mind_Scan_Extraction = ""//扫描提取
        this.Recharge_Members_Can_Get_More_Use_Times = ""//充值会员可获得更多使用次数
        this.Mind_Go_Now = ""//立即前往
        this.Mind_Available_Times = ""//可用次数
        this.Mind_Edit_Title = ""//标题
        this.Supported_Scan_File_Types = ""//目前支持扫描的文件类型

        this.Please_Enter_Mind_Password = "请输入4～8位密码"
        this.Please_Enter_Mind_Password_For_Unlock = "请输入密码"
        this.Please_Enter_Mind_Password_Member_Tips = "此功能是会员功能"
        this.Please_Enter_Mind_Password_Remove_Tips = "已经移除文件密码"
        this.Please_Enter_Mind_Password_Length_Short = "密码位数不够，请输入4倒8位密码"
        this.Please_Enter_Mind_Password_Length_Long = "密码太长，请输入4倒8位密码"
        this.Please_Enter_Mind_Password_Success = "密码设置成功"
        this.Please_Enter_Mind_Password_Format_Error = "密码格式错误"
        this.Mind_Load_Data_Error = "数据加载出错"
        this.Please_Enter_Mind_Password_Error = "密码错误"
        this.Please_Enter_Mind_Password_For_Owner = "只有该导图创建者和管理员可以设置密码"

        this.Mind_Extremely_DrawOblique = "纹理"
        this.Mind_Extremely_Gradient = "渐变"
        this.Mind_Extremely_Shadow = "阴影"

        this.Mind_Edit_Left_Menu_Export_Save_Cloud = "云同步"
        this.Mind_Edit_Left_Menu_Export_No_Save_Cloud = "未同步"
        this.Mind_Edit_Left_Menu_Export_Save_As = "另存为"
        this.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As = "共享组不支持另存为， 可以先讲文件保存到【最近创作】，再保存到本地"
        this.Create_Mind_Storage_Mode = "云保存"
        this.Node_Shadow_Label = "Shadow Effect"
        
        this.Mind_Style_Tight_Mode_Label = "紧促型布局"
        this.Mind_Style_Same_Level_Alignment_Label = "同级主题对齐"
        this.Mind_Style_Node_Overlap = "主题层叠"
        this.Mind_Style_To_Peer = "使用到同级"
        this.Mind_Style_To_All_Son_Subject = "使用到所有子主题"
        this.Mind_Edit_Node_Menu_Save_Picture = "Save Picture As"
        this.Mind_Edit_Order_Label = 'Order'
        this.Mind_Edit_Order_1_Label = '1, 2, 3, ...'
        this.Mind_Edit_Order_2_Label = 'Ⅰ, Ⅱ, Ⅲ, ...'
        this.Mind_Edit_Order_3_Label = 'ⅰ, ⅱ, ⅲ, ...'
        this.Mind_Edit_Order_4_Label = 'A, B, C, ...'
        this.Mind_Edit_Order_5_Label = 'a, b, c, ...'
        this.Mind_Edit_Order_6_Label = '(1),(2),(3), ...'
        this.Mind_Edit_Order_7_Label = '①, ②, ③, ...'
        this.Mind_Edit_Order_8_Label = '一, 二, 三, ...'
        this.Mind_Edit_Order_Level_1_Label = '1 Level'
        this.Mind_Edit_Order_Level_2_Label = '2 Level'
        this.Mind_Edit_Order_Level_3_Label = '3 Level'
        this.Mind_Edit_Order_Level_4_Label = '4 Level'
        this.Mind_Edit_Order_Remove_Label = 'None'

        this.Mind_Edit_Open_Hidde_Title = '显示层级数'
        this.Mind_Edit_Open_Hidde_1 = '显示1层'
        this.Mind_Edit_Open_Hidde_2 = '显示2层'
        this.Mind_Edit_Open_Hidde_3 = '显示3层'
        this.Mind_Edit_Open_Hidde_4 = '显示4层'
        this.Mind_Edit_Open_Hidde_5 = '显示5层'
        this.Mind_Edit_Open_Hidde_All = '显示所有层'

        this.Mindmap_Review_Play_Order_Peer = '背景比例'
        this.Mindmap_Only_Play_Text = '只播放文字'
        this.Mindmap_Review_Tab_Lable = "思维回顾模式"
        this.Mindmap_Speech_Tab_Lable = "演示模式"

        this.Mind_Edit_Node_Menu_Ai_Label = "Ai助手-GPT"
        this.Mind_Edit_Ai_Title = 'Ai助手'
        this.Mind_Ai_Empty_Tips_1_Label = '输入你想要的任何问题，Ai将给出结果'
        this.Mind_Ai_Empty_Tips_2_Label = '请合理文明使用Ai助手'
        this.Mind_Ai_To_Map_Label = '加入导图'
        this.Mind_Copy_Cuccess_Label = '成功复制'
        this.Mind_Ai_Input_Hint_Label = '请输入问题，Ai将给出结果...'
        this.Mind_Ai_Submit_Label = '提交'
        this.Mind_Ai_Exceeding_Limit_Label = '很抱歉，单日使用次数太多，请明天再使用'
        this.Mind_Ai_To_Senior_Member_Label = '今日使用次数已用完，永久会员可获得更多单日使用次数'
        this.Mind_Ai_To_Member_Label = '免费次数已用完，会员可获得更多单日使用次数'
        this.Mind_Ai_Wait_0_Label = 'AI加载中'
        this.Mind_Ai_Wait_1_Label = 'Ai正在收集信息'
        this.Mind_Ai_Wait_2_Label = 'Ai正在整理优化信息'
        this.Mind_Ai_Wait_3_Label = '请请耐心等待，你的问题比较专业'
        this.Mind_Ai_Wait_4_Label = '请您耐心等待'
        this.Mind_Ai_Wait_5_Label = '已经在返回的道路上'
        this.Mind_Ai_Submit_Content_Psuffix_Label = '请以步骤方式给我'
        this.Mind_Ai_Service_Upgraded_Label = 'The Ai service is being upgraded, please try again later'

        this.Mind_Node_To_Card_Label= "转化为卡片"
        this.My_Mind_Left_Right_Focus_Modol = "发散思维-头脑风暴模型"
        this.My_Mind_Left_Right_Free_Modol = "灵动-自由流线模型"
        this.My_Mind_Left_Right_Neat_Modol = "笔记、小说-梳理模型"
        this.My_Mind_Left_Right_Fresh_Modol = "全线条-清新模型"
        this.My_Mind_Tree_Left_Right_Modol = "[父子]层级关系模型"
        this.My_Mind_Left_Right_Outline_Modol = "便捷、快速、随手记"
        this.My_Mind_Fish_Modol = "因果分析模型"
        this.My_Mind_Bridge_Modol = "类比相似模型"
        this.My_Mind_Brackets_Right_Modol = "整体和局部[总分]关系模型"
        this.My_Mind_Radiate_Modol = "平行放射-关系聚合模型"
        this.My_Mind_Right_Modol = "右侧拓展分析模型"
        this.My_Mind_Left_Modol = "左侧拓展分析模型"
        this.My_Mind_Form_Modol = "对比、罗列分块分析模型"
        this.My_Mind_Horizontal_Right_Modol = "具有前后事件关系模型"
        this.My_Mind_Tree_Right_Modol = "竖屏、顺序性、全局性模型"
        this.My_Mind_Bottom_Modol = "组织架构、平行知识模型"
        this.My_Mind_Top_Modol = "事物分析模型"
        this.My_Mind_Bubble_Modol = "分析事物属性、关系模型"
        this.My_Mind_Circular_Modol = "分类、归纳模型"
        this.My_Mind_Double_Bubble_Modol = "对比共性和特性"
        this.My_Mind_Time_Vertical_Modol = "竖向先后顺序事件模型"
        this.My_Mind_Time_Horizontal_Modol = "横向先后顺序事件模型"
        this.My_Mind_Time_S_Horizontal_Modol = "大信息量、事件模型"
        this.My_Mind_Left_Right_Card_Modol = "卡片模型"
        this.Mind_Menu_Edit_Title = "编辑标题"
        this.Mind_Edit_Upgrade_Item = "Upgrade to Pro"

        this.Mind_Fast_Style_Rainbow_Label = "活力"
        this.Mind_Fast_Style_Solid_Label = "纯真"
        this.Mind_Import_File = "导入文件"

        this.Mind_Add_Font_Label = "+ 添加本地字体:ttf、woff..."
        this.Mind_Import_Font_Web_Tips = "请到官网下载电脑客户端，使用该功能，https://mindyushu.com"
        this.Mind_Import_Font_Upgrade_Label = "免费试用版只能添加1个外部字体，升级到Pro用户，可添加更多外部字体以及所有专业版功能。"
        this.Mind_Flow_Chart_Map = "流程图"
        this.Mind_Shape_Corner_Text = "流程"
        this.Mind_Shape_Start_Text = "开始"
        this.Mind_Shape_End_Text = "结束"
        this.Mind_Shape_Determine_Text = "判定"
        this.Mind_Shape_Ellipse_3D_Text = "数据库"
        this.Mind_Shape_Cube_Text = "科学"
        this.Mind_Shape_Form_Text = "文档"
        this.Mind_Shape_Corner_Right_Text = "卡片"
        this.Mind_Shape_Book_Text = "书本"
        this.Mind_Shape_Text = "文本"

        this.Mind_Map_Picture_Paddinng_H_Text = "横行边距"
        this.Mind_Map_Picture_Paddinng_V_Text = "竖向边距"

        this.Mind_Map_Create_Map_Info_Text = "选择多种思维结构框架"
        this.Mind_Map_Create_File_Info_Text = "快捷管理你的思维导图"
        this.Mind_Map_Open_File_Info_Text = "打开你设备上的文件"
        
        this.Mind_Edit_Circle_Radius = "半径长度"
        this.Mind_Edit_Left_Right_Spacing = "左右间距"
        this.Mind_Edit_Up_Down_Spacing = "上下间距"
        this.Mind_Edit_Numbe_Columns = "列数"

        this.Mind_Calendar_Input_Tip = "输入内容，按Enter键确认"
        this.Mind_Calendar_Tip = "日历组件，可以直观查看过去每一天的编辑情况，绿色：创建和最后修改在同一天；红色：表示创建日期；蓝色：表示最后修改日期；"

        this.makeUpTheSerialNumber()
    }
    makeUpTheSerialNumber() {
        let count = 1;
        for (let key in this) {
            this[key] = count++;
        }

        // this.setTranslateStringCode()
    }

    setTranslateStringCode() {
        let cnString = '';
        let enString = '';
        let nameString = '';
        let jaString = '';
        //去除首尾空格
        String.prototype.trimThree = function () {
            return this.replace(/^\s+|\s+$/g, '')
        };
        let str = `
         //存储使用情况||Storage usage||ストレージの使用量
         //
         `
        // console.log(str);
        let strArr = str.split('//');
        // console.log(strArr);
        let strArrLen = strArr.length;
        let objArr = []
        for(let index = 0; index < strArrLen; index++) {
            let obj = {};
            let arr = strArr[index].split('||');
            if (arr[1] == null) {   //没有切割的不处理了。
                continue
            }
            obj.cn = arr[0]; //汉字
            arr[1] = arr[1].trimThree() //去除首尾字符串
            obj.en = arr[1].replace(arr[1][0],arr[1][0].toUpperCase()); //英文，英文首字母应该大写。
            obj.ja = ''
            if (arr[2] != null) {
                obj.ja = arr[2].trimThree()
            }
            obj.enArr = arr[1].split(' ') //英文单词数组
            let enArrLen = obj.enArr.length;
            for(let j = 0; j < enArrLen; j++) { 
                let val = obj.enArr[j]
                val = val.replace(/\W+/gi,'') //去除每个单词中的符号
                val = val.replace(val[0],val[0].toUpperCase())//让每个单词的首字母变为大写
                // console.log('val',val)
                obj.enArr[j] = val;
            }
            
            obj.Name = 'Mind_Mind_' + obj.enArr.join('_'); //名称前缀
            obj.EnStr = 'stringMap[strings.' + obj.Name + '] = "' + obj.en + '"'
            obj.CnStr = 'stringMap[strings.' + obj.Name + '] = "' + obj.cn + '"'
            obj.JaStr = 'stringMap[strings.' + obj.Name + '] = "' + obj.ja + '"'
            // objArr.push(obj);
            //换行
            cnString += obj.CnStr + '\n' ;
            enString += obj.EnStr + '\n';
            jaString += obj.JaStr + '\n';
            nameString += 'this.' + obj.Name + ' = ""' + '//' + strArr[index].trimThree() + '\n';  //这里必须写等于"",否则一会循环的时候为undefined不会赋值。
        }
        // console.log(objArr);
        // console.log('cnString',cnString);
        // console.log('enString',enString);
        // console.log('jaString',jaString);
        // console.log('nameString',nameString);
    }

}
export default new Strings()
