const { default: strings } = require("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Mind + Mind Map';
stringMap [strings.TEMPLATE] = 'แม่แบบ';
stringMap [strings.Mind_Edit_Main_Topic] = 'หัวข้อสาขา';
stringMap [strings.Global_Add_Free_Node_Tips] = 'คลิกบนหน้าจอเพื่อเลือกตำแหน่งที่จะเพิ่ม';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'ดับเบิลคลิกที่หน้าจอเพื่อเลือกตำแหน่งที่จะเพิ่ม';
stringMap [strings.Global_Add_Relationship_Tips] = 'เลือกเป้าหมายที่ต้องการเชื่อมโยง';
stringMap [strings.Global_Add_Target_Tips] = 'โปรดเลือกโหนดเพื่อเพิ่มวัตถุ';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'วัตถุนี้เชื่อมโยงกับแผนที่อื่นแล้ว';
stringMap [strings.Mind_Edit_Main_Idea] = 'ธีมกลาง';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'ไม่มีแผนที่ที่เกี่ยวข้อง';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'ลำดับความสำคัญ';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'ความคืบหน้า';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'ดาว';
stringMap [strings.Mind_Style_Add_Icon_Week] = 'สัปดาห์';
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'แนวตั้ง';
stringMap [strings.Establish] = 'สร้าง';
stringMap [strings.Create_From_Template] = 'สร้างจากเทมเพลต';
stringMap [strings.Common] = 'ทั่วไป';
stringMap [strings.Function_List] = 'รายการฟังก์ชัน';
stringMap [strings.Recent_Updates] = 'อัปเดตล่าสุด';
stringMap [strings.The_Latest_To_Delete] = 'ลบล่าสุด';
stringMap [strings.Setting_Up] = 'ติดต่อเรา';
stringMap [strings.Usinghelp] = 'ใช้วิธีใช้';
stringMap [strings.The_Download_Client] = 'ดาวน์โหลดไคลเอนต์ / เวอร์ชันมือถือ';
stringMap [strings.Global_Purchase] = 'ซื้อ';
stringMap [strings.Global_Login] = 'เข้าสู่ระบบ';
stringMap [strings.My_map] = 'แผนที่ของฉัน';
stringMap [strings.Create_Map] = 'สร้างแผนที่';
stringMap [strings.Storm] = 'พายุ';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Common \ nCharacteristic';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'ลักษณะเฉพาะ';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'ศูนย์กลางองค์กร';
stringMap [strings.Mind_Edit_Free_Topic] = 'หัวข้อฟรี';
stringMap [strings.Mind_Edit_Sub_Topic] = 'หัวข้อย่อย';
stringMap [strings.Mind_Organization_Main_Topic] = 'แผนก';
stringMap [strings.Mind_Edit_Main_Topic] = 'หัวข้อสาขา';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'การวางแผน';
stringMap [strings.Mind_Time_Edit_Head] = 'เวลา';
stringMap [strings.Mind_Time_Edit_Title] = 'แผน';
stringMap [strings.Mind_Edit_Details] = 'คำอธิบายโดยละเอียด';
stringMap [strings.Checking_Data] = 'กำลังตรวจสอบข้อมูล';
stringMap [strings.Setting_Information] = 'ข้อมูลการตั้งค่า';
stringMap [strings.Sign_Out] = 'ออกจากระบบ';

// รายการฟังก์ชัน
stringMap [strings.Create_a_Mind_Map] = 'สร้างแผนที่ความคิด';
stringMap [strings.Create_Mind_Map_1] = '[1] สร้างแผนที่ที่มีเลย์เอาต์สิบสี่แบบ (โครงสร้างสองทาง / ขวา / ซ้าย / องค์กร / แผนภูมิฟองเดียว / แผนภูมิฟองคู่ / แผนผังต้นไม้ด้านซ้าย / แผนผังต้นไม้ด้านขวา / แผนภาพต้นไม้สองทาง / table / ไทม์ไลน์แนวนอน / ไทม์ไลน์แนวตั้ง / ไทม์ไลน์ S / พีระมิด) ';
stringMap [strings.Create_Mind_Map_2] = '[2] สามารถใช้เทมเพลตเพื่อสร้างแผนที่ได้อย่างรวดเร็ว (การอ้างอิงเนื้อหาเทมเพลตจำนวนมาก)';
stringMap [strings.Create_Mind_Map_3] = '[3] แก้ไขแผนที่ด้วยรูปแบบเส้นเก้าประเภท';
stringMap [strings.Create_Mind_Map_4] = '[4] ทำการเปลี่ยนแปลงเค้าโครงเก้ารายการและแก้ไขแผนที่';
stringMap [strings.Create_Mind_Map_5] = '[5] เพิ่มโหนดลูกไปยังโหนดเป้าหมายและเพิ่มโหนดในระดับเดียวกัน (บนและล่าง)';
stringMap [strings.Create_Mind_Map_6] = '[6] สรุป: เพิ่ม [สรุป] เพื่ออธิบายโหนดเดียว / หลายโหนด';
stringMap [strings.Create_Mind_Map_7] = '[7] Block: เพิ่ม [block] ให้กับโหนดเดียว / หลายกล่องโหนดของแผนที่ที่จะโมดูลาร์';
stringMap [strings.Create_Mind_Map_8] = '[8] เพิ่มโหนดว่าง';
stringMap [strings.Create_Mind_Map_9] = '[9] สร้างการเชื่อมโยงของรูปแบบสี่บรรทัดระหว่างโหนดใด ๆ ';
stringMap [strings.Create_Mind_Map_10] = '[10] แทรกรูปภาพ (โลคัล + ไลบรารีวัสดุ), ไอคอน, โน้ต, ลิงก์ไปยังโหนด';
stringMap [strings.Create_Mind_Map_11] = '[11] คัดลอก / วางโหนดคัดลอก / วางสไตล์';
stringMap [strings.Create_Mind_Map_12] = '[12] ยกเลิกเรียกคืนและยกเลิก';
stringMap [strings.Create_Mind_Map_13] = '[13] มีหลายตัวเลือกสามารถใช้งานได้หลายโหนดพร้อมกัน';
stringMap [strings.Create_Mind_Map_14] = '[14] สามารถแทรกกราฟสถิติได้';
stringMap [strings.Create_Mind_Map_15] = '[15] สามารถบันทึกแผนที่ทั้งหมดได้';
stringMap [strings.Create_Mind_Map_16] = '[16] คัดลอกแผนที่';
stringMap [strings.Create_Mind_Map_17] = '[17] คุณสามารถคัดลอกแผนที่หรือแผนที่ทั้งหมดไปยังแผนที่ของไฟล์อื่น ๆ ';
stringMap [strings.Create_Mind_Map_18] = '[18] สามารถสร้างแผนที่ได้หลังจากสรุป';
stringMap [strings.Create_Mind_Map_19] = '[19] การแก้ไขแผนที่สามารถเปลี่ยนได้ในระหว่างการแก้ไข (ด้านคอมพิวเตอร์)';
stringMap [strings.Create_Mind_Map_20] = '[20] แผนที่การแก้ไขแป้นพิมพ์บลูทู ธ : \ n \ t [Command] + [a] เลือกโหนดหลัก \ n \ t [Command] + [←] [↑] [→] [↓ ] เปลี่ยนเป้าหมายการเลือกหรือย้ายแผนที่ \ n \ t [Command] + [e] แก้ไขเป้าหมาย \ n \ t [Command] + [w] สร้างโหนดลูก \ n \ t [Command] + [s] สร้างโหนดพี่น้องเป้าหมาย ( ด้านบน) \ n \ t [Command] + [d] สร้างโหนดพี่น้องเป้าหมาย (ด้านล่าง) \ n \ t [Command] + [q] ออกจากเป้าหมายที่เลือก \ n \ t [Command] + [-] [+] ซูม แผนที่';

stringMap [strings. Beautify_The_Mind_Map] = 'ปรับแต่ง Mind Map';
stringMap [strings. Beautify_The_Mind_Map_1] = '[1] กำหนดสีพื้นหลังของแผนที่ความคิด';
stringMap [strings. Beautify_The_Mind_Map_2] = '[2] กำหนดความหนาและสีของเส้นแผนที่ทั้งหมดไม่ว่าจะเรียวหรือไม่';
stringMap [strings. beautify_The_Mind_Map_3] = '[3] ตั้งค่าสีเติมข้อความ, สีเส้นขอบ, เส้นขอบ 13 รูปร่าง, ความกว้างของเส้นขอบ, เอฟเฟกต์เงา';
stringMap [strings. Beautify_The_Mind_Map_4] = '[4] กำหนดสีเส้นและความกว้างของแผนที่ทั้งหมดหรือบางส่วน';
stringMap [strings. beautify_The_Mind_Map_5] = '[5] กำหนดขนาดฟอนต์ตัวอักษรตัวหนาตัวเอียงลบสไตล์';
stringMap [strings. Beautify_The_Mind_Map_6] = '[6] กำหนดขนาดภาพตำแหน่งและลักษณะรูปร่าง';
stringMap [strings. beautify_The_Mind_Map_7] = '[7] เพิ่มไอคอนเจ็ดหมวดหมู่ (ทั่วไป / 3D / การศึกษา / งาน / บุคคล / สัตว์ / อาหาร) และกำหนดขนาด / สถานที่';
stringMap [strings. Beautify_The_Mind_Map_8] = '[8] เพิ่มการอ้างอิงสไตล์คลิกเดียวให้กับแผนที่ทั้งหมด';
stringMap [strings. Beautify_The_Mind_Map_9] = '[9] แผนที่การจัดการกลุ่ม';
stringMap [strings. Beautify_The_Mind_Map_10] = '[10] พื้นหลังส่วนบุคคล (ไลบรารีวัสดุ) สามารถเพิ่มลงในแผนที่ทั้งหมดได้';
stringMap [strings. Beautify_The_Mind_Map_9] = '[11] สามารถเพิ่มเส้นขอบหรือพื้นหลังส่วนบุคคล (ไลบรารีวัสดุ) ลงในโหนดเดียวได้';
stringMap [strings. Beautify_The_Mind_Map_9] = '[12] สามารถลากความยาวของโหนดได้';


stringMap [strings.Map_Output] = 'แมปเอาท์พุท';
stringMap [strings.Map_Output_1] = '[1] บันทึกภาพ';
stringMap [strings.Map_Output_2] = '[2] บันทึก PDF';
stringMap [strings.Map_Output_3] = '[3] แชร์ลิงค์';
stringMap [strings.Map_Output_4] = '[4] แบ่งปันกับพายุ';
stringMap [strings.Map_Output_4] = '[5] พิมพ์';

stringMap [strings.Work_together] = 'ทำงานร่วมกัน';
stringMap [strings.Work_together_1] = '[1] สร้างกลุ่มที่แชร์ (ป้ายชื่อกลุ่มประกาศกลุ่ม)';
stringMap [strings.Work_together_2] = '[2] สมาชิกกลุ่มที่แชร์ใหม่';
stringMap [strings.Work_together_3] = '【 3 】สมาชิกหลายคนในกลุ่มแก้ไขแผนที่เดียวกันด้วยกัน';
stringMap [strings.Work_together_4] = '[4] อัปโหลดไฟล์ที่แชร์ภายในกลุ่ม (word / excle / pdf)';
stringMap [strings.Work_together_5] = '[5] คุณสามารถฝากข้อความไว้ในกลุ่ม';
stringMap [strings.Work_together_6] = '[6] การตั้งค่าสิทธิ์การจัดการกลุ่มที่ใช้ร่วมกัน';
stringMap [strings.Work_together_7] = '[7] ยกเลิกกลุ่ม';

stringMap [strings.Work_together] = 'Creative Sharing (Storm)';
stringMap [strings.Community_Sharing_1] = '[1] คุณสามารถแบ่งปันสิ่งที่คุณสร้างกับพายุเพื่อให้คนอื่น ๆ ได้เห็นมันมากขึ้น';
stringMap [strings.Community_Sharing_1] = '[2] คุณสามารถเลือกแท็กเพื่อดูเนื้อหาพายุที่เกี่ยวข้อง';
stringMap [strings.Community_Sharing_1] = '[3] คุณสามารถกดไลค์และแสดงความคิดเห็นเกี่ยวกับผลงานของพายุ';

// อัปเดตล่าสุด
stringMap [strings.Recently_Updated_Title_1] = 'สร้างบัญชีส่วนตัว';
stringMap [strings.Recently_Updated_Title_2] = 'ฐานความรู้เทมเพลตขนาดใหญ่';
stringMap [strings.Recently_Updated_Title_3] = 'รองรับหลายแพลตฟอร์ม';
stringMap [strings.Recently_Updated_Title_4] = 'ปรับแต่งทุกรายละเอียด';
stringMap [strings.Recently_Updated_Title_5] = 'ไลบรารีสไตล์ส่วนตัว';
stringMap [strings.Recently_Updated_Title_6] = 'แม็พเอาท์พุท';
stringMap [strings.Recently_Updated_Title_7] = 'Cloud Storage';
stringMap [strings.Recently_Updated_Title_8] = 'สิบห้าประเภทของแผนที่';


stringMap [strings.Recently_Updated_Content_1] = 'สร้างบัญชีส่วนตัวและบันทึกแผนที่ที่สร้างด้วยบัญชี';
stringMap [strings.Recently_Updated_Content_2] = 'ฐานความรู้แม่แบบจำนวนมากถูกอ้างถึงโดยตรงเพื่อปรับปรุงประสิทธิภาพการเรียนรู้';
stringMap [strings.Recently_Updated_Content_3] = 'รองรับการผลิตออนไลน์เวอร์ชันเว็บ, เทอร์มินัลมือถือ IOS, เทอร์มินอล Mac, เทอร์มินัลมือถือ Android, แท็บเล็ต Android ใช้หลายแพลตฟอร์ม';
stringMap [strings.Recently_Updated_Content_4] = 'คุณสามารถออกแบบข้อความพื้นหลังเส้นไอคอนรูปภาพและอื่น ๆ ให้สวยงามตามความชอบส่วนบุคคล';
stringMap [strings.Recently_Updated_Content_5] = 'ไลบรารีสไตล์สไตล์ส่วนบุคคลถูกยกมาโดยตรงช่วยขจัดปัญหาในการจับคู่สี';
stringMap [strings.Recently_Updated_Content_6] = 'คุณสามารถบันทึกรูปภาพรูปแบบ PDF และแบ่งปันกับเพื่อนได้โดยตรง';
stringMap [strings.Recently_Updated_Content_7] = 'การสนับสนุนที่เก็บข้อมูลบนคลาวด์ที่แข็งแกร่งไม่ต้องกังวลเรื่องข้อมูลสูญหายอีกต่อไป';
stringMap [strings.Recently_Updated_Content_8] = 'สองทาง, ขวา, ซ้าย, โครงสร้างองค์กร, แผนภูมิฟองเดี่ยว, แผนภูมิฟองคู่, แผนภูมิต้นไม้ด้านซ้าย, แผนภูมิต้นไม้ด้านขวา, แผนภูมิต้นไม้สองทาง, ตาราง, เส้นเวลาแนวนอน, เส้นเวลาแนวตั้ง, S ไทม์ไลน์แปดเลย์เอาต์สามารถเปลี่ยนแปลงได้ตามต้องการ ';

// เพิ่งถูกลบ
stringMap [strings.Mind_Mindmap_Deleted_Title] = 'ลบล่าสุด';
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = 'การกู้คืน';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'ลบ';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'ชื่อไฟล์';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'หลังจากลบที่นี่แล้วมันจะถูกลบออกอย่างถาวร';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'การทำงาน';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = 'ค้นหาแผนที่ที่เพิ่งลบ';
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = 'ลบวันที่';

//ติดต่อเรา
stringMap [strings.Contact_Us_No_Public] = 'บัญชีสาธารณะ';
stringMap [strings.Contact_Us_QQ_Group] = 'QQ Group';
stringMap [strings.Contact_Us_Message_Board] = 'กระดานข้อความ';
stringMap [strings.Contact_Us_Username] = 'ชื่อ';
stringMap [strings.Contact_Us_Email] = 'อีเมล';
stringMap [strings.Contact_Us_Voicemail] = 'กรุณาแสดงความคิดเห็นที่มีค่าของคุณ';
stringMap [strings.Contact_Us_Send_Email] = 'ส่งอีเมล';

// ยืนยันข้อความ
stringMap [strings.Input_Username_isNull] = 'ชื่อต้องไม่ว่างเปล่า';
stringMap [strings.Input_Email_isNull] = 'กรุณากรอกที่อยู่อีเมลที่ถูกต้อง';
stringMap [strings.Input_Voicemail_isNull] = 'โปรดป้อนเนื้อหาที่ส่ง';
stringMap [strings.Input_Introduction_isNull] = 'บทนำต้องไม่ว่างเปล่า';
stringMap [strings.Input_Title_isNull] = 'ชื่อเรื่องต้องไม่ว่างเปล่า';

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง';
stringMap [strings.Input_Please_Enter_Verification_Code] = 'โปรดป้อนรหัสยืนยัน! ';
stringMap [strings.Input_Please_Enter_The_Password] = 'กรุณาใส่รหัสผ่าน! ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = 'รูปแบบรหัสผ่านไม่ถูกต้อง! ';
stringMap [strings.Input_Please_Enter_A_New_Password] = 'โปรดป้อนรหัสผ่านใหม่! ';
stringMap [strings.Input_Confirm_The_New_Password] = 'โปรดป้อนรหัสผ่านยืนยัน! ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = 'รหัสผ่านไม่สอดคล้องกัน! ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = 'โปรดป้อนรหัสยืนยันที่ถูกต้อง! ';
stringMap [strings.Input_The_Verification_Code_Error] = 'ข้อผิดพลาดรหัสยืนยัน! ';

// ใช้วิธีใช้
stringMap [strings.Mind_Usinghelp_1] = 'สร้างแผนที่ความคิด';
stringMap [strings.Mind_Usinghelp_2] = 'วิธีเพิ่มโหนด';
stringMap [strings.Mind_Usinghelp_3] = 'สร้างโหนดว่าง';
stringMap [strings.Mind_Usinghelp_4] = 'สร้างการเชื่อมโยง';
stringMap [strings.Mind_Usinghelp_5] = 'ทำให้ผ้าใบสวยงาม';
stringMap [strings.Mind_Usinghelp_6] = 'ทำให้ข้อความสวยงาม';
stringMap [strings.Mind_Usinghelp_7] = 'แทรกบันทึกย่อลิงค์';
stringMap [strings.Mind_Usinghelp_8] = 'แทรกไอคอน';
stringMap [strings.Mind_Usinghelp_9] = 'แทรกรูปภาพ';
stringMap [strings.Mind_Usinghelp_10] = 'วิธีคืนค่าการลบ';
stringMap [strings.Mind_Usinghelp_11] = 'ส่งออก Mind Map';
stringMap [strings.Mind_Usinghelp_12] = 'การอัปเกรดสมาชิก';
stringMap [strings.Mind_Usinghelp_13] = 'คีย์ลัด';
stringMap [strings.Mind_Usinghelp_Understood] = 'ฉันเข้าใจแล้ว';
stringMap [strings.Mind_Usinghelp_14] = 'แทรกไลบรารีวัสดุ';
// ใช้วิธีใช้ -2
stringMap [strings.Mind_Usinghelp_15] = 'แผนที่ใหม่';
// stringMap [strings.Mind_Usinghelp_2] = 'เพิ่มโหนด';
// stringMap [strings.Mind_Usinghelp_4] = 'สร้างการเชื่อมโยง';
// stringMap [strings.Mind_Usinghelp_5] = 'ทำให้ผ้าใบสวยงาม';
// stringMap [strings.Mind_Usinghelp_6] = 'ปรับแต่งข้อความ';
// stringMap [strings.Mind_Usinghelp_7] = 'เพิ่มบันทึกลิงค์';
stringMap [strings.Mind_Usinghelp_17] = 'เลิกทำยกเลิก';
stringMap [strings.Mind_Usinghelp_18] = 'ลากโหนดเพื่อสลับ';
stringMap [strings.Mind_Usinghelp_19] = 'การจัดตำแหน่งโหนด';
stringMap [strings.Mind_Usinghelp_20] = 'เปลี่ยนประเภทแผนที่';
stringMap [strings.Mind_Usinghelp_21] = 'รูปแบบเส้นแผนที่';
stringMap [strings.Mind_Usinghelp_22] = 'สีพื้นหลังของแผนที่';
stringMap [strings.Mind_Usinghelp_23] = 'ตั้งค่ารูปร่างโหนด';
stringMap [strings.Mind_Usinghelp_24] = 'Node line style';
stringMap [strings.Mind_Usinghelp_25] = 'การตั้งค่ารูปภาพโหนด';
stringMap [strings.Mind_Usinghelp_26] ='การตั้งค่าไอคอนโหนด';
stringMap [strings.Mind_Usinghelp_27] = 'Node Add Summary';
stringMap [strings.Mind_Usinghelp_28] = 'การเลือกกล่องโหนดเดียว';
stringMap [strings.Mind_Usinghelp_29] = 'หลายโหนดในบล็อก';
stringMap [strings.Mind_Usinghelp_30] = 'โหนดเพิ่มเส้นขอบ';
stringMap [strings.Mind_Usinghelp_31] = 'เพิ่มแผนที่สถิติ';
stringMap [strings.Mind_Usinghelp_32] = 'ซ่อนโหนดและเรียกคืน';
stringMap [strings.Mind_Usinghelp_33] = 'การดำเนินการหลายโหนด';
stringMap [strings.Mind_Usinghelp_34] = 'เปลี่ยนรูปแบบ';
stringMap [strings.Mind_Usinghelp_35] = 'ใส่เสียงและคำอธิบาย';
stringMap [strings.Mind_Usinghelp_36] = 'Guide Map ลงในรูปภาพ';
stringMap [strings.Mind_Usinghelp_37] = 'แมปเอกสาร PDF';
stringMap [strings.Mind_Usinghelp_38] = 'พิมพ์แผนที่';
stringMap [strings.Mind_Usinghelp_39] = 'การแชร์และดูลิงก์';
stringMap [strings.Mind_Usinghelp_40] = 'แผนที่เพื่อแบ่งปันกับพายุ';
stringMap [strings.Mind_Usinghelp_41] = 'การใช้โฟลเดอร์';
stringMap [strings.Mind_Usinghelp_42] = 'ลบและกู้คืนแผนที่';
// ใช้วิธีใช้ 3
stringMap [strings.Mind_Usinghelp_43] = 'เพื่อนสมัครเข้าร่วมกลุ่ม';
stringMap [strings.Mind_Usinghelp_44] = 'วิธีการสลายกลุ่ม';
stringMap [strings.Mind_Usinghelp_45] = 'อัปโหลดข้อมูลไฟล์';
stringMap [strings.Mind_Usinghelp_46] = 'ตกลงเข้าร่วมแอปพลิเคชัน';
stringMap [strings.Mind_Usinghelp_47] = 'การแก้ไขร่วมกัน';
stringMap [strings.Mind_Usinghelp_48] = 'กลุ่มการแบ่งปันใหม่';
stringMap [strings.Mind_Usinghelp_49] = 'เชิญสมาชิกกลุ่ม';

//พายุ
stringMap [strings.Mind_Storm_Recommend] = 'คำแนะนำ';
stringMap [strings.Mind_Storm_Boilingpoint] = 'จุดเดือด';
stringMap [strings.Mind_Storm_Unfold] = 'กางออก';
stringMap [strings.Mind_Storm_Putaway] = 'Putaway';
stringMap [strings.Mind_Storm_Myclassification] = 'การจำแนกประเภทของฉัน';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'การจัดประเภทที่แนะนำ';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'คลิกเพื่อเพิ่มหมวดหมู่';
stringMap [strings.Mind_Storm_No_Classification] = 'ยังไม่มีการจัดหมวดหมู่';
stringMap [strings.Mind_Storm_No_Tinterested] = 'ไม่สนใจ';
stringMap [strings.Mind_Storm_Report] = 'รายงาน';
stringMap [strings.Mind_Storm_Views] = 'มุมมอง';

stringMap [strings.Mind_Storm_Pornographic] = 'ลามกอนาจาร';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'การคุกคามการโฆษณา';
stringMap [strings.Mind_Storm_Political_Religion] = 'การเมืองและศาสนา';
stringMap [strings.Mind_Storm_Infringement] = 'การละเมิด (ภาพบุคคลการหมิ่นประมาทการลอกเลียนแบบการฉ้อโกง)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'เนื้อหาต้องห้าม';
stringMap [strings.Mind_Storm_Spread_Rumors] = 'กระจายข่าวลือและกระจายข่าวลือ';
stringMap [strings.Mind_Storm_Report_Successful] = 'รายงานสำเร็จ';

// แก้ไข
stringMap [strings.Global_Edit] = 'แก้ไข';
stringMap [strings.Global_Cancel] = 'ยกเลิก';
stringMap [strings.Global_Ok] = 'ตกลง';
stringMap [strings.Global_Name] = 'ชื่อเล่น';
stringMap [strings.Global_Introduction] = 'บทนำ';
stringMap [strings.Global_Change_Avatar] = 'เปลี่ยน Avatar';
stringMap [strings.Global_Local_Upload] = 'อัปโหลดภายในเครื่อง';

//ข้อมูลส่วนบุคคล
stringMap [strings.User_Personal_information] = 'ข้อมูลส่วนบุคคล';
stringMap [strings.User_Member_Validity_Period] = 'Member Validity Period';
stringMap [strings.User_My_Share] = 'My Share';
stringMap [strings.User_My_Collection] = 'คอลเล็กชันของฉัน';
stringMap [strings.User_More_Message] = 'เพิ่มเติม';
stringMap [strings.User_Deleted_Sotrm] = 'ลบพายุ';
stringMap [strings.User_Folder_Rename] = 'เปลี่ยนชื่อ';
stringMap [strings.User_Folder_Rename_Root] = 'แก้ไขสิทธิ์';
stringMap [strings.User_Folder_Rename_Root_Title] = 'แก้ไขสิทธิ์';
stringMap [strings.User_Folder_Root_Public] = 'สาธารณะทั้งหมด';
stringMap [strings.User_Folder_Root_Viewonly] = 'อนุญาตให้ดูโฟลเดอร์สาธารณะเท่านั้น';
stringMap [strings.User_Folder_Root_Private] = 'ส่วนตัว';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'บุคคลอื่นสามารถดูรายการโปรดของคุณในหน้าโปรไฟล์ของคุณ';
stringMap [strings.User_Message_Link] = 'ลิงค์';
stringMap [strings.User_New_Group] = 'กลุ่มใหม่';
stringMap [strings.User_New_Group_Title] = 'สร้างกลุ่มใหม่';
stringMap [strings.User_New_Group_Style_Title] = 'ประเภทไฟล์';
stringMap [strings.User_Share_Is_Null] = 'ยังไม่มีการแบ่งปัน! ';
stringMap [strings.User_Deleted_Storm_Tips] = 'แน่ใจไหมว่าต้องการลบพายุนี้? ';
stringMap [strings.User_Remove_From_List] = 'ลบออกจากรายการ',
    stringMap [strings.User_Move_To] = 'ย้ายไปที่',
    stringMap [strings.User_Deleted_Link] = 'ลบลิงค์'
stringMap [strings.User_Collction_Is_Null] = 'ยังไม่มีการรวบรวม'
stringMap [strings.User_Collection] = 'คอลเล็กชัน'

// กล่องเข้าสู่ระบบ
stringMap [strings.User_Lolgin_Welcome_To_Login] = 'ยินดีต้อนรับสู่ Mind +'
stringMap [strings.User_Lolgin_Free_Registration] = 'ลงทะเบียนฟรี'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'โปรดป้อนหมายเลขโทรศัพท์ของคุณ'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'โปรดป้อนรหัสยืนยัน'
stringMap [strings.User_Lolgin_Obtain] = 'รับ'
stringMap [strings.User_Lolgin_Password_Login] = 'รหัสผ่านเข้าสู่ระบบ'
stringMap [strings.User_Lolgin_Login] = 'เข้าสู่ระบบ'
stringMap [strings.User_Lolgin_Register_An_Account] = 'การลงทะเบียนบัญชี'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'มีบัญชีอยู่แล้ว'
stringMap [strings.User_Lolgin_To_Log_In] = 'เข้าสู่ระบบ'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'โปรดป้อนรหัสผ่าน'
stringMap [strings.User_Lolgin_Confirm_Password] = 'ยืนยันรหัสผ่าน'
stringMap [strings.User_Lolgin_Registered] = 'ลงทะเบียน'
stringMap [strings.User_Lolgin_Account_Login] = 'การเข้าสู่ระบบบัญชี'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'สแกนรหัสเข้าสู่ระบบ'
stringMap [strings.User_Lolgin_Forgot_Password] = 'ลืมรหัสผ่าน'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'รหัสยืนยันการเข้าสู่ระบบ'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'เปิดใจ + สแกนที่ด้านบนของอินเทอร์เฟซการตั้งค่า'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'รหัส QR นี้จะหมดอายุในห้านาทีโปรดคลิกเมื่อมันหมดอายุ'
stringMap [strings.User_Lolgin_Refresh] = 'รีเฟรช'
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'การสแกนสำเร็จโปรดยืนยันบนโทรศัพท์ของคุณ'
stringMap [strings.User_Lolgin_Where_Yo_Authorize_Login] = 'ว่าจะอนุญาตการเข้าสู่ระบบ'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'โปรดป้อนรหัสผ่านใหม่'
stringMap [strings.User_Lolgin_Reset] = 'รีเซ็ต'

// แผนที่ของฉัน
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'ค้นหาประเภทเทมเพลตหรือเทมเพลต'
stringMap [strings.My_Mind_Date] = 'วันที่'
stringMap [strings.My_Mind_Create_Folder] = 'สร้างโฟลเดอร์'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'โปรดป้อนชื่อโฟลเดอร์'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'โปรดป้อนชื่อโฟลเดอร์ใหม่'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'ยังไม่มีแผนที่ไปเร็ว ๆ '
stringMap [strings.My_Mind_Create] = 'สร้าง'
stringMap [strings.My_Mind_Right] = 'บาร์!'
stringMap [strings.My_Mind_Open] = 'เปิด'
stringMap [strings.My_Mind_Type_Left_Right] = 'แผนที่นำทางคู่'
stringMap [strings.My_Mind_Type_Unilateral] = 'แผนที่ข้างเดียว'
stringMap [strings.My_Mind_Type_Bottom] = 'แผนผังองค์กร'
stringMap [strings.My_Mind_Type_Bubble] = 'แผนภูมิฟอง'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'กำหนดการแผนที่'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'Blank Mind Map'
stringMap [strings.My_Mind_All] = 'ทั้งหมด'
stringMap [strings.My_Mind_Views] = 'มุมมอง:'

// ค้นหาพายุ
stringMap [strings.Storm_Search_How_Title] = 'แท็กยอดนิยม'
stringMap [strings.Storm_History_Search] = 'การค้นหาประวัติ'

// ส่วนหัวสาธารณะ
stringMap [strings.Public_Header_Mind] = 'แผนที่'
stringMap [strings.Public_Header_Outline] = 'เค้าร่าง'
stringMap [strings.Public_Header_Download_Apps] = 'ดาวน์โหลดไคลเอนต์'
stringMap [strings.Public_Header_Mobile_Version] = 'เวอร์ชันมือถือ'
stringMap [strings.Public_Header_Creator] = 'ผู้สร้าง:'
stringMap [strings.Public_Header_View_Frequency] = 'มุมมอง:'
stringMap [strings.Public_Header_Save] = 'บันทึก'
stringMap [strings.Header_Mind_Tips_Saved] = 'บันทึกแล้ว'
stringMap [strings.Header_Mind_Tips_Edit] = 'แก้ไขแล้ว'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'บันทึกการอัปเดตแล้ว'

// เมนูแก้ไขแผนที่
stringMap [strings.Mind_Edit_Revoke] = 'เพิกถอน'
stringMap [strings.Mind_Edit_Restore] = 'คืนค่า'
stringMap [strings.Mind_Edit_Subtopic] = 'หัวข้อย่อย'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'Top Subtopic'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'Bottom Subtopic'
stringMap [strings.Mind_Edit_Free_Node] = 'โหนดฟรี'
stringMap [strings.Mind_Edit_Summary] = 'สรุป'
stringMap [strings.Mind_Edit_Block] = 'เข้าไปในบล็อก'
stringMap [strings.Mind_Edit_Insert] = 'แทรก'
stringMap [strings.Mind_Edit_Related] = 'เกี่ยวข้อง'
stringMap [strings.Mind_Edit_Style] = 'สไตล์'
stringMap [strings.Mind_Edit_Recording] = 'การบันทึก'
stringMap [strings.Mind_Edit_Insert_Picture] = 'แทรกรูปภาพ'
stringMap [strings.Mind_Edit_Insert_Icon] = 'แทรกไอคอน'
stringMap [strings.Mind_Edit_Insert_Remarks] = 'แทรกหมายเหตุ'
stringMap [strings.Mind_Edit_Insert_Link] = 'แทรกลิงค์'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'แทรกกราฟสถิติ'
stringMap [strings.Mind_Edit_Upload] = 'อัปโหลด'
stringMap [strings.Mind_Edit_Right_Icon] = 'ไอคอน'
stringMap [strings.Mind_Edit_Right_Style] = 'สไตล์'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'ถอดรหัส ... '
stringMap [strings.Mind_Edit_Audio_Duration] = 'ระยะเวลา'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = 'คุณแน่ใจหรือไม่ว่าจะลบเสียงที่บันทึก? '
stringMap [strings.Mind_Edit_Join_Membership] = 'เปิดการเป็นสมาชิก'
stringMap [strings.Mind_Edit_Upgrade] = 'อัพเกรด'
stringMap [strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = 'สมาชิกสามารถมีเวลาในการบันทึกมากขึ้นไปอัปเกรดเป็นสมาชิกได้ไหม '

stringMap [strings.Mind_Edit_Choose_Material] = 'เลือกวัสดุ'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'ลากและวางรูปภาพที่นี่'
stringMap [strings.Mind_Edit_Select_locally] = 'เลือกจากในเครื่อง'
stringMap [strings.Mind_Edit_Choose_From] = 'เลือกจากไลบรารีวัสดุ'
stringMap [strings.Mind_Edit_Mine] = 'ของฉัน'
stringMap [strings.Mind_Edit_My_Material] = 'วัสดุของฉัน'
stringMap [strings.Mind_Edit_Recently_Used] = 'ใช้ล่าสุด'
stringMap [strings.Mind_Edit_Use] = 'ใช้'
stringMap [strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = 'ไม่มีวัสดุในขณะนี้ไปเพิ่ม! '
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'เลือกประเภทแผนภูมิ'
stringMap [strings.Mind_Edit_Remarks] = 'หมายเหตุ'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'กรุณาใส่หมายเหตุ'
stringMap [strings.Mind_Edit_Add] = 'เพิ่ม'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'กรุณาใส่ลิงค์'

// แก้ไขเมนูทางด้านซ้ายของเพจ
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'บันทึกรูปภาพ'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = 'ส่งออก PDF'
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'แชร์ลิงก์'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'แบ่งปันกับพายุ'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'พิมพ์'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'ปลดล็อกเวอร์ชันเต็ม'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'ช่วยเหลือ'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'บันทึกรูปแบบ'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'รูปแบบ png'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'รูปแบบ jpg'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'รูปแบบ webp'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'แชร์แผนที่'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'สวิตช์แชร์'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'คุณสามารถคัดลอกลิงค์และส่งได้'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'คัดลอกลิงค์'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'ถ้าคุณต้องการเปลี่ยนลิงค์กรุณาคลิก'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'สร้างใหม่'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'รหัสผ่านลิงก์:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ='อนุญาตให้บันทึก'
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'จำนวนการดูสูงสุด'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'ไม่มีที่สิ้นสุด'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'จำกัด '
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'วินาที'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'ระยะเวลาความถูกต้องของลิงก์'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = '1 วัน'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = '3 วัน'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = '7 วัน'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'ถาวร'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'คัดลอกเนื้อหาและลิงก์'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = 'คุณแน่ใจหรือไม่ว่าต้องการสร้างใหม่? ลิงค์ก่อนหน้านี้จะไม่ถูกต้อง! '
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'แบ่งปัน'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'หมวดหมู่ที่กำหนดเอง:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'อนุญาตให้แสดงความคิดเห็นหรือไม่'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'แก้ไขเพื่อเพิ่มหมวดหมู่'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'สิทธิการเป็นสมาชิก'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'แพลตฟอร์มการสนับสนุน'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'เปิดการเป็นสมาชิก'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'การแลกเปลี่ยนรหัสการเปิดใช้งาน'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = 'สิทธิประโยชน์สำหรับสมาชิกหลังการซื้อ'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'เลือกวิธีการชำระเงิน'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'ราคากิจกรรม'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'จำนวนเงินที่จ่ายจริง'
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'ต่ออายุทันที'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《 ข้อกำหนดในการให้บริการ》'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《 นโยบายความเป็นส่วนตัว》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'รหัสเปิดใช้งาน:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'โปรดป้อนรหัสเปิดใช้งาน'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'ยืนยันรหัสเปิดใช้งาน'
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'เลือกหมวดหมู่'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'ชื่อหลักต้องไม่ว่างเปล่า'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'คูปอง:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'โปรดป้อนรหัสคูปอง'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'ยืนยันการใช้คูปอง'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'การยืนยัน'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'จำนวนที่ต้องการ'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'จ่ายทันที'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'ยืนยันข้อมูลการแลกเปลี่ยน'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'รหัสแลกเปลี่ยน:'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'แลกเปลี่ยน'

// แก้ไขเมนูทางด้านขวาของเพจ
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'ผ้าใบ'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'สีพื้นหลัง'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'เค้าโครง'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'สไตล์เส้น'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'สีของเส้น'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'ความกว้างของเส้น'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = 'เส้นเรียวเล็ก'
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'ธีมฟอนต์'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'แบบอักษรเด็ก'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'สีข้อความ'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'ขนาดตัวอักษร'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'รูปแบบตัวอักษร'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'สีเส้นขอบ'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'เติมสี'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'รูปทรงเส้นขอบ'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'ความกว้างของเส้นขอบ'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'แสดงเอฟเฟกต์เงา (ฟังก์ชั่นการสมัครสมาชิก)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'สีของเส้น'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'เส้นประ'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'ขนาดรูปภาพ'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'ตำแหน่งรูปภาพ'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'รูปทรงรูปภาพ'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'ขนาดไอคอน'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'ตำแหน่งไอคอน'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'ทั่วไป'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'ลำดับความสำคัญ'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'ความคืบหน้า'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'ดาว'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'สัปดาห์'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'แนวตั้ง'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'การเรียนรู้'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'งาน'
stringMap [strings.Mind_Edit_Right_Menu_Characters] = 'อักขระ'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'สัตว์'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'อาหาร'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'คัดลอกสไตล์'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'รูปแบบการติด'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'แก้ไขรูปร่าง'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'ดูรูปภาพ'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'ลบรูปภาพ'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'คัดลอก'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'วาง'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'ลบบรรทัด'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'ลบ'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'แก้ไขข้อสังเกต'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'เปิดลิงค์'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'ลบหมายเหตุ'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'ลบลิงค์'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'ลบไอคอน'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'รูปแบบไอคอน'

// แผงแก้ไขกราฟสถิติ
stringMap [strings.Echart_Edit_Statistical] = 'สถิติ'
stringMap [strings.Echart_Edit_Value] = 'ค่า'
stringMap [strings.Echart_Edit_Color] = 'สี'
stringMap [strings.Echart_Edit_Title] = 'ชื่อเรื่อง'
stringMap [strings.Echart_Edit_Detailed_Description] = 'คำอธิบายโดยละเอียด'
stringMap [strings.Echart_Edit_Value_Sort] = 'เรียงลำดับ'
stringMap [strings.Echart_Edit_Value_Display_Type] = 'ประเภทการแสดงผล'
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'โครงร่างสี'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'ขนาดที่แสดง'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'ขนาดหัวเรื่อง'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'อธิบายขนาด'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'สมัครสมาชิก'

// แท็บพายุ
stringMap [strings.StormBookTag_Subscribed] = 'สมัครเป็นสมาชิก'
stringMap [strings.StormBookTag_List] = 'รายการ'

// เพจแชร์พายุ
stringMap [strings.Storm_Share_Comment] = 'ความคิดเห็น'
stringMap [strings.Storm_Share_Reply] = 'ตอบกลับ'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'ขอฉันแสดงความคิดเห็นด้วย ~'
stringMap [strings.Storm_Share_Release] = 'ปล่อย'
stringMap [strings.Storm_Share_Main_Title] = 'ชื่อหลัก'
stringMap [strings.Storm_Share_Subtitle] = 'คำบรรยาย'
stringMap [strings.Storm_Share_Author] = 'ผู้เขียน'
stringMap [strings.Storm_Share_Replies] = 'ตอบกลับ'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'ยังไม่มีความคิดเห็นมาเป็นคนแรก'
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'กรุณาใส่ความคิดเห็น ~'
stringMap [strings.Storm_Share_Delete_Comment_Tips] = 'แน่ใจไหมว่าต้องการลบความคิดเห็นนี้? '

// ความช่วยเหลือสำหรับการใช้งานแผนที่ภายใน
stringMap [strings.Mind_Usinghelp_Btn_1] = 'แป้นพิมพ์ลัด (windows)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'ปุ่มทางลัด (mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'คำอธิบาย'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Command] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'เพิ่มโหนดลูก'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[ลบ]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'ลบโหนดปัจจุบัน (โปรดทราบว่าไม่สามารถลบได้หากอยู่ในสถานะแก้ไข)'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Command] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'แก้ไขโหนดปัจจุบัน'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Command] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Command] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'ขยายแผนที่'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Command] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'คำนึงถึงแผนที่'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Command] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Command] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Command] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Command] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'ออกจากการแก้ไข'

// ข้อความแจ้ง
stringMap [strings.Message_Tips_Login_Success] = 'เข้าสู่ระบบสำเร็จ'
stringMap [strings.Message_Tips_Dlt_Success] = 'ลบสำเร็จ'
stringMap [strings.Message_Tips_Report_Success] = 'รายงานสำเร็จ'
stringMap [strings.Message_Tips_Copy_Success] = 'คัดลอกสำเร็จ'
stringMap [strings.Message_Tips_Collction_Success] = 'การรวบรวมความสำเร็จ'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'การแก้ไขอวตารสำเร็จ'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'การแก้ไขลายเซ็นสำเร็จ'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'แก้ไขชื่อเล่นเรียบร้อยแล้ว'
stringMap [strings.Message_Tips_Modification_Success] = 'การแก้ไขสำเร็จ'
stringMap [strings.Message_Tips_New_Profile_Success] = 'โฟลเดอร์ใหม่สำเร็จ'
stringMap [strings.Message_Tips_Mobile_Success] = 'ความสำเร็จบนมือถือ'
stringMap [strings.Message_Tips_Submit_Success] = 'ส่งสำเร็จ'
stringMap [strings.Message_Tips_Exchange_Success] = 'แลกเปลี่ยนสำเร็จ'
stringMap [strings.Message_Tips_Please_Code] = 'โปรดป้อนรหัสเปิดใช้งาน'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'โปรดยอมรับนโยบายความเป็นส่วนตัว'
stringMap [strings.Message_Tips_Please_Sign_In] = 'โปรดลงชื่อเข้าใช้'
stringMap [strings.Message_Tips_Share_Success] = 'แชร์สำเร็จ'
stringMap [strings.Message_Tips_Create_Success] = 'สร้างสำเร็จแล้ว'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'ชื่อโฟลเดอร์ต้องไม่ว่างเปล่า'
stringMap [strings.Message_Tips_Selecte_Success] = 'การค้นหาเสร็จสิ้น'
stringMap [strings.Message_Tips_Subscription_Success] = 'การสมัครสมาชิกสำเร็จ'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'ยกเลิกการสมัครสำเร็จ'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'ไม่พบเนื้อหาที่เกี่ยวข้อง'
stringMap [strings.Message_Tips_Max_Add_Class] = 'สามารถเพิ่มได้มากที่สุดเท่านั้น'
stringMap [strings.Message_Tips_Registered_Success] = 'ลงทะเบียนเรียบร้อยแล้ว'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'การเข้าสู่ระบบสแกนรหัสล้มเหลว'
stringMap [strings.Message_Tips_Share_Is_Null] = 'ไม่มีลิงก์แชร์'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = 'ไฟล์ที่อัพโหลดต้องไม่เกิน 3MB'
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'รับสิทธิ์สำเร็จ'
stringMap [strings.Message_Tips_Membership_Required] = 'ต้องเป็นสมาชิก'
stringMap [strings.Message_Tips_Save_Success] = 'บันทึกสำเร็จ'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = 'สแกนรหัสเข้าสู่ระบบสำเร็จ'
stringMap [strings.Message_Tips_Style] = 'หมวดหมู่?'

// โหมดเค้าร่าง
stringMap [strings.Outline_Menu_Indent] = 'เยื้อง'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'ลดการเยื้อง'

// ช่วยบอกใบ้
stringMap[strings.Help_Background_Content] = "พื้นหลังเส้นที่เกี่ยวข้อง, พื้นหลังเส้นที่เกี่ยวข้อง, พื้นหลังเส้นที่เกี่ยวข้อง, พื้นหลังเส้นที่เกี่ยวข้อง, เส้นพื้นหลังที่เกี่ยวข้อง, เส้นพื้นหลังที่เกี่ยวข้อง, เส้นพื้นหลังที่เกี่ยวข้อง, เส้นพื้นหลังที่เกี่ยวข้อง,"
stringMap [strings.Help_Mind_Content] = 'แผนที่ที่มีเลย์เอาต์ที่แตกต่างกันสามารถสร้างขึ้นได้ตามความต้องการ: แผนที่นำทางคู่แผนที่ซ้ายแผนที่ด้านขวาแผนผังองค์กรต้นไม้สองทางต้นไม้ทางซ้ายต้นไม้ด้านขวาตาราง'
stringMap [strings.Help_Share_Link_Content] = 'แชร์ลิงก์ได้ใน WeChat, QQ, Weibo และช่องทางอื่น ๆ ในรูปแบบของลิงก์และเพื่อน ๆ สามารถดูเนื้อหาได้'
stringMap [strings.Using_Templates] = 'การใช้เทมเพลต'

//รหัสการเชิญ
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'โปรดป้อนรหัสเชิญ'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'โปรดป้อนรหัสเชิญที่ถูกต้อง'
stringMap [strings.User_Lolgin_Optional] = 'ไม่บังคับ'

// ใช้วัสดุ

stringMap [strings.Cannot_Add_To_Node_Background] = 'ไม่สามารถใช้ได้กับพื้นหลังของโหนด'
stringMap [strings.Cannot_Add_To_Img_Background] = 'ไม่สามารถใช้ได้กับพื้นหลังข้อความ'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'ไม่สามารถใช้ได้กับพื้นหลังของเส้นพัสดุ'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'เพิ่มภาพพื้นหลัง'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'ลบภาพพื้นหลัง'
stringMap [strings.Mind_Add_Background_Image] = 'แทรกภาพพื้นหลัง'
stringMap [strings.Mind_Delete_Background_Image] = 'ลบภาพพื้นหลัง'

stringMap [strings.Permanent_Member] = 'สมาชิกถาวร'
stringMap [strings.Insufficient_Storage_Space] = 'พื้นที่เก็บข้อมูลไม่เพียงพออัปเกรดการเป็นสมาชิกเพื่อเพิ่มพื้นที่ '
stringMap [strings.Used_Space] = 'พื้นที่เก็บข้อมูล'
stringMap [strings.Current_Storage] = 'ที่เก็บข้อมูลปัจจุบัน:'
stringMap [strings.Used_Space_Upgrade] = 'อัพเกรดพื้นที่เก็บข้อมูล'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Mind + Mind Map & Notes การจำสุดยอดเพิ่มปีกในการคิดสร้างแสดงวางแผนและบันทึก'
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = 'พื้นที่เก็บข้อมูลไม่เพียงพอคุณสามารถใช้เทมเพลตเมื่อคุณอัปเกรดการเป็นสมาชิกของคุณ '

stringMap [strings.Group_Management] = 'กลุ่ม'
stringMap [strings.Save_Img_Fill_Full] = 'ปูเต็ม'
stringMap [strings.Save_Img_Center_Tilt] = 'Center Tilt'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'เต็มที่ (มืด)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'Mind + Mind Map Watermarking'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'เพิ่มลายน้ำเพื่อป้องกันการสร้างความรู้:'
stringMap [strings.Save_Img_Watermark_Text] = 'ข้อความลายน้ำ:'
stringMap [strings.Save_Img_Watermark_Logo] = 'โลโก้ลายน้ำ:'
stringMap [strings.Save_Img_Watermark_Style] = 'รูปแบบลายน้ำ:'
stringMap [strings.Save_Img_Please_Input] = 'กรุณาใส่'
stringMap [strings.Save_Img_Chop_Tips] = 'คุณสามารถเพลิดเพลินกับสิทธิ์ลายน้ำเมื่อคุณเปิดสมาชิก'
stringMap [strings.Public_Header_Mindmap_Mind] = 'โหมดแผนที่'
stringMap [strings.Copy_Mind_Map] = 'คัดลอก Mind Map'
stringMap [strings.Font_Style_Bold] = 'ตัวหนา'
stringMap [strings.Font_Style_Italics] = 'ตัวเอียง'
stringMap [strings.Font_Style_Delete_Line] = 'ขีดฆ่า'
stringMap [strings.Remove_Any] = 'ลบ'

stringMap [strings.Mindmap_Review] = 'การตรวจสอบแผนที่'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'เพิ่มความประทับใจและเพิ่มความจำจัดเรียงแนวคิดและข้อมูลอย่างมีประสิทธิภาพ'
stringMap [strings.Mindmap_Review_Content] = 'การตรวจสอบแผนที่หมายถึงการเล่นของกระบวนการแก้ไขและเนื้อหาจะเล่นตามลำดับโดยเริ่มจากธีมกลางการตรวจสอบแผนที่สามารถช่วยคุณดำเนินการจัดเก็บระบบวิเคราะห์และค้นหาแนวคิดและช่องโหว่ ในระหว่างกระบวนการตรวจสอบคุณอาจพบแรงบันดาลใจที่แตกต่างกัน'
stringMap [strings.Mindmap_Review_Speed] = 'ความเร็ว'
stringMap [strings.Mindmap_Review_Word_Speed] = 'ความเร็วของคำ'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'อัตราส่วนพื้นหลัง'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'การขยายโหนดการเล่นปัจจุบัน'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'ลำดับการเล่นโหนด'
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap [strings.Mind_Group_Picture_Upload] = 'อัพโหลดรูปภาพ'
stringMap [strings.Mind_Group_Upload_From_Local] = 'การอัปโหลดในเครื่อง'
stringMap [strings.Mind_Group_Mind_Mapping] = 'แผนที่ความคิด'
stringMap [strings.Mind_Group_Information] = 'ข้อมูล'

stringMap [strings.Mind_Group_Invite_New_Members] = 'เชิญสมาชิกใหม่'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'โปรดป้อนหมายเลขโทรศัพท์ของคุณ'
stringMap [strings.Mind_Group_Cancel] = 'ยกเลิก'
stringMap [strings.Mind_Group_Confirm] = 'ตกลง'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'หมายเลขโทรศัพท์มือถือต้องไม่ว่างเปล่า'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'คำเชิญสำเร็จ'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "สมาชิกกลุ่มที่ใช้ร่วมกัน"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "จำนวนสมาชิกกลุ่มที่แชร์สูงสุด"
stringMap [strings.Mind_Group_Membership_Limit] = "ขีด จำกัด การเป็นสมาชิก"
stringMap [strings.Mind_Group_Current_Member_Limit] = "ขีด จำกัด สูงสุดของสมาชิกปัจจุบัน"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "อัพเกรดขีด จำกัด บนของสมาชิก"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "ค้นหาสมาชิก"
stringMap [strings.Mind_Group_Creator] = "ผู้สร้าง"
stringMap [strings.Mind_Group_Administrator] = "ผู้ดูแลระบบ"
stringMap [strings.Mind_Group_Number_Of_People] = "ผู้คน"
stringMap [strings.Mind_Group_Selected] = "เลือกแล้ว"
stringMap [strings.Mind_Group_Member] = "สมาชิก"
stringMap [strings.Mind_Group_Check_All] = "เลือกทั้งหมด"
stringMap [strings.Mind_Group_Remove] = "ลบ"
stringMap [strings.Mind_Group_Invite_New_Members] = "เชิญสมาชิกใหม่"
stringMap [strings.Mind_Group_Delete_Members] = "ลบสมาชิก"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "โปรดเลือกสมาชิกที่จะลบ"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "คุณแน่ใจหรือไม่ว่าต้องการลบผู้ใช้ออกจากกลุ่มนี้"
stringMap [strings.Mind_Group_Add_As_Administrator] = "เพิ่มผู้ดูแลระบบ"
stringMap [strings.Mind_Group_Accomplish] = "เสร็จสมบูรณ์"
stringMap [strings.Mind_Group_Compile] = "แก้ไข"
stringMap [strings.Mind_Group_Group_Owner] = "เจ้าของกลุ่ม"
stringMap [strings.Mind_Group_Add_Admin] = "เพิ่มผู้ดูแลระบบ"
stringMap [strings.Mind_Group_Group_ID] = "รหัสกลุ่ม"
stringMap [strings.Mind_Group_Group_Number] = "หมายเลขกลุ่ม"
stringMap [strings.Mind_Group_Name] = "ชื่อ"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "โปรดป้อนชื่อของกลุ่มที่ใช้ร่วมกัน"
stringMap [strings.Mind_Group_Introduce] = "แนะนำ"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "โปรดแนะนำกลุ่มที่แชร์โดยย่อ"
stringMap [strings.Mind_Group_Announcement] = "ประกาศ"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "แท็กกลุ่มที่ใช้ร่วมกัน"
stringMap [strings.Mind_Group_Custom_Tag] = "แท็กที่กำหนดเอง"
stringMap [strings.Mind_Group_Save] = "บันทึก"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "ชื่อกลุ่มต้องไม่ว่างเปล่า"
stringMap [strings.Mind_Group_Establish] = "สร้าง"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "อนุญาตให้สร้างแผนที่ / โฟลเดอร์"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "อนุญาตให้แก้ไขแผนที่"
stringMap [strings.Mind_Group_Holder] = "ทุกคน"
stringMap [strings.Mind_Group_Only_The_Administrator] = "เฉพาะผู้ดูแลระบบ"
stringMap [strings.Mind_Group_Not_Allow] = "ไม่อนุญาต"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "อนุญาตให้ลบแผนที่"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "อนุญาตให้ฟอรัมโพสต์ข้อความ"
stringMap [strings.Mind_Group_Export] = "ส่งออก"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "อนุญาตให้บันทึกภาพ / PDF"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "เปิดลายน้ำกลุ่ม"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "หลังจากเปิดแล้วโลโก้กลุ่มจะถูกพิมพ์บนแผนที่ความคิด"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "อนุญาตให้บันทึกแผนที่"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "บันทึกลงในแผนที่ของฉัน"
stringMap [strings.Mind_Group_Where_Sharing_To_Storm_Is_Allowed] = "อนุญาตให้แชร์ไปยังพายุหรือไม่"
stringMap [strings.Mind_Group_Join_The_Way] = "วิธีเข้าร่วม"
stringMap [strings.Mind_Group_Wither_To_Invite_New_Members] = "อนุญาตให้เชิญสมาชิกใหม่ได้หรือไม่"
stringMap [strings.Mind_Group_Wreat_To_Allow_Search_Group_Numbers_To_Be_Added] = "อนุญาตให้เพิ่มหมายเลขกลุ่มการค้นหาหรือไม่"
stringMap [strings.Mind_Group_Where_The_Message_Needs_To_Be_Validated_When_Joining] = "ว่าข้อความต้องได้รับการตรวจสอบเมื่อเข้าร่วมหรือไม่"
stringMap [strings.Mind_Group_Shared_Group_Data] = "ข้อมูลกลุ่มที่ใช้ร่วมกัน"
stringMap [strings.Mind_Group_Authority_Management] = "การจัดการอำนาจ"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "ออกจากกลุ่มการแชร์"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "คุณแน่ใจหรือไม่ว่าจะออกจากกลุ่มที่แชร์"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Dissolution Group"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "ไฟล์จะถูกลบหลังจากที่กลุ่มถูกยกเลิกยืนยันที่จะยกเลิกหรือไม่"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "กลับไปที่โฮมเพจ"
stringMap [strings.Mind_Group_Join_The_Group] = "เข้าร่วมกลุ่ม"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "โปรดป้อนหมายเลขกลุ่ม / ชื่อกลุ่มที่ต้องการค้นหา"
stringMap [strings.Mind_Group_Apply_To_Join] = "สมัครเข้าร่วม"
stringMap [strings.Mind_Group_Join] = "เข้าร่วม"
stringMap [strings.Mind_Group_Verification] = "การยืนยัน"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "โปรดป้อนข้อความตรวจสอบความถูกต้อง"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "เนื้อหาต้องไม่ว่างเปล่า"
stringMap [strings.Mind_Group_Name] = "ชื่อกลุ่มที่ใช้ร่วมกัน"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "โปรดป้อนชื่อของกลุ่มที่ใช้ร่วมกัน"
stringMap [strings.Mind_Group_Introduction] = "แบ่งปันการแนะนำกลุ่ม"
stringMap [strings.Mind_Group_Group_Indication] = "หมายเลขกลุ่ม"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "ผู้สร้างขี้เกียจฉันยังไม่ได้แนะนำเลย ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "ประกาศกลุ่มที่แชร์"
stringMap [strings.Mind_Group_No_Announcement] = "ยังไม่มีประกาศ"
stringMap [strings.Mind_Group_Click_On] = "คลิกเพื่อขยาย"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "คลิกเพื่อซ่อน"
stringMap [strings.Mind_Group_Altogether] = "ผลรวม"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "คัดลอกหมายเลขกลุ่มสำเร็จ"
stringMap [strings.Mind_Group_Stick] = "ติดด้านบน"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "มีเนื้อหาใหม่"
stringMap [strings.Mind_Group_Cancel_The_Top] = "ยกเลิกด้านบน"
stringMap [strings.Mind_Group_Setting_Up] = "การตั้งค่า"
stringMap [strings.Mind_Group_Usinghelp] = "การใช้ความช่วยเหลือ"
stringMap [strings.Mind_Group_Set_The_Message] = "ข้อความกลุ่ม"
stringMap [strings.Mind_Group_Apply_To_Join] = "สมัครเข้าร่วม"
stringMap [strings.Mind_Group_Invite_To_Join] = "เชิญเข้าร่วม"
stringMap [strings.Mind_Group_Consent] = "ยอมรับ"
stringMap [strings.Mind_Group_Turn_Down] = "ปฏิเสธ"
stringMap [strings.Mind_Group_Label] = "ป้ายกำกับ"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "โปรดป้อนชื่อของป้ายกำกับกลุ่มที่ใช้ร่วมกัน"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "เชิญเพื่อนและเพื่อนร่วมงานมาร่วมสร้างและแบ่งปันเนื้อหาความรู้"
stringMap [strings.Mind_Group_Immediately_Create] = "สร้างทันที"
stringMap [strings.Mind_Group_Immediately_To_Join] = "เข้าร่วมทันที"

stringMap [strings.Currently_Selected] = "กำลังติดตามอยู่"
stringMap [strings.LabelManagement] ="การจัดการฉลาก"
stringMap [strings.NoAttention] = "ไม่มีความสนใจ"
stringMap [strings.Mindmap_Review_Play] = 'เล่น'

stringMap [strings.Mind_Group_Joined] = "เข้าร่วม"
stringMap [strings.Mind_Group_Download_App] = "ดาวน์โหลด APP"
stringMap [strings.Mind_Group_No_Content] = "ยังไม่มีเนื้อหา"
stringMap [strings.Mind_Group_Individual] = "ชิ้น"
stringMap [strings.Mind_Group_Message_Area] = "พื้นที่ข้อความ"
stringMap [strings.Mind_Group_No_Message] = "ไม่มีข้อความไปเลย"
stringMap [strings.Mind_Group_Leave_Message] = "ฝากข้อความ"
stringMap [strings.Mind_Group_Bar] = "บาร์"
stringMap [strings.Mind_Group_See_More] = "ดูเพิ่มเติม"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "ป้อนรหัสเชิญ"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "สมัครเข้าร่วมกลุ่มแบ่งปัน"
stringMap [strings.Reasons_For_Application_0_30_Words] = "เหตุผลในการสมัคร (0-30 คำ)"
stringMap [strings.Mind_Group_Send_Out] = "ส่ง"
stringMap [strings.Mind_Group_Join_Successfully] = "เข้าร่วมสำเร็จ"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "แอปพลิเคชันถูกส่งเรียบร้อยแล้ว"

stringMap [strings.Mind_Group_Save_The_Mapping] = "บันทึกแผนที่"
stringMap [strings.Mind_Group_Update_Record] = "แก้ไขบันทึก"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "สลับแผนที่"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "รีวิวแผนที่"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "ผู้ใช้ที่มีอยู่กำลังแก้ไขแผนที่"
stringMap [strings.Mind_Edit_Page_Password] = "รหัสผ่าน"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "กลุ่มการแชร์"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "MBean ของฉัน"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "การชำระเงินด้วย Alipay"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "โปรดไปที่หน้าการชำระเงินเพื่อชำระเงิน"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "รวม"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "ยืนยันการชำระเงิน"
stringMap [strings.Mind_Shooping_Redeem_Now] = "แลกทันที"

stringMap [strings.Mind_Wreat_Confirm_Payment_Purchase] = "ยืนยันการชำระเงินสำหรับการซื้อหรือไม่"
stringMap [strings.Mind_Tips] = "เคล็ดลับ"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Wither_Go__Purchase_M_Bean] = "ถั่วเอ็มของคุณไม่เพียงพอคุณต้องการซื้อถั่วเอ็มหรือไม่"

stringMap [strings.Mind_share_scan_code] = "Mind + scan code"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "จะคืนค่าไปยังตำแหน่งที่เล่นล่าสุดหรือไม่"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "เวลาที่สามารถเล่นได้ที่เหลืออยู่"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "ไฟล์จะถูกลบทั้งหมดคุณแน่ใจหรือไม่ว่าจะแยกวง"
stringMap [strings.Group_Mind_Up_One_Level] = "ขึ้นหนึ่งระดับ"
stringMap [strings.Group_Mind_No_Documents] = "ยังไม่มีไฟล์"
stringMap [strings.Group_Mind_PaymentSuccessful] = "ชำระเงินสำเร็จ"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "การแก้ไขร่วมกันของผู้คนการซิงโครไนซ์แบบเรียลไทม์แบบหลายเทอร์มินัลการแชร์เอกสารการปรับปรุงประสิทธิภาพการทำงานร่วมกันของทีม"

stringMap [strings.Mind_Edit_Subscript] = "ตัวสมัคร"
stringMap [strings.Mind_Edit_Text_Location] = "ตำแหน่งข้อความ"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "นำเข้าสู่กลุ่มที่ใช้ร่วมกัน"

stringMap [strings.Standard_Colors] = "สีมาตรฐาน"
stringMap [strings.More_Colors] = "สีเพิ่มเติม"
stringMap [strings.Reset_Password] = "รีเซ็ตรหัสผ่าน"
stringMap [strings.Account_Password_Login] = "เข้าสู่ระบบรหัสผ่านบัญชี"
stringMap [strings.Authentication_code_Login_registration] = "การเข้าสู่ระบบ / การลงทะเบียนรหัสยืนยัน"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "การสร้างปลายด้านเดียวการซิงโครไนซ์ปลายหลายรายการ"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "โครงสร้างแผนที่ที่สมบูรณ์และหลากหลาย"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "ไลบรารีเทมเพลตประณีตขนาดใหญ่"
stringMap [strings.Map_Review_Play] = "Map Review-Play"
stringMap [strings.CommunityLearningAndSharingPlatform] = "แพลตฟอร์มแลกเปลี่ยนเรียนรู้และแบ่งปันความรู้"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "อัพเกรดพื้นที่เก็บข้อมูล"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "ให้ฉันแสดงความคิดเห็นด้วย"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "ยังไม่มีการแจ้งเตือนข้อความ"
stringMap[strings.Failed_To_Get_Recording_Device] = "ไม่สามารถรับอุปกรณ์บันทึก"

stringMap[strings.Search_Pagination] = "ค้นหาการแบ่งหน้า"
stringMap[strings.Please_Enter_A_Paging_Name] = "โปรดป้อนชื่อเพจ"
stringMap[strings.Operation_Settings] = "การตั้งค่าการทำงาน"
stringMap[strings.Shortcut_key_Settings] = "การตั้งค่าคีย์ลัด"
stringMap[strings.Press_Key_Combination] = "โปรดกดคีย์ผสมที่จำเป็น"
stringMap[strings.Mind_Reduction] = "ลด"
stringMap[strings.Double_Click_Add_Free_Node] = "ดับเบิลคลิกที่พื้นที่ว่างเพื่อเพิ่มโหนดว่าง"
stringMap[strings.Shortcut_Conflict] = "ข้อขัดแย้งของคีย์ลัด"
stringMap[strings.Select_Main_Node] = "เลือกโหนดหลัก"
stringMap[strings.Telephone] = "โทรศัพท์"
stringMap[strings.Microblog] = "ไมโครบล็อก"
stringMap[strings.Address] = "ที่อยู่"
stringMap[strings.Company_Address] = "Block B, Building 21, No. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap[strings.Mind_Archive] = "เก็บถาวร"
stringMap[strings.Mind_Bookshelf] = "ชั้นวางหนังสือ"
stringMap[strings.Search_Archive] = "ค้นหาชั้นวางหนังสือ"
stringMap[strings.Modify_Archive_Order] = "แก้ไขลำดับชั้นหนังสือ"
stringMap[strings.Modification_Order] = "ลำดับการแก้ไข"
stringMap[strings.Please_Enter_Archive_Name] = "โปรดป้อนชื่อชั้นวางหนังสือ"
stringMap[strings.Message_Tips_Archive_Name_No_Null] = "ต้องระบุชื่อชั้นวางหนังสือ"
stringMap[strings.Mind_Edit_Delete_Archive_Tips] = "คุณแน่ใจหรือไม่ว่าต้องการลบชั้นวางหนังสือนี้"
stringMap[strings.Mind_Edit_Delete_Bookcase_Tips] = "คุณแน่ใจหรือไม่ว่าต้องการลบตู้หนังสือนี้"
stringMap[strings.Mind_Edit_Delete_Map_Tips] = "คุณแน่ใจหรือไม่ว่าจะลบแผนที่นี้"
stringMap[strings.Mind_Edit_Confirm] = "ยืนยัน"
stringMap[strings.Mind_Import_Map] = "นำเข้าแผนที่"
stringMap[strings.Mind_Bookcase] = "ตู้หนังสือ"
stringMap[strings.Added_Successfully] = "เพิ่มสำเร็จแล้ว"
stringMap[strings.Message_Tips_Bookcase_Name_No_Null] = "ต้องระบุชื่อตู้หนังสือ"

stringMap[strings.Mind_Association_Map] = "แผนที่สมาคม"
stringMap[strings.Mind_confirm_Association_Map] = "คุณแน่ใจว่าจะเชื่อมโยงแผนที่นี้หรือไม่"
stringMap[strings.Mind_Association_Map_See] = "ดูแผนที่สมาคม"
stringMap[strings.Mind_Association_Map_Delete] = "ลบแผนที่สมาคม"

stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "โครงสร้างเส้นสหสัมพันธ์"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "จุดเริ่มต้นของ Correlation Line"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "จุดสิ้นสุดของบรรทัดสหสัมพันธ์"

stringMap[strings.Share_Group_To_Storm] = "ไม่ว่าจะแชร์กลุ่มกับพายุ"
stringMap[strings.Apply_To_Join_Storm_Platform] = "บนแพลตฟอร์มสตอร์ม คนอื่นเห็นและสมัครเข้าร่วม ระดมสมองกับผู้ที่สนใจด้วยความรู้ทั่วไป"
stringMap[strings.Pay_To_Goin] = "ชำระเงินเพื่อเข้าร่วม"
stringMap[strings.Pay_To_Goin_Need_Mbeans] = "ต้องจ่ายถั่ว M เมื่อเข้าร่วม"
stringMap[strings.Mbean_Can_Be_Realized] = "Mbean รับรู้"

stringMap[strings.Mind_Visit] = "เยี่ยมชม"
stringMap[strings.Mind_Details] = "รายละเอียด"
stringMap[strings.Mind_See] = "ดู"
stringMap[strings.Earn_Mbeans] = "รับ Mbeans"
stringMap[strings.Mind_Explain] = "คำอธิบาย"
stringMap[strings.Mind_Parenting] = "การเลี้ยงดู"
stringMap[strings.Mind_Internet] = "อินเทอร์เน็ต"
stringMap[strings.Mind_Psychology] = "จิตวิทยา"
stringMap[strings.Mind_literature] = "วรรณกรรม"
stringMap[strings.Mind_Technology] = "เทคโนโลยี"
stringMap[strings.Mind_Workplace] = "สถานที่ทำงาน"
stringMap[strings.Mind_Life] = "ชีวิต"
stringMap[strings.Mind_Subject] = "เรื่อง"
stringMap[strings.Hot_Group] = "กลุ่มร้อนแรง"
stringMap[strings.Open_Group] = "เปิดกลุ่ม"
stringMap[strings.Mind_Group_Used] = "ใช้แล้ว"

stringMap[strings.Mind_Click_Select] = "คลิกเพื่อเลือก"
stringMap[strings.Mind_Double_Click_Enter] = "ดับเบิลคลิกเพื่อเข้าสู่"
stringMap[strings.Search_Shared_Groups] = "ค้นหากลุ่มที่แชร์"
stringMap[strings.Search_Storm] = "ค้นหาพายุ"
stringMap[strings.Members_Save_Free] = "สมาชิกบันทึกฟรี"
stringMap[strings.Bank_Card_Account_Name] = "โปรดป้อนชื่อบัญชีธนาคารของคุณ"
stringMap[strings.Bank_Card_No] = "หมายเลขบัตรธนาคาร"
stringMap[strings.Please_Enter_Your_Bank_Card_No] = "โปรดป้อนหมายเลขบัตรธนาคารของคุณ"
stringMap[strings.Branch_Sub_Branch_Information] = "ข้อมูลสาขาของสาขา"
stringMap[strings.Branch_Sub_Branch_Example] = "ตัวอย่างเช่น สาขาย่อยเฉิงซีสาขาหางโจว"
stringMap[strings.Application_For_Withdrawal] = "การขอถอนเงิน"
stringMap[strings.Withdrawal_Amount] = "จำนวนเงินที่ถอน"
stringMap[strings.Withdrawal] = "ถอนออก"
stringMap[strings.Current_Revenue] = "รายได้ปัจจุบัน"
stringMap[strings.Recharge] = "เติมเงิน"
stringMap[strings.Current_Total_MBeans] = "จำนวน MBeans ทั้งหมดในปัจจุบัน"
stringMap[strings.Withdrawal_Status] = "สถานะการถอนเงิน"
stringMap[strings.Insufficient_Withdrawal_Amount] = "จำนวนเงินที่ถอนไม่เพียงพอ"
stringMap[strings.Load_More] = "โหลดเพิ่มเติม"
stringMap[strings.Loading] = "กำลังโหลด"
stringMap[strings.It_Is_Over] = "จบแล้ว"
stringMap[strings.Default_Label] = "ป้ายกำกับเริ่มต้น"
stringMap[strings.Selected_Label] = "ป้ายกำกับที่เลือก"
stringMap[strings.Current_Location] = "ตำแหน่งปัจจุบัน"
stringMap[strings.MGold_Introduction] = "คุณได้รับ M ทองผ่าน Mind+"
stringMap[strings.MGold_Details] = "กลุ่มคลาวด์ที่ชำระเงินของคุณ มีแผนที่ความรู้ สื่อการเรียนรู้ ฯลฯ ในกลุ่ม หากผู้ใช้เข้าร่วมการชำระเงิน M bean 50% ของพวกเขาจะถูกแปลงเป็น M gold และ M gold มากกว่า เกิน 50 ถอนได้"
stringMap[strings.Cloud_Group_Introduction] = "แชร์กลุ่มของคุณ คนอื่นสามารถดูได้ในหน้าพายุ"
stringMap[strings.Cloud_Group_Details] = "กลุ่มคลาวด์ที่ชำระเงินของคุณ มีแผนที่ความรู้ สื่อการเรียนรู้ ฯลฯ ในกลุ่ม หากผู้ใช้เข้าร่วมการชำระเงิน M bean 50% จะถูกแปลงเป็น M ทอง และ M ทองมากกว่า สกัดได้กว่า 50 ตัว"
stringMap[strings.Share_Storm_Introduction] = "แบ่งปันแผนที่ของคุณกับชุมชนพายุ เพื่อให้ผู้คนสามารถเรียนรู้และสำรวจความรู้จากคุณได้มากขึ้น"
stringMap[strings.Share_Storm_Details] = "หากคุณเปิดใช้งานการใช้ M bean เพื่อบันทึกแผนที่ ผู้ใช้รายอื่นสามารถใช้แผนที่ความรู้ของคุณได้ และคุณจะได้รับ 50% ของถั่ว M ถั่ว M สามารถใช้ซื้อสมาชิกและเข้าร่วมแบบชำระเงินได้ กลุ่ม คุณยังสามารถชำระเงินเพื่อใช้แผนที่ของผู้อื่นได้"

stringMap[strings.Linked_Successfully] = "เชื่อมโยงสำเร็จ"
stringMap[strings.Cancel_Archiving] = "ยกเลิกการเก็บถาวร"
stringMap[strings.Please_Select_Archive_Location] = "โปรดเลือกตำแหน่งที่เก็บถาวร"
stringMap[strings.The_Search_Result_Is_Empty] = "ผลการค้นหาว่างเปล่า"

stringMap[strings.Customize_A_Style] = "ผู้ที่ไม่ใช่สมาชิกสามารถปรับแต่งสไตล์ได้เพียงสไตล์เดียวเท่านั้น"
stringMap[strings.Adds_Current_Guide_Style_Library] = "เพิ่มรูปแบบแผนที่ปัจจุบันไปยังไลบรารีสไตล์"
stringMap[strings.Trial_Version] = "รุ่นทดลอง"
stringMap[strings.Not_Supported_At_The_Moment] = "ไม่รองรับในขณะนี้"
stringMap[strings.Mind_File] = "ไฟล์"
stringMap[strings.Mind_Import] = "นำเข้า"
stringMap[strings.Importing] = "กำลังนำเข้า"

stringMap[strings.Mind_Edit_Insert_Video] = "แทรกวิดีโอ"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "ลากและวางวิดีโอที่นี่"
stringMap[strings.Message_Tips_Video_Upload_Time_Limit] = "ระยะเวลาอัปโหลดวิดีโอต้องไม่เกิน 60 วินาที"
stringMap[strings.Mind_Edit_Node_Menu_View_Video] = "ดูวิดีโอ"
stringMap[strings.Mind_Edit_Node_Delete_View_Video] = "ลบวิดีโอ"
stringMap[strings.Message_Tips_Share_Video_Does_Not_Exist] = "วิดีโอที่อัปโหลดต้องไม่เกิน 10MB"
stringMap[strings.Mind_Upload_Video_Restrictions] = "รองรับการอัปโหลดวิดีโอในรูปแบบ MP4 สูงสุด 120s และ 10M เท่านั้น"
stringMap[strings.Mind_Video_Size] = "ขนาดวิดีโอ"
stringMap[strings.Mind_Edit_Left_Menu_Export_Word] = "ส่งออกคำ"
stringMap[strings.Mind_Edit_Left_Menu_Export_TXT] = "ส่งออก TXT"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap[strings.Export_Failed] = "การส่งออกล้มเหลว"
stringMap[strings.Add_File] = "เพิ่มไฟล์"
stringMap[strings.Drag_The_File_Directly_To_Upload] = "ลากไปยังไฟล์โดยตรงเพื่ออัปโหลด"
stringMap[strings.Supported_Imported_File_Types] = "ประเภทไฟล์ที่รองรับการนำเข้าในปัจจุบัน: Word (เฉพาะ .docx), XMind, .txt"
stringMap[strings.No_Permission_Do_This] = "คุณไม่ได้รับอนุญาตให้ดำเนินการนี้"

stringMap[strings.Upgrade_To_Professional] = "อัปเกรดเป็น Professional Edition"
stringMap[strings.Non_Members_Insert_Videos] = "ผู้ที่ไม่ได้เป็นสมาชิกสามารถแทรกวิดีโอได้หลังจากลงทะเบียน 24 ชั่วโมงเท่านั้น"
stringMap[strings.Upload_Failed] = "การอัปโหลดล้มเหลว"
stringMap[strings.Upload_Failed_Title_Message] = "การอัปโหลดล้มเหลว โปรดคลิกเพื่อยืนยัน ลบรูปภาพที่เหลือที่ไม่สามารถอัปโหลดได้"

stringMap[strings.Mind_Edit_Members_Use] = "สมาชิกใช้"
stringMap[strings.Invitation_Code] = "รหัสเชิญ"
stringMap[strings.Student_Certification] = "ใบรับรองนักศึกษา"
stringMap[strings.I_Get_It] = "ฉันจะ ข้ามคำแนะนำ"
stringMap[strings.Welcome_To_Mind] = "ยินดีต้อนรับนายน้อยเข้าร่วม Mind+ เรามาเริ่มต้นการเดินทางแห่งการคิดกันเถอะ!"
stringMap[strings.Mind_Before] = "ก่อน"
stringMap[strings.Try_A_Map] = "ลองใช้แผนที่"
stringMap[strings.Click_Create] = "คลิกเพื่อสร้าง"
stringMap[strings.Click_Create_A_Mind_Map] = "คลิกเพื่อสร้างแผนที่ความคิด"
stringMap[strings.Mind_About] = "เกี่ยวกับ"
stringMap[strings.See_Checking_Data] = "ดูข้อมูลส่วนบุคคล"
stringMap[strings.Modify_Checking_Data] = "แก้ไขข้อมูลส่วนบุคคล"
stringMap[strings.Mind_Shear] = "เฉือน"

stringMap[strings.Mind_Style_Empty] = "ไม่มีรูปแบบที่ชื่นชอบหรือกำหนดเอง"
stringMap[strings.Mind_Branch_Free_Layout] = "เค้าโครงฟรีของสาขา"
stringMap[strings.Mind_Branch_Free_Layout_explain] = "โครงสร้างแบบสองทางและสาขาแผนที่แบบฟองสามารถลากไปยังเลย์เอาต์ได้อย่างอิสระ"
stringMap[strings.Mind_Free_Theme_Drag_Dnd_Snap] = "สแนปลากธีมฟรี"

stringMap[strings.Select_A_Node] = "เลือกหัวข้อ"
stringMap[strings.Create_Child_Node] = "สร้างธีมลูก"
stringMap[strings.Add_A_Nice_Image_To_The_Node] = "ต่อไปเราจะเพิ่มธีมที่ดีให้กับธีมย่อย"
stringMap[strings.Click_Icon_Panel] = "คลิกที่แผงไอคอน"
stringMap[strings.Select_An_Icon_You_Like] = "เลือกไอคอนที่คุณชอบ"
stringMap[strings.Replace_The_Mind_Map_With_A_New_One] = "ต่อไปเราจะแทนที่แผนที่ความคิดด้วยแผนที่ใหม่"
stringMap[strings.Select_The_Central_Theme] = "เลือกธีมหลัก"
stringMap[strings.Switch_To_Canvas_Panel] = "สลับไปที่แผงผ้าใบ"
stringMap[strings.Click_Layout] = "คลิกเพื่อเลย์เอาต์"
stringMap[strings.Choose_A_Layout_You_Like] = "เลือกเลย์เอาต์ที่คุณชอบ"
stringMap[strings.Mind_Map_Save_Share] = "ยินดีด้วย คุณสร้างแผนที่ความคิดที่สวยงามสำเร็จแล้ว มาบันทึกและแบ่งปันกับเพื่อนของคุณกันเถอะ~"
stringMap[strings.Click_Save_Picture] = "คลิกเพื่อบันทึกรูปภาพ"
stringMap[strings.Click_OK] = "คลิกตกลง"
stringMap[strings.Congratulations_On_Completing_The_Novice_Guide] = "ยินดีด้วยที่ทำตามคำแนะนำของมือใหม่~"
stringMap[strings.Upload_Succeeded] = "อัปโหลดสำเร็จแล้ว"

stringMap[strings.Mind_Edit_Node_Menu_Quick_Style] = "รูปแบบด่วน"
stringMap[strings.Mind_Extremely_Important] = "สำคัญมาก"
stringMap[strings.Mind_Important] = "สำคัญ"
stringMap[strings.Mind_Commonly] = "ทั่วไป"
stringMap[strings.Mind_Ordinary] = "ธรรมดา"
stringMap[strings.Member_Exclusive] = "คุณสมบัตินี้เป็นเอกสิทธิ์ของสมาชิก คุณต้องการอัพเกรดสมาชิกภาพหรือไม่"
stringMap[strings.Member_Exclusive_Color_Scheme] = "ชุดสีนี้เป็นเอกสิทธิ์ของสมาชิก คุณต้องการอัพเกรดสมาชิกภาพหรือไม่"
stringMap[strings.Mind_Enlarge] = "ขยาย"
stringMap[strings.Mind_Narrow] = "แคบ"
stringMap[strings.Mind_Center] = "อยู่ตรงกลาง"
stringMap[strings.Mind_Full_Screen] = "เต็มหน้าจอ"

stringMap[strings.Mind_Association_Node] = "โหนดการเชื่อมโยง"

stringMap[strings.Mind_Resource_See] = "ดูไฟล์แนบ"
stringMap[strings.Mind_Resource_Delete] = "ลบไฟล์แนบ"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "ลากและวางไฟล์ที่นี่"
stringMap[strings.Mind_Edit_choose_explain] = "เลือกไฟล์"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "ไฟล์ที่อัปโหลดต้องไม่เกิน 20MB"

stringMap[strings.Please_Enter_Mind_Password] = "กรุณาป้อนรหัสผ่าน 4-8 บิต"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "กรุณาใส่รหัสผ่าน"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "คุณลักษณะนี้เป็นฟังก์ชันสมาชิก"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "ลบรหัสผ่านไฟล์"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "รหัสผ่านไม่เพียงพอกรุณาป้อนรหัสผ่าน"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "รหัสผ่านยาวเกินไปโปรดป้อนรหัสผ่าน"
stringMap[strings.Please_Enter_Mind_Password_Success] = "ตั้งค่ารหัสผ่านเรียบร้อยแล้ว"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "ข้อผิดพลาดในรูปแบบรหัสผ่าน"
stringMap[strings.Mind_Load_Data_Error] = "เกิดข้อผิดพลาดในการโหลดข้อมูล"
stringMap[strings.Please_Enter_Mind_Password_Error] = "รหัสผ่านผิดพลาด"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "ผู้สร้างแผนที่เท่านั้นที่สามารถตั้งค่ารหัสผ่าน"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;