const { default: strings } = require("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Mind + Mind Map';
stringMap [strings.TEMPLATE] = 'Templat';
stringMap [strings.Mind_Edit_Main_Topic] = 'Topik Cabang';
stringMap [strings.Global_Add_Free_Node_Tips] = 'Klik pada layar untuk memilih lokasi untuk ditambahkan';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'Klik dua kali layar untuk memilih lokasi yang akan ditambahkan';
stringMap [strings.Global_Add_Relationship_Tips] = 'Pilih target yang perlu dikaitkan';
stringMap [strings.Global_Add_Target_Tips] = 'Silakan pilih node untuk menambahkan objek';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Objek ini sudah diasosiasikan dengan peta lain';
stringMap [strings.Mind_Edit_Main_Idea] = 'Tema Pusat';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'Peta terkait tidak ada';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'Prioritas';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'Kemajuan';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'Bintang';
stringMap [strings.Mind_Style_Add_Icon_Week] = 'minggu';
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'Potret';
stringMap [strings.Establish] = 'Buat';
stringMap [strings.Create_From_Template] = 'Buat dari template';
stringMap [strings.Common] = 'Umum';
stringMap [strings.Function_List] = 'Daftar Fungsi';
stringMap [strings.Recent_Updates] = 'Baru-baru ini diperbarui';
stringMap [strings.The_Latest_To_Delete] = 'Baru dihapus';
stringMap [strings.Setting_Up] = 'Hubungi kami';
stringMap [strings.Usinghelp] = 'Menggunakan bantuan';
stringMap [strings.The_Download_Client] = 'Unduh versi klien / seluler';
stringMap [strings.Global_Purchase] = 'Pembelian';
stringMap [strings.Global_Login] = 'Masuk';
stringMap [strings.My_map] = 'Petaku';
stringMap [strings.Create_Map] = 'Buat peta';
stringMap [strings.Storm] = 'Badai';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Umum \ nKarakteristik';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'Karakteristik';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'Pusat Organisasi';
stringMap [strings.Mind_Edit_Free_Topic] = 'Topik Gratis';
stringMap [strings.Mind_Edit_Sub_Topic] = 'Sub Topik';
stringMap [strings.Mind_Organization_Main_Topic] = 'Departemen';
stringMap [strings.Mind_Edit_Main_Topic] = 'Topik Cabang';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'Perencanaan';
stringMap [strings.Mind_Time_Edit_Head] = 'Waktu';
stringMap [strings.Mind_Time_Edit_Title] = 'Rencana';
stringMap [strings.Mind_Edit_Details] = 'Deskripsi Terperinci';
stringMap [strings.Checking_Data] = 'Memeriksa data';
stringMap [strings.Setting_Information] = 'Informasi Pengaturan';
stringMap [strings.Sign_Out] = 'Keluar';

// daftar fungsi
stringMap [strings.Create_a_Mind_Map] = 'Buat Peta Pikiran';
stringMap [strings.Create_Mind_Map_1] = '[1] Buat peta dengan empat belas tata letak (dua arah / kanan / kiri / struktur organisasi / bagan gelembung tunggal / bagan gelembung ganda / peta pohon kiri / peta pohon kanan / Diagram pohon dua arah / tabel / garis waktu horizontal / garis waktu vertikal / garis waktu S / piramida) ';
stringMap [strings.Create_Mind_Map_2] = '[2] Template dapat digunakan untuk membuat peta dengan cepat (sejumlah besar referensi konten template)';
stringMap [strings.Create_Mind_Map_3] = '[3] Edit peta dengan sembilan jenis gaya garis';
stringMap [strings.Create_Mind_Map_4] = '[4] Buat sembilan perubahan tata letak dan edit peta';
stringMap [strings.Create_Mind_Map_5] = '[5] Tambahkan node anak ke node target dan tambahkan node pada tingkat yang sama (atas dan bawah)';
stringMap [strings.Create_Mind_Map_6] = '[6] Ringkasan: Tambahkan [Ringkasan] untuk mendeskripsikan satu node / beberapa node';
stringMap [strings.Create_Mind_Map_7] = '[7] Blok: tambahkan [blok] ke satu node / beberapa kotak node dari peta yang akan dimodulasi';
stringMap [strings.Create_Mind_Map_8] = '[8] Tambahkan node gratis';
stringMap [strings.Create_Mind_Map_9] = '[9] Buat asosiasi dari empat gaya garis antara setiap node';
stringMap [strings.Create_Mind_Map_10] = '[10] Sisipkan gambar (pustaka bahan + lokal), ikon, catatan, tautan ke simpul';
stringMap [strings.Create_Mind_Map_11] = '[11] Salin / tempel simpul, salin / tempel gaya';
stringMap [strings.Create_Mind_Map_12] = '[12] Batalkan, pulihkan dan batalkan';
stringMap [strings.Create_Mind_Map_13] = '[13] Beberapa pilihan tersedia, beberapa node dapat dioperasikan secara bersamaan';
stringMap [strings.Create_Mind_Map_14] = '[14] Grafik statistik dapat disisipkan';
stringMap [strings.Create_Mind_Map_15] = '[15] Seluruh peta dapat direkam';
stringMap [strings.Create_Mind_Map_16] = '[16] Salin peta';
stringMap [strings.Create_Mind_Map_17] = '[17] Anda dapat menyalin peta atau seluruh peta ke peta dari file lain';
stringMap [strings.Create_Mind_Map_18] = '[18] Sebuah peta dapat dibuat setelah ringkasan';
stringMap [strings.Create_Mind_Map_19] = '[19] Pengeditan peta dapat dialihkan selama pengeditan (sisi komputer)';
stringMap [strings.Create_Mind_Map_20] = '[20] Peta pengeditan keyboard Bluetooth: \ n \ t [Command] + [a] Pilih node master \ n \ t [Command] + [←] [↑] [→] [↓ ] Ubah target pilihan atau pindahkan peta \ n \ t [Command] + [e] Edit target \ n \ t [Command] + [w] Buat node anak \ n \ t [Command] + [s] Buat node saudara target ( Atas) \ n \ t [Perintah] + [d] Buat simpul saudara target (bawah) \ n \ t [Perintah] + [q] Keluar dari target yang dipilih \ n \ t [Perintah] + [-] [+] Zoom peta';

stringMap [strings.Beautify_The_Mind_Map] = 'Percantik Peta Pikiran';
stringMap [strings.Beautify_The_Mind_Map_1] = '[1] Setel warna latar belakang peta pikiran';
stringMap [strings.Beautify_The_Mind_Map_2] = '[2] Atur ketebalan dan warna seluruh garis peta, apakah itu meruncing atau tidak';
stringMap [strings.Beautify_The_Mind_Map_3] = '[3] Atur warna isian teks, warna pembatas, bentuk pembatas 13, lebar pembatas, efek bayangan';
stringMap [strings.Beautify_The_Mind_Map_4] = '[4] Setel semua atau sebagian warna garis dan lebar peta';
stringMap [strings.Beautify_The_Mind_Map_5] = '[5] Atur ukuran font teks, cetak tebal, miring, hapus gaya';
stringMap [strings.Beautify_The_Mind_Map_6] = '[6] Atur ukuran gambar, posisi, dan gaya bentuk';
stringMap [strings.Beautify_The_Mind_Map_7] = '[7] Tambahkan ikon dari tujuh kategori (umum / 3D / studi / kerja / orang / hewan / makanan) dan setel ukuran / lokasi';
stringMap [strings.Beautify_The_Mind_Map_8] = '[8] Tambahkan referensi gaya sekali klik ke seluruh peta';
stringMap [strings.Beautify_The_Mind_Map_9] = '[9] Peta Manajemen Grup';
stringMap [strings.Beautify_The_Mind_Map_10] = '[10] Latar belakang yang dipersonalisasi (perpustakaan bahan) dapat ditambahkan ke seluruh peta';
stringMap [strings.Beautify_The_Mind_Map_9] = '[11] Batas individu atau latar belakang (perpustakaan bahan) dapat ditambahkan ke satu node';
stringMap [strings.Beautify_The_Mind_Map_9] = '[12] Panjang node bisa diseret';


stringMap [strings.Map_Output] = 'Keluaran peta';
stringMap [strings.Map_Output_1] = '[1] Simpan gambar';
stringMap [strings.Map_Output_2] = '[2] Simpan PDF';
stringMap [strings.Map_Output_3] = '[3] Bagikan tautan';
stringMap [strings.Map_Output_4] = '[4] Bagikan ke badai';
stringMap [strings.Map_Output_4] = '[5] Cetak';

stringMap [strings.Work_together] = 'Bekerja dalam kolaborasi';
stringMap [strings.Work_together_1] = '[1] Buat grup bersama (label grup, pengumuman grup)';
stringMap [strings.Work_together_2] = '[2] Anggota grup bersama baru';
stringMap [strings.Work_together_3] = '【3】 Beberapa anggota dalam grup mengedit peta yang sama bersama-sama';
stringMap [strings.Work_together_4] = '[4] Unggah file bersama di dalam grup (word / excle / pdf)';
stringMap [strings.Work_together_5] = '[5] Anda dapat meninggalkan pesan di grup';
stringMap [strings.Work_together_6] = '[6] Pengaturan otoritas manajemen grup bersama';
stringMap [strings.Work_together_7] = '[7] Bubarkan Grup';

stringMap [strings.Work_together] = 'Berbagi Kreatif (Badai)';
stringMap [strings.Community_Sharing_1] = '[1] Anda dapat membagikan kreasi Anda ke badai sehingga lebih banyak orang dapat melihatnya';
stringMap [strings.Community_Sharing_1] = '[2] Anda dapat memilih tag untuk melihat konten badai terkait';
stringMap [strings.Community_Sharing_1] = '[3] Anda dapat menyukai dan mengomentari karya badai';

// Baru-baru ini diperbarui
stringMap [strings.Recently_Updated_Title_1] = 'Buat akun pribadi';
stringMap [strings.Recently_Updated_Title_2] = 'Basis Pengetahuan Template Besar-besaran';
stringMap [strings.Recently_Updated_Title_3] = 'Mendukung banyak platform';
stringMap [strings.Recently_Updated_Title_4] = 'Sesuaikan setiap detail';
stringMap [strings.Recently_Updated_Title_5] = 'Perpustakaan gaya gaya pribadi';
stringMap [strings.Recently_Updated_Title_6] = 'Keluaran peta';
stringMap [strings.Recently_Updated_Title_7] = 'Penyimpanan Awan';
stringMap [strings.Recently_Updated_Title_8] = 'Lima belas jenis peta';


stringMap [strings.Recently_Updated_Content_1] = 'Buat akun pribadi dan simpan peta yang dibuat dengan akun tersebut';
stringMap [strings.Recently_Updated_Content_2] = 'Sejumlah besar basis pengetahuan template dikutip secara langsung untuk meningkatkan efisiensi pembelajaran';
stringMap [strings.Recently_Updated_Content_3] = 'Mendukung produksi online versi web, terminal seluler IOS, terminal Mac, terminal seluler Android, penggunaan multi-platform tablet Android';
stringMap [strings.Recently_Updated_Content_4] = 'Anda dapat mempercantik desain teks, latar belakang, garis, ikon gambar, dll sesuai dengan preferensi pribadi';
stringMap [strings.Recently_Updated_Content_5] = 'Pustaka gaya gaya pribadi dikutip langsung, menghilangkan masalah pencocokan warna';
stringMap [strings.Recently_Updated_Content_6] = 'Anda dapat menyimpan gambar, format PDF, dan berbagi langsung dengan teman';
stringMap [strings.Recently_Updated_Content_7] = 'Dukungan penyimpanan awan yang kuat, tidak perlu lagi khawatir tentang kehilangan data';
stringMap [strings.Recently_Updated_Content_8] = 'Dua arah, kanan, kiri, struktur organisasi, bagan gelembung tunggal, bagan gelembung ganda, bagan pohon kiri, bagan pohon kanan, bagan pohon dua arah, tabel, garis waktu horizontal, Garis waktu vertikal, S garis waktu, delapan tata letak dapat diubah sesuka hati ';

// Baru dihapus
stringMap [strings.Mind_Mindmap_Deleted_Title] = 'Baru dihapus';
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = 'Pemulihan';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'Hapus';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'Nama file';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'Setelah menghapus di sini, ini akan dihapus secara permanen';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'Operasi';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = 'Cari peta yang baru saja dihapus';
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = 'Hapus Tanggal';

//Hubungi kami
stringMap [strings.Contact_Us_No_Public] = 'Akun Publik';
stringMap [strings.Contact_Us_QQ_Group] = 'Grup QQ';
stringMap [strings.Contact_Us_Message_Board] = 'Papan Pesan';
stringMap [strings.Contact_Us_Username] = 'Nama';
stringMap [strings.Contact_Us_Email] = 'Email';
stringMap [strings.Contact_Us_Voicemail] = 'Silakan tinggalkan komentar berharga Anda';
stringMap [strings.Contact_Us_Send_Email] = 'Kirim Email';

// verifikasi pesan
stringMap [strings.Input_Username_isNull] = 'Nama tidak boleh kosong';
stringMap [strings.Input_Email_isNull] = 'Silakan masukkan alamat email yang benar';
stringMap [strings.Input_Voicemail_isNull] = 'Silakan masukkan konten yang dikirim';
stringMap [strings.Input_Introduction_isNull] = 'Perkenalan tidak boleh kosong';
stringMap [strings.Input_Title_isNull] = 'Judul tidak boleh kosong';

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'Silakan masukkan nomor telepon yang benar';
stringMap [strings.Input_Please_Enter_Verification_Code] = 'Silakan masukkan kode verifikasi! ';
stringMap [strings.Input_Please_Enter_The_Password] = 'Silakan masukkan kata sandi! ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = 'Format kata sandi salah! ';
stringMap [strings.Input_Please_Enter_A_New_Password] = 'Silakan masukkan sandi baru! ';
stringMap [strings.Input_Confirm_The_New_Password] = 'Silakan masukkan kata sandi konfirmasi! ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = 'Kata sandi tidak konsisten! ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = 'Silakan masukkan kode verifikasi yang benar! ';
stringMap [strings.Input_The_Verification_Code_Error] = 'Kesalahan kode verifikasi! ';

// Menggunakan bantuan
stringMap [strings.Mind_Usinghelp_1] = 'Buat peta pikiran';
stringMap [strings.Mind_Usinghelp_2] = 'Bagaimana cara menambahkan node';
stringMap [strings.Mind_Usinghelp_3] = 'Buat node gratis';
stringMap [strings.Mind_Usinghelp_4] = 'Buat asosiasi';
stringMap [strings.Mind_Usinghelp_5] = 'mempercantik kanvas';
stringMap [strings.Mind_Usinghelp_6] = 'mempercantik teks';
stringMap [strings.Mind_Usinghelp_7] = 'Sisipkan catatan, tautan';
stringMap [strings.Mind_Usinghelp_8] = 'Sisipkan ikon';
stringMap [strings.Mind_Usinghelp_9] = 'Sisipkan Gambar';
stringMap [strings.Mind_Usinghelp_10] = 'Cara memulihkan penghapusan';
stringMap [strings.Mind_Usinghelp_11] = 'Ekspor Peta Pikiran';
stringMap [strings.Mind_Usinghelp_12] = 'Peningkatan keanggotaan';
stringMap [strings.Mind_Usinghelp_13] = 'Tombol Pintas';
stringMap [strings.Mind_Usinghelp_Understood] = 'Saya mengerti';
stringMap [strings.Mind_Usinghelp_14] = 'Sisipkan perpustakaan materi';
// Gunakan bantuan-2
stringMap [strings.Mind_Usinghelp_15] = 'Peta Baru';
// stringMap [strings.Mind_Usinghelp_2] = 'Tambah node';
// stringMap [strings.Mind_Usinghelp_4] = 'Buat asosiasi';
// stringMap [strings.Mind_Usinghelp_5] = 'Percantik kanvas';
// stringMap [strings.Mind_Usinghelp_6] = 'Percantik teks';
// stringMap [strings.Mind_Usinghelp_7] = 'Tambahkan catatan, tautan';
stringMap [strings.Mind_Usinghelp_17] = 'Batalkan operasi, batalkan';
stringMap [strings.Mind_Usinghelp_18] = 'Tarik node untuk bertukar';
stringMap [strings.Mind_Usinghelp_19] = 'Perataan node';
stringMap [strings.Mind_Usinghelp_20] = 'Ubah tipe peta';
stringMap [strings.Mind_Usinghelp_21] = 'Gaya garis peta';
stringMap [strings.Mind_Usinghelp_22] = 'Warna latar belakang peta';
stringMap [strings.Mind_Usinghelp_23] = 'Setel bentuk simpul';
stringMap [strings.Mind_Usinghelp_24] = 'Gaya garis node';
stringMap [strings.Mind_Usinghelp_25] = 'Pengaturan Gambar Node';
stringMap [strings.Mind_Usinghelp_26] ='Pengaturan ikon node';
stringMap [strings.Mind_Usinghelp_27] = 'Node Tambah Ringkasan';
stringMap [strings.Mind_Usinghelp_28] = 'Pemilihan kotak node tunggal';
stringMap [strings.Mind_Usinghelp_29] = 'Multi-node menjadi blok';
stringMap [strings.Mind_Usinghelp_30] = 'Node tambahkan batas';
stringMap [strings.Mind_Usinghelp_31] = 'Tambahkan peta statistik';
stringMap [strings.Mind_Usinghelp_32] = 'Sembunyikan node dan pulihkan';
stringMap [strings.Mind_Usinghelp_33] = 'Node beberapa operasi';
stringMap [strings.Mind_Usinghelp_34] = 'Ubah gaya';
stringMap [strings.Mind_Usinghelp_35] = 'Sisipkan suara dan penjelasan';
stringMap [strings.Mind_Usinghelp_36] = 'Panduan Memetakan ke Gambar';
stringMap [strings.Mind_Usinghelp_37] = 'Petakan dokumen PDF';
stringMap [strings.Mind_Usinghelp_38] = 'Cetak peta';
stringMap [strings.Mind_Usinghelp_39] = 'Berbagi dan melihat tautan';
stringMap [strings.Mind_Usinghelp_40] = 'Peta untuk dibagikan ke badai';
stringMap [strings.Mind_Usinghelp_41] = 'Menggunakan folder';
stringMap [strings.Mind_Usinghelp_42] = 'Hapus dan pulihkan peta';
// Gunakan bantuan 3
stringMap [strings.Mind_Usinghelp_43] = 'Teman mendaftar untuk bergabung dengan grup';
stringMap [strings.Mind_Usinghelp_44] = 'Bagaimana membubarkan grup';
stringMap [strings.Mind_Usinghelp_45] = 'Unggah informasi file';
stringMap [strings.Mind_Usinghelp_46] = 'Setuju untuk bergabung dengan aplikasi';
stringMap [strings.Mind_Usinghelp_47] = 'Pengeditan Kolaboratif';
stringMap [strings.Mind_Usinghelp_48] = 'Grup Berbagi Baru';
stringMap [strings.Mind_Usinghelp_49] = 'Undang anggota grup';

//badai
stringMap [strings.Mind_Storm_Recommend] = 'Rekomendasi';
stringMap [strings.Mind_Storm_Boilingpoint] = 'Titik didih';
stringMap [strings.Mind_Storm_Unfold] = 'Terungkap';
stringMap [strings.Mind_Storm_Putaway] = 'Putaway';
stringMap [strings.Mind_Storm_Myclassification] = 'Klasifikasi saya';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'Klasifikasi yang Direkomendasikan';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'Klik untuk menambah kategori';
stringMap [strings.Mind_Storm_No_Classification] = 'Belum ada klasifikasi';
stringMap [strings.Mind_Storm_No_Tinterested] = 'Tidak tertarik';
stringMap [strings.Mind_Storm_Report] = 'Laporan';
stringMap [strings.Mind_Storm_Views] = 'Tampilan';

stringMap [strings.Mind_Storm_Pornographic] = 'Pornografi vulgar';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'Pelecehan Iklan';
stringMap [strings.Mind_Storm_Political_Religion] = 'Politik dan Agama';
stringMap [strings.Mind_Storm_Infringement] = 'Pelanggaran (potret, fitnah, plagiarisme, penggunaan curang)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'Konten yang dilarang';
stringMap [strings.Mind_Storm_Spread_Rumors] = 'Sebarkan rumor dan sebarkan rumor';
stringMap [strings.Mind_Storm_Report_Successful] = 'Laporkan berhasil';

// edit
stringMap [strings.Global_Edit] = 'Sunting';
stringMap [strings.Global_Cancel] = 'Batal';
stringMap [strings.Global_Ok] = 'OK';
stringMap [strings.Global_Name] = 'Nama Panggilan';
stringMap [strings.Global_Introduction] = 'Perkenalan';
stringMap [strings.Global_Change_Avatar] = 'Ubah Avatar';
stringMap [strings.Global_Local_Upload] = 'Unggahan lokal';

//informasi pribadi
stringMap [strings.User_Personal_information] = 'Informasi Pribadi';
stringMap [strings.User_Member_Validity_Period] = 'Masa Berlaku Anggota';
stringMap [strings.User_My_Share] = 'Bagian Saya';
stringMap [strings.User_My_Collection] = 'Koleksi Saya';
stringMap [strings.User_More_Message] = 'Lainnya';
stringMap [strings.User_Deleted_Sotrm] = 'Hapus Badai';
stringMap [strings.User_Folder_Rename] = 'Ganti Nama';
stringMap [strings.User_Folder_Rename_Root] = 'Ubah izin';
stringMap [strings.User_Folder_Rename_Root_Title] = 'Ubah izin';
stringMap [strings.User_Folder_Root_Public] = 'Sepenuhnya publik';
stringMap [strings.User_Folder_Root_Viewonly] = 'Folder publik hanya diperbolehkan untuk dilihat';
stringMap [strings.User_Folder_Root_Private] = 'Pribadi';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'Orang lain dapat melihat favorit Anda pada halaman profil Anda';
stringMap [strings.User_Message_Link] = 'Tautan';
stringMap [strings.User_New_Group] = 'Grup baru';
stringMap [strings.User_New_Group_Title] = 'Buat grup baru';
stringMap [strings.User_New_Group_Style_Title] = 'Jenis File';
stringMap [strings.User_Share_Is_Null] = 'Belum ada pembagian! ';
stringMap [strings.User_Deleted_Storm_Tips] = 'Anda yakin ingin menghapus badai ini? ';
stringMap [strings.User_Remove_From_List] = 'Hapus dari daftar',
    stringMap [strings.User_Move_To] = 'Pindah ke',
    stringMap [strings.User_Deleted_Link] = 'Hapus link'
stringMap [strings.User_Collction_Is_Null] = 'Belum ada koleksi'
stringMap [strings.User_Collection] = 'Koleksi'

// Kotak login
stringMap [strings.User_Lolgin_Welcome_To_Login] = 'Selamat Datang di Pikiran +'
stringMap [strings.User_Lolgin_Free_Registration] = 'Pendaftaran gratis'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'Silakan masukkan nomor telepon Anda'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'Silakan masukkan kode verifikasi'
stringMap [strings.User_Lolgin_Obtain] = 'Dapatkan'
stringMap [strings.User_Lolgin_Password_Login] = 'Masuk kata sandi'
stringMap [strings.User_Lolgin_Login] = 'Masuk'
stringMap [strings.User_Lolgin_Register_An_Account] = 'Pendaftaran Akun'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'Sudah punya akun'
stringMap [strings.User_Lolgin_To_Log_In] = 'Masuk ke log in'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'Silakan masukkan sandi'
stringMap [strings.User_Lolgin_Confirm_Password] = 'Konfirmasi kata sandi'
stringMap [strings.User_Lolgin_Registered] = 'Terdaftar'
stringMap [strings.User_Lolgin_Account_Login] = 'Login akun'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'Masuk kode pindai'
stringMap [strings.User_Lolgin_Forgot_Password] = 'Lupa kata sandi'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'Login kode verifikasi'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Buka Pikiran + pindai di bagian atas antarmuka pengaturan'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'Kode QR ini kedaluwarsa dalam lima menit, silakan klik ketika kadaluarsa'
stringMap [strings.User_Lolgin_Refresh] = 'Segarkan'
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'Pemindaian berhasil, mohon konfirmasi pada ponsel Anda'
stringMap [strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'Apakah akan mengotorisasi proses masuk'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'Silakan masukkan kata sandi baru'
stringMap [strings.User_Lolgin_Reset] = 'Setel Ulang'

// Peta saya
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'Cari template jenis atau template'
stringMap [strings.My_Mind_Date] = 'Tanggal'
stringMap [strings.My_Mind_Create_Folder] = 'Buat folder'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'Silakan masukkan nama folder'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'Silakan masukkan nama folder baru'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'Belum ada peta, cepat pergi'
stringMap [strings.My_Mind_Create] = 'Buat'
stringMap [strings.My_Mind_Right] = 'Bar!'
stringMap [strings.My_Mind_Open] = 'Buka'
stringMap [strings.My_Mind_Type_Left_Right] = 'Peta panduan ganda'
stringMap [strings.My_Mind_Type_Unilateral] = 'Peta sepihak'
stringMap [strings.My_Mind_Type_Bottom] = 'Bagan Organisasi'
stringMap [strings.My_Mind_Type_Bubble] = 'Bagan Gelembung'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'Jadwalkan peta'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'Peta Pikiran Kosong'
stringMap [strings.My_Mind_All] = 'Semua'
stringMap [strings.My_Mind_Views] = 'Tampilan:'

// Pencarian badai
stringMap [strings.Storm_Search_How_Title] = 'Tag Teratas'
stringMap [strings.Storm_History_Search] = 'Pencarian riwayat'

// Header publik
stringMap [strings.Public_Header_Mind] = 'Peta'
stringMap [strings.Public_Header_Outline] = 'Garis Besar'
stringMap [strings.Public_Header_Download_Apps] = 'Unduh klien'
stringMap [strings.Public_Header_Mobile_Version] = 'Versi seluler'
stringMap [strings.Public_Header_Creator] = 'Pembuat:'
stringMap [strings.Public_Header_View_Frequency] = 'Tampilan:'
stringMap [strings.Public_Header_Save] = 'Simpan'
stringMap [strings.Header_Mind_Tips_Saved] = 'Disimpan'
stringMap [strings.Header_Mind_Tips_Edit] = 'Diedit'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'Pembaruan telah disimpan'

// Menu pengeditan peta
stringMap [strings.Mind_Edit_Revoke] = 'Cabut'
stringMap [strings.Mind_Edit_Restore] = 'Pulihkan'
stringMap [strings.Mind_Edit_Subtopic] = 'Subtopik'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'Subtopik Teratas'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'Subtopik Bawah'
stringMap [strings.Mind_Edit_Free_Node] = 'Node Gratis'
stringMap [strings.Mind_Edit_Summary] = 'Ringkasan'
stringMap [strings.Mind_Edit_Block] = 'Ke dalam blok'
stringMap [strings.Mind_Edit_Insert] = 'Sisipkan'
stringMap [strings.Mind_Edit_Related] = 'Terkait'
stringMap [strings.Mind_Edit_Style] = 'Gaya'
stringMap [strings.Mind_Edit_Recording] = 'Rekaman'
stringMap [strings.Mind_Edit_Insert_Picture] = 'Sisipkan gambar'
stringMap [strings.Mind_Edit_Insert_Icon] = 'Sisipkan ikon'
stringMap [strings.Mind_Edit_Insert_Remarks] = 'Sisipkan Komentar'
stringMap [strings.Mind_Edit_Insert_Link] = 'Sisipkan tautan'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'Sisipkan grafik statistik'
stringMap [strings.Mind_Edit_Upload] = 'Unggah'
stringMap [strings.Mind_Edit_Right_Icon] = 'Ikon'
stringMap [strings.Mind_Edit_Right_Style] = 'Gaya'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'Mendekode ...'
stringMap [strings.Mind_Edit_Audio_Duration] = 'Durasi'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = 'Anda yakin akan menghapus rekaman audio? '
stringMap [strings.Mind_Edit_Join_Membership] = 'Buka keanggotaan'
stringMap [strings.Mind_Edit_Upgrade] = 'Tingkatkan'
stringMap [strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = 'Anggota dapat memiliki lebih banyak waktu perekaman, sekarang tingkatkan ke keanggotaan? '

stringMap [strings.Mind_Edit_Choose_Material] = 'Pilih materi'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'Tarik dan lepas gambar di sini'
stringMap [strings.Mind_Edit_Select_locally] = 'Pilih dari lokal'
stringMap [strings.Mind_Edit_Choose_From] = 'Pilih dari perpustakaan material'
stringMap [strings.Mind_Edit_Mine] = 'Saya'
stringMap [strings.Mind_Edit_My_Material] = 'Materi Saya'
stringMap [strings.Mind_Edit_Recently_Used] = 'Baru saja digunakan'
stringMap [strings.Mind_Edit_Use] = 'Gunakan'
stringMap [strings.Mind_Edit_No_Material_Temporally_Go_Add_It] = 'Tidak ada materi saat ini, pergi dan tambahkan! '
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'Pilih jenis bagan'
stringMap [strings.Mind_Edit_Remarks] = 'Komentar'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'Silakan masukkan komentar'
stringMap [strings.Mind_Edit_Add] = 'Tambah'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'Silakan masukkan tautan'

// Edit menu di sisi kiri halaman
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'Simpan gambar'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = 'Ekspor PDF'
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'Bagikan tautan'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'Bagikan ke badai'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'Cetak'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'Buka versi lengkapnya'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'Bantuan'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'Simpan format'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'format png'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'format jpg'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'format webp'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'Bagikan peta'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'Tombol berbagi'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'Anda dapat menyalin tautan dan mengirimkannya'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'Salin tautan'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'Jika Anda ingin mengubah tautan, silakan klik'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'Buat ulang'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'Tautkan kata sandi:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ='Izinkan menyimpan'
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Jumlah maksimum tampilan'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'Tak Terbatas'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'Batas'
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'Detik'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'Masa berlaku tautan'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = '1 hari'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = '3 hari'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = '7 hari'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'Permanen'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'Salin konten & tautan'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = 'Anda yakin ingin membuat ulang? Tautan sebelumnya tidak valid! '
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'Bagikan'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'Kategori khusus:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'Apakah akan mengizinkan komentar'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'Edit untuk menambah kategori'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'Hak Keanggotaan'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'Platform dukungan'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'Buka Keanggotaan'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'Pertukaran kode aktivasi'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = 'Manfaat keanggotaan setelah pembelian'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'Pilih metode pembayaran'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'Harga aktivitas'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'Jumlah yang sebenarnya dibayarkan'
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Perbarui segera'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《Persyaratan Layanan》'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《Kebijakan Privasi》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'Kode aktivasi:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Silakan masukkan kode aktivasi'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'Verifikasi kode aktivasi'
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'Pilih kategori'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'Judul utama tidak boleh kosong'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'Kupon:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Silakan masukkan kode kupon'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'Konfirmasi penggunaan kupon'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'Verifikasi'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'Jumlah preferensi'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'Bayar segera'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'Pembayaran WeChat'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Konfirmasi informasi pertukaran'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'Kode pertukaran:'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Exchange'

// Edit menu di sisi kanan halaman
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'Kanvas'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'Warna latar belakang'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'Tata Letak'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'Gaya garis'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'Warna garis'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'Lebar garis'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = 'Garis meruncing'
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'Font tema'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'Font anak'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'Warna teks'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'Ukuran font'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'Gaya Font'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'Warna batas'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'Isi warna'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'Bentuk Perbatasan'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'Lebar batas'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Tampilkan efek bayangan (fungsi langganan)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'Warna garis'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'Garis putus-putus'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'Ukuran gambar'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'Lokasi Gambar'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'Bentuk Gambar'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'Ukuran ikon'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'Posisi ikon'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'Umum'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'Prioritas'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'Kemajuan'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'Bintang'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'minggu'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Potret'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'Belajar'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'Kantor'
stringMap [strings.Mind_Edit_Right_Menu_Characters] = 'Karakter'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'Binatang'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'Makanan'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'Salin gaya'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'Gaya menempel'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'Ubah bentuk'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'Lihat gambar'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'Hapus gambar'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'Salin'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'Tempel'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'Hapus baris'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Hapus'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'Edit Komentar'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'Buka tautan'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'Hapus Komentar'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'Hapus tautan'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'Hapus ikon'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'Gaya ikon'

// Panel pengeditan grafik statistik
stringMap [strings.Echart_Edit_Statistic] = 'Statistik'
stringMap [strings.Echart_Edit_Value] = 'Nilai'
stringMap [strings.Echart_Edit_Color] = 'Warna'
stringMap [strings.Echart_Edit_Title] = 'Judul'
stringMap [strings.Echart_Edit_Detailed_Description] = 'Deskripsi detail'
stringMap [strings.Echart_Edit_Value_Sort] = 'Urutkan'
stringMap [strings.Echart_Edit_Value_Display_Type] = 'Jenis Tampilan'
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'Skema warna'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'Ukuran tampilan'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'Ukuran judul'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'Jelaskan ukuran'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'Berlangganan'

// Tab Badai
stringMap [strings.StormBookTag_Subscribed] = 'Berlangganan'
stringMap [strings.StormBookTag_List] = 'Daftar'

// Halaman berbagi badai
stringMap [strings.Storm_Share_Comment] = 'Komentar'
stringMap [strings.Storm_Share_Reply] = 'Balas'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'Izinkan saya juga mengomentarinya ~'
stringMap [strings.Storm_Share_Release] = 'Rilis'
stringMap [strings.Storm_Share_Main_Title] = 'Judul Utama'
stringMap [strings.Storm_Share_Subtitle] = 'Subtitle'
stringMap [strings.Storm_Share_Author] = 'Penulis'
stringMap [strings.Storm_Share_Replies] = 'Balas'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'Belum ada komentar. Jadilah yang pertama jadi yang pertama.'
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'Silakan masukkan komentar ~'
stringMap [strings.Storm_Share_Delete_Comment_Tips] = 'Anda yakin ingin menghapus komentar ini? '

// Bantuan untuk penggunaan internal peta
stringMap [strings.Mind_Usinghelp_Btn_1] = 'Tombol pintas (windows)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'Tombol pintas (mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'Deskripsi'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Perintah] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'Tambahkan node anak'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[Hapus]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'Hapus node saat ini (perhatikan bahwa tidak dapat dihapus jika dalam status edit)'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Perintah] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'Ubah node saat ini'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Perintah] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Perintah] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'Perbesar peta'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Perintah] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'Perhatikan petanya'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Perintah] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Perintah] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Perintah] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Perintah] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'Keluar dari pengeditan'

// Teks prompt
stringMap [strings.Message_Tips_Login_Success] = 'Login berhasil'
stringMap [strings.Message_Tips_Dlt_Success] = 'Hapus berhasil'
stringMap [strings.Message_Tips_Report_Success] = 'Laporkan berhasil'
stringMap [strings.Message_Tips_Copy_Success] = 'Salin berhasil'
stringMap [strings.Message_Tips_Collction_Success] = 'Koleksi berhasil'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'Modifikasi Avatar berhasil'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'Modifikasi tanda tangan berhasil'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'nama panggilan berhasil diubah'
stringMap [strings.Message_Tips_Modification_Success] = 'Modifikasi berhasil'
stringMap [strings.Message_Tips_New_Profile_Success] = 'Folder baru berhasil'
stringMap [strings.Message_Tips_Mobile_Success] = 'Keberhasilan seluler'
stringMap [strings.Message_Tips_Submit_Success] = 'Kirim berhasil'
stringMap [strings.Message_Tips_Exchange_Success] = 'Pertukaran berhasil'
stringMap [strings.Message_Tips_Please_Code] = 'Silakan masukkan kode aktivasi'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'Setujui kebijakan privasi'
stringMap [strings.Message_Tips_Please_Sign_In] = 'Silakan masuk'
stringMap [strings.Message_Tips_Share_Success] = 'Berbagi berhasil'
stringMap [strings.Message_Tips_Create_Success] = 'Dibuat dengan sukses'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'Nama folder tidak boleh kosong'
stringMap [strings.Message_Tips_Selecte_Success] = 'Pencarian selesai'
stringMap [strings.Message_Tips_Subscription_Success] = 'Langganan berhasil'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'Berhasil berhenti berlangganan'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'Konten terkait tidak ditemukan'
stringMap [strings.Message_Tips_Max_Add_Class] = 'Hanya dapat menambahkan paling banyak'
stringMap [strings.Message_Tips_Registered_Success] = 'Berhasil terdaftar'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'Login pemindaian kode gagal'
stringMap [strings.Message_Tips_Share_Is_Null] = 'Tautan berbagi tidak ada'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = 'File yang diunggah tidak boleh lebih dari 3MB'
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'Berhasil mendapatkan izin'
stringMap [strings.Message_Tips_Membership_Required] = 'Perlu keanggotaan'
stringMap [strings.Message_Tips_Save_Success] = 'Simpan dengan sukses'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = 'Pindai kode login berhasil'
stringMap [strings.Message_Tips_Style] = 'Kategori?'

// Mode garis besar
stringMap [strings.Outline_Menu_Indent] = 'Indentasi'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'Kurangi indentasi'

// Petunjuk bantuan
stringMap [strings.Help_Background_Content] = 'Terkait latar belakang baris, terkait latar belakang baris, terkait latar belakang baris, terkait latar belakang baris, terkait latar belakang baris, terkait latar belakang baris, terkait latar belakang baris, terkait latar belakang baris, terkait latar belakang baris,'
stringMap [strings.Help_Mind_Content] = 'Peta dengan tata letak berbeda dapat dibuat sesuai dengan preferensi: peta panduan ganda, peta kiri, peta kanan, bagan organisasi, pohon dua arah, pohon kiri, pohon kanan, tabel'
stringMap [strings.Help_Share_Link_Content] = 'Tautan berbagi dapat dibagikan di WeChat, QQ, Weibo, dan saluran lain dalam bentuk tautan, dan teman dapat melihat kontennya'
stringMap [strings.Using_Templates] = 'Menggunakan template'

// Kode undangan
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'Silakan masukkan kode undangan'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Silakan masukkan kode undangan yang benar'
stringMap [strings.User_Lolgin_Optional] = 'Opsional'

// Gunakan materi

stringMap [strings.Cannot_Add_To_Node_Background] = 'Tidak berlaku untuk latar belakang node'
stringMap [strings.Cannot_Add_To_Img_Background] = 'Tidak berlaku untuk latar belakang teks'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'Tidak berlaku untuk latar belakang garis parsel'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Tambahkan gambar latar belakang'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Hapus gambar latar belakang'
stringMap [strings.Mind_Add_Background_Image] = 'Sisipkan gambar latar belakang'
stringMap [strings.Mind_Delete_Background_Image] = 'Hapus gambar latar belakang'

stringMap [strings.Permanent_Member] = 'Anggota Tetap'
stringMap [strings.Insufficient_Storage_Space] = 'Ruang penyimpanan tidak cukup, tingkatkan keanggotaan untuk menambah ruang. '
stringMap [strings.Used_Space] = 'Ruang penyimpanan'
stringMap [strings.Current_Storage] = 'Penyimpanan saat ini:'
stringMap [strings.Used_Space_Upgrade] = 'Peningkatan ruang penyimpanan'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Pikiran + Peta Pikiran & Catatan Super mnemonik, tambahkan sayap untuk berpikir, membuat, menampilkan, merencanakan, dan mencatat'
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = 'Ruang penyimpanan tidak mencukupi. Anda dapat menggunakan templat saat Anda meningkatkan keanggotaan Anda. '

stringMap [strings.Group_Management] = 'Grup'
stringMap [strings.Save_Img_Fill_Full] = 'Buat penuh'
stringMap [strings.Save_Img_Center_Tilt] = 'Kemiringan Tengah'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'Sepenuhnya (gelap)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'Penandaan Air Peta Pikiran + Pikiran'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'Tambahkan tanda air untuk melindungi penciptaan pengetahuan:'
stringMap [strings.Save_Img_Watermark_Text] = 'Teks tanda air:'
stringMap [strings.Save_Img_Watermark_Logo] = 'Tanda Air LOGO:'
stringMap [strings.Save_Img_Watermark_Style] = 'Gaya tanda air:'
stringMap [strings.Save_Img_Please_Input] = 'Silakan masukkan'
stringMap [strings.Save_Img_Chop_Tips] = 'Anda dapat menikmati hak tanda air saat Anda membuka anggota'
stringMap [strings.Public_Header_Mindmap_Mind] = 'Mode peta'
stringMap [strings.Copy_Mind_Map] = 'Salin Peta Pikiran'
stringMap [strings.Font_Style_Bold] = 'Bold'
stringMap [strings.Font_Style_Italics] = 'Miring'
stringMap [strings.Font_Style_Delete_Line] = 'Coret'
stringMap [strings.Remove_Any] = 'Hapus'

stringMap [strings.Mindmap_Review] = 'Tinjauan peta'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'Perdalam kesan dan tingkatkan memori, pilah ide dan informasi secara efisien'
stringMap [strings.Mindmap_Review_Content] = 'Tinjauan peta mengacu pada pemutaran proses pengeditan, dan konten diputar secara berurutan mulai dari tema pusat; tinjauan peta dapat membantu Anda melakukan inventarisasi sistem, analisis, dan menemukan ide dan celah . Selama proses peninjauan, Anda mungkin menemukan inspirasi yang berbeda '
stringMap [strings.Mindmap_Review_Speed] = 'Kecepatan'
stringMap [strings.Mindmap_Review_Word_Speed] = 'Kecepatan kata'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'Rasio latar belakang'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'Perbesaran node pemutaran saat ini'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'Urutan Pemutaran Node'
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap [strings.Mind_Group_Picture_Upload] = 'Unggahan gambar'
stringMap [strings.Mind_Group_Upload_From_Local] = 'Unggahan lokal'
stringMap [strings.Mind_Group_Mind_Mapping] = 'Peta Pikiran'
stringMap [strings.Mind_Group_Information] = 'Data'

stringMap [strings.Mind_Group_Invite_New_Members] = 'Undang anggota baru'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Silakan masukkan nomor telepon Anda'
stringMap [strings.Mind_Group_Cancel] = 'Batal'
stringMap [strings.Mind_Group_Confirm] = 'OK'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'Nomor ponsel tidak boleh kosong'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'Undangan berhasil'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "Anggota grup bersama"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Jumlah maksimum anggota grup bersama"
stringMap [strings.Mind_Group_Membership_Limit] = "Batas Keanggotaan"
stringMap [strings.Mind_Group_Current_Member_Limit] = "Batas Atas Anggota Saat Ini"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "Tingkatkan batas atas anggota"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "Cari anggota"
stringMap [strings.Mind_Group_Creator] = "Pembuat"
stringMap [strings.Mind_Group_Administrator] = "Administrator"
stringMap [strings.Mind_Group_Number_Of_People] = "Orang"
stringMap [strings.Mind_Group_Selected] = "Dipilih"
stringMap [strings.Mind_Group_Member] = "Anggota"
stringMap [strings.Mind_Group_Check_All] = "Pilih semua"
stringMap [strings.Mind_Group_Remove] = "Hapus"
stringMap [strings.Mind_Group_Invite_New_Members] = "Undang anggota baru"
stringMap [strings.Mind_Group_Delete_Members] = "Hapus anggota"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Silakan pilih anggota yang akan dihapus"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Anda yakin ingin menghapus pengguna dari grup ini?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "Tambahkan Administrator"
stringMap [strings.Mind_Group_Accomplish] = "Selesai"
stringMap [strings.Mind_Group_Compile] = "Edit"
stringMap [strings.Mind_Group_Group_Owner] = "Pemilik Grup"
stringMap [strings.Mind_Group_Add_Admin] = "Tambah Administrator"
stringMap [strings.Mind_Group_Group_ID] = "ID Grup"
stringMap [strings.Mind_Group_Group_Number] = "Nomor Grup"
stringMap [strings.Mind_Group_Name] = "Nama"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Silakan masukkan nama grup bersama"
stringMap [strings.Mind_Group_Introduce] = "Perkenalkan"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Harap perkenalkan grup bersama sebentar"
stringMap [strings.Mind_Group_Announcement] = "Pengumuman"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "Tag Grup Bersama"
stringMap [strings.Mind_Group_Custom_Tag] = "Tag Kustom"
stringMap [strings.Mind_Group_Save] = "Simpan"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "Nama grup tidak boleh kosong"
stringMap [strings.Mind_Group_Establish] = "Buat"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "Izinkan untuk membuat peta / folder"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "Izinkan pengeditan peta"
stringMap [strings.Mind_Group_Holder] = "Semua Orang"
stringMap [strings.Mind_Group_Only_The_Administrator] = "Hanya administrator"
stringMap [strings.Mind_Group_Not_Allow] = "Tidak diizinkan"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "Izinkan untuk menghapus peta"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Izinkan forum untuk memposting pesan"
stringMap [strings.Mind_Group_Export] = "Ekspor"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Izinkan untuk menyimpan gambar / PDF"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "Buka tanda air grup"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Setelah dibuka, logo grup akan dicetak di peta pikiran"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "Izinkan menyimpan peta"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "Simpan ke Petaku"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Apakah akan mengizinkan berbagi ke badai"
stringMap [strings.Mind_Group_Join_The_Way] = "Bagaimana cara bergabung"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "Apakah mengizinkan anggota baru diundang"
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Apakah mengizinkan nomor grup pencarian untuk ditambahkan"
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Apakah pesan perlu divalidasi saat bergabung"
stringMap [strings.Mind_Group_Shared_Group_Data] = "Data grup bersama"
stringMap [strings.Mind_Group_Authority_Management] = "Manajemen Otoritas"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "Keluar dari grup berbagi"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Apakah Anda yakin untuk keluar dari grup bersama?"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Grup Pembubaran"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "File akan dihapus setelah grup dibubarkan. Konfirmasi pembubaran?"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "Kembali ke halaman muka"
stringMap [strings.Mind_Group_Join_The_Group] = "Gabung dengan Grup"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "Silakan masukkan nomor grup / nama grup untuk mencari"
stringMap [strings.Mind_Group_Apply_To_Join] = "Daftar untuk bergabung"
stringMap [strings.Mind_Group_Join] = "Gabung"
stringMap [strings.Mind_Group_Verification] = "Verifikasi"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "Silakan masukkan pesan validasi"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "Konten tidak boleh kosong"
stringMap [strings.Mind_Group_Name] = "Nama grup bersama"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Silakan masukkan nama grup bersama"
stringMap [strings.Mind_Group_Introduction] = "Bagikan pengenalan grup"
stringMap [strings.Mind_Group_Group_Indication] = "Nomor Grup"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "Pembuatnya malas, saya belum memperkenalkannya ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "Pengumuman grup bersama"
stringMap [strings.Mind_Group_No_Announcement] = "Belum ada pengumuman"
stringMap [strings.Mind_Group_Click_On] = "Klik untuk memperluas"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "Klik untuk menyembunyikan"
stringMap [strings.Mind_Group_Altogether] = "Total"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Berhasil menyalin nomor grup"
stringMap [strings.Mind_Group_Stick] = "Tetap di atas"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "Ada konten baru"
stringMap [strings.Mind_Group_Cancel_The_Top] = "Batalkan Bagian Atas"
stringMap [strings.Mind_Group_Setting_Up] = "Pengaturan"
stringMap [strings.Mind_Group_Usinghelp] = "Menggunakan bantuan"
stringMap [strings.Mind_Group_Set_The_Message] = "Pesan Grup"
stringMap [strings.Mind_Group_Apply_To_Join] = "Daftar untuk bergabung"
stringMap [strings.Mind_Group_Invite_To_Join] = "Undang untuk bergabung"
stringMap [strings.Mind_Group_Consent] = "Setuju"
stringMap [strings.Mind_Group_Turn_Down] = "Tolak"
stringMap [strings.Mind_Group_Label] = "Label"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Silakan masukkan nama label grup bersama"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Undang teman dan kolega untuk bersama-sama membuat dan berbagi konten pengetahuan"
stringMap [strings.Mind_Group_Immediately_Create] = "Buat segera"
stringMap [strings.Mind_Group_Immediately_To_Join] = "Gabung Sekarang"

stringMap [strings.Currently_Selected] = "Saat ini mengikuti"
stringMap [strings.LabelManagement] ="Manajemen Label"
stringMap [strings.NoAttention] = "Tidak Ada Perhatian"
stringMap [strings.Mindmap_Review_Play] = 'Mainkan'

stringMap [strings.Mind_Group_Joined] = "Bergabung"
stringMap [strings.Mind_Group_Download_App] = "Unduh APLIKASI"
stringMap [strings.Mind_Group_No_Content] = "Belum ada konten"
stringMap [strings.Mind_Group_Individual] = "potongan"
stringMap [strings.Mind_Group_Message_Area] = "Area Pesan"
stringMap [strings.Mind_Group_No_Message] = "Tidak ada pesan, pergi sekarang"
stringMap [strings.Mind_Group_Leave_Message] = "Tinggalkan Pesan"
stringMap [strings.Mind_Group_Bar] = "Bar"
stringMap [strings.Mind_Group_See_More] = "Lihat lebih banyak"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "Masukkan kode undangan"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Daftar untuk bergabung dengan grup berbagi"
stringMap [strings.Reasons_For_Application_0_30_Words] = "Alasan aplikasi (0-30 kata)"
stringMap [strings.Mind_Group_Send_Out] = "Kirim"
stringMap [strings.Mind_Group_Join_Successfully] = "Berhasil bergabung"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "Aplikasi berhasil dikirim"

stringMap [strings.Mind_Group_Save_The_Mapping] = "Simpan Peta"
stringMap [strings.Mind_Group_Update_Record] = "Ubah Data"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "Ganti Peta"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "Tinjauan Peta"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Pengguna yang sudah ada sedang mengedit peta"
stringMap [strings.Mind_Edit_Page_Password] = "Kata Sandi"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "Grup Berbagi"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "MBean saya"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Pembayaran Alipay"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "Silakan pergi ke halaman pembayaran untuk membayar"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "Total"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "Konfirmasi pembayaran"
stringMap [strings.Mind_Shooping_Redeem_Now] = "Tukarkan sekarang"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "Apakah akan mengkonfirmasi pembayaran untuk pembelian"
stringMap [strings.Mind_Tips] = "Tips"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "M Beans Anda tidak mencukupi, apakah Anda ingin membeli M Beans"

stringMap [strings.Mind_share_scan_code] = "Pikiran + kode pindai"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "Apakah akan mengembalikan ke tempat yang terakhir diputar?"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Sisa waktu pemutaran"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "File akan dihapus seluruhnya, Anda yakin akan bubar?"
stringMap [strings.Group_Mind_Up_One_Level] = "Naik Satu Tingkat"
stringMap [strings.Group_Mind_No_Documents] = "Belum ada file"
stringMap [strings.Group_Mind_PaymentSuccessful] = "Pembayaran berhasil"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "Pengeditan kolaboratif orang, sinkronisasi waktu-nyata multi-terminal, berbagi dokumen, meningkatkan efisiensi kolaborasi tim"

stringMap [strings.Mind_Edit_Subscript] = "Subskrip"
stringMap [strings.Mind_Edit_Text_Location] = "Lokasi Teks"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "Impor ke Grup Bersama"

stringMap [strings.Standard_Colors] = "Warna Standar"
stringMap [strings.More_Colors] = "Lebih banyak warna"
stringMap [strings.Reset_Password] = "Setel Ulang Kata Sandi"
stringMap [strings.Account_Password_Login] = "Login Kata Sandi Akun"
stringMap [strings.Authentication_code_Login_registration] = "Login / registrasi kode verifikasi"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "Satu pembuatan akhir, sinkronisasi beberapa ujung"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "Struktur peta yang kaya dan beragam"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "Perpustakaan template sangat indah"
stringMap [strings.Map_Review_Play] = "Putar-Tinjauan Peta"
stringMap [strings.CommunityLearningAndSharingPlatform] = "Platform Pembelajaran Pengetahuan dan Berbagi Berbagi"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "Tingkatkan ruang penyimpanan"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "Izinkan saya juga mengomentarinya"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Belum ada pemberitahuan pesan"
stringMap[strings.Failed_To_Get_Recording_Device] = "Gagal mendapatkan alat perekam"

stringMap[strings.Search_Pagination] = "Pencarian Pagination"
stringMap[strings.Please_Enter_A_Paging_Name] = "Silakan masukkan nama halaman"
stringMap[strings.Operation_Settings] = "Pengaturan Operasi"
stringMap[strings.Shortcut_key_Settings] = "Pengaturan Tombol Pintasan"
stringMap[strings.Press_Key_Combination] = "Silakan tekan kombinasi tombol yang diperlukan"
stringMap[strings.Mind_Reduction] = "Pengurangan"
stringMap[strings.Double_Click_Add_Free_Node] = "Klik dua kali ruang kosong untuk menambahkan simpul gratis"
stringMap[strings.Shortcut_Conflict] = "Konflik tombol pintas"
stringMap[strings.Select_Main_Node] = "Pilih Node Utama"
stringMap[strings.Telephone] = "Telepon"
stringMap[strings.Microblog] = "Microblog"
stringMap[strings.Address] = "Alamat"
stringMap[strings.Company_Address] = "Blok B, Gedung 21, No. 1158 Zhongxin Road, Distrik Songjiang, Shanghai (Songjiang Lingang Caohejing)"
stringMap[strings.Mind_Archive] = "Arsip"
stringMap[strings.Mind_Bookshelf] = "Rak Buku"
stringMap[strings.Search_Archive] = "Cari Rak Buku"
stringMap[strings.Modify_Archive_Order] = "Ubah Urutan Rak Buku"
stringMap[strings.Modification_Order] = "Urutan Modifikasi"
stringMap[strings.Please_Enter_Archive_Name] = "Silakan masukkan nama rak buku"
stringMap[strings.Message_Tips_Archive_Name_No_Null] = "Nama rak buku tidak boleh kosong"
stringMap[strings.Mind_Edit_Delete_Archive_Tips] = "Apakah Anda yakin ingin menghapus rak buku ini?"
stringMap[strings.Mind_Edit_Delete_Bookcase_Tips] = "Apakah Anda yakin ingin menghapus rak buku ini?"
stringMap[strings.Mind_Edit_Delete_Map_Tips] = "Apakah Anda yakin ingin menghapus peta ini?"
stringMap[strings.Mind_Edit_Confirm] = "Konfirmasi"
stringMap[strings.Mind_Import_Map] = "Impor Peta"
stringMap[strings.Mind_Bookcase] = "Rak Buku"
stringMap[strings.Added_Successfully] = "Berhasil ditambahkan"
stringMap[strings.Message_Tips_Bookcase_Name_No_Null] = "Nama rak buku tidak boleh kosong"

stringMap[strings.Mind_Association_Map] = "Peta Asosiasi"
stringMap[strings.Mind_confirm_Association_Map] = "Apakah Anda yakin untuk mengaitkan peta ini?"
stringMap[strings.Mind_Association_Map_See] = "Lihat Peta Asosiasi"
stringMap[strings.Mind_Association_Map_Delete] = "Hapus Peta Asosiasi"

stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "Struktur Garis Korelasi"
stringMap[strings.Mind_Edit_Right_Menu_Corelation_Line_Start] = "Awal Garis Korelasi"
stringMap[strings.Mind_Edit_Right_Menu_Corelation_Line_End] = "Akhir dari garis korelasi"

stringMap[strings.Share_Group_To_Storm] = "Apakah akan membagikan grup ke badai"
stringMap[strings.Apply_To_Join_Storm_Platform] = "Di platform badai, orang lain melihat dan melamar untuk bergabung, bertukar pikiran dengan pecinta pengetahuan umum"
stringMap[strings.Pay_To_Goin] = "Bayar untuk bergabung"
stringMap[strings.Pay_To_Goin_Need_Mbeans] = "M kacang harus dibayar saat bergabung"
stringMap[strings.Mbean_Can_Be_Realized] = "Mbean Realized"

stringMap[strings.Mind_Visit] = "Kunjungi"
stringMap[strings.Mind_Details] = "Detail"
stringMap[strings.Mind_See] = "Tampilan"
stringMap[strings.Earn_Mbeans] = "Dapatkan Mbeans"
stringMap[strings.Mind_Explain] = "Deskripsi"
stringMap[strings.Mind_Parenting] = "Pengasuhan"
stringMap[strings.Mind_Internet] = "Internet"
stringMap[strings.Mind_Psychology] = "Psikologi"
stringMap[strings.Mind_literature] = "Sastra"
stringMap[strings.Mind_Technology] = "Teknologi"
stringMap[strings.Mind_Workplace] = "Tempat Kerja"
stringMap[strings.Mind_Life] = "Hidup"
stringMap[strings.Mind_Subject] = "Subjek"
stringMap[strings.Hot_Group] = "Grup Panas"
stringMap[strings.Open_Group] = "Buka Grup"
stringMap[strings.Mind_Group_Used] = "Digunakan"

stringMap[strings.Mind_Click_Select] = "Klik untuk memilih"
stringMap[strings.Mind_Double_Click_Enter] = "Klik dua kali untuk masuk"
stringMap[strings.Search_Shared_Groups] = "Telusuri grup bersama"
stringMap[strings.Search_Storm] = "Cari Badai"
stringMap[strings.Members_Save_Free] = "Anggota Hemat Gratis"
stringMap[strings.Bank_Card_Account_Name] = "Silakan masukkan nama rekening kartu bank Anda"
stringMap[strings.Bank_Card_No] = "Nomor Kartu Bank"
stringMap[strings.Please_Enter_Your_Bank_Card_No] = "Silakan masukkan nomor kartu bank Anda"
stringMap[strings.Branch_Sub_Branch_Information] = "Informasi cabang cabang"
stringMap[strings.Branch_Sub_Branch_Example] = "Misalnya: Cabang Pembantu Chengxi Cabang Hangzhou"
stringMap[strings.Application_For_Withdrawal] = "Permohonan penarikan"
stringMap[strings.Withdrawal_Amount] = "Jumlah penarikan"
stringMap[strings.Withdrawal] = "Tarik"
stringMap[strings.Current_Revenue] = "Pendapatan Saat Ini"
stringMap[strings.Recharge] = "Isi Ulang"
stringMap[strings.Current_Total_MBeans] = "Jumlah total MBeans saat ini"
stringMap[strings.Withdrawal_Status] = "Status Penarikan"
stringMap[strings.Insufficient_Withdrawal_Amount] = "Jumlah penarikan tidak mencukupi"
stringMap[strings.Load_More] = "Muat lebih banyak"
stringMap[strings.Loading] = "Memuat"
stringMap[strings.It_Is_Over] = "Sudah berakhir"
stringMap[strings.Default_Label] = "Label default"
stringMap[strings.Selected_Label] = "Label yang dipilih"
stringMap[strings.Current_Location] = "Lokasi Saat Ini"
stringMap[strings.MGold_Introduction] = "M emas diperoleh oleh Anda melalui Pikiran+"
stringMap[strings.MGold_Details] = "Grup cloud berbayar Anda, ada peta pengetahuan, materi pembelajaran, dll di grup. Jika pengguna bergabung dengan pembayaran M kacang, 50% dari mereka akan dikonversi menjadi M emas, dan M emas lebih besar dari 50 dapat diekstraksi"
stringMap[strings.Cloud_Group_Introduction] = "Bagikan grup Anda, orang lain dapat melihatnya di halaman badai"
stringMap[strings.Cloud_Group_Details] = "Grup cloud berbayar Anda. Ada peta pengetahuan, materi pembelajaran, dll. di grup. Jika pengguna bergabung dengan pembayaran M kacang, 50% dari mereka akan dikonversi menjadi M emas, dan M emas lebih besar dari 50 dapat ditarik."
stringMap[strings.Share_Storm_Introduction] = "Bagikan peta Anda ke komunitas badai, sehingga lebih banyak orang dapat belajar dan menjelajahi pengetahuan dari Anda"
stringMap[strings.Share_Storm_Details] = "Jika Anda mengaktifkan penggunaan kacang M untuk menyimpan peta, pengguna lain dapat menggunakan peta pengetahuan Anda, dan Anda juga akan mendapatkan 50% kacang M. Kacang M dapat digunakan untuk membeli anggota dan bergabung dengan berbayar grup , Anda juga dapat membayar untuk menggunakan peta orang lain."

stringMap[strings.Linked_Successfully] = "Berhasil ditautkan"
stringMap[strings.Cancel_Archiving] = "Batalkan Pengarsipan"
stringMap[strings.Please_Select_Archive_Location] = "Silakan pilih lokasi arsip"
stringMap[strings.The_Search_Result_Is_Empty] = "Hasil pencarian kosong"

stringMap[strings.Customize_A_Style] = "Non-anggota hanya dapat menyesuaikan satu gaya"
stringMap[strings.Adds_Current_Guide_Style_Library] = "Tambahkan gaya peta saat ini ke perpustakaan gaya"
stringMap[strings.Trial_Version] = "Versi Percobaan"
stringMap[strings.Not_Supported_At_The_Moment] = "Saat ini tidak didukung"
stringMap[strings.Mind_File] = "File"
stringMap[strings.Mind_Import] = "Impor"
stringMap[strings.Importing] = "Mengimpor"

stringMap[strings.Mind_Edit_Insert_Video] = "Sisipkan Video"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Seret dan lepas video di sini"
stringMap[strings.Message_Tips_Video_Upload_Time_Limit] = "Durasi upload video tidak boleh lebih dari 60 detik"
stringMap[strings.Mind_Edit_Node_Menu_View_Video] = "Lihat Video"
stringMap[strings.Mind_Edit_Node_Delete_View_Video] = "Hapus video"
stringMap[strings.Message_Tips_Share_Video_Does_Not_Exist] = "Video yang diunggah tidak boleh lebih dari 10 MB"
stringMap[strings.Mind_Upload_Video_Restrictions] = "Hanya mendukung pengunggahan video dalam format MP4, hingga ukuran 120-an dan 10M"
stringMap[strings.Mind_Video_Size] = "Ukuran video"
stringMap[strings.Mind_Edit_Left_Menu_Export_Word] = "Ekspor Kata"
stringMap[strings.Mind_Edit_Left_Menu_Export_TXT] = "Ekspor TXT"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap[strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap[strings.Export_Failed] = "Ekspor gagal"
stringMap[strings.Add_File] = "Tambahkan file"
stringMap[strings.Drag_The_File_Directly_To_Upload] = "Seret langsung ke dalam file untuk diunggah"
stringMap[strings.Supported_Imported_File_Types] = "Jenis file yang saat ini didukung untuk impor: Word (hanya .docx), XMind, .txt"
stringMap[strings.No_Permission_Do_This] = "Anda tidak memiliki izin untuk melakukan operasi ini"

stringMap[strings.Upgrade_To_Professional] = "Tingkatkan ke Edisi Profesional"
stringMap[strings.Non_Members_Insert_Videos] = "Non-anggota hanya dapat menyisipkan video 24 jam setelah pendaftaran"
stringMap[strings.Upload_Failed] = "Upload gagal"
stringMap[strings.Upload_Failed_Title_Message] = "Upload gagal, silahkan klik untuk konfirmasi, hapus sisa gambar yang gagal upload"

stringMap[strings.Mind_Edit_Members_Use] = "Penggunaan Anggota"
stringMap[strings.Invitation_Code] = "Kode Undangan"
stringMap[strings.Student_Certiification] = "Sertifikasi Siswa"
stringMap[strings.I_Get_It] = "Saya akan, lewati panduan ini"
stringMap[strings.Welcome_To_Mind] = "Selamat datang tuan muda bergabung dengan Mind+, mari kita mulai perjalanan berpikir!"
stringMap[strings.Mind_Before] = "Pertama"
stringMap[strings.Try_A_Map] = "Coba peta"
stringMap[strings.Click_Create] = "Klik untuk membuat"
stringMap[strings.Click_Create_A_Mind_Map] = "Klik untuk membuat peta pikiran"
stringMap[strings.Mind_About] = "Tentang"
stringMap[strings.See_Checking_Data] = "Melihat informasi pribadi"
stringMap[strings.Modify_Checking_Data] = "Ubah informasi pribadi"
stringMap[strings.Mind_Shear] = "Geser"

stringMap[strings.Mind_Style_Empty] = "Tidak ada gaya favorit atau kustom"
stringMap[strings.Mind_Branch_Free_Layout] = "Tata Letak Bebas Cabang"
stringMap[strings.Mind_Branch_Free_Layout_explain] = "Struktur dua arah dan cabang peta gelembung dapat dengan bebas diseret ke tata letak"
stringMap[strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Jepret Seret Tema Gratis"

stringMap[strings.Select_A_Node] = "Pilih topik"
stringMap[strings.Create_Child_Node] = "Buat tema anak"
stringMap[strings.Add_A_Nice_Image_To_The_Node] = "Selanjutnya kita akan menambahkan yang bagus ke tema anak"
stringMap[strings.Click_Icon_Panel] = "Klik panel ikon"
stringMap[strings.Select_An_Icon_You_Like] = "Pilih ikon yang Anda suka"
stringMap[strings.Replace_The_Mind_Map_With_A_New_One] = "Selanjutnya kita akan mengganti mind map dengan yang baru"
stringMap[strings.Select_The_Central_Theme] = "Pilih tema utama"
stringMap[strings.Switch_To_Canvas_Panel] = "Beralih ke Panel Kanvas"
stringMap[strings.Click_Layout] = "Klik ke Tata Letak"
stringMap[strings.Choose_A_Layout_You_Like] = "Pilih tata letak yang Anda suka"
stringMap[strings.Mind_Map_Save_Share] = "Selamat, Anda telah berhasil membuat peta pikiran yang indah! Ayo simpan dan bagikan dengan teman-teman Anda~"
stringMap[strings.Click_Save_Picture] = "Klik untuk menyimpan gambar"
stringMap[strings.Click_OK] = "Klik OK"
stringMap[strings.Congratulations_On_Completing_The_Novice_Guide] = "Selamat telah menyelesaikan panduan pemula~"
stringMap[strings.Upload_Succeeded] = "Berhasil diunggah"

stringMap[strings.Mind_Edit_Node_Menu_Quick_Style] = "Gaya Cepat"
stringMap[strings.Mind_Extremely_Important] = "Sangat penting"
stringMap[strings.Mind_Important] = "Penting"
stringMap[strings.Mind_Commonly] = "Umum"
stringMap[strings.Mind_Ordinary] = "Biasa"
stringMap[strings.Member_Exclusive] = "Fitur ini eksklusif untuk anggota. Apakah Anda ingin meningkatkan keanggotaan Anda?"
stringMap[strings.Member_Exclusive_Color_Scheme] = "Skema warna ini eksklusif untuk anggota. Apakah Anda ingin meningkatkan keanggotaan Anda?"
stringMap[strings.Mind_Enlarge] = "Perbesar"
stringMap[strings.Mind_Narrow] = "Sempit"
stringMap[strings.Mind_Center] = "Terpusat"
stringMap[strings.Mind_Full_Screen] = "Layar Penuh"

stringMap[strings.Mind_Association_Node] = "Simpul Asosiasi"

stringMap[strings.Mind_Resource_See] = "Lihat lampiran"
stringMap[strings.Mind_Resource_Delete] = "Hapus lampiran"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Seret dan lepas file di sini"
stringMap[strings.Mind_Edit_choose_explain] = "Pilih Berkas"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "File yang diupload tidak boleh lebih dari 20MB"

stringMap[strings.Please_Enter_Mind_Password] = "Sila masukkan kata laluan 4-8 digit"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "Sila masukkan kata laluan"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "Fungsi ini adalah fungsi ahli"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "Katalaluan fail telah dibuang"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "Bilangan digit kata laluan tidak cukup. Sila masukkan kata laluan dengan 4 digit terbalik 8 digit"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "Katalaluan terlalu panjang. Sila masukkan kata laluan dengan 4 8 digit terbalik"
stringMap[strings.Please_Enter_Mind_Password_Success] = "Tetapkan kata laluan berjaya"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "Ralat format kata laluan"
stringMap[strings.Mind_Load_Data_Error] = "Ralat memuatkan data"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Ralat kata laluan"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Hanya pencipta peta ini boleh tetapkan kata laluan"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;