import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 2024/6/5
 * Copyright(c) 2024 mindyushu.com
 */

class HandwritingContentCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isHandwritingContent()) {
            return;
         }
         let handwritingContent = this.data.handwritingContent;
         let size = this.data.handwritingContent.getSizeByPoint();
         this.data.width = size.getWidth();
         this.data.height = size.getHeight();
         let moveX = 0;
         let moveY = 0;
   
         if (handwritingContent.endPointX < 0) {
            moveX = -handwritingContent.endPointX;
         }
         if (handwritingContent.endPointY < 0) {
            moveY = -handwritingContent.endPointY;
         }
         handwritingContent.startPointX = handwritingContent.startPointX + moveX;
         handwritingContent.startPointY = handwritingContent.startPointY + moveY;
   
         handwritingContent.endPointX = handwritingContent.endPointX + moveX;
         handwritingContent.endPointY = handwritingContent.endPointY + moveY;
         for (let index = 0; index < handwritingContent.points.length; index++) {
            let cell = handwritingContent.points[index]
            cell.x += moveX;
            cell.y += moveY;
         }
         this.data.x -= moveX;
         this.data.y -= moveY;
    }
}

export default HandwritingContentCalculation