const { default: strings } = require("./strings");

var stringMap = {};
stringMap[strings.About_App_Name] ="ذهن + نقشه ذهن ";
stringMap[strings.TEMPLATE] ="قالب ";
stringMap[strings.Mind_Edit_Main_Topic] ="تم شعبه ";
stringMap[strings.Global_Add_Free_Node_Tips] ="برای انتخاب مکان برای افزودن روی صفحه کلیک کنید ";
stringMap[strings.Global_Add_Free_Chart_Node_Tips] ="برای انتخاب مکان مورد نظر برای افزودن صفحه ، دو ضربه سریع بزنید  ";
stringMap[strings.Global_Add_Relationship_Tips] ="هدفی را که باید مرتبط شود انتخاب کنید ";
stringMap[strings.Global_Add_Target_Tips] ="لطفاً یک گره برای افزودن اشیا انتخاب کنید ";
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] ="این شی از قبل با نقشه های دیگر مرتبط شده است ";
stringMap[strings.Mind_Edit_Main_Idea] ="تم مرکزی ";
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] ="نقشه مرتبط وجود ندارد ";
stringMap[strings.Mind_Style_Add_Icon_Priority] ="اولویت ";
stringMap[strings.Mind_Style_Add_Icon_Progress] ="برنامه ";
stringMap[strings.Mind_Style_Add_Icon_Stars] ="ستاره ";
stringMap[strings.Mind_Style_Add_Icon_Week] ="هفته ";
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] ="پرتره ";
stringMap[strings.Establish] ="ايجاد كردن ";
stringMap[strings.Create_From_Template] ="ایجاد از الگو ";
stringMap[strings.Common] ="عمومی ";
stringMap[strings.Function_List] ="لیست عملکرد ";
stringMap[strings.Recent_Updates] ="اخیراً به روز شده ";
stringMap[strings.The_Latest_To_Delete] ="اخیراً حذف شده ";
stringMap[strings.Setting_Up] ="با ما تماس بگیرید ";
stringMap[strings.Usinghelp] ="استفاده از کمک ";
stringMap[strings.The_Download_Client] ="بارگیری نسخه مشتری / موبایل ";
stringMap[strings.Global_Purchase] ="خرید ";
stringMap[strings.Global_Login] ="وارد شدن ";
stringMap[strings.My_map] ="نقشه من  ";
stringMap[strings.Create_Map] ="ایجاد نقشه  ";
stringMap[strings.Storm] ="طوفان ";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] ="ویژگی های مشترک \ n ";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] ="امکانات ";
stringMap[strings.Mind_Organization_Edit_Main_Idea] ="مرکز سازمان ";
stringMap[strings.Mind_Edit_Free_Topic] ="تم رایگان ";
stringMap[strings.Mind_Edit_Sub_Topic] ="زیرمجموعه ";
stringMap[strings.Mind_Organization_Main_Topic] ="بخش";
stringMap[strings.Mind_Edit_Main_Topic] ="تم شعبه ";
stringMap[strings.Mind_Time_Edit_Main_Idea] ="مدیریت برنامه ";
stringMap[strings.Mind_Time_Edit_Head] ="زمان ";
stringMap[strings.Mind_Time_Edit_Title] ="طرح ";
stringMap[strings.Mind_Edit_Details] ="توصیف همراه با جزئیات ";
stringMap[strings.Checking_Data] ="بررسی داده ها  ";
stringMap[strings.Setting_Information] ="اطلاعات تنظیمات  ";
stringMap[strings.Sign_Out] ="خروج از سیستم ";

//功能列表
stringMap[strings.Create_a_Mind_Map] ="ایجاد نقشه ذهنی ";
stringMap[strings.Create_Mind_Map_1] ="[1] ایجاد یک نقشه با چهارده طرح (دو طرفه / راست / چپ / ساختار سازمانی / نمودار حبابی تک / نمودار حباب دوتایی / نمودار درخت چپ / نمودار درخت راست / نمودار درختی دو طرفه / جدول / جدول زمانی افقی / عمودی) timeline / S timeline / هرم)  ";
stringMap[strings.Create_Mind_Map_2] ="[2] می توان از الگوها برای ایجاد سریع نقشه (تعداد زیادی از منابع محتوای الگو) استفاده کرد ";
stringMap[strings.Create_Mind_Map_3] ="[3] نه نوع سبک خط را بر روی نقشه ویرایش کنید ";
stringMap[strings.Create_Mind_Map_4] ="[4] ویرایش نه تغییر طرح در نقشه ";
stringMap[strings.Create_Mind_Map_5] ="【5-گره های فرعی را به گره مورد نظر اضافه کنید و گره ها را در همان سطح (بالا و پایین) اضافه کنید ";
stringMap[strings.Create_Mind_Map_6] ="【6】 خلاصه: [خلاصه] را برای توضیحات تک گره / چند گره اضافه کنید ";
stringMap[strings.Create_Mind_Map_7] ="【7】 Block: [Module] را به یک گره / جعبه گره های متعدد نقشه اضافه کنید ";
stringMap[strings.Create_Mind_Map_8] ="【8】 گره رایگان اضافه کنید ";
stringMap[strings.Create_Mind_Map_9] ="[9] ارتباطی از چهار سبک خط بین هر گره ایجاد کنید ";
stringMap[strings.Create_Mind_Map_10] ="[10] درج تصاویر (محلی + کتابخانه مطالب) ، آیکون ها ، سخنان ، پیوندها به گره ";
stringMap[strings.Create_Mind_Map_11] ="[11] گره کپی / چسباندن ، سبک کپی / چسباندن ";
stringMap[strings.Create_Mind_Map_12] ="【12】 لغو ، بازیابی لغو ";
stringMap[strings.Create_Mind_Map_13] ="[13] چند انتخاب امکان پذیر است و چندین گره همزمان کار می کنند ";
stringMap[strings.Create_Mind_Map_14] ="【14】 آمار درج می شود ";
stringMap[strings.Create_Mind_Map_15] ="[15] کل نقشه قابل ضبط است ";
stringMap[strings.Create_Mind_Map_16] ="【16 y کپی نقشه ";
stringMap[strings.Create_Mind_Map_17] ="[17] می توانید نقشه یا کل نقشه را روی نقشه پرونده های دیگر کپی کنید ";
stringMap[strings.Create_Mind_Map_18] ="[18] می توان پس از خلاصه نقشه ایجاد کرد ";
stringMap[strings.Create_Mind_Map_19] ="[19] می توانید هنگام ویرایش نقشه را تغییر دهید (سمت رایانه) ";
stringMap[strings.Create_Mind_Map_20] ="[20] map نقشه ویرایش صفحه کلید بلوتوث: \ n \ t [Command] + [a] گره اصلی را انتخاب کنید \ n \ t [Command] + [←] [↑] [→] [↓] هدف انتخاب شده را تغییر دهید یا حرکت دهید راهنما شکل \ n \ t [Command] + [e] ویرایش هدف \ n \ t [Command] + [w] ایجاد گره فرزند \ n \ t [Command] + [s] ایجاد گره خواهر یا برادر هدف (بالا) \ n \ t [Command] + [d] ایجاد گره خواهر و برادر هدف (زیر) \ n \ t [Command] + [q] از هدف انتخاب شده خارج شوید \ n \ t [Command] + [-] [+] نقشه بزرگنمایی  ";

stringMap[strings.Beautify_The_Mind_Map] ="زیباسازی نقشه ذهن ";
stringMap[strings.Beautify_The_Mind_Map_1] ="[1] رنگ پس زمینه نقشه ذهن را تنظیم کنید ";
stringMap[strings.Beautify_The_Mind_Map_2] ="[2] ضخامت و رنگ کل خط نقشه را تنظیم کنید ، خواه مخروطی باشد یا نه ";
stringMap[strings.Beautify_The_Mind_Map_3] ="[3] تنظیم رنگ پر کردن متن ، رنگ حاشیه ، 13 شکل حاشیه ، عرض حاشیه ، جلوه سایه ";
stringMap[strings.Beautify_The_Mind_Map_4] ="[4] تمام یا بخشی از رنگ خط و عرض نقشه را تنظیم کنید ";
stringMap[strings.Beautify_The_Mind_Map_5] ="[5] تنظیم اندازه قلم متن ، پررنگ ، اریب و سبک حذف ";
stringMap[strings.Beautify_The_Mind_Map_6] ="[6] اندازه تصویر ، موقعیت ، سبک شکل را تنظیم کنید ";
stringMap[strings.Beautify_The_Mind_Map_7] ="[7 ]icons نمادهای هفت دسته (مشترک / سه بعدی / مطالعه / کار / شخصیت / حیوان / غذا) را اضافه کنید و اندازه / موقعیت را تنظیم کنید ";
stringMap[strings.Beautify_The_Mind_Map_8] ="[8] یک سبک ارجاع با یک کلیک به کل نقشه اضافه کنید ";
stringMap[strings.Beautify_The_Mind_Map_9] ="[9 ]map نقشه مدیریت گروه ";
stringMap[strings.Beautify_The_Mind_Map_10] ="[10] background پس زمینه شخصی (کتابخانه مواد) را می توان به کل نقشه اضافه کرد ";
stringMap[strings.Beautify_The_Mind_Map_9] ="[11] مرزها یا پس زمینه های فردی (کتابخانه مواد) را می توان به یک گره اضافه کرد ";
stringMap[strings.Beautify_The_Mind_Map_9] ="[12] length طول گره قابل کشیدن است ";


stringMap[strings.Map_Output] ="خروجی نقشه ";
stringMap[strings.Map_Output_1] ="[1] تصویر را ذخیره کنید ";
stringMap[strings.Map_Output_2] ="[2] ذخیره PDF ";
stringMap[strings.Map_Output_3] ="[3] اشتراک لینک ";
stringMap[strings.Map_Output_4] ="[4] اشتراک در طوفان ";
stringMap[strings.Map_Output_4] ="[5] چاپ ";

stringMap[strings.Work_together] ="همکاری ";
stringMap[strings.Work_together_1] ="[1] یک گروه مشترک ایجاد کنید (برچسب گروه ، اعلامیه گروه) ";
stringMap[strings.Work_together_2] ="[2] اعضای گروه اشتراک گذاری جدید را اضافه کنید ";
stringMap[strings.Work_together_3] ="[3] چندین عضو در گروه یک نقشه را با هم ویرایش می کنند ";
stringMap[strings.Work_together_4] ="[4] پرونده های مشترک را در گروه بارگذاری کنید (word / excle / pdf) ";
stringMap[strings.Work_together_5] ="[5] می توانید در گروه پیامی بگذارید ";
stringMap[strings.Work_together_6] ="[6] setting اشتراک تنظیم اقتدار مدیریت گروه ";
stringMap[strings.Work_together_7] ="[7] گروه انحلال ";

stringMap[strings.Work_together] ="اشتراک دانش (طوفان) ";
stringMap[strings.Community_Sharing_1] ="[1] می توانید آفرینش خود را با طوفان به اشتراک بگذارید ، تا افراد بیشتری بتوانند آن را ببینند ";
stringMap[strings.Community_Sharing_1] ="[2] می توانید برچسب ها را برای مشاهده محتوای مربوط به طوفان انتخاب کنید ";
stringMap[strings.Community_Sharing_1] ="[3] شما می توانید کارهای Storm را پسندیده و نظر دهید ";

//最近更新
stringMap[strings.Recently_Updated_Title_1] ="ایجاد یک حساب شخصی ";
stringMap[strings.Recently_Updated_Title_2] ="پایگاه دانش گسترده الگو ";
stringMap[strings.Recently_Updated_Title_3] ="پشتیبانی از چندین سیستم عامل ";
stringMap[strings.Recently_Updated_Title_4] ="سفارشی کردن تمام جزئیات  ";
stringMap[strings.Recently_Updated_Title_5] ="کتابخانه سبک شخصی";
stringMap[strings.Recently_Updated_Title_6] ="خروجی نقشه ";
stringMap[strings.Recently_Updated_Title_7] ="فضای ذخیره ابری ";
stringMap[strings.Recently_Updated_Title_8] ="پانزده نوع نقشه ";


stringMap[strings.Recently_Updated_Content_1] ="یک حساب شخصی ایجاد کنید و نقشه ایجاد شده را با حساب ذخیره کنید ";
stringMap[strings.Recently_Updated_Content_2] ="تعداد زیادی از پایگاه های دانش الگو برای بهبود کارایی یادگیری و کار مستقیماً نقل می شوند ";
stringMap[strings.Recently_Updated_Content_3] ="پشتیبانی از تولید آنلاین نسخه وب ، ترمینال تلفن همراه IOS ، ترمینال Mac ، ترمینال تلفن همراه Android ، استفاده از چند پلتفرم تبلت Android ";
stringMap[strings.Recently_Updated_Content_4] ="طراحی متن ، پس زمینه ، خطوط ، نمادهای تصویر و غیره را می توان با توجه به تنظیمات شخصی زیبا کرد ";
stringMap[strings.Recently_Updated_Content_5] ="کتابخانه به سبک شخصی به طور مستقیم نقل قول می شود ، و مشکل تطبیق رنگ را برطرف می کند";
stringMap[strings.Recently_Updated_Content_6] ="می تواند تصاویر ، قالب PDF را ذخیره کرده و به طور مستقیم با دوستان خود به اشتراک بگذارد ";
stringMap[strings.Recently_Updated_Content_7] ="پشتیبانی قوی از ذخیره سازی ابر ، دیگر نگران از دست دادن داده ها نباشید ";
stringMap[strings.Recently_Updated_Content_8] ="دو طرفه ، راست ، چپ ، ساختار سازمانی ، نمودار حباب منفرد ، نمودار حباب دوتایی ، نمودار درخت چپ ، نمودار درخت راست ، نمودار درختی دو طرفه ، جدول ، جدول زمانی افقی ، جدول زمانی عمودی ، خط زمانی S ، هشت چیدمان می تواند باشد به میل خود تغییر کرد  ";

//最近删除
stringMap[strings.Mind_Mindmap_Deleted_Title] ="اخیراً حذف شده ";
stringMap[strings.Mind_Mindmap_Deleted_Recovery] ="بازگرداندن ";
stringMap[strings.Mind_Mindmap_Deleted_Forever] ="حذف ";
stringMap[strings.Mind_Mindmap_Deleted_Filename] ="نام فایل ";
stringMap[strings.Mind_Mindmap_Deleted_Tips] ="پس از حذف در اینجا ، برای همیشه حذف می شود ";
stringMap[strings.Mind_Mindmap_Deleted_Operating] ="عملیاتی ";
stringMap[strings.Mind_Mindmap_Deleted_Selected] ="جستجوی نقشه اخیراً حذف شده ";
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] ="حذف تاریخ ";

//联系我们
stringMap[strings.Contact_Us_No_Public] ="عموم ";
stringMap[strings.Contact_Us_QQ_Group] ="گروه QQ  ";
stringMap[strings.Contact_Us_Message_Board] ="صفحه پیام ";
stringMap[strings.Contact_Us_Username] ="نام ";
stringMap[strings.Contact_Us_Email] ="صندوق پستی ";
stringMap[strings.Contact_Us_Voicemail] ="لطفا نظرات ارزشمند خود را بگذارید ";
stringMap[strings.Contact_Us_Send_Email] ="ایمیل بفرست ";

//验证信息
stringMap[strings.Input_Username_isNull] ="نام نمی تواند خالی باشد ";
stringMap[strings.Input_Email_isNull] ="لطفا ایمیل خود را وارد کنید ";
stringMap[strings.Input_Voicemail_isNull] ="لطفاً محتوا را برای ارسال وارد کنید ";
stringMap[strings.Input_Introduction_isNull] ="مقدمه نمی تواند خالی باشد ";
stringMap[strings.Input_Title_isNull] ="عنوان نمی تواند خالی باشد ";

stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] ="لطفا یک شماره تلفن معتبر وارد کنید ";
stringMap[strings.Input_Please_Enter_Verification_Code] ="لطفا کد تأیید را وارد کنید! ";
stringMap[strings.Input_Please_Enter_The_Password] ="لطفا رمز عبور خود را وارد کنید! ";
stringMap[strings.Input_The_Password_Format_Is_Incorrect] ="قالب رمز عبور نادرست است! ";
stringMap[strings.Input_Please_Enter_A_New_Password] ="لطفاً یک رمز عبور جدید وارد کنید! ";
stringMap[strings.Input_Confirm_The_New_Password] ="لطفاً رمز تأیید را وارد کنید! ";
stringMap[strings.Input_The_Passwords_Are_Inconsistent] ="رمزهای عبور متناقض هستند! ";
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] ="لطفاً کد تأیید صحیح را وارد کنید! ";
stringMap[strings.Input_The_Verification_Code_Error] ="خطای کد تأیید! ";

//使用帮助
stringMap[strings.Mind_Usinghelp_1] ="ایجاد نقشه ذهنی ";
stringMap[strings.Mind_Usinghelp_2] ="نحوه افزودن گره جدید ";
stringMap[strings.Mind_Usinghelp_3] ="ایجاد یک گره رایگان ";
stringMap[strings.Mind_Usinghelp_4] ="ایجاد انجمن  ";
stringMap[strings.Mind_Usinghelp_5] ="زیباسازی بوم ";
stringMap[strings.Mind_Usinghelp_6] ="زیباسازی متن ";
stringMap[strings.Mind_Usinghelp_7] ="درج یادداشت ها ، پیوندها ";
stringMap[strings.Mind_Usinghelp_8] ="درج نماد ";
stringMap[strings.Mind_Usinghelp_9] ="درج تصویر ";
stringMap[strings.Mind_Usinghelp_10] ="نحوه بازیابی حذف شده ";
stringMap[strings.Mind_Usinghelp_11] ="صادرات نقشه ذهنی ";
stringMap[strings.Mind_Usinghelp_12] ="ارتقا Member عضو ";
stringMap[strings.Mind_Usinghelp_13] ="کلید داغ ";
stringMap[strings.Mind_Usinghelp_Understood] ="فهمیدم";
stringMap[strings.Mind_Usinghelp_14] ="قرار دادن کتابخانه مواد ";
//使用帮助-2
stringMap[strings.Mind_Usinghelp_15] ="نقشه جدید ";
// stringMap[strings.Mind_Usinghelp_2] ="افزودن گره  ";
// stringMap[strings.Mind_Usinghelp_4] ="تاسیس انجمن  ";
// stringMap[strings.Mind_Usinghelp_5] ="زیباسازی بوم ";
// stringMap[strings.Mind_Usinghelp_6] ="زیباسازی متن ";
// stringMap[strings.Mind_Usinghelp_7] ="افزودن یادداشت ، پیوند ";
stringMap[strings.Mind_Usinghelp_17] ="لغو عملیات ، لغو ";
stringMap[strings.Mind_Usinghelp_18] ="گره را به انتقال بکشید ";
stringMap[strings.Mind_Usinghelp_19] ="هم ترازی گره ";
stringMap[strings.Mind_Usinghelp_20] ="تغییر نوع نقشه ";
stringMap[strings.Mind_Usinghelp_21] ="سبک خط نقشه  ";
stringMap[strings.Mind_Usinghelp_22] ="رنگ پس زمینه نقشه ";
stringMap[strings.Mind_Usinghelp_23] ="تنظیم شکل گره ";
stringMap[strings.Mind_Usinghelp_24] ="سبک خط گره ";
stringMap[strings.Mind_Usinghelp_25] ="تنظیمات تصویر گره ";
stringMap[strings.Mind_Usinghelp_26] ="تنظیمات نماد گره ";
stringMap[strings.Mind_Usinghelp_27] ="خلاصه افزودن گره ";
stringMap[strings.Mind_Usinghelp_28] ="انتخاب فریم تک گره ";
stringMap[strings.Mind_Usinghelp_29] ="چند گره به بلوک ";
stringMap[strings.Mind_Usinghelp_30] ="افزودن حاشیه به گره ";
stringMap[strings.Mind_Usinghelp_31] ="افزودن نمودار  ";
stringMap[strings.Mind_Usinghelp_32] ="پنهان کردن گره و بازیابی  ";
stringMap[strings.Mind_Usinghelp_33] ="چندین عملیات گره ";
stringMap[strings.Mind_Usinghelp_34] ="تغییر سبک ";
stringMap[strings.Mind_Usinghelp_35] ="وارد کردن صدا و توضیحات  ";
stringMap[strings.Mind_Usinghelp_36] ="نقشه راهنمای تصویر ";
stringMap[strings.Mind_Usinghelp_37] ="سند PDF نقشه ";
stringMap[strings.Mind_Usinghelp_38] ="چاپ نقشه  ";
stringMap[strings.Mind_Usinghelp_39] ="اشتراک لینک و مشاهده ";
stringMap[strings.Mind_Usinghelp_40] ="اشتراک نقشه به طوفان ";
stringMap[strings.Mind_Usinghelp_41] ="استفاده از پوشه ";
stringMap[strings.Mind_Usinghelp_42] ="حذف و بازیابی نقشه ";
//使用帮助3
stringMap[strings.Mind_Usinghelp_43] ="دوستان برای عضویت در گروه اقدام می کنند ";
stringMap[strings.Mind_Usinghelp_44] ="چگونه یک گروه را منحل کنیم ";
stringMap[strings.Mind_Usinghelp_45] ="بارگذاری اطلاعات پرونده ";
stringMap[strings.Mind_Usinghelp_46] ="برای پیوستن به برنامه موافقم ";
stringMap[strings.Mind_Usinghelp_47] ="ویرایش مشارکتی ";
stringMap[strings.Mind_Usinghelp_48] ="گروه اشتراک جدید ";
stringMap[strings.Mind_Usinghelp_49] ="دعوت از اعضای گروه ";

//风暴
stringMap[strings.Mind_Storm_Recommend] ="توصیه ";
stringMap[strings.Mind_Storm_Boilingpoint] ="نقطه جوش ";
stringMap[strings.Mind_Storm_Unfold] ="بسط دادن ";
stringMap[strings.Mind_Storm_Putaway] ="سقوط - فروپاشی ";
stringMap[strings.Mind_Storm_Myclassification] ="طبقه بندی من ";
stringMap[strings.Mind_Storm_Recommended_Classification] ="گروه پیشنهادی ";
stringMap[strings.Mind_Storm_Click_Add_Category] ="برای افزودن دسته کلیک کنید ";
stringMap[strings.Mind_Storm_No_Classification] ="هنوز طبقه بندی نشده است ";
stringMap[strings.Mind_Storm_No_Tinterested] ="علاقه ای ندارم ";
stringMap[strings.Mind_Storm_Report] ="گزارش ";
stringMap[strings.Mind_Storm_Views] ="نماها ";

stringMap[strings.Mind_Storm_Pornographic] ="پورنوگرافی و ابتذال ";
stringMap[strings.Mind_Storm_Advertising_Harassment] ="آزار و اذیت تبلیغاتی ";
stringMap[strings.Mind_Storm_Political_Religion] ="سیاست و دین ";
stringMap[strings.Mind_Storm_Infringement] ="تخلف (پرتره ، افترا ، سرقت ادبی ، استفاده متقلبانه)  ";
stringMap[strings.Mind_Storm_Prohibited_Content] ="محتوای ممنوع ";
stringMap[strings.Mind_Storm_Spread_Rumors] ="شایعات در حال گسترش ";
stringMap[strings.Mind_Storm_Report_Successful] ="گزارش موفقیت آمیز ";

//编辑
stringMap[strings.Global_Edit] ="ویرایش ";
stringMap[strings.Global_Cancel] ="لغو ";
stringMap[strings.Global_Ok] ="تعیین";
stringMap[strings.Global_Name] ="نام مستعار ";
stringMap[strings.Global_Introduction] ="معرفی ";
stringMap[strings.Global_Change_Avatar] ="تغییر آواتار ";
stringMap[strings.Global_Local_Upload] ="بارگذاری محلی  ";

//个人资料
stringMap[strings.User_Personal_information] ="اطلاعات شخصی ";
stringMap[strings.User_Member_Validity_Period] ="دوره اعتبار عضویت ";
stringMap[strings.User_My_Share] ="سهم من ";
stringMap[strings.User_My_Collection] ="مجموعه من ";
stringMap[strings.User_More_Message] ="بیشتر ";
stringMap[strings.User_Deleted_Sotrm] ="حذف طوفان ";
stringMap[strings.User_Folder_Rename] ="تغییر نام ";
stringMap[strings.User_Folder_Rename_Root] ="اصلاح مجوزها  ";
stringMap[strings.User_Folder_Rename_Root_Title] ="اصلاح مجوزها  ";
stringMap[strings.User_Folder_Root_Public] ="افشای کامل ";
stringMap[strings.User_Folder_Root_Viewonly] ="پوشه عمومی فقط اجازه مشاهده می دهد ";
stringMap[strings.User_Folder_Root_Private] ="خصوصی ";
stringMap[strings.User_Folder_Root_Public_Introduction] ="افراد دیگر می توانند موارد دلخواه شما را در صفحه نمایه شما مشاهده کنند ";
stringMap[strings.User_Message_Link] ="ارتباط دادن ";
stringMap[strings.User_New_Group] ="گروه جدید ";
stringMap[strings.User_New_Group_Title] ="یک گروه جدید ایجاد کنید ";
stringMap[strings.User_New_Group_Style_Title] ="نوع فایل ";
stringMap[strings.User_Share_Is_Null] ="هنوز اشتراک گذاری نشده است! ";
stringMap[strings.User_Deleted_Storm_Tips] ="آیا مطمئن هستید که می خواهید این طوفان را حذف کنید؟ ";
stringMap[strings.User_Remove_From_List] ="از لیست حذف کن،";
    stringMap[strings.User_Move_To] ="انتقال به ،";
    stringMap[strings.User_Deleted_Link] ="حذف لینک";
stringMap[strings.User_Collction_Is_Null] ="بدون موارد دلخواه";
stringMap[strings.User_Collection] ="مجموعه";

//登录框
stringMap[strings.User_Lolgin_Welcome_To_Login] ="به ذهن خوش آمدید +";
stringMap[strings.User_Lolgin_Free_Registration] ="ثبت نام رایگان";
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] ="لطفا شماره تلفن را وارد کنید";
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] ="لطفا کد تأیید را وارد کنید";
stringMap[strings.User_Lolgin_Obtain] ="به دست آوردن";
stringMap[strings.User_Lolgin_Password_Login] ="ورود به سیستم رمز عبور";
stringMap[strings.User_Lolgin_Login] ="وارد شدن";
stringMap[strings.User_Lolgin_Register_An_Account] ="ثبت نام حساب";
stringMap[strings.User_Lolgin_Already_Have_An_Account] ="از قبل یک حساب کاربری دارید";
stringMap[strings.User_Lolgin_To_Log_In] ="رفتن به سیستم ورود";
stringMap[strings.User_Lolgin_Please_Enter_Password] ="لطفا یک رمز عبور وارد کنید";
stringMap[strings.User_Lolgin_Confirm_Password] ="تأیید رمز عبور";
stringMap[strings.User_Lolgin_Registered] ="ثبت شده";
stringMap[strings.User_Lolgin_Account_Login] ="ورود به حساب کاربری";
stringMap[strings.User_Lolgin_Scan_Code_Login] ="اسکن ورود کد";
stringMap[strings.User_Lolgin_Forgot_Password] ="رمز عبور خود را فراموش کرده اید";
stringMap[strings.User_Lolgin_Verification_Code_Login] ="ورود به سیستم کد تأیید";
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] ="Open Mind + و کش رفتن در بالای رابط تنظیمات";
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] ="این کد QR در مدت پنج دقیقه منقضی می شود ، لطفاً هنگام انقضا کلیک کنید";
stringMap[strings.User_Lolgin_Refresh] ="تازه کردن";
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] ="اسکن موفقیت آمیز است ، لطفاً بر روی تلفن خود تأیید کنید";
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] ="آیا مجاز به ورود به سیستم هستید";
stringMap[strings.User_Lolgin_Please_Enter_New_Password] ="لطفاً یک رمز عبور جدید وارد کنید";
stringMap[strings.User_Lolgin_Reset] ="تنظیم مجدد";

//我的导图
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] ="جستجوی نوع الگو یا الگو";
stringMap[strings.My_Mind_Date] ="تاریخ";
stringMap[strings.My_Mind_Create_Folder] ="پرونده بساز";
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] ="لطفا یک نام پوشه را وارد کنید";
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] ="لطفا یک نام پوشه جدید وارد کنید";
stringMap[strings.My_Mind__No_Map_Yet_Go] ="هنوز هیچ نقشه ای نیست ، برو جلو";
stringMap[strings.My_Mind_Create] ="ايجاد كردن";
stringMap[strings.My_Mind_Right] ="اکنون!";
stringMap[strings.My_Mind_Open] ="روشن کن";
stringMap[strings.My_Mind_Type_Left_Right] ="نمودار جادوگر دوگانه";
stringMap[strings.My_Mind_Type_Unilateral] ="نمودار جادوگر تنها";
stringMap[strings.My_Mind_Type_Bottom] ="چارت سازمانی";
stringMap[strings.My_Mind_Type_Bubble] ="نمودار حباب";
stringMap[strings.My_Mind_Type_Time_Vertical] ="نمودار برنامه";
stringMap[strings.My_Mind_Blank_Mind_Map] ="نقشه ذهن خالی";
stringMap[strings.My_Mind_All] ="همه";
stringMap[strings.My_Mind_Views] ="بازدیدها:";

//风暴搜索
stringMap[strings.Storm_Search_How_Title] ="برچسب های محبوب";
stringMap[strings.Storm_History_Search] ="جستجوی تاریخچه";

//公共 header
stringMap[strings.Public_Header_Mind] ="نقشه";
stringMap[strings.Public_Header_Outline] ="طرح کلی";
stringMap[strings.Public_Header_Download_Apps] ="بارگیری برنامه ها";
stringMap[strings.Public_Header_Mobile_Version] ="نسخه موبایل";
stringMap[strings.Public_Header_Creator] ="ایجاد کننده:";
stringMap[strings.Public_Header_View_Frequency] ="فرکانس مشاهده:";
stringMap[strings.Public_Header_Save] ="صرفه جویی";
stringMap[strings.Header_Mind_Tips_Saved] ="ذخیره";
stringMap[strings.Header_Mind_Tips_Edit] ="ویرایش شده";
stringMap[strings.Header_Mind_Tips_Update_Saved] ="به روزرسانی ذخیره شد";

//导图编辑菜单
stringMap[strings.Mind_Edit_Revoke] ="لغو";
stringMap[strings.Mind_Edit_Restore] ="بازگرداندن";
stringMap[strings.Mind_Edit_Subtopic] ="زیرمجموعه";
stringMap[strings.Mind_Edit_Top_Subtopic] ="موضوع برتر";
stringMap[strings.Mind_Edit_Bottom_Subtopic] ="موضوع بعدی";
stringMap[strings.Mind_Edit_Free_Node] ="گره رایگان";
stringMap[strings.Mind_Edit_Summary] ="خلاصه";
stringMap[strings.Mind_Edit_Block] ="تکه ها";
stringMap[strings.Mind_Edit_Insert] ="درج";
stringMap[strings.Mind_Edit_Related] ="اتحادیه";
stringMap[strings.Mind_Edit_Style] ="سبک";
stringMap[strings.Mind_Edit_Recording] ="ضبط";
stringMap[strings.Mind_Edit_Insert_Picture] ="درج تصویر";
stringMap[strings.Mind_Edit_Insert_Icon] ="درج نماد";
stringMap[strings.Mind_Edit_Insert_Remarks] ="درج یادداشت";
stringMap[strings.Mind_Edit_Insert_Link] ="درج پیوند";
stringMap[strings.Mind_Edit_Insert_Summary_Graph] ="درج نمودار";
stringMap[strings.Mind_Edit_Upload] ="بارگذاری";
stringMap[strings.Mind_Edit_Right_Icon] ="آیکون";
stringMap[strings.Mind_Edit_Right_Style] ="سبک";
stringMap[strings.Mind_Edit_Audio_Decoding] ="رمزگشایی ...";
stringMap[strings.Mind_Edit_Audio_Duration] ="مدت زمان";
stringMap[strings.Mind_Edit_Delete_Audio_Tips] ="آیا مطمئن هستید که می خواهید صدای ضبط شده را حذف کنید؟";
stringMap[strings.Mind_Edit_Join_Membership] ="عضویت در عضویت";
stringMap[strings.Mind_Edit_Upgrade] ="ارتقا";
stringMap[strings.Mind_Edit_Upgrade_Item] = "ترقية برو"
stringMap[strings.Mind_Edit_Join_Membership_Tips] ="اعضا می توانند زمان ضبط بیشتری داشته باشند ، اکنون برای ارتقا به عضو بروید؟";

stringMap[strings.Mind_Edit_Choose_Material] ="انتخاب مواد";
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] ="تصویر را بکشید و رها کنید";
stringMap[strings.Mind_Edit_Select_locally] ="انتخاب از محلی";
stringMap[strings.Mind_Edit_Choose_From] ="انتخاب از کتابخانه مواد";
stringMap[strings.Mind_Edit_Mine] ="مال خودم";
stringMap[strings.Mind_Edit_My_Material] ="مواد من";
stringMap[strings.Mind_Edit_Recently_Used] ="اخیرا مورد استفاده قرار گرفته";
stringMap[strings.Mind_Edit_Use] ="استفاده کنید";
stringMap[strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] ="هنوز هیچ ماده ای نیست ، بگذارید آن را اضافه کنیم!";
stringMap[strings.Mind_Edit_Select_Chart_Type] ="انتخاب نوع نمودار";
stringMap[strings.Mind_Edit_Remarks] ="ملاحظات";
stringMap[strings.Mind_Edit_Remarks_Tips] ="لطفاً محتوای اظهارات را وارد کنید";
stringMap[strings.Mind_Edit_Add] ="اضافه کردن به";
stringMap[strings.Mind_Edit_Please_Enter_The_Link] ="لطفا یک پیوند وارد کنید";

//编辑页左侧菜单
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture] ="عکس را ذخیره کن";
stringMap[strings.Mind_Edit_Left_Menu_Export_Pdf] ="صادرات PDF";
stringMap[strings.Mind_Edit_Left_Menu_Share_Links] ="لینک را به اشتراک بگذارید";
stringMap[strings.Mind_Edit_Left_Menu_Share_The_Storm] ="اشتراک در طوفان";
stringMap[strings.Mind_Edit_Left_Menu_Printing] ="چاپ";
stringMap[strings.Mind_Edit_Left_Menu_Unlock_Full_Version] ="باز کردن نسخه کامل";
stringMap[strings.Mind_Edit_Left_Menu_Help] ="کمک";
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Format] ="ذخیره قالب";
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Png] ="قالب png";
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] ="قالب jpg";
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Webp] ="قالب وب";
stringMap[strings.Mind_Edit_Left_Menu_Share_Map] ="اشتراک نقشه";
stringMap[strings.Mind_Edit_Left_Menu_Share_Switch] ="اشتراک سوییچ";
stringMap[strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] ="می توانید پیوند را کپی کرده و ارسال کنید";
stringMap[strings.Mind_Edit_Left_Menu_Copy_Link] ="پیوند کپی";
stringMap[strings.Mind_Edit_Left_Menu_Follow_The_Link] ="اگر می خواهید پیوند را تغییر دهید ، لطفا کلیک کنید";
stringMap[strings.Mind_Edit_Left_Menu_Regenerate] ="بازسازی";
stringMap[strings.Mind_Edit_Left_Menu_Link_Password] ="گذرواژه پیوند:";
stringMap[strings.Mind_Edit_Left_Menu_Allow_Save] ="مجاز به پس انداز";
stringMap[strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] ="حداکثر بازدید";
stringMap[strings.Mind_Edit_Left_Menu_Infinite] ="نامحدود";
stringMap[strings.Mind_Edit_Left_Menu_Limit] ="حد";
stringMap[strings.Mind_Edit_Left_Menu_Second] ="بار";
stringMap[strings.Mind_Edit_Left_Menu_Link_Validity] ="دوره اعتبار پیوند";
stringMap[strings.Mind_Edit_Left_Menu_1_Day] ="1 روز";
stringMap[strings.Mind_Edit_Left_Menu_3_Days] ="3 روز";
stringMap[strings.Mind_Edit_Left_Menu_7_Days] ="7 روز";
stringMap[strings.Mind_Edit_Left_Menu_Permanent] ="دائمی";
stringMap[strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] ="کپی کردن مطالب و پیوند";
stringMap[strings.Mind_Edit_Left_Menu_Regenerate_Tips] ="آیا مطمئن هستید که می خواهید دوباره تولید کنید؟ پیوند قبلی نامعتبر است!";
stringMap[strings.Mind_Edit_Left_Menu_Share] ="به اشتراک بگذارید";
stringMap[strings.Mind_Edit_Left_Menu_Custom_Category] ="طبقه بندی سفارشی:";
stringMap[strings.Mind_Edit_Left_Menu_Allow_Comments] ="آیا اجازه می دهید نظر بدهید";
stringMap[strings.Mind_Edit_Left_Menu_Edit_Add_Category] ="ویرایش برای افزودن دسته";
stringMap[strings.Mind_Edit_Left_Menu_Membership_Rights] ="مزایای عضو";
stringMap[strings.Mind_Edit_Left_Menu_Support_Platform] ="بستر پشتیبانی";
stringMap[strings.Mind_Edit_Left_Menu_Open_Membership] ="عضویت در عضویت";
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] ="استفاده از کد فعال سازی";
stringMap[strings.Mind_Edit_Left_Menu_purchase_Tips] ="مزایای عضویت پس از خرید";
stringMap[strings.Mind_Edit_Left_Menu_Payment_Type] ="انتخاب روش پرداخت";
stringMap[strings.Mind_Edit_Left_Menu_Activity_Price] ="قیمت فعالیت";
stringMap[strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] ="مبلغ پرداخت شده در واقع";
stringMap[strings.Mind_Edit_Left_Menu_Immediate_Renewal] ="اکنون تجدید کنید";
stringMap[strings.Mind_Edit_Left_Menu_Terms_Of_Service] ="شرایط استفاده از خدمات";
stringMap[strings.Mind_Edit_Left_Menu_Privacy_Policy] ="سیاست حفظ حریم خصوصی";
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code] ="کد فعال سازی:";
stringMap[strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] ="لطفا کد فعال سازی را وارد کنید";
stringMap[strings.Mind_Edit_Left_Menu_Verify_Activation_Code] ="تأیید کد فعال سازی";
stringMap[strings.Mind_Edit_Left_Menu_Select_Categories] ="انتخاب گروه";
stringMap[strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] ="عنوان اصلی نمی تواند خالی باشد";
stringMap[strings.Mind_Edit_Left_Menu_Coupon] ="کوپن:";
stringMap[strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] ="لطفا کد کوپن را وارد کنید";
stringMap[strings.Mind_Confirm_Use_Coupons] ="استفاده از کوپن را تأیید کنید";
stringMap[strings.Mind_Edit_Left_Menu_Verification] ="تایید";
stringMap[strings.Mind_Edit_Left_Menu_Preferential_Amount] ="قیمت با تخفیف";
stringMap[strings.Mind_Edit_Left_Menu_Pay_Immediately] ="فوری پرداخت";
stringMap[strings.Mind_Edit_Left_Menu_Wx_Pay] ="WeChat Pay";
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] ="تأیید اطلاعات بازخرید";
stringMap[strings.Mind_Edit_Left_Menu_Exchange_Code] ="کد بازخرید:";
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange] ="تبادل";

//编辑页右侧菜单
stringMap[strings.Mind_Edit_Right_Menu_canvas] ="بوم";
stringMap[strings.Mind_Edit_Right_Menu_background_Color] ="رنگ پس زمینه";
stringMap[strings.Mind_Edit_Right_Menu_layout] ="چیدمان";
stringMap[strings.Mind_Edit_Right_Menu_Line_Style] ="سبک خط";
stringMap[strings.Mind_Edit_Right_Menu_line_Color] ="رنگ خط";
stringMap[strings.Mind_Edit_Right_Menu_Line_Width] ="عرض خط";
stringMap[strings.Mind_Edit_Right_Menu_Line_Tapering] ="خطوط مخروطی هستند";
stringMap[strings.Mind_Edit_Right_Menu_Theme_Font] ="قلم تم";
stringMap[strings.Mind_Edit_Right_Menu_Child_Font] ="زیر قلم";
stringMap[strings.Mind_Edit_Right_Menu_text_Color] ="رنگ متن";
stringMap[strings.Mind_Edit_Right_Menu_font_Size] ="اندازه فونت";
stringMap[strings.Mind_Edit_Right_Menu_Font_Style] ="نوع قلم";
stringMap[strings.Mind_Edit_Right_Menu_Border_Color] ="رنگ لبه";
stringMap[strings.Mind_Edit_Right_Menu_Fill_Color] ="پر کردن رنگ";
stringMap[strings.Mind_Edit_Right_Menu_Border_Shape] ="شکل حاشیه";
stringMap[strings.Mind_Edit_Right_Menu_Border_Width] ="عرض مرز";
stringMap[strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] ="نمایش جلوه سایه (عملکرد اشتراک)";
stringMap[strings.Mind_Edit_Right_Menu_Line_Color] ="رنگ خط";
stringMap[strings.Mind_Edit_Right_Menu_Dotted_Line] ="خط نقطه چین";
stringMap[strings.Mind_Edit_Right_Menu_Picture_Size] ="اندازه تصویر";
stringMap[strings.Mind_Edit_Right_Menu_Picture_Location] ="مکان عکس";
stringMap[strings.Mind_Edit_Right_Menu_Picture_Shape] ="شکل تصویر";
stringMap[strings.Mind_Edit_Right_Menu_Icon_Size] ="اندازه نماد";
stringMap[strings.Mind_Edit_Right_Menu_Icon_Position] ="موقعیت نماد";
stringMap[strings.Mind_Edit_Right_Menu_routine] ="مرسوم";
stringMap[strings.Mind_Edit_Right_Menu_priority] ="اولویت";
stringMap[strings.Mind_Edit_Right_Menu_Progress] ="برنامه";
stringMap[strings.Mind_Edit_Right_Menu_Stars] ="ستاره";
stringMap[strings.Mind_Edit_Right_Menu_Week] ="هفته";
stringMap[strings.Mind_Edit_Right_Menu_Head_Portrait] ="آواتار";
stringMap[strings.Mind_Edit_Right_Menu_3D] ="سه بعدی";
stringMap[strings.Mind_Edit_Right_Menu_Learning] ="فرا گرفتن";
stringMap[strings.Mind_Edit_Right_Menu_Work] ="شغل ها";
stringMap[strings.Mind_Edit_Right_Menu_Characters] ="شخصیت";
stringMap[strings.Mind_Edit_Right_Menu_Animals] ="حیوان";
stringMap[strings.Mind_Edit_Right_Menu_Food] ="غذا";
stringMap[strings.Mind_Edit_Node_Menu_Copy_Style] ="کپی کردن سبک";
stringMap[strings.Mind_Edit_Node_Menu_Sticking_Style] ="چسباندن سبک";
stringMap[strings.Mind_Edit_Node_Menu_Modify_Shape] ="اصلاح شکل";
stringMap[strings.Mind_Edit_Node_Menu_View_Picture] ="مشاهده تصویر";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Picture] ="حذف تصویر";
stringMap[strings.Mind_Edit_Node_Menu_Copy] ="کپی 🀄";
stringMap[strings.Mind_Edit_Node_Menu_Paste] ="چسباندن";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Line] ="حذف خط";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] ="حذف";
stringMap[strings.Mind_Edit_Node_Menu_Edit_Remarks] ="ویرایش یادداشت ها";
stringMap[strings.Mind_Edit_Node_Menu_Open_Link] ="لینک باز";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Remarks] ="حذف یادداشت";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Link] ="حذف لینک";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Icon] ="حذف نماد";
stringMap[strings.Mind_Edit_Node_Menu_Icon_Style] ="سبک نماد";

//统计图编辑面板
stringMap[strings.Echart_Edit_Statistical] ="آمار";
stringMap[strings.Echart_Edit_Value] ="مقدار";
stringMap[strings.Echart_Edit_Color] ="رنگ";
stringMap[strings.Echart_Edit_Title] ="عنوان";
stringMap[strings.Echart_Edit_Detailed_Description] ="توصیف همراه با جزئیات";
stringMap[strings.Echart_Edit_Value_Sort] ="مرتب سازی";
stringMap[strings.Echart_Edit_Value_Display_Type] ="نوع نمایش";
stringMap[strings.Echart_Edit_Value_Color_Scheme] ="طرح رنگ آمیزی";
stringMap[strings.Echart_Edit_Value_Display_Size] ="اندازه نمایش";
stringMap[strings.Echart_Edit_Value_Title_Size] ="اندازه عنوان";
stringMap[strings.Echart_Edit_Value_Explain_Size] ="اندازه تفسیر";
stringMap[strings.Echart_Edit_Value_Subscribe] ="اشتراک، ابونمان";

//风暴标签页
stringMap[strings.StormBookTag_Subscribed] ="مشترک";
stringMap[strings.StormBookTag_List] ="لیست";

//风暴分享页
stringMap[strings.Storm_Share_Comment] ="اظهار نظر";
stringMap[strings.Storm_Share_Reply] ="پاسخ";
stringMap[strings.Storm_Share_Let_Me_Comment_Too] ="بگذارید من هم نظر بدهم ...";
stringMap[strings.Storm_Share_Release] ="رهایی";
stringMap[strings.Storm_Share_Main_Title] ="عنوان اصلی";
stringMap[strings.Storm_Share_Subtitle] ="عنوان فرعی";
stringMap[strings.Storm_Share_Author] ="نویسنده";
stringMap[strings.Storm_Share_Replies] ="پاسخ";
stringMap[strings.Storm_Share_Comment_Is_Null_Tips] ="بدون نظر ، بیا و اولین نفر باش";
stringMap[strings.Storm_Share_Comment_Input_Is_Null_Tips] ="لطفاً یک نظر وارد کنید ...";
stringMap[strings.Storm_Share_Delete_Comment_Tips] ="آیا مطمئن هستید که می خواهید این نظر را حذف کنید؟";

//导图内部使用帮助
stringMap[strings.Mind_Usinghelp_Btn_1] ="کلیدهای میانبر (ویندوز)";
stringMap[strings.Mind_Usinghelp_Btn_2] ="کلید میانبر (mac)";
stringMap[strings.Mind_Usinghelp_Btn_3] ="شرح";
stringMap[strings.Mind_Usinghelp_Btn_4] ="[Ctrl] + [D]";
stringMap[strings.Mind_Usinghelp_Btn_5] ="[Command]+[D]";
stringMap[strings.Mind_Usinghelp_Btn_6] ="افزودن گره کودک";
stringMap[strings.Mind_Usinghelp_Btn_7] ="[Delet]";
stringMap[strings.Mind_Usinghelp_Btn_8] ="گره فعلی را حذف کنید (توجه داشته باشید که اگر در حالت ویرایش باشد نمی توان آن را حذف کرد)";
stringMap[strings.Mind_Usinghelp_Btn_9] ="[Ctrl] + [E]";
stringMap[strings.Mind_Usinghelp_Btn_10] ="[Command]+[E]";
stringMap[strings.Mind_Usinghelp_Btn_11] ="تغییر گره فعلی";
stringMap[strings.Mind_Usinghelp_Btn_12] ="[Ctrl] + [↑] [↓] [←] [→]";
stringMap[strings.Mind_Usinghelp_Btn_13] ="[Command] + [↑] [↓] [←] [→]";
stringMap[strings.Mind_Usinghelp_Btn_14] ="[Ctrl] + [+]";
stringMap[strings.Mind_Usinghelp_Btn_15] ="[Command]+[+]";
stringMap[strings.Mind_Usinghelp_Btn_16] ="بزرگنمایی نقشه";
stringMap[strings.Mind_Usinghelp_Btn_17] ="[Ctrl] + [-]";
stringMap[strings.Mind_Usinghelp_Btn_18] ="[Command]+[-]";
stringMap[strings.Mind_Usinghelp_Btn_19] ="کوچک کردن نقشه";
stringMap[strings.Mind_Usinghelp_Btn_20] ="[Ctrl] + [S]";
stringMap[strings.Mind_Usinghelp_Btn_21] ="[Command] + [S]";
stringMap[strings.Mind_Usinghelp_Btn_22] ="[Ctrl] + [Z]";
stringMap[strings.Mind_Usinghelp_Btn_23] ="[Command]+[Z]";
stringMap[strings.Mind_Usinghelp_Btn_24] ="[Ctrl] + [Shift] + [Z]";
stringMap[strings.Mind_Usinghelp_Btn_25] ="[Command] + [Shift] + [Z]";
stringMap[strings.Mind_Usinghelp_Btn_26] ="[Ctrl] + [B]";
stringMap[strings.Mind_Usinghelp_Btn_27] ="[Command]+[B]";
stringMap[strings.Mind_Usinghelp_Btn_28] ="خروج از ویرایش";

//提示文字
stringMap[strings.Message_Tips_Login_Success] ="ورود موفقیت آمیز";
stringMap[strings.Message_Tips_Dlt_Success] ="با موفقیت حذف شد";
stringMap[strings.Message_Tips_Report_Success] ="گزارش موفقیت آمیز";
stringMap[strings.Message_Tips_Copy_Success] ="کپی موفقیت آمیز";
stringMap[strings.Message_Tips_Collction_Success] ="مجموعه موفقیت آمیز";
stringMap[strings.Message_Tips_Avatar_Modification_Success] ="آواتار با موفقیت اصلاح شد";
stringMap[strings.Message_Tips_Signature_Modification_Success] ="امضا با موفقیت اصلاح شد";
stringMap[strings.Message_Tips_Name_Modification_Success] ="نام مستعار با موفقیت اصلاح شد";
stringMap[strings.Message_Tips_Modification_Success] ="با موفقیت اصلاح شد";
stringMap[strings.Message_Tips_New_Profile_Success] ="ایجاد یک پوشه جدید با موفقیت";
stringMap[strings.Message_Tips_Mobile_Success] ="حرکت موفقیت آمیز";
stringMap[strings.Message_Tips_Submit_Success] ="با موفقیت ثبت شد";
stringMap[strings.Message_Tips_Exchange_Success] ="تبادل موفقیت آمیز";
stringMap[strings.Message_Tips_Please_Code] ="لطفا کد فعال سازی را وارد کنید";
stringMap[strings.Message_Tips_Agree_To_Terms] ="لطفا با سیاست حفظ حریم خصوصی موافقت کنید";
stringMap[strings.Message_Tips_Please_Sign_In] ="لطفا وارد شوید";
stringMap[strings.Message_Tips_Share_Success] ="اشتراک موفقیت";
stringMap[strings.Message_Tips_Create_Success] ="با موفقیت ایجاد شد";
stringMap[strings.Message_Tips_Profile_Name_No_Null] ="نام پوشه نمی تواند خالی باشد";
stringMap[strings.Message_Tips_Selecte_Success] ="جستجو کامل";
stringMap[strings.Message_Tips_Subscription_Success] ="اشتراک موفقیت آمیز";
stringMap[strings.Message_Tips_Unsubscribe_Success] ="لغو اشتراک موفق است";
stringMap[strings.Message_Tips_Related_Content_Not_Found] ="محتوای مرتبط یافت نشد";
stringMap[strings.Message_Tips_Max_Add_Class] ="حداکثر فقط می تواند اضافه کند";
stringMap[strings.Message_Tips_Registered_Success] ="موفقیت در ثبت نام";
stringMap[strings.Message_Tips_Scan_Code_Login_Failed] ="ورود اسکن کد انجام نشد";
stringMap[strings.Message_Tips_Share_Is_Null] ="پیوند اشتراک گذاری وجود ندارد";
stringMap[strings.Message_Tips_Share_Link_Does_Not_Exist] ="بارگذاری پرونده ها نباید بیش از 3 مگابایت باشد";
stringMap[strings.Message_Tips_Get_Permission_Successfully] ="اخذ مجوز با موفقیت";
stringMap[strings.Message_Tips_Membership_Required] ="عضویت لازم است";
stringMap[strings.Message_Tips_Save_Success] ="با موفقیت ذخیره شد";
stringMap[strings.Message_Tips_Scan_Code_Login_Success] ="اسکن ورود کد با موفقیت";
stringMap[strings.Message_Tips_Style] ="یک طبقه بندی؟";

//大纲模式
stringMap[strings.Outline_Menu_Indent] ="تورفتگی";
stringMap[strings.Outline_Menu_Decrease_Indent] ="کاهش تورفتگی";

//帮助提示
stringMap[strings.Help_Background_Content] ="مربوط به پس زمینه خط ، مربوط به پس زمینه خط ، مربوط به پس زمینه خط ، مربوط به پس زمینه خط ، مربوط به پس زمینه خط ، مربوط به پس زمینه خط ، مربوط به پس زمینه خط ، مربوط به پس زمینه خط ، مربوط به پس زمینه خط ،";
stringMap[strings.Help_Mind_Content] ="شما می توانید نقشه ها را با طرح های مختلف مطابق با ترجیحات خود تهیه کنید: نقشه راهنمای دوگانه ، نقشه سمت چپ ، نقشه سمت راست ، نمودار سازمان ، درخت دو طرفه ، درخت سمت چپ ، درخت سمت راست و جدول";
stringMap[strings.Help_Share_Link_Content] ="پیوندهای اشتراک گذاری می توانند در WeChat ، QQ ، Weibo و کانالهای دیگر به صورت پیوند به اشتراک گذاشته شوند و دوستان می توانند محتوا را مشاهده کنند";
stringMap[strings.Using_Templates] ="استفاده از الگو";

//邀请码
stringMap[strings.User_Lolgin_Please_Enter_Invitation_Code] ="لطفا کد دعوت را وارد کنید";
stringMap[strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] ="لطفا کد دعوت صحیح را وارد کنید";
stringMap[strings.User_Lolgin_Optional] ="اختیاری";

//使用素材

stringMap[strings.Cannot_Add_To_Node_Background] ="برای پس زمینه گره قابل استفاده نیست";
stringMap[strings.Cannot_Add_To_Img_Background] ="برای پس زمینه متن قابل استفاده نیست";
stringMap[strings.Cannot_Add_To_Mind_Background] ="برای پس زمینه بسته بندی خط قابل استفاده نیست";
stringMap[strings.Mind_Edit_Node_Menu_Add_Encircle_Background] ="افزودن تصویر پس زمینه";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] ="حذف تصویر پس زمینه";
stringMap[strings.Mind_Add_Background_Image] ="درج تصویر پس زمینه";
stringMap[strings.Mind_Delete_Background_Image] ="حذف تصویر پس زمینه";

stringMap[strings.Permanent_Member] ="عضو دائمی";
stringMap[strings.Insufficient_Storage_Space] ="فضای ذخیره سازی کافی نیست ، برای افزایش فضا اعضا را ارتقا دهید.";
stringMap[strings.Used_Space] ="ذخیره سازی";
stringMap[strings.Current_Storage] ="ذخیره سازی فعلی:";
stringMap[strings.Used_Space_Upgrade] ="ارتقا Space فضای ذخیره سازی";
stringMap[strings.My_Mind_Is_Null_Tips] ="ذهن + نقشه مغز و یادداشت برداری فوق العاده یادداشت ها ، برای افزودن بال به ذهن برای ایجاد ، نمایش ، برنامه ریزی و یادداشت";
stringMap[strings.Insufficient_Storage_Space_Tempalte_Tips] ="فضای ذخیره سازی کافی نیست ، کاربران را برای استفاده از الگو ارتقا دهید.";

stringMap[strings.Group_Management] ="گروه";
stringMap[strings.Save_Img_Fill_Full] ="سنگ فرش کامل";
stringMap[strings.Save_Img_Center_Tilt] ="مرکز و کج";
stringMap[strings.Save_Img_File_Full_Dark_Color] ="سنگ فرش (تاریک)";
stringMap[strings.Save_Img_Mind_Map_Watermarking] ="علامت + ذهن نقشه علامت";
stringMap[strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] ="برای محافظت از ایجاد دانش ، علامت اضافه کنید:";
stringMap[strings.Save_Img_Watermark_Text] ="متن علامت:";
stringMap[strings.Save_Img_Watermark_Logo] ="آرم واترمارک:";
stringMap[strings.Save_Img_Watermark_Style] ="سبک علامت:";
stringMap[strings.Save_Img_Please_Input] ="لطفا وارد شوید";
stringMap[strings.Save_Img_Chop_Tips] ="عضویت را برای بهره مندی از حقوق علامت چاپ";
stringMap[strings.Public_Header_Mindmap_Mind] ="حالت نقشه";
stringMap[strings.Copy_Mind_Map] ="کپی کردن نقشه";
stringMap[strings.Font_Style_Bold] ="پررنگ";
stringMap[strings.Font_Style_Italics] ="ایتالیک";
stringMap[strings.Font_Style_Delete_Line] ="Strikethrough";
stringMap[strings.Remove_Any] ="برداشتن";

stringMap[strings.Mindmap_Review] ="بررسی نقشه";
stringMap[strings.Mindmap_Review_Recently_Updated_Content_10] ="درک عمیق تر و تقویت حافظه ، مرتب کردن ایده ها و اطلاعات به طور کارآمد";
stringMap[strings.Mindmap_Review_Content] ="بررسی نقشه به پخش فرآیند ویرایش اشاره دارد و محتوا به صورت پی در پی از موضوع اصلی پخش می شود   بازبینی نقشه می تواند به شما در انجام موجودی سیستم ، تجزیه و تحلیل و یافتن نقاط ضعف در ایده کمک کند. الهامات مختلف پیدا کنید. ";
stringMap[strings.Mindmap_Review_Speed] ="سرعت";
stringMap[strings.Mindmap_Review_Word_Speed] ="سرعت متن";
stringMap[strings.Mindmap_Review_Background_Ratio] ="نسبت پس زمینه";
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap[strings.Mindmap_Review_Current_Playback_Node_Magnification] ="بزرگنمایی گره پخش کنونی";
stringMap[strings.Mindmap_Review_Node_Playback_Order] ="سفارش بازی گره";
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap[strings.Mind_Group_Picture_Upload] ="بارگذاری پرتره";
stringMap[strings.Mind_Group_Upload_From_Local] ="بارگذاری محلی";
stringMap[strings.Mind_Group_Mind_Mapping] ="نقشه برداری ذهن";
stringMap[strings.Mind_Group_Information] ="داده ها";

stringMap[strings.Mind_Group_Invite_New_Members] ="دعوت از اعضای جدید";
stringMap[strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] ="لطفا شماره تلفن را وارد کنید";
stringMap[strings.Mind_Group_Cancel] ="لغو";
stringMap[strings.Mind_Group_Confirm] ="تعیین";
stringMap[strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] ="شماره تلفن همراه نمی تواند خالی باشد";
stringMap[strings.Mind_Group_Invite_The_Successful] ="دعوت موفقیت آمیز";


stringMap[strings.Mind_Group_Shared_Group_Membership] ="اعضای گروه مشترک";
stringMap[strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] ="حداکثر تعداد اعضای گروه مشترک";
stringMap[strings.Mind_Group_Membership_Limit] ="حد اعضا";
stringMap[strings.Mind_Group_Current_Member_Limit] ="حد اعضا فعلی";
stringMap[strings.Mind_Group_Upgrade_Member_Limit] ="ارتقا Member حد اعضا";
stringMap[strings.Mind_Group_Members_Of_The_Search] ="جستجو در اعضا";
stringMap[strings.Mind_Group_Creator] ="ایجاد کننده";
stringMap[strings.Mind_Group_Administrator] ="مدیر";
stringMap[strings.Mind_Group_Number_Of_People] ="مردم";
stringMap[strings.Mind_Group_Selected] ="انتخاب";
stringMap[strings.Mind_Group_Member] ="عضو";
stringMap[strings.Mind_Group_Check_All] ="انتخاب همه";
stringMap[strings.Mind_Group_Remove] ="برداشتن";
stringMap[strings.Mind_Group_Invite_New_Members] ="دعوت از اعضای جدید";
stringMap[strings.Mind_Group_Delete_Members] ="حذف عضو";
stringMap[strings.Mind_Group_Please_Select_The_Member_To_Delete] ="لطفاً عضو را برای حذف انتخاب کنید";
stringMap[strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] ="آیا مطمئن هستید که می خواهید کاربر را از این گروه حذف کنید؟";
stringMap[strings.Mind_Group_Add_As_Administrator] ="افزودن مدیر";
stringMap[strings.Mind_Group_Accomplish] ="انجام دادن";
stringMap[strings.Mind_Group_Compile] ="ویرایش";
stringMap[strings.Mind_Group_Group_Owner] ="خداوند";
stringMap[strings.Mind_Group_Add_Admin] ="افزودن مدیر";
stringMap[strings.Mind_Group_Group_ID] ="شناسه گروه";
stringMap[strings.Mind_Group_Group_Number] ="گروه شماره";
stringMap[strings.Mind_Group_Name] ="نام";
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] ="لطفاً نام گروه اشتراک گذاری را وارد کنید";
stringMap[strings.Mind_Group_Introduce] ="معرفی";
stringMap[strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] ="لطفاً گروه اشتراک را مختصر معرفی کنید";
stringMap[strings.Mind_Group_Announcement] ="اعلامیه";
stringMap[strings.Mind_Group_Shared_Group_Tag] ="برچسب گروه به اشتراک گذاری";
stringMap[strings.Mind_Group_Custom_Tag] ="برچسب سفارشی";
stringMap[strings.Mind_Group_Save] ="صرفه جویی";
stringMap[strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] ="نام گروه نمی تواند خالی باشد";
stringMap[strings.Mind_Group_Establish] ="ايجاد كردن";
stringMap[strings.Mind_Group_Allows_Mapfolder_Creation] ="اجازه ایجاد نقشه / پوشه";
stringMap[strings.Mind_Group_Allows_Editing_Of_Maps] ="اجازه ویرایش نقشه";
stringMap[strings.Mind_Group_Holder] ="هر کس";
stringMap[strings.Mind_Group_Only_The_Administrator] ="فقط مدیر";
stringMap[strings.Mind_Group_Not_Allow] ="مجاز نیست";
stringMap[strings.Mind_Group_Allows_Map_Deletion] ="اجازه حذف نقشه";
stringMap[strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] ="به انجمن گفتگو اجازه ارسال پیام";
stringMap[strings.Mind_Group_Export] ="صادرات";
stringMap[strings.Mind_Group_Allows_You_To_Save_Images_PDF] ="اجازه ذخیره تصاویر / PDF";
stringMap[strings.Mind_Group_Open_Group_Watermark] ="علامت گذاری گروهی را روشن کنید";
stringMap[strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] ="پس از باز شدن ، آرم گروه بر روی نقشه ذهن چاپ خواهد شد";
stringMap[strings.Mind_Group_Allow_Saving_Maps] ="اجازه ذخیره نقشه";
stringMap[strings.Mind_Group_Save_It_To_My_Map] ="ذخیره در نقشه من";
stringMap[strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] ="آیا اجازه اشتراک در طوفان وجود دارد";
stringMap[strings.Mind_Group_Join_The_Way] ="چگونگی عضویت";
stringMap[strings.Mind_Group_Whether_To_Invite_New_Members] ="آیا اجازه دعوت از اعضای جدید وجود دارد";
stringMap[strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] ="آیا اجازه ورود شماره گروه جستجو برای پیوستن وجود دارد";
stringMap[strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] ="آیا هنگام پیوستن باید پیام را تأیید کنید";
stringMap[strings.Mind_Group_Shared_Group_Data] ="اشتراک اطلاعات گروه";
stringMap[strings.Mind_Group_Authority_Management] ="مدیریت اقتدار";
stringMap[strings.Mind_Group_Quit_Sharing_Of_Group] ="ترک گروه اشتراک";
stringMap[strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] ="آیا مطمئن هستید که از گروه اشتراک خارج می شوید؟";
stringMap[strings.Mind_Group_The_Dissolution_Of_Group] ="انحلال گروه";
stringMap[strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] ="پس از انحلال گروه ، پرونده ها حذف می شوند. آیا مطمئن هستید که منحل می شوید؟";
stringMap[strings.Mind_Group_Return_To_Home_Page] ="بازگشت به صفحه اصلی";
stringMap[strings.Mind_Group_Join_The_Group] ="به گروه بپیوندید";
stringMap[strings.Mind_Group_Please_Enter_The_Group_Number] ="لطفاً شماره گروه / نام گروه را برای جستجو وارد کنید";
stringMap[strings.Mind_Group_Apply_To_Join] ="درخواست عضویت";
stringMap[strings.Mind_Group_Join] ="پیوستن";
stringMap[strings.Mind_Group_Verification] ="تایید";
stringMap[strings.Mind_Group_Please_Enter_A_Validation_Message] ="لطفاً یک پیام تأیید وارد کنید";
stringMap[strings.Mind_Group_The_Content_Cannot_Be_Empty] ="محتوا نمی تواند خالی باشد";
stringMap[strings.Mind_Group_Name] ="نام گروه مشترک";
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] ="لطفاً نام گروه اشتراک گذاری را وارد کنید";
stringMap[strings.Mind_Group_Introduction] ="مقدمه ای بر اشتراک گروه ها";
stringMap[strings.Mind_Group_Group_Indication] ="گروه شماره";
stringMap[strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] ="خالق تنبل است ، من هنوز آن را معرفی نکرده ام ...";
stringMap[strings.Mind_Group_Shared_Group_Announcement] ="اطلاعیه گروه اشتراک";
stringMap[strings.Mind_Group_No_Announcement] ="هنوز اطلاعیه ای نیست";
stringMap[strings.Mind_Group_Click_On] ="برای گسترش کلیک کنید";
stringMap[strings.Mind_Group_Click_On_The_Hidden] ="برای پنهان کردن کلیک کنید";
stringMap[strings.Mind_Group_Altogether] ="با یکدیگر";
stringMap[strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] ="کپی کردن شماره گروه با موفقیت";
stringMap[strings.Mind_Group_Stick] ="بالا";
stringMap[strings.Mind_Group_There_Is_A_New_Content] ="محتوای جدیدی وجود دارد";
stringMap[strings.Mind_Group_Cancel_The_Top] ="باز کردن قسمت بالا";
stringMap[strings.Mind_Group_Setting_Up] ="تنظیمات";
stringMap[strings.Mind_Group_Usinghelp] ="استفاده از کمک";
stringMap[strings.Mind_Group_Set_The_Message] ="پیام گروهی";
stringMap[strings.Mind_Group_Apply_To_Join] ="درخواست عضویت";
stringMap[strings.Mind_Group_Invite_To_Join] ="دعوت به عضویت";
stringMap[strings.Mind_Group_Consent] ="موافق";
stringMap[strings.Mind_Group_Turn_Down] ="امتناع";
stringMap[strings.Mind_Group_Label] ="برچسب";
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] ="لطفاً نام برچسب گروه مشترک را وارد کنید";
stringMap[strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] ="دعوت از دوستان و همكاران برای ایجاد و به اشتراک گذاری محتوای دانش";
stringMap[strings.Mind_Group_Immediately_Create] ="اکنون ایجاد کنید";
stringMap[strings.Mind_Group_Immediately_To_Join] ="همین الان ملحق شوید، همین الان بپیوندید";

stringMap[strings.Currently_Selected] ="در حال پیگیری";
stringMap[strings.LabelManagement] ="مدیریت برچسب";
stringMap[strings.NoAttention] ="بدون پیگیری";
stringMap[strings.Mindmap_Review_Play] ="بازی";

stringMap[strings.Mind_Group_Joined] ="پیوست";
stringMap[strings.Mind_Group_Download_App] ="بارگیری برنامه";
stringMap[strings.Mind_Group_No_Content] ="هنوز هیچ محتوایی";
stringMap[strings.Mind_Group_Individual] ="آ";
stringMap[strings.Mind_Group_Message_Area] ="منطقه پیام";
stringMap[strings.Mind_Group_No_Message] ="هنوز نظری ندارید ، اکنون بروید";
stringMap[strings.Mind_Group_Leave_Message] ="پیام بگذارید";
stringMap[strings.Mind_Group_Bar] ="بار";
stringMap[strings.Mind_Group_See_More] ="بیشتر ببین";
stringMap[strings.Mind_Group_Enter_Invitation_Code] ="کد دعوت را وارد کنید";
stringMap[strings.Mind_Group_Apply_To_Join_Sharing_Group] ="ثبت نام برای پیوستن به یک گروه مشترک";
stringMap[strings.Reasons_For_Application_0_30_Words] ="دلیل کاربرد (0-30 کلمه)";
stringMap[strings.Mind_Group_Send_Out] ="ارسال";
stringMap[strings.Mind_Group_Join_Successfully] ="با موفقیت بپیوندید";
stringMap[strings.Mind_Group_Application_Sent_Successfully] ="برنامه با موفقیت ارسال شد";

stringMap[strings.Mind_Group_Save_The_Mapping] ="ذخیره نقشه";
stringMap[strings.Mind_Group_Update_Record] ="اصلاح رکورد";

stringMap[strings.Mind_Edit_Page_Switch_Mapping] ="تغییر نقشه";
stringMap[strings.Mind_Edit_Page_Mind_Retrospect] ="بررسی نقشه";
stringMap[strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] ="یک کاربر موجود در حال ویرایش نقشه است";
stringMap[strings.Mind_Edit_Page_Password] ="کلمه عبور";

stringMap[strings.Mind_Edit_Page_Sharing_Group] ="اشتراک گروه";
stringMap[strings.Mind_MBean] ="لوبیای M";
stringMap[strings.Mind_My_MBean] ="My M Bean";
stringMap[strings.Alipay] ="Alipay";
stringMap[strings.Alipay_Payment] ="پرداخت با علی پرداخت";
stringMap[strings.Please_Pay_On_The_Payment_Page] ="لطفا برای پرداخت به صفحه پرداخت بروید";
stringMap[strings.WeChat] ="WeChat";
stringMap[strings.Mind_Shooping_Total] ="جمع";
stringMap[strings.Mind_Shooping_Confirm_Payment] ="پرداخت را تایید کنید";
stringMap[strings.Mind_Shooping_Redeem_Now] ="اکنون استفاده کنید";

stringMap[strings.Mind_Whether_Confirm_Payment_Purchase] ="آیا مطمئن هستید که هزینه خرید را پرداخت می کنید؟";
stringMap[strings.Mind_Tips] ="سریع";
stringMap[strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] ="لوبیای M شما کافی نیست ، آیا می خواهید لوبیای M خریداری کنید؟";

stringMap[strings.Mind_share_scan_code] ="ذهن + کد پویش";

stringMap[strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] ="آیا می توانم به مکانی که آخرین بار در آن بازی شده است برگردم؟";
stringMap[strings.Mind_Play_Number_Of_Playable_Times_Remaining] ="باقی مانده زمان پخش";

stringMap[strings.Group_Mind_Play_The_File_Will_Not_Exist] ="پرونده کاملاً پاک خواهد شد ، آیا مطمئن هستید که منحل می شوید؟";
stringMap[strings.Group_Mind_Up_One_Level] ="مرحله بالاتر";
stringMap[strings.Group_Mind_No_Documents] ="هنوز پرونده ای وجود ندارد";
stringMap[strings.Group_Mind_PaymentSuccessful] ="پرداخت موفقیت آمیز";

stringMap[strings.Mind_Edit_Page_Sharing_Group_introduce] ="ویرایش مشارکتی توسط افراد ، همگام سازی در زمان واقعی چند ترمینال ، به اشتراک گذاری اسناد ، بهبود کارایی همکاری تیم";

stringMap[strings.Mind_Edit_Subscript] ="زیرنویس";
stringMap[strings.Mind_Edit_Text_Location] ="موقعیت متن";
stringMap[strings.Mind_Mind_Import_Into_A_Shared_Group] ="وارد کردن به گروه مشترک";

stringMap[strings.Standard_Colors] ="رنگ استاندارد";
stringMap[strings.More_Colors] ="رنگهای بیشتر";
stringMap[strings.Reset_Password] ="تنظیم مجدد رمز عبور";
stringMap[strings.Account_Password_Login] ="ورود به سیستم رمز عبور حساب";
stringMap[strings.Authentication_code_Login_registration] ="ورود / ثبت نام کد تأیید";
stringMap[strings.One_End_Creation_Multi_End_Synchronization] ="ایجاد در یک انتها و همگام سازی در چند انتها";
stringMap[strings.Rich_And_Diverse_Mapping_Structure] ="ساختار نقشه غنی و متنوع";
stringMap[strings.Massive_And_ExquisiteTemplateLibrary] ="کتابخانه الگویی عظیم و نفیس";
stringMap[strings.Map_Review_Play] ="مرور نقشه-بازی";
stringMap[strings.CommunityLearningAndSharingPlatform] ="سکوی تبادل دانش و اشتراک دانش";

stringMap[strings.Mind_Mind_Upgrade_Storage_Space] ="ارتقا St فضای ذخیره سازی";
stringMap[strings.Mind_Mind_Let_Me_Comment_Too] ="بگذارید در مورد آن نیز نظر دهم";
stringMap[strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] ="هنوز هیچ اخباری اعلام نشده است";
stringMap [strings.Failed_To_Get_Recording_Device] = "دریافت دستگاه ضبط انجام نشد"

stringMap [strings.Search_Pagination] = "جستجو در صفحه"
stringMap [strings.Please_Enter_A_Paging_Name] = "لطفاً نام صفحه را وارد کنید"
stringMap [strings.Operation_Settings] = "تنظیمات عملیات"
stringMap [strings.Shortcut_key_Settings] = "تنظیمات کلید میانبر"
stringMap [strings.Press_Key_Combination] = "لطفا کلید کلید مورد نیاز را فشار دهید"
stringMap [strings.Mind_Reduction] = "کاهش"
stringMap [strings.Double_Click_Add_Free_Node] = "برای افزودن یک گره رایگان روی فضای خالی دوبار کلیک کنید"
stringMap [strings.Shortcut_Conflict] = "تضاد کلید میانبر"
stringMap [strings.Select_Main_Node] = "انتخاب گره اصلی"
stringMap [strings.Telephone] = "تلفن"
stringMap [strings.Microblog] = "میکروبلاگ"
stringMap [strings.Address] = "آدرس"
stringMap [strings.Company_Address] = "بلوک B ، ساختمان 21 ، شماره 1158 جاده Zhongxin ، منطقه Songjiang ، شانگهای (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "بایگانی"
stringMap [strings.Mind_Bookshelf] = "قفسه کتاب"
stringMap [strings.Search_Archive] = "جستجوی قفسه کتاب"
stringMap [strings.Modify_Archive_Order] = "تغییر ترتیب قفسه کتاب"
stringMap [strings.Modification_Order] = "سفارش اصلاح"
stringMap [strings.Please_Enter_Archive_Name] = "لطفا نام قفسه کتاب را وارد کنید"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "نام قفسه کتاب نمی تواند خالی باشد"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "آیا مطمئن هستید که می خواهید این قفسه کتاب را حذف کنید؟"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "آیا مطمئن هستید که می خواهید این قفسه کتاب را حذف کنید؟"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "آیا مطمئن هستید که می خواهید این نقشه را حذف کنید؟"
stringMap [strings.Mind_Edit_Confirm] = "تأیید"
stringMap [strings.Mind_Import_Map] = "وارد کردن نقشه"
stringMap [strings.Mind_Bookcase] = "قفسه کتاب"
stringMap [strings.Added_Successfully] = "با موفقیت اضافه شد"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "نام قفسه کتاب نمی تواند خالی باشد"

stringMap [strings.Mind_Association_Map] = "نقشه انجمن"
stringMap [strings.Mind_confirm_Association_Map] = "آیا مطمئن هستید که این نقشه را مرتبط می کنید؟"
stringMap [strings.Mind_Association_Map_See] = "مشاهده نقشه انجمن"
stringMap [strings.Mind_Association_Map_Delete] = "حذف نقشه انجمن"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "ساختار خط همبستگی"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "شروع خط همبستگی"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "انتهای خط همبستگی"

stringMap [strings.Share_Group_To_Storm] = "آیا گروه را در طوفان به اشتراک بگذارید"
stringMap [strings.Apply_To_Join_Storm_Platform] = "در پلتفرم طوفان ، افراد دیگر می بینند و درخواست پیوستن می دهند ، با دوستداران دانش عمومی طوفان فکری می کنند"
stringMap [strings.Pay_To_Goin] = "برای عضویت پرداخت کنید"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "حبوبات M هنگام پیوستن نیاز به پرداخت دارند"
stringMap [strings.Mbean_Can_Be_Realized] = "Mbean Realized"

stringMap [strings.Mind_Visit] = "بازدید"
stringMap [strings.Mind_Details] = "جزئیات"
stringMap [strings.Mind_See] = "مشاهده"
stringMap [strings.Earn_Mbeans] = "کسب Mbeans"
stringMap [strings.Mind_Explain] = "شرح"
stringMap [strings.Mind_Parenting] = "فرزندپروری"
stringMap [strings.Mind_Internet] = "اینترنت"
stringMap [strings.Mind_Psychology] = "روانشناسی"
stringMap [strings.Mind_literature] = "ادبیات"
stringMap [strings.Mind_Technology] = "فناوری"
stringMap [strings.Mind_Workplace] = "محل کار"
stringMap [strings.Mind_Life] = "زندگی"
stringMap [strings.Mind_Subject] = "موضوع"
stringMap [strings.Hot_Group] = "گروه داغ"
stringMap [strings.Open_Group] = "باز کردن گروه"
stringMap [strings.Mind_Group_Used] = "استفاده شده"

stringMap [strings.Mind_Click_Select] = "برای انتخاب کلیک کنید"
stringMap [strings.Mind_Double_Click_Enter] = "برای ورود دوبار کلیک کنید"
stringMap [strings.Search_Shared_Groups] = "جستجوی گروه های مشترک"
stringMap [strings.Search_Storm] = "جستجو در طوفان"
stringMap [strings.Members_Save_Free] = "ذخیره رایگان اعضا"
stringMap [strings.Bank_Card_Account_Name] = "لطفا نام حساب کارت بانکی خود را وارد کنید"
stringMap [strings.Bank_Card_No] = "شماره کارت بانکی"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "لطفاً شماره کارت بانکی خود را وارد کنید"
stringMap [strings.Branch_Sub_Branch_Information] = "اطلاعات شعبه شعبه"
stringMap [strings.Branch_Sub_Branch_Example] = "به عنوان مثال: زیر شاخه چنگشی شاخه Hangzhou"
stringMap [strings.Application_For_Withdrawal] = "درخواست برداشت"
stringMap [strings.Withdrawal_Amount] = "مبلغ برداشت"
stringMap [strings.Withdrawal] = "برداشت"
stringMap [strings.Current_Revenue] = "درآمد جاری"
stringMap [strings.Recharge] = "شارژ مجدد"
stringMap [strings.Current_Total_MBeans] = "تعداد کل MBeans فعلی"
stringMap [strings.Withdrawal_Status] = "وضعیت برداشت"
stringMap [strings.Insufficient_Withdrawal_Amount] = "مقدار برداشت ناکافی است"
stringMap [strings.Load_More] = "بارگیری بیشتر"
stringMap [strings.Loading] = "در حال بارگیری"
stringMap [strings.It_Is_Over] = "تمام شد"
stringMap [strings.Default_Label] = "برچسب پیش فرض"
stringMap [strings.Selected_Label] = "برچسب انتخاب شده"
stringMap [strings.Current_Location] = "مکان فعلی"
stringMap [strings.MGold_Introduction] = "M طلا توسط Mind+توسط شما به دست می آید"
stringMap [strings.MGold_Details] = "گروه ابری پولی شما ، نقشه های دانش ، مطالب آموزشی و غیره در گروه وجود دارد. اگر کاربران به دانه های پرداخت M بپیوندند ، 50٪ آنها به M طلا تبدیل می شود ، و M طلا بیشتر بیش از 50 را می توان استخراج کرد "
stringMap [strings.Cloud_Group_Introduction] = "گروه خود را به اشتراک بگذارید ، دیگران می توانند آن را در صفحه طوفان مشاهده کنند"
stringMap [strings.Cloud_Group_Details] = "گروه ابری پولی شما. نقشه های دانش ، مطالب آموزشی و غیره در گروه وجود دارد. در صورت پیوستن کاربران به لوبیا M پرداخت ، 50٪ آنها به M طلا تبدیل می شود ، و M طلا بیشتر بیش از 50 مورد قابل برداشت است. "
stringMap [strings.Share_Storm_Introduction] = "نقشه خود را با جامعه طوفان به اشتراک بگذارید ، تا افراد بیشتری بتوانند دانش را از شما بیاموزند و کشف کنند"
stringMap [strings.Share_Storm_Details] = "اگر استفاده از حبوبات M را برای ذخیره نقشه ها فعال کنید ، سایر کاربران می توانند از نقشه دانش شما استفاده کنند و همچنین 50٪ لوبیا M را دریافت خواهید کرد. برای خرید اعضا و پیوستن به افراد پولی می توانید از لوبیا M استفاده کنید. گروه ها ، همچنین می توانید برای استفاده از نقشه های افراد دیگر هزینه کنید. "

stringMap [strings.Linked_Successfully] = "با موفقیت پیوند شد"
stringMap [strings.Cancel_Archiving] = "لغو بایگانی"
stringMap [strings.Please_Select_Archive_Location] = "لطفاً مکان بایگانی را انتخاب کنید"
stringMap [strings.The_Search_Result_Is_Empty] = "نتایج جستجو خالی است"

stringMap [strings.Customize_A_Style] = "افراد غیر عضو فقط می توانند یک سبک را سفارشی کنند"
stringMap [strings.Adds_Current_Guide_Style_Library] = "افزودن سبک نقشه فعلی به کتابخانه سبک"
stringMap [strings.Trial_Version] = "نسخه آزمایشی"
stringMap [strings.Not_Supported_At_The_Moment] = "در حال حاضر پشتیبانی نمی شود"
stringMap [strings.Mind_File] = "فایل"
stringMap [strings.Mind_Import] = "وارد کردن"

stringMap [strings.Importing] = "وارد کردن"

stringMap [strings.Mind_Edit_Insert_Video] = "درج ویدئو"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "فیلم را اینجا بکشید و رها کنید"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "مدت زمان بارگذاری ویدیو نباید بیش از 60 ثانیه باشد"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "مشاهده ویدئو"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "حذف ویدئو"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "ویدیوی بارگذاری شده نباید بیش از 10 مگابایت باشد"
stringMap [strings.Mind_Upload_Video_Restrictions] = "فقط از بارگذاری فیلم ها با فرمت MP4 ، حداکثر تا 120 ثانیه و اندازه 10M پشتیبانی می کند"
stringMap [strings.Mind_Video_Size] = "اندازه ویدئو"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "صادر کردن کلمه"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "صادرات TXT"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap [strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap [strings.Export_Failed] = "صادرات انجام نشد"
stringMap [strings.Add_File] = "افزودن فایل"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "برای بارگذاری مستقیماً داخل فایل بکشید"
stringMap [strings.Supported_Imported_File_Types] = "انواع فایل هایی که در حال حاضر برای وارد کردن پشتیبانی می شوند: Word (فقط .docx) ، XMind ، .txt"
stringMap [strings.No_Permission_Do_This] = "شما اجازه انجام این عملیات را ندارید"

stringMap [strings.Upgrade_To_Professional] = "ارتقاء به نسخه حرفه ای"
stringMap [strings.Non_Members_Insert_Videos] = "افراد غیرحضور فقط 24 ساعت پس از ثبت نام می توانند ویدیوها را وارد کنند"
stringMap [strings.Upload_Failed] = "بارگذاری انجام نشد"
stringMap [strings.Upload_Failed_Title_Message] = "بارگذاری ناموفق بود ، لطفاً برای تأیید کلیک کنید ، باقی تصاویر را که بارگذاری نشد حذف کنید"

stringMap [strings.Mind_Edit_Members_Use] = "اعضا استفاده می کنند"
stringMap [strings.Invitation_Code] = "کد دعوت"
stringMap [strings.Student_Certification] = "صدور گواهینامه دانشجویی"
stringMap [strings.I_Get_It] = "من می خواهم ، راهنما را رد کنم"
stringMap [strings.Welcome_To_Mind] = "خوش آمدید استاد جوان برای پیوستن به Mind+، اجازه دهید سفر تفکر را آغاز کنیم!"
stringMap [strings.Mind_Before] = "اول"
stringMap [strings.Try_A_Map] = "نقشه را امتحان کنید"
stringMap [strings.Click_Create] = "برای ایجاد کلیک کنید"
stringMap [strings.Click_Create_A_Mind_Map] = "برای ایجاد نقشه ذهنی کلیک کنید"
stringMap [strings.Mind_About] = "درباره"
stringMap [strings.See_Checking_Data] = "مشاهده اطلاعات شخصی"
stringMap [strings.Modify_Checking_Data] = "تغییر اطلاعات شخصی"
stringMap [strings.Mind_Shear] = "برش"

stringMap [strings.Mind_Style_Empty] = "بدون سبک دلخواه یا سفارشی"
stringMap [strings.Mind_Branch_Free_Layout] = "طرح بندی رایگان شعبه"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "ساختار دو طرفه و شاخه های نقشه حباب را می توان آزادانه به طرح کشید"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "ضربه محکم و ناگهانی کشیدن تم"

stringMap [strings.Select_A_Node] = "انتخاب موضوع"
stringMap [strings.Create_Child_Node] = "ایجاد تم کودک"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "بعداً یک مورد زیبا به موضوع کودک اضافه می کنیم"
stringMap [strings.Click_Icon_Panel] = "روی پنل نماد کلیک کنید"
stringMap [strings.Select_An_Icon_You_Like] = "نمادی را که دوست دارید انتخاب کنید"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "بعد ما نقشه ذهنی را با یک نقشه جدید جایگزین می کنیم"
stringMap [strings.Select_The_Central_Theme] = "انتخاب تم اصلی"
stringMap [strings.Switch_To_Canvas_Panel] = "تغییر به پنل بوم"
stringMap [strings.Click_Layout] = "برای چیدمان کلیک کنید"
stringMap [strings.Choose_A_Layout_You_Like] = "چیدمانی را که دوست دارید انتخاب کنید"
stringMap [strings.Mind_Map_Save_Share] = "تبریک می گوییم ، شما با موفقیت یک نقشه ذهنی زیبا ایجاد کرده اید! اجازه دهید آن را ذخیره کرده و با دوستان خود به اشتراک بگذارید ~"
stringMap [strings.Click_Save_Picture] = "برای ذخیره تصویر کلیک کنید"
stringMap [strings.Click_OK] = "OK را کلیک کنید"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "تبریک به خاطر تکمیل راهنمای مبتدی ~"
stringMap [strings.Upload_Succeeded] = "با موفقیت بارگذاری شد"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "سبک سریع"
stringMap [strings.Mind_Extremely_Important] = "بسیار مهم"
stringMap [strings.Mind_Important] = "مهم"
stringMap [strings.Mind_Commonly] = "عمومی"
stringMap [strings.Mind_Ordinary] = "معمولی"
stringMap [strings.Member_Exclusive] = "این ویژگی منحصر به اعضا است. آیا می خواهید عضویت خود را ارتقا دهید؟"
stringMap [strings.Member_Exclusive_Color_Scheme] = "این طرح رنگ منحصر به اعضا است. آیا می خواهید عضویت خود را ارتقا دهید؟"
stringMap [strings.Mind_Enlarge] = "بزرگنمایی"
stringMap [strings.Mind_Narrow] = "باریک"
stringMap [strings.Mind_Center] = "مرکز"
stringMap [strings.Mind_Full_Screen] = "تمام صفحه"

stringMap [strings.Mind_Association_Node] = "گره انجمن"

stringMap[strings.Mind_Resource_See] = "مشاهده پیوست"
stringMap[strings.Mind_Resource_Delete] = "حذف پیوست ها"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "فایل ها را به اینجا بکشید و رها کنید"
stringMap[strings.Mind_Edit_choose_explain] = "انتخاب فایل"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "فایل آپلود شده نمی تواند بیش از 20 مگابایت باشد"

stringMap[strings.Please_Enter_Mind_Password] = "الرجاء إدخال 4-8 بت كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "الرجاء إدخال كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "هذه الوظيفة هي وظيفة الأعضاء"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "ملف كلمة السر قد أزيلت"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "ليس هناك ما يكفي من أرقام كلمة السر ، الرجاء إدخال 4 إلى 8 أرقام كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "كلمة السر طويلة جدا ، الرجاء إدخال 4 عكس 8 بت كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_Success] = "تعيين كلمة المرور بنجاح"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "شكل كلمة السر خطأ"
stringMap[strings.Mind_Load_Data_Error] = "تحميل البيانات خطأ"
stringMap[strings.Please_Enter_Mind_Password_Error] = "كلمة السر خطأ"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "خريطة الخالق فقط يمكن تعيين كلمة السر"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;
