const { default: strings } = require("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Zihin + Zihin Haritası';
stringMap [strings.TEMPLATE] = 'Şablon';
stringMap [strings.Mind_Edit_Main_Topic] = 'Dal Konusu';
stringMap [strings.Global_Add_Free_Node_Tips] = 'Eklenecek konumu seçmek için ekrana tıklayın';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'Eklenecek konumu seçmek için ekrana çift tıklayın';
stringMap [strings.Global_Add_Relationship_Tips] = 'İlişkilendirilmesi gereken hedefi seçin';
stringMap [strings.Global_Add_Target_Tips] = 'Lütfen nesne eklemek için bir düğüm seçin';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Bu nesne zaten diğer haritalarla ilişkilendirilmiş';
stringMap [strings.Mind_Edit_Main_Idea] = 'Merkezi Tema';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'İlişkili harita mevcut değil';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'Öncelik';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'İlerleme';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'Yıldızlar';
stringMap [strings.Mind_Style_Add_Icon_Week] = 'hafta';
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'Portre';
stringMap [strings.Establish] = 'Oluştur';
stringMap [strings.Create_From_Template] = 'Şablondan oluştur';
stringMap [strings.Common] = 'Ortak';
stringMap [strings.Function_List] = 'İşlev Listesi';
stringMap [strings.Recent_Updates] = 'Yakın zamanda güncellendi';
stringMap [strings.The_Latest_To_Delete] = 'Yakın zamanda silindi';
stringMap [strings.Setting_Up] = 'Bize ulaşın';
stringMap [strings.Usinghelp] = 'Yardımı kullanmak';
stringMap [strings.The_Download_Client] = 'İstemci / mobil sürümü indir';
stringMap [strings.Global_Purchase] = 'Satın Al';
stringMap [strings.Global_Login] = 'Giriş Yap';
stringMap [strings.My_map] = 'Haritam';
stringMap [strings.Create_Map] = 'Bir harita oluştur';
stringMap [strings.Storm] = 'Fırtına';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Ortak \ nKarakteristik';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'Karakteristik';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'Organizasyon Merkezi';
stringMap [strings.Mind_Edit_Free_Topic] = 'Ücretsiz Konu';
stringMap [strings.Mind_Edit_Sub_Topic] = 'Alt Konu';
stringMap [strings.Mind_Organization_Main_Topic] = 'Bölüm';
stringMap [strings.Mind_Edit_Main_Topic] = 'Dal Konusu';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'Planlama';
stringMap [strings.Mind_Time_Edit_Head] = 'Zaman';
stringMap [strings.Mind_Time_Edit_Title] = 'Plan';
stringMap [strings.Mind_Edit_Details] = 'Ayrıntılı açıklama';
stringMap [strings.Checking_Data] = 'Veriler kontrol ediliyor';
stringMap [strings.Setting_Information] = 'Ayar Bilgileri';
stringMap [strings.Sign_Out] = 'Oturumu kapat';

// işlev listesi
stringMap [strings.Create_a_Mind_Map] = 'Bir Zihin Haritası Oluşturun';
stringMap [strings.Create_Mind_Map_1] = '[1] On dört yerleşim düzenine sahip bir harita oluşturun (iki yönlü / sağ / sol / organizasyon yapısı / tek kabarcık grafiği / çift kabarcık grafiği / sol ağaç haritası / sağ ağaç haritası / İki yönlü ağaç diyagramı / tablo / yatay zaman çizelgesi / dikey zaman çizelgesi / S zaman çizelgesi / piramit) ';
stringMap [strings.Create_Mind_Map_2] = '[2] Şablonlar, haritaları hızlı bir şekilde oluşturmak için kullanılabilir (çok sayıda şablon içerik referansı)';
stringMap [strings.Create_Mind_Map_3] = '[3] Haritayı dokuz tür çizgi stiliyle düzenleyin';
stringMap [strings.Create_Mind_Map_4] = '[4] Haritada dokuz düzen değişikliği ve düzenleme yapın';
stringMap [strings.Create_Mind_Map_5] = '[5] Hedef düğüme alt düğümler ekleyin ve aynı düzeyde (üst ve alt) düğümler ekleyin';
stringMap [strings.Create_Mind_Map_6] = '[6] Özet: Tek bir düğümü / birden çok düğümü tanımlamak için [Özet] ekleyin';
stringMap [strings.Create_Mind_Map_7] = '[7] Blok: modülerleştirilecek haritanın tek bir düğüm / çoklu düğüm kutusuna [blok] ekleyin';
stringMap [strings.Create_Mind_Map_8] = '[8] Ücretsiz düğüm ekle';
stringMap [strings.Create_Mind_Map_9] = '[9] Herhangi bir düğüm arasında dört çizgi stilinden oluşan bir ilişki oluştur';
stringMap [strings.Create_Mind_Map_10] = '[10] Düğüme resimler (yerel + malzeme kitaplığı), simgeler, notlar, bağlantılar ekleyin';
stringMap [strings.Create_Mind_Map_11] = '[11] Düğümü kopyala / yapıştır, stili kopyala / yapıştır';
stringMap [strings.Create_Mind_Map_12] = '[12] İptal et, geri yükle ve iptal et';
stringMap [strings.Create_Mind_Map_13] = '[13] Birden çok seçim mevcuttur, birden çok düğüm aynı anda çalıştırılabilir';
stringMap [strings.Create_Mind_Map_14] = '[14] İstatistiksel grafik eklenebilir';
stringMap [strings.Create_Mind_Map_15] = '[15] Haritanın tamamı kaydedilebilir';
stringMap [strings.Create_Mind_Map_16] = '[16] Haritayı kopyala';
stringMap [strings.Create_Mind_Map_17] = '[17] Haritayı veya tüm haritayı diğer dosyaların haritasına kopyalayabilirsiniz';
stringMap [strings.Create_Mind_Map_18] = '[18] Özetten sonra bir harita oluşturulabilir';
stringMap [strings.Create_Mind_Map_19] = '[19] Harita düzenleme, düzenleme sırasında değiştirilebilir (bilgisayar tarafı)';
stringMap [strings.Create_Mind_Map_20] = '[20] Bluetooth klavye düzenleme haritası: \ n \ t [Command] + [a] Ana düğümü seçin \ n \ t [Command] + [←] [↑] [→] [↓ ] Seçim hedefini değiştir veya haritayı taşı \ n \ t [Command] + [e] Hedefi düzenle \ n \ t [Command] + [w] Alt düğüm oluştur \ n \ t [Command] + [s] Hedef kardeş düğümü oluştur ( Üst) \ n \ t [Command] + [d] Hedef kardeş düğüm oluştur (alt) \ n \ t [Command] + [q] Seçili hedeften çık \ n \ t [Command] + [-] [+] Yakınlaştır harita';

stringMap [strings.Beautify_The_Mind_Map] = 'Zihin Haritasını Güzelleştirin';
stringMap [strings.Beautify_The_Mind_Map_1] = '[1] Zihin haritasının arka plan rengini ayarlayın';
stringMap [strings.Beautify_The_Mind_Map_2] = '[2] Konik olsun veya olmasın, tüm harita çizgisinin kalınlığını ve rengini ayarlayın';
stringMap [strings.Beautify_The_Mind_Map_3] = '[3] Metin dolgu rengini, kenarlık rengini, sınır 13 şeklini, kenarlık genişliğini, gölge efektini ayarlayın';
stringMap [strings.Beautify_The_Mind_Map_4] = '[4] Çizgi renginin tamamını veya bir kısmını ve haritanın genişliğini ayarlayın';
stringMap [strings.Beautify_The_Mind_Map_5] = '[5] Metin yazı tipi boyutunu ayarla, kalın, italik, stili sil';
stringMap [strings.Beautify_The_Mind_Map_6] = '[6] Resim boyutunu, konumunu ve şekil stilini ayarlayın';
stringMap [strings.Beautify_The_Mind_Map_7] = '[7] Yedi kategoriden (ortak / 3D / çalışma / iş / kişi / hayvan / yiyecek) simge ekleyin ve boyutu / konumu ayarlayın';
stringMap [strings.Beautify_The_Mind_Map_8] = '[8] Haritanın tamamına tek tıklamayla stil referansı ekleyin';
stringMap [strings.Beautify_The_Mind_Map_9] = '[9] Grup Yönetim Haritası';
stringMap [strings.Beautify_The_Mind_Map_10] = '[10] Kişiselleştirilmiş bir arka plan (malzeme kitaplığı) haritanın tamamına eklenebilir';
stringMap [strings.Beautify_The_Mind_Map_9] = '[11] Tek bir düğüme ayrı ayrı kenarlıklar veya arka planlar (malzeme kitaplığı) eklenebilir';
stringMap [strings.Beautify_The_Mind_Map_9] = '[12] Düğümün uzunluğu sürüklenebilir';


stringMap [strings.Map_Output] = 'Harita çıktısı';
stringMap [strings.Map_Output_1] = '[1] Resmi kaydet';
stringMap [strings.Map_Output_2] = "[2] PDF'yi Kaydet";
stringMap [strings.Map_Output_3] = '[3] Bağlantı paylaş';
stringMap [strings.Map_Output_4] = '[4] Fırtınayla paylaş';
stringMap [strings.Map_Output_4] = '[5] Yazdır';

stringMap [strings.Work_together] = 'İşbirliği içinde çalışın';
stringMap [strings.Work_together_1] = '[1] Paylaşılan bir grup oluşturun (grup etiketi, grup duyurusu)';
stringMap [strings.Work_together_2] = '[2] Yeni paylaşılan grup üyeleri';
stringMap [strings.Work_together_3] = '【3】 Gruptaki birden çok üye aynı haritayı birlikte düzenler';
stringMap [strings.Work_together_4] = '[4] Grup içindeki paylaşılan dosyaları yükleyin (word / excle / pdf)';
stringMap [strings.Work_together_5] = '[5] Grupta bir mesaj bırakabilirsiniz';
stringMap [strings.Work_together_6] = '[6] Paylaşılan grup yönetimi yetki ayarları';
stringMap [strings.Work_together_7] = '[7] Grubu Dağıt';

stringMap [strings.Work_together] = 'Yaratıcı Paylaşım (Fırtına)';
stringMap [strings.Community_Sharing_1] = '[1] Yaratılışınızı daha fazla insanın görebilmesi için fırtınayla paylaşabilirsiniz';
stringMap [strings.Community_Sharing_1] = '[2] İlgili fırtına içeriğini görüntülemek için etiketleri seçebilirsiniz';
stringMap [strings.Community_Sharing_1] = '[3] Fırtına çalışmalarını beğenebilir ve yorum yazabilirsiniz';

// Yakın zamanda güncellendi
stringMap [strings.Recently_Updated_Title_1] = 'Kişisel bir hesap oluşturun';
stringMap [strings.Recently_Updated_Title_2] = 'Massive Template Community Base';
stringMap [strings.Recently_Updated_Title_3] = 'Birden çok platformu destekleyin';
stringMap [strings.Recently_Updated_Title_4] = 'Her ayrıntıyı özelleştirin';
stringMap [strings.Recently_Updated_Title_5] = 'Kişisel stil kitaplığı';
stringMap [strings.Recently_Updated_Title_6] = 'Harita çıktısı';
stringMap [strings.Recently_Updated_Title_7] = 'Bulut Depolama';
stringMap [strings.Recently_Updated_Title_8] = 'On beş tür harita';


stringMap [strings.Recently_Updated_Content_1] = 'Kişisel bir hesap oluşturun ve oluşturulan haritayı hesapla kaydedin';
stringMap [strings.Recently_Updated_Content_2] = 'Öğrenme verimliliğini artırmak için çok sayıda şablon bilgi tabanı doğrudan alıntılanmıştır';
stringMap [strings.Recently_Updated_Content_3] = 'Web sürümü çevrimiçi üretimi, IOS mobil terminali, Mac terminali, Android mobil terminali, Android tablet çoklu platform kullanımını destekleyin';
stringMap [strings.Recently_Updated_Content_4] = 'Metin, arka plan, çizgiler, resim simgeleri vb. tasarımını kişisel tercihlere göre güzelleştirebilirsiniz';
stringMap [strings.Recently_Updated_Content_5] = 'Kişisel stil kitaplığı doğrudan alıntılanarak renk eşleştirme sorununu ortadan kaldırır';
stringMap [strings.Recently_Updated_Content_6] = 'Resimleri, PDF formatını kaydedebilir ve doğrudan arkadaşlarınızla paylaşabilirsiniz';
stringMap [strings.Recently_Updated_Content_7] = 'Güçlü bulut depolama desteği, artık veri kaybı konusunda endişelenmenize gerek yok';
stringMap [strings.Recently_Updated_Content_8] = 'İki yönlü, sağ, sol, organizasyon yapısı, tek kabarcık grafiği, çift kabarcık grafiği, sol ağaç grafiği, sağ ağaç grafiği, iki yönlü ağaç grafiği, tablo, yatay zaman çizelgesi, Dikey zaman çizelgesi, S zaman çizelgesi, sekiz düzen isteğe göre değiştirilebilir ';

// Yakın zamanda silindi
stringMap [strings.Mind_Mindmap_Deleted_Title] = 'Yakın zamanda silindi';
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = 'Kurtarma';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'Sil';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'Dosya adı';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'Buradan silindikten sonra kalıcı olarak silinecektir';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'İşlem';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = 'Yakın zamanda silinen haritayı ara';
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = 'Tarihi Sil';

//Bize Ulaşın
stringMap [strings.Contact_Us_No_Public] = 'Genel Hesap';
stringMap [strings.Contact_Us_QQ_Group] = 'QQ Grubu';
stringMap [strings.Contact_Us_Message_Board] = 'Mesaj Panosu';
stringMap [strings.Contact_Us_Username] = 'Ad';
stringMap [strings.Contact_Us_Email] = 'E-posta';
stringMap [strings.Contact_Us_Voicemail] = 'Lütfen değerli yorumlarınızı bırakın';
stringMap [strings.Contact_Us_Send_Email] = 'E-posta Gönder';

// mesajı doğrula
stringMap [strings.Input_Username_isNull] = 'Ad boş olamaz';
stringMap [strings.Input_Email_isNull] = 'Lütfen doğru e-posta adresini girin';
stringMap [strings.Input_Voicemail_isNull] = 'Lütfen gönderilen içeriği girin';
stringMap [strings.Input_Introduction_isNull] = 'Giriş boş olamaz';
stringMap [strings.Input_Title_isNull] = 'Başlık boş olamaz';

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'Lütfen doğru telefon numarasını girin';
stringMap [strings.Input_Please_Enter_Verification_Code] = 'Lütfen doğrulama kodunu girin! ';
stringMap [strings.Input_Please_Enter_The_Password] = 'Lütfen şifreyi girin! ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = 'Parola biçimi yanlış! ';
stringMap [strings.Input_Please_Enter_A_New_Password] = 'Lütfen yeni bir şifre girin! ';
stringMap [strings.Input_Confirm_The_New_Password] = 'Lütfen onay şifresini girin! ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = 'Parolalar tutarsız! ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = 'Lütfen doğru doğrulama kodunu girin! ';
stringMap [strings.Input_The_Verification_Code_Error] = 'Doğrulama kodu hatası! ';

// Yardım kullanmak
stringMap [strings.Mind_Usinghelp_1] = 'Bir zihin haritası oluştur';
stringMap [strings.Mind_Usinghelp_2] = 'Bir düğüm nasıl eklenir';
stringMap [strings.Mind_Usinghelp_3] = 'Ücretsiz bir düğüm oluştur';
stringMap [strings.Mind_Usinghelp_4] = 'İlişki oluştur';
stringMap [strings.Mind_Usinghelp_5] = 'tuvali güzelleştirin';
stringMap [strings.Mind_Usinghelp_6] = 'metni güzelleştir';
stringMap [strings.Mind_Usinghelp_7] = 'Notlar, bağlantılar ekle';
stringMap [strings.Mind_Usinghelp_8] = 'Simge ekle';
stringMap [strings.Mind_Usinghelp_9] = 'Resim Ekle';
stringMap [strings.Mind_Usinghelp_10] = 'Silme nasıl geri yüklenir';
stringMap [strings.Mind_Usinghelp_11] = 'Zihin Haritasını Dışa Aktar';
stringMap [strings.Mind_Usinghelp_12] = 'Üyelik yükseltmesi';
stringMap [strings.Mind_Usinghelp_13] = 'Kısayol Tuşu';
stringMap [strings.Mind_Usinghelp_Underslished] = 'Anladım';
stringMap [strings.Mind_Usinghelp_14] = 'Malzeme kitaplığı ekle';
// help-2 kullan
stringMap [strings.Mind_Usinghelp_15] = 'Yeni Harita';
// stringMap [strings.Mind_Usinghelp_2] = 'Düğüm ekle';
// stringMap [strings.Mind_Usinghelp_4] = 'İlişki oluştur';
// stringMap [strings.Mind_Usinghelp_5] = 'Tuvali güzelleştirin';
// stringMap [strings.Mind_Usinghelp_6] = 'Metni güzelleştir';
// stringMap [strings.Mind_Usinghelp_7] = 'Notlar, bağlantılar ekleyin';
stringMap [strings.Mind_Usinghelp_17] = 'İşlemi geri al, iptal et';
stringMap [strings.Mind_Usinghelp_18] = 'Değiştirmek için düğümü sürükleyin';
stringMap [strings.Mind_Usinghelp_19] = 'Düğüm hizalaması';
stringMap [strings.Mind_Usinghelp_20] = 'Harita türünü değiştir';
stringMap [strings.Mind_Usinghelp_21] = 'Harita çizgisi stili';
stringMap [strings.Mind_Usinghelp_22] = 'Harita arka plan rengi';
stringMap [strings.Mind_Usinghelp_23] = 'Düğüm şeklini ayarla';
stringMap [strings.Mind_Usinghelp_24] = 'Düğüm çizgisi stili';
stringMap [strings.Mind_Usinghelp_25] = 'Düğüm Resim Ayarları';
stringMap [strings.Mind_Usinghelp_26] ='Düğüm simgesi ayarları';
stringMap [strings.Mind_Usinghelp_27] = 'Düğüm Ekleme Özeti';
stringMap [strings.Mind_Usinghelp_28] = 'Tek düğüm kutusu seçimi';
stringMap [strings.Mind_Usinghelp_29] = 'Bloklara çoklu düğümler';
stringMap [strings.Mind_Usinghelp_30] = 'Düğüm sınır ekle';
stringMap [strings.Mind_Usinghelp_31] = 'İstatistiksel harita ekle';
stringMap [strings.Mind_Usinghelp_32] = 'Düğümü gizle ve geri yükle';
stringMap [strings.Mind_Usinghelp_33] = 'Birden çok işlemi düğümleyin';
stringMap [strings.Mind_Usinghelp_34] = 'Stili değiştir';
stringMap [strings.Mind_Usinghelp_35] = 'Ses ve açıklama ekle';
stringMap [strings.Mind_Usinghelp_36] = 'Haritayı Resme Dönüştür';
stringMap [strings.Mind_Usinghelp_37] = 'PDF belgesini eşle';
stringMap [strings.Mind_Usinghelp_38] = 'Haritayı yazdır';
stringMap [strings.Mind_Usinghelp_39] = 'Bağlantı paylaşımı ve görüntüleme';
stringMap [strings.Mind_Usinghelp_40] = 'Fırtınayla paylaşmak için harita';
stringMap [strings.Mind_Usinghelp_41] = 'Klasörü kullanma';
stringMap [strings.Mind_Usinghelp_42] = 'Haritayı sil ve geri yükle';
// Yardım 3'ü kullan
stringMap [strings.Mind_Usinghelp_43] = 'Arkadaşlar gruba katılmak için başvuruyor';
stringMap [strings.Mind_Usinghelp_44] = 'Grup nasıl dağıtılır';
stringMap [strings.Mind_Usinghelp_45] = 'Dosya bilgilerini yükle';
stringMap [strings.Mind_Usinghelp_46] = 'Uygulamaya katılmayı kabul edin';
stringMap [strings.Mind_Usinghelp_47] = 'Ortak Düzenleme';
stringMap [strings.Mind_Usinghelp_48] = 'Yeni Paylaşım Grubu';
stringMap [strings.Mind_Usinghelp_49] = 'Grup üyelerini davet et';

//fırtına
stringMap [strings.Mind_Storm_Recommend] = 'Öneri';
stringMap [strings.Mind_Storm_Boilingpoint] = 'Kaynama noktası';
stringMap [strings.Mind_Storm_Unfold] = 'Aç';
stringMap [strings.Mind_Storm_Putaway] = 'Yerleştirme';
stringMap [strings.Mind_Storm_Myclassification] = 'Sınıflandırmam';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'Önerilen Sınıflandırma';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'Kategori eklemek için tıklayın';
stringMap [strings.Mind_Storm_No_Classification] = 'Henüz sınıflandırma yok';
stringMap [strings.Mind_Storm_No_Tinterested] = 'İlgilenmiyorum';
stringMap [strings.Mind_Storm_Report] = 'Rapor Et';
stringMap [strings.Mind_Storm_Views] = 'Görünümler';

stringMap [strings.Mind_Storm_Pornographic] = 'Pornografik kaba';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'Reklam Tacizi';
stringMap [strings.Mind_Storm_Political_Religion] = 'Politika ve Din';
stringMap [strings.Mind_Storm_Infringement] = 'İhlal (portre, hakaret, intihal, dolandırıcılık)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'Yasaklanmış içerik';
stringMap [strings.Mind_Storm_Spread_Rumors] = 'Söylentileri ve söylentileri yay';
stringMap [strings.Mind_Storm_Report_Successful] = 'Rapor başarılı';

//Düzenle
stringMap [strings.Global_Edit] = 'Düzenle';
stringMap [strings.Global_Cancel] = 'İptal';
stringMap [strings.Global_Ok] = 'Tamam';
stringMap [strings.Global_Name] = 'Takma ad';
stringMap [strings.Global_Introduction] = 'Giriş';
stringMap [strings.Global_Change_Avatar] = 'Avatarı Değiştir';
stringMap [strings.Global_Local_Upload] = 'Yerel yükleme';

//kişisel bilgi
stringMap [strings.User_Personal_information] = 'Kişisel Bilgiler';
stringMap [strings.User_Member_Validity_Period] = 'Üye Geçerlilik Süresi';
stringMap [strings.User_My_Share] = 'Paylaşımım';
stringMap [strings.User_My_Collection] = 'Koleksiyonum';
stringMap [strings.User_More_Message] = 'Daha Fazla';
stringMap [strings.User_Deleted_Sotrm] = 'Fırtınayı Sil';
stringMap [strings.User_Folder_Rename] = 'Yeniden Adlandır';
stringMap [strings.User_Folder_Rename_Root] = 'İzinleri değiştir';
stringMap [strings.User_Folder_Rename_Root_Title] = 'İzinleri değiştir';
stringMap [strings.User_Folder_Root_Public] = 'Tamamen herkese açık';
stringMap [strings.User_Folder_Root_Viewonly] = 'Ortak klasörün yalnızca görüntülenmesine izin verilir';
stringMap [strings.User_Folder_Root_Private] = 'Özel';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'Diğer insanlar favorilerinizi profil sayfanızda görebilir';
stringMap [strings.User_Message_Link] = 'Bağlantı';
stringMap [strings.User_New_Group] = 'Yeni grup';
stringMap [strings.User_New_Group_Title] = 'Yeni bir grup oluştur';
stringMap [strings.User_New_Group_Style_Title] = 'Dosya Türü';
stringMap [strings.User_Share_Is_Null] = 'Henüz paylaşım yok! ';
stringMap [strings.User_Deleted_Storm_Tips] = 'Bu fırtınayı silmek istediğinizden emin misiniz? ';
stringMap [strings.User_Remove_From_List] = 'Listeden kaldır',
    stringMap [strings.User_Move_To] = 'Taşı',
    stringMap [strings.User_Deleted_Link] = 'Bağlantıyı sil'
stringMap [strings.User_Collction_Is_Null] = 'Henüz koleksiyon yok'
stringMap [strings.User_Collection] = 'Koleksiyon'

// Giriş kutusu
stringMap [strings.User_Lolgin_Welcome_To_Login] = "Mind'e Hoş Geldiniz +"
stringMap [strings.User_Lolgin_Free_Registration] = 'Ücretsiz kayıt'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'Lütfen telefon numaranızı girin'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'Lütfen doğrulama kodunu girin'
stringMap [strings.User_Lolgin_Obtain] = 'Al'
stringMap [strings.User_Lolgin_Password_Login] = 'Şifre girişi'
stringMap [strings.User_Lolgin_Login] = 'Giriş Yap'
stringMap [strings.User_Lolgin_Register_An_Account] = 'Hesap Kaydı'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'Zaten bir hesabınız var'
stringMap [strings.User_Lolgin_To_Log_In] = 'Giriş yapmak için git'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'Lütfen bir şifre girin'
stringMap [strings.User_Lolgin_Confirm_Password] = 'Parolayı onayla'
stringMap [strings.User_Lolgin_Registered] = 'Kayıtlı'
stringMap [strings.User_Lolgin_Account_Login] = 'Hesap girişi'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'Kod girişini tara'
stringMap [strings.User_Lolgin_Forgot_Password] = 'Şifremi unuttum'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'Doğrulama kodu girişi'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Ayar arayüzünün en üstünde Açık Zihin + tarama'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'Bu QR kodunun süresi beş dakika içinde dolar, lütfen süresi dolduğunda tıklayın'
stringMap [strings.User_Lolgin_Refresh] = 'Yenile'
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'Tarama başarılı, lütfen telefonunuzda onaylayın'
stringMap [strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'Oturum açma yetkisinin verilip verilmeyeceği'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'Lütfen yeni bir şifre girin'
stringMap [strings.User_Lolgin_Reset] = 'Sıfırla'

// Haritam
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'Şablon türü veya şablon ara'
stringMap [strings.My_Mind_Date] = 'Tarih'
stringMap [strings.My_Mind_Create_Folder] = 'Klasör oluştur'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'Lütfen klasör adını girin'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'Lütfen yeni bir klasör adı girin'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'Henüz harita yok, hızlı gidin'
stringMap [strings.My_Mind_Create] = 'Oluştur'
stringMap [strings.My_Mind_Right] = 'Çubuk!'
stringMap [strings.My_Mind_Open] = 'Aç'
stringMap [strings.My_Mind_Type_Left_Right] = 'Çift kılavuzlu harita'
stringMap [strings.My_Mind_Type_Unilateral] = 'Tek taraflı harita'
stringMap [strings.My_Mind_Type_Bottom] = 'Organizasyon Şeması'
stringMap [strings.My_Mind_Type_Bubble] = 'Kabarcık Grafiği'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'Harita planla'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'Boş Zihin Haritası'
stringMap [strings.My_Mind_All] = 'Tümü'
stringMap [strings.My_Mind_Views] = 'Görünümler:'

// Fırtına araması
stringMap [strings.Storm_Search_How_Title] = 'En İyi Etiketler'
stringMap [strings.Storm_History_Search] = 'Geçmiş arama'

// Genel başlık
stringMap [strings.Public_Header_Mind] = 'Harita'
stringMap [strings.Public_Header_Outline] = 'Anahat'
stringMap [strings.Public_Header_Download_Apps] = 'İstemciyi indir'
stringMap [strings.Public_Header_Mobile_Version] = 'Mobil sürüm'
stringMap [strings.Public_Header_Creator] = 'Oluşturan:'
stringMap [strings.Public_Header_View_Frequency] = 'Görünümler:'
stringMap [strings.Public_Header_Save] = 'Kaydet'
stringMap [strings.Header_Mind_Tips_Saved] = 'Kaydedildi'
stringMap [strings.Header_Mind_Tips_Edit] = 'Düzenlendi'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'Güncelleme kaydedildi'

// Harita düzenleme menüsü
stringMap [strings.Mind_Edit_Revoke] = 'İptal Et'
stringMap [strings.Mind_Edit_Restore] = 'Geri Yükle'
stringMap [strings.Mind_Edit_Subtopic] = 'Alt Konu'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'En İyi Alt Konu'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'Alt Alt Konu'
stringMap [strings.Mind_Edit_Free_Node] = 'Ücretsiz Düğüm'
stringMap [strings.Mind_Edit_Summary] = 'Özet'
stringMap [strings.Mind_Edit_Block] = 'Bir bloğun içine'
stringMap [strings.Mind_Edit_Insert] = 'Ekle'
stringMap [strings.Mind_Edit_Related] = 'İlgili'
stringMap [strings.Mind_Edit_Style] = 'Stil'
stringMap [strings.Mind_Edit_Recording] = 'Kayıt'
stringMap [strings.Mind_Edit_Insert_Picture] = 'Bir resim ekle'
stringMap [strings.Mind_Edit_Insert_Icon] = 'Simge ekle'
stringMap [strings.Mind_Edit_Insert_Remarks] = 'Açıklamalar Ekle'
stringMap [strings.Mind_Edit_Insert_Link] = 'Bağlantı ekle'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'İstatistiksel grafik ekle'
stringMap [strings.Mind_Edit_Upload] = 'Yükle'
stringMap [strings.Mind_Edit_Right_Icon] = 'Simge'
stringMap [strings.Mind_Edit_Right_Style] = 'Stil'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'Kod çözülüyor ...'
stringMap [strings.Mind_Edit_Audio_Duration] = 'Süre'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = 'Kaydedilen sesi silmek istediğinizden emin misiniz? '
stringMap [strings.Mind_Edit_Join_Membership] = 'Açık üyelik'
stringMap [strings.Mind_Edit_Upgrade] = 'Yükselt'
stringMap [strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = 'Üyeler daha fazla kayıt süresine sahip olabilir, şimdi üyeliğe yükseltmeye gidin mi? '

stringMap [strings.Mind_Edit_Choose_Material] = 'Malzemeyi seçin'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'Resimleri buraya sürükleyip bırakın'
stringMap [strings.Mind_Edit_Select_locally] = 'Yerelden seç'
stringMap [strings.Mind_Edit_Choose_From] = 'Malzeme kitaplığından seçin'
stringMap [strings.Mind_Edit_Mine] = 'Benim'
stringMap [strings.Mind_Edit_My_Material] = 'Malzemem'
stringMap [strings.Mind_Edit_Recently_Used] = 'Son kullanılan'
stringMap [strings.Mind_Edit_Use] = 'Kullan'
stringMap [strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = 'Şu anda malzeme yok, gidin ve ekleyin! '
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'Grafik türünü seçin'
stringMap [strings.Mind_Edit_Remarks] = 'Açıklamalar'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'Lütfen açıklamaları girin'
stringMap [strings.Mind_Edit_Add] = 'Ekle'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'Lütfen bağlantıyı girin'

// Sayfanın sol tarafındaki menüyü düzenleyin
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'Resmi kaydet'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = "PDF'yi Dışa Aktar"
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'Bağlantıları paylaş'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'Fırtınayla paylaşın'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'Yazdır'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'Tam sürümün kilidini açın'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'Yardım'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'Biçimi kaydet'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'png biçimi'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'jpg biçimi'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'webp formatı'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'Haritayı paylaş'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'Paylaşım anahtarı'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'Bağlantıyı kopyalayıp gönderebilirsiniz'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'Bağlantıyı kopyala'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'Bağlantıyı değiştirmek istiyorsanız, lütfen tıklayın'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'Yeniden Oluştur'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'Bağlantı şifresi:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ='Kaydetmeye izin ver'
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Maksimum görüntüleme sayısı'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'Sonsuz'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'Sınırla'
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'İkinci'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'Bağlantı geçerlilik süresi'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = '1 gün'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = '3 gün'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = '7 gün'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'Kalıcı'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'İçeriği ve bağlantıyı kopyala'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = 'Yeniden oluşturmak istediğinizden emin misiniz? Önceki bağlantı geçersiz olacaktır! '
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'Paylaş'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'Özel kategori:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'Yorumlara izin verilip verilmeyeceği'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'Kategori eklemek için düzenleyin'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'Üyelik Hakları'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'Destek platformu'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'Üyeliği Aç'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'Aktivasyon kodu değişimi'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = 'Satın aldıktan sonra üyelik avantajları'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'Ödeme yöntemini seçin'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'Aktivite fiyatı'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'Gerçekte ödenen miktar'
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Hemen yenileyin'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《Hizmet Şartları》'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《Gizlilik Politikası》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'Aktivasyon kodu:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Lütfen aktivasyon kodunu girin'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'Aktivasyon kodunu doğrula'
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'Kategorileri seçin'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'Ana başlık boş olamaz'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'Kupon:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Lütfen kupon kodunu girin'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'Kupon kullanımını onaylayın'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'Doğrulama'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'Tercihli miktar'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'Hemen ödeyin'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Değişim bilgilerini onayla'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'Exchange kodu:'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Değişim'

// Sayfanın sağ tarafındaki menüyü düzenleyin
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'Tuval'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'Arka plan rengi'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'Düzen'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'Çizgi stili'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'Çizgi rengi'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'Çizgi genişliği'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = 'Çizgiler sivriltildi'
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'Tema yazı tipi'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'Çocuk yazı tipi'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'Metin rengi'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'Yazı tipi boyutu'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'Yazı Tipi Stili'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'Kenarlık rengi'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'Renk doldur'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'Kenar Şekli'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'Kenarlık genişliği'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Gölge efektini göster (abonelik işlevi)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'Çizgi rengi'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'Noktalı çizgi'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'Resim boyutu'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'Resim Konumu'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'Resim Şekli'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'Simge boyutu'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'Simge konumu'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'Genel'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'Öncelik'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'İlerleme'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'Yıldızlar'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'hafta'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Dikey'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'Öğreniyor'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'Çalışma'
stringMap [strings.Mind_Edit_Right_Menu_Characters] = 'Karakterler'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'Hayvanlar'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'Yiyecek'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'Stili kopyala'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'Yapışma stili'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'Şekli değiştir'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'Resmi görüntüle'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'Resmi sil'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'Kopyala'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'Yapıştır'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'Satırı sil'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Sil'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'Açıklamaları Düzenle'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'Bağlantıyı aç'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'Açıklamaları Sil'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'Bağlantıyı sil'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'Simgeyi sil'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'Simge stili'

// İstatistiksel grafik düzenleme paneli
stringMap [strings.Echart_Edit_Statistical] = 'İstatistikler'
stringMap [strings.Echart_Edit_Value] = 'Değer'
stringMap [strings.Echart_Edit_Color] = 'Renk'
stringMap [strings.Echart_Edit_Title] = 'Başlık'
stringMap [strings.Echart_Edit_Detailed_Description] = 'Ayrıntılı açıklama'
stringMap [strings.Echart_Edit_Value_Sort] = 'Sırala'
stringMap [strings.Echart_Edit_Value_Display_Type] = 'Görüntü Türü'
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'Renk düzeni'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'Görüntü boyutu'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'Başlık boyutu'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'Boyutu açıkla'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'Abone Ol'

// Fırtına sekmesi
stringMap [strings.StormBookTag_Subscribed] = 'Abone olundu'
stringMap [strings.StormBookTag_List] = 'Liste'

// Fırtına paylaşım sayfası
stringMap [strings.Storm_Share_Comment] = 'Yorum'
stringMap [strings.Storm_Share_Reply] = 'Yanıtla'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'Bir de yorum yapmama izin verin ~'
stringMap [strings.Storm_Share_Release] = 'Yayınla'
stringMap [strings.Storm_Share_Main_Title] = 'Ana Başlık'
stringMap [strings.Storm_Share_Subtitle] = 'Altyazı'
stringMap [strings.Storm_Share_Author] = 'Yazar'
stringMap [strings.Storm_Share_Replies] = 'Yanıtla'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'Henüz yorum yok. İlk yazan siz olun.'
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'Lütfen bir yorum girin ~'
stringMap [strings.Storm_Share_Delete_Comment_Tips] = 'Bu yorumu silmek istediğinizden emin misiniz? '

// Haritanın dahili kullanımı için yardım
stringMap [strings.Mind_Usinghelp_Btn_1] = 'Kısayol tuşları (pencereler)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'Kısayol tuşu (mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'Açıklama'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Komut] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'Alt düğüm ekle'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[Sil]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'Geçerli düğümü silin (düzenleme durumundaysa silinemeyeceğini unutmayın)'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Komut] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'Mevcut düğümü değiştir'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Komut] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Command] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'Haritayı büyütün'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Komut] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'Haritaya dikkat edin'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Komut] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Komut] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Üst Karakter] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Command] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Komut] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'Düzenlemeden çık'

// Bilgi istemi metni
stringMap [strings.Message_Tips_Login_Success] = 'Giriş başarılı'
stringMap [strings.Message_Tips_Dlt_Success] = 'Başarıyla sil'
stringMap [strings.Message_Tips_Report_Success] = 'Rapor başarılı'
stringMap [strings.Message_Tips_Copy_Success] = 'Kopyalama başarılı'
stringMap [strings.Message_Tips_Collction_Success] = 'Koleksiyon başarılı'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'Avatar değişikliği başarılı'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'İmza değiştirme başarılı'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'takma ad başarıyla değiştirildi'
stringMap [strings.Message_Tips_Modification_Success] = 'Değişiklik başarılı'
stringMap [strings.Message_Tips_New_Profile_Success] = 'Yeni klasör başarılı'
stringMap [strings.Message_Tips_Mobile_Success] = 'Mobil başarı'
stringMap [strings.Message_Tips_Submit_Success] = 'Başarıyla Gönderin'
stringMap [strings.Message_Tips_Exchange_Success] = 'Exchange başarılı'
stringMap [strings.Message_Tips_Please_Code] = 'Lütfen aktivasyon kodunu girin'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'Lütfen gizlilik politikasını kabul edin'
stringMap [strings.Message_Tips_Please_Sign_In] = 'Lütfen oturum açın'
stringMap [strings.Message_Tips_Share_Success] = 'Paylaşım başarılı'
stringMap [strings.Message_Tips_Create_Success] = 'Başarıyla oluşturuldu'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'Klasör adı boş olamaz'
stringMap [strings.Message_Tips_Selecte_Success] = 'Arama tamamlandı'
stringMap [strings.Message_Tips_Subscription_Success] = 'Abonelik başarılı'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'Aboneliği iptal etme başarılı'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'İlgili içerik bulunamadı'
stringMap [strings.Message_Tips_Max_Add_Class] = 'Yalnızca en fazla eklenebilir'
stringMap [strings.Message_Tips_Registered_Success] = 'Başarıyla kaydedildi'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'Kod taraması girişi başarısız oldu'
stringMap [strings.Message_Tips_Share_Is_Null] = 'Paylaşım bağlantısı mevcut değil'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = "Yüklenen dosya 3MB'yi aşamaz"
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'Başarıyla izin alın'
stringMap [strings.Message_Tips_Membership_Required] = 'Üyelik gerekli'
stringMap [strings.Message_Tips_Save_Success] = 'Başarıyla kaydet'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = 'Kod girişini başarıyla tara'
stringMap [strings.Message_Tips_Style] = 'Bir kategori mi?'

// Anahat modu
stringMap [strings.Outline_Menu_Indent] = 'Girinti'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'Girintiyi azalt'

// Yardım ipucu
stringMap [strings.Help_Background_Content] = 'Satır arkaplanıyla ilgili, satır arkaplanıyla ilgili, satır arkaplanıyla ilgili, satır arkaplanıyla ilgili, satır arkaplanıyla ilgili, satır arkaplanıyla ilgili, satır arkaplanıyla ilgili, satır arkaplanıyla ilgili, satır arkaplanıyla ilgili,'
stringMap [strings.Help_Mind_Content] = 'Farklı yerleşimlere sahip haritalar tercihlere göre yapılabilir: ikili kılavuz harita, sol harita, sağ harita, organizasyon şeması, iki yönlü ağaç, sol ağaç, sağ ağaç, tablo'
stringMap [strings.Help_Share_Link_Content] = 'Bağlantılar WeChat, QQ, Weibo ve diğer kanallarda bağlantı şeklinde paylaşılabilir ve arkadaşlar içeriği görüntüleyebilir'
stringMap [strings.Using_Templates] = 'Şablonları kullanma'

//Davet Kodu
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'Lütfen davetiye kodunu girin'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Lütfen doğru davetiye kodunu girin'
stringMap [strings.User_Lolgin_Optional] = 'İsteğe Bağlı'

// Materyal kullan

stringMap [strings.Cannot_Add_To_Node_Background] = 'Düğüm arka planı için geçerli değil'
stringMap [strings.Cannot_Add_To_Img_Background] = 'Metin arka planı için geçerli değil'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'Parsel satırı arka planı için geçerli değil'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Arka plan resmi ekle'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Arka plan resmini sil'
stringMap [strings.Mind_Add_Background_Image] = 'Arka plan resmi ekle'
stringMap [strings.Mind_Delete_Background_Image] = 'Arka plan resmini sil'

stringMap [strings.Permanent_Member] = 'Kalıcı Üye'
stringMap [strings.Insufficient_Storage_Space] = 'Yetersiz depolama alanı, alanı artırmak için üyeliği yükseltin. '
stringMap [strings.Used_Space] = 'Depolama alanı'
stringMap [strings.Current_Storage] = 'Mevcut depolama:'
stringMap [strings.Used_Space_Upgrade] = 'Depolama alanı yükseltmesi'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Zihin + Zihin Haritası ve Notlar Süper anımsatıcılar, düşünmeye, yaratmaya, göstermeye, planlamaya ve not almaya kanat ekleyin'
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = 'Depolama alanı yetersiz. Üyeliğinizi yükseltirken şablonu kullanabilirsiniz. '

stringMap [strings.Group_Management] = 'Grup'
stringMap [strings.Save_Img_Fill_Full] = 'Döşeme dolu'
stringMap [strings.Save_Img_Center_Tilt] = 'Merkez Eğim'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'Tam (koyu)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'Zihin + Zihin Haritası Filigranı'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'Bilgi oluşturmayı korumak için filigran ekleyin:'
stringMap [strings.Save_Img_Watermark_Text] = 'Filigran metni:'
stringMap [strings.Save_Img_Watermark_Logo] = 'Filigran LOGOSU:'
stringMap [strings.Save_Img_Watermark_Style] = 'Filigran stili:'
stringMap [strings.Save_Img_Please_Input] = 'Lütfen girin'
stringMap [strings.Save_Img_Chop_Tips] = 'Bir üyeyi açtığınızda filigran haklarından yararlanabilirsiniz'
stringMap [strings.Public_Header_Mindmap_Mind] = 'Harita modu'
stringMap [strings.Copy_Mind_Map] = 'Zihin Haritasını Kopyala'
stringMap [strings.Font_Style_Bold] = 'Kalın'
stringMap [strings.Font_Style_Italics] = 'İtalik'
stringMap [strings.Font_Style_Delete_Line] = 'Üstü çizili'
stringMap [strings.Remove_Any] = 'Kaldır'

stringMap [strings.Mindmap_Review] = 'Harita incelemesi'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'Gösterimi derinleştirin ve belleği geliştirin, fikirleri ve bilgileri verimli bir şekilde sıralayın'
stringMap [strings.Mindmap_Review_Content] = 'Harita incelemesi, düzenleme sürecinin oynatılması anlamına gelir ve içerik, ana temadan başlayarak sırayla oynatılır; harita incelemesi, sistem envanteri, analiz, fikir ve boşluklar bulmanıza yardımcı olabilir . İnceleme süreci boyunca, farklı ilham kaynakları bulabilirsiniz.'
stringMap [strings.Mindmap_Review_Speed] = 'Hız'
stringMap [strings.Mindmap_Review_Word_Speed] = 'Kelime hızı'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'Arka plan oranı'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'Geçerli oynatma düğümü büyütmesi'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'Düğüm Oynatma Sırası'
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap [strings.Mind_Group_Picture_Upload] = 'Resim yükleme'
stringMap [strings.Mind_Group_Upload_From_Local] = 'Yerel yükleme'
stringMap [strings.Mind_Group_Mind_Mapping] = 'Zihin Haritası'
stringMap [strings.Mind_Group_Information] = 'Veri'

stringMap [strings.Mind_Group_Invite_New_Members] = 'Yeni üye davet et'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Lütfen telefon numaranızı girin'
stringMap [strings.Mind_Group_Cancel] = 'İptal'
stringMap [strings.Mind_Group_Confirm] = 'Tamam'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'Cep telefonu numarası boş olamaz'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'Davet başarılı'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "Paylaşılan grup üyeleri"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Maksimum paylaşılan grup üyesi sayısı"
stringMap [strings.Mind_Group_Membership_Limit] = "Üyelik Sınırı"
stringMap [strings.Mind_Group_Current_Member_Limit] = "Mevcut Üye Üst Sınırı"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "Üye üst limitini yükselt"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "Üyeleri ara"
stringMap [strings.Mind_Group_Creator] = "Oluşturan"
stringMap [strings.Mind_Group_Administrator] = "Yönetici"
stringMap [strings.Mind_Group_Number_Of_People] = "Kişiler"
stringMap [strings.Mind_Group_Selected] = "Seçildi"
stringMap [strings.Mind_Group_Member] = "Üye"
stringMap [strings.Mind_Group_Check_All] = "Tümünü seç"
stringMap [strings.Mind_Group_Remove] = "Kaldır"
stringMap [strings.Mind_Group_Invite_New_Members] = "Yeni üye davet et"
stringMap [strings.Mind_Group_Delete_Members] = "Üyeleri sil"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Lütfen silinecek üyeyi seçin"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Kullanıcıyı bu gruptan çıkarmak istediğinizden emin misiniz?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "Yönetici Ekle"
stringMap [strings.Mind_Group_Accomplish] = "Tamamlandı"
stringMap [strings.Mind_Group_Compile] = "Düzenle"
stringMap [strings.Mind_Group_Group_Owner] = "Grup Sahibi"
stringMap [strings.Mind_Group_Add_Admin] = "Yönetici Ekle"
stringMap [strings.Mind_Group_Group_ID] = "Grup kimliği"
stringMap [strings.Mind_Group_Group_Number] = "Grup Numarası"
stringMap [strings.Mind_Group_Name] = "Ad"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Lütfen paylaşılan grubun adını girin"
stringMap [strings.Mind_Group_Introduce] = "Tanıtın"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Lütfen kısaca paylaşılan grupları tanıtın"
stringMap [strings.Mind_Group_Announcement] = "Duyuru"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "Paylaşılan Grup Etiketi"
stringMap [strings.Mind_Group_Custom_Tag] = "Özel Etiket"
stringMap [strings.Mind_Group_Save] = "Kaydet"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "Grup adı boş olamaz"
stringMap [strings.Mind_Group_Establish] = "Oluştur"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "Haritalar / klasörler oluşturmaya izin ver"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "Haritaların düzenlenmesine izin ver"
stringMap [strings.Mind_Group_Holder] = "Herkes"
stringMap [strings.Mind_Group_Only_The_Administrator] = "Yalnızca yönetici"
stringMap [strings.Mind_Group_Not_Allow] = "İzin verilmiyor"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "Haritayı silmeye izin ver"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Forumların mesaj göndermesine izin ver"
stringMap [strings.Mind_Group_Export] = "Dışa Aktar"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Resimleri / PDF'leri kaydetmeye izin ver"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "Grup filigranını aç"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Açtıktan sonra, grup logosu zihin haritasına yazdırılacaktır"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "Haritaları kaydetmeye izin ver"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "Haritama Kaydet"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Fırtınayla paylaşıma izin verilip verilmeyeceği"
stringMap [strings.Mind_Group_Join_The_Way] = "Nasıl katılırım?"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "Yeni üyelerin davet edilmesine izin verilip verilmeyeceği"
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Arama grubu numaralarının eklenmesine izin verilip verilmeyeceği"
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Katılırken mesajın doğrulanması gerekip gerekmediği"
stringMap [strings.Mind_Group_Shared_Group_Data] = "Paylaşılan grup verileri"
stringMap [strings.Mind_Group_Authority_Management] = "Yetki Yönetimi"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "Paylaşım grubundan ayrıl"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Paylaşılan gruptan çıkmak istediğinize emin misiniz?"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Çözünme Grubu"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "Grup dağıtıldıktan sonra dosyalar silinecek. Dağıtmayı onaylıyor musunuz?"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "Ana sayfaya dön"
stringMap [strings.Mind_Group_Join_The_Group] = "Gruba Katıl"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "Lütfen aranacak grup numarasını / grup adını girin"
stringMap [strings.Mind_Group_Apply_To_Join] = "Katılmak için başvur"
stringMap [strings.Mind_Group_Join] = "Katıl"
stringMap [strings.Mind_Group_Verification] = "Doğrulama"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "Lütfen doğrulama mesajı girin"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "İçerik boş olamaz"
stringMap [strings.Mind_Group_Name] = "Paylaşılan grup adı"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Lütfen paylaşılan grubun adını girin"
stringMap [strings.Mind_Group_Introduction] = "Grup tanıtımını paylaşın"
stringMap [strings.Mind_Group_Group_Indication] = "Grup Numarası"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "Oluşturucu tembel, henüz tanıtmadım ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "Paylaşılan grup duyurusu"
stringMap [strings.Mind_Group_No_Announcement] = "Henüz duyuru yok"
stringMap [strings.Mind_Group_Click_On] = "Genişletmek için tıklayın"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "Gizlemek için tıklayın"
stringMap [strings.Mind_Group_Altogether] = "Toplam"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Grup numarası başarıyla kopyalanıyor"
stringMap [strings.Mind_Group_Stick] = "En üste yapış"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "Yeni içerik var"
stringMap [strings.Mind_Group_Cancel_The_Top] = "Başı İptal Et"
stringMap [strings.Mind_Group_Setting_Up] = "Ayarlar"
stringMap [strings.Mind_Group_Usinghelp] = "Yardımı kullanma"
stringMap [strings.Mind_Group_Set_The_Message] = "Grup Mesajı"
stringMap [strings.Mind_Group_Apply_To_Join] = "Katılmak için başvur"
stringMap [strings.Mind_Group_Invite_To_Join] = "Katılmaya davet et"
stringMap [strings.Mind_Group_Consent] = "Kabul Ediyorum"
stringMap [strings.Mind_Group_Turn_Down] = "Reddet"
stringMap [strings.Mind_Group_Label] = "Etiket"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Lütfen paylaşılan grup etiketinin adını girin"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Arkadaşlarınızı ve meslektaşlarınızı bilgi içeriğini birlikte oluşturmaya ve paylaşmaya davet edin"
stringMap [strings.Mind_Group_Immediately_Create] = "Hemen oluşturun"
stringMap [strings.Mind_Group_Immediately_To_Join] = "Şimdi Katılın"

stringMap [strings.Currently_Selected] = "Şu anda takip ediliyor"
stringMap [strings.LabelManagement] ="Etiket Yönetimi"
stringMap [strings.NoAttention] = "Dikkat Yok"
stringMap [strings.Mindmap_Review_Play] = 'Oynat'

stringMap [strings.Mind_Group_Joined] = "Katıldı"
stringMap [strings.Mind_Group_Download_App] = "Uygulamayı İndir"
stringMap [strings.Mind_Group_No_Content] = "Henüz içerik yok"
stringMap [strings.Mind_Group_Individual] = "parçalar"
stringMap [strings.Mind_Group_Message_Area] = "Mesaj Alanı"
stringMap [strings.Mind_Group_No_Message] = "Mesaj yok, şimdi gidin"
stringMap [strings.Mind_Group_Leave_Message] = "Mesaj Bırakın"
stringMap [strings.Mind_Group_Bar] = "Çubuk"
stringMap [strings.Mind_Group_See_More] = "Daha fazlasını görün"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "Davet kodunu girin"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Paylaşım grubuna katılmak için başvurun"
stringMap [strings.Reasons_For_Application_0_30_Words] = "Başvuru nedenleri (0-30 kelime)"
stringMap [strings.Mind_Group_Send_Out] = "Gönder"
stringMap [strings.Mind_Group_Join_Successfully] = "Başarıyla katılın"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "Uygulama başarıyla gönderildi"

stringMap [strings.Mind_Group_Save_The_Mapping] = "Haritayı Kaydet"
stringMap [strings.Mind_Group_Update_Record] = "Kaydı Değiştir"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "Haritayı Değiştir"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "Harita İnceleme"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Mevcut bir kullanıcı haritayı düzenliyor"
stringMap [strings.Mind_Edit_Page_Password] = "Şifre"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "Paylaşım Grubu"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "MBean'ım"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Alipay Ödemesi"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "Lütfen ödemek için ödeme sayfasına gidin"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "Toplam"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "Ödemeyi onayla"
stringMap [strings.Mind_Shooping_Redeem_Now] = "Şimdi kullanın"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "Satın alma için ödemenin onaylanıp onaylanmayacağı"
stringMap [strings.Mind_Tips] = "İpuçları"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "M Fasulyeleriniz yetersiz, M Fasulye mi almak istiyorsunuz?"

stringMap [strings.Mind_share_scan_code] = "Zihin + tarama kodu"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "En son oynanan yere geri yüklenmeli mi?"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Kalan oynatılabilir süreler"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "Dosya tamamen silinecek, dağılacağına emin misiniz?"
stringMap [strings.Group_Mind_Up_One_Level] = "Bir Seviye Yukarı"
stringMap [strings.Group_Mind_No_Documents] = "Henüz dosya yok"
stringMap [strings.Group_Mind_PaymentSuccessful] = "Ödeme başarılı"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "Kişiler işbirliğine dayalı düzenleme, çoklu terminal gerçek zamanlı senkronizasyon, belgeleri paylaşma, ekip işbirliği verimliliğini artırma"

stringMap [strings.Mind_Edit_Subscript] = "Alt Simge"
stringMap [strings.Mind_Edit_Text_Location] = "Metin Konumu"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "Paylaşılan Gruba Aktar"

stringMap [strings.Standard_Colors] = "Standart Renkler"
stringMap [strings.More_Colors] = "Daha fazla renk"
stringMap [strings.Reset_Password] = "Parolayı Sıfırla"
stringMap [strings.Account_Password_Login] = "Hesap Şifresi Girişi"
stringMap [strings.Authentication_code_Login_registration] = "Doğrulama kodu girişi / kaydı"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "Tek uç oluşturma, çoklu uç senkronizasyonu"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "Zengin ve çeşitli harita yapısı"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "Çok büyük mükemmel şablon kitaplığı"
stringMap [strings.Map_Review_Play] = "Harita İnceleme-Oynat"
stringMap [strings.CommunityLearningAndSharingPlatform] = "Bilgi Öğrenme ve Paylaşım Değişim Platformu"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "Depolama alanını yükseltin"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "Bir de yorum yapmama izin ver"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Henüz mesaj bildirimi yok"
stringMap[strings.Failed_To_Get_Recording_Device] = "Kayıt cihazı alınamadı"

stringMap[strings.Search_Pagination] = "Sayfalandırmayı Ara"
stringMap[strings.Please_Enter_A_Paging_Name] = "Lütfen sayfa adını girin"
stringMap[strings.Operation_Settings] = "İşlem Ayarları"
stringMap[strings.Shortcut_key_Settings] = "Kısayol Tuşu Ayarları"
stringMap[strings.Press_Key_Combination] = "Lütfen gerekli tuş kombinasyonuna basın"
stringMap[strings.Mind_Reduction] = "Küçültme"
stringMap[strings.Double_Click_Add_Free_Node] = "Boş bir düğüm eklemek için boş alana çift tıklayın"
stringMap[strings.Shortcut_Conflict] = "Kısayol tuşu çakışması"
stringMap[strings.Select_Main_Node] = "Ana Düğümü Seç"
stringMap[strings.Telephone] = "Telefon"
stringMap[strings.Microblog] = "Mikroblog"
stringMap[strings.Address] = "Adres"
stringMap[strings.Company_Address] = "B Blok, Bina 21, No. 1158 Zhongxin Yolu, Songjiang Bölgesi, Şanghay (Songjiang Lingang Caohejing)"
stringMap[strings.Mind_Archive] = "Arşiv"
stringMap[strings.Mind_Bookshelf] = "Kitaplık"
stringMap[strings.Search_Archive] = "Kitaplık Ara"
stringMap[strings.Modify_Archive_Order] = "Kitaplık Sırasını Değiştir"
stringMap[strings.Modification_Order] = "Değişiklik Sırası"
stringMap[strings.Please_Enter_Archive_Name] = "Lütfen kitaplığın adını girin"
stringMap[strings.Message_Tips_Archive_Name_No_Null] = "Kitap raf adı boş olamaz"
stringMap[strings.Mind_Edit_Delete_Archive_Tips] = "Bu kitap rafını silmek istediğinizden emin misiniz?"
stringMap[strings.Mind_Edit_Delete_Bookcase_Tips] = "Bu kitaplığı silmek istediğinizden emin misiniz?"
stringMap[strings.Mind_Edit_Delete_Map_Tips] = "Bu haritayı silmek istediğinizden emin misiniz?"
stringMap[strings.Mind_Edit_Confirm] = "Onayla"
stringMap[strings.Mind_Import_Map] = "Haritayı İçe Aktar"
stringMap[strings.Mind_Bookcase] = "Kitaplık"
stringMap[strings.Added_Successfully] = "Başarıyla eklendi"
stringMap[strings.Message_Tips_Bookcase_Name_No_Null] = "Kitaplık adı boş olamaz"

stringMap[strings.Mind_Association_Map] = "İlişkilendirme Haritası"
stringMap[strings.Mind_confirm_Association_Map] = "Bu haritayı ilişkilendirmek istediğinizden emin misiniz?"
stringMap[strings.Mind_Association_Map_See] = "İlişkilendirme Haritasını Görüntüle"
stringMap[strings.Mind_Association_Map_Delete] = "İlişkilendirme Haritasını Sil"

stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "Korelasyon Çizgisi Yapısı"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Korelasyon Çizgisinin Başlangıcı"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "Korelasyon satırının sonu"

stringMap[strings.Share_Group_To_Storm] = "Grubu fırtınada paylaşıp paylaşmama"
stringMap[strings.Apply_To_Join_Storm_Platform] = "Fırtına platformunda, diğer insanlar ortak bilgiye sahip meraklılarla beyin fırtınası yaparak katılmak için görür ve başvurur"
stringMap[strings.Pay_To_Goin] = "Katılmak için ödeyin"
stringMap[strings.Pay_To_Goin_Need_Mbeans] = "Katılırken M çekirdeklerinin ödenmesi gerekiyor"
stringMap[strings.Mbean_Can_Be_Realized] = "Mbean Gerçekleştirildi"

stringMap[strings.Mind_Visit] = "Ziyaret"
stringMap[strings.Mind_Details] = "Ayrıntılar"
stringMap[strings.Mind_See] = "Görüntüle"
stringMap[strings.Earn_Mbeans] = "Mbeans Kazanın"
stringMap[strings.Mind_Explain] = "Açıklama"
stringMap[strings.Mind_Parenting] = "Ebeveynlik"
stringMap[strings.Mind_Internet] = "İnternet"
stringMap[strings.Mind_Psychology] = "Psikoloji"
stringMap[strings.Mind_literature] = "Edebiyat"
stringMap[strings.Mind_Technology] = "Teknoloji"
stringMap[strings.Mind_Workplace] = "İşyeri"
stringMap[strings.Mind_Life] = "Hayat"
stringMap[strings.Mind_Subject] = "Konu"
stringMap[strings.Hot_Group] = "Sıcak Grup"
stringMap[strings.Open_Group] = "Grubu Aç"
stringMap[strings.Mind_Group_Used] = "Kullanılmış"

stringMap[strings.Mind_Click_Select] = "Seçmek için tıklayın"
stringMap[strings.Mind_Double_Click_Enter] = "Girmek için çift tıklayın"
stringMap[strings.Search_Shared_Groups] = "Paylaşılan grupları ara"
stringMap[strings.Search_Storm] = "Arama Fırtınası"
stringMap[strings.Members_Save_Free] = "Üyeler Ücretsiz Kaydediyor"
stringMap[strings.Bank_Card_Account_Name] = "Lütfen banka kartı hesap adınızı girin"
stringMap[strings.Bank_Card_No] = "Banka Kartı Numarası"
stringMap[strings.Please_Enter_Your_Bank_Card_No] = "Lütfen banka kartı numaranızı girin"
stringMap[strings.Branch_Sub_Branch_Information] = "Şube şube bilgisi"
stringMap[strings.Branch_Sub_Branch_Example] = "Örneğin: Hangzhou Şubesi Chengxi Alt Şubesi"
stringMap[strings.Application_For_Withdrawal] = "Çekilme başvurusu"
stringMap[strings.Withdrawal_Amount] = "Çekilme miktarı"
stringMap[strings.Withdrawal] = "Çekin"
stringMap[strings.Current_Revenue] = "Mevcut Gelir"
stringMap[strings.Recharge] = "Yeniden Şarj Et"
stringMap[strings.Current_Total_MBeans] = "Mevcut toplam MBean sayısı"
stringMap[strings.Withdrawal_Status] = "Çekilme Durumu"
stringMap[strings.Insufficient_Withdrawal_Amount] = "Yetersiz para çekme miktarı"
stringMap[strings.Load_More] = "Daha fazlasını yükle"
stringMap[strings.Loading] = "Yükleniyor"
stringMap[strings.It_Is_Over] = "Bitti"
stringMap[strings.Default_Label] = "Varsayılan etiket"
stringMap[strings.Selected_Label] = "Seçili etiket"
stringMap[strings.Current_Location] = "Geçerli Konum"
stringMap[strings.MGold_Introduction] = "Mind+ aracılığıyla M altın kazanıyorsunuz"
stringMap[strings.MGold_Details] = "Ücretli bulut grubunuz, grupta bilgi haritaları, öğrenme materyalleri vb. var. Kullanıcılar M fasulye ödemesine katılırsa, bunların %50'si M altına ve M altına daha fazla dönüştürülür 50'den fazla geri çekilebilir"
stringMap[strings.Cloud_Group_Introduction] = "Grubunuzu paylaşın, diğerleri fırtına sayfasında görebilir"
stringMap[strings.Cloud_Group_Details] = "Ücretli bulut grubunuz. Grupta bilgi haritaları, öğrenme materyalleri vb. var. Kullanıcılar M fasulye ödemesine katılırsa, bunların %50'si M altına ve M altına daha fazla dönüştürülür. 50'den fazla çıkarılabilir"
stringMap[strings.Share_Storm_Introduction] = "Haritanızı fırtına topluluğuyla paylaşın, böylece daha fazla insan sizden bilgi alabilir ve keşfedebilir"
stringMap[strings.Share_Storm_Details] = "Haritaları kaydetmek için M fasulye kullanımını etkinleştirirseniz, diğer kullanıcılar bilgi haritanızı kullanabilir ve ayrıca M fasulyelerinin %50'sini alırsınız. M fasulyeleri üye satın almak ve ücretli katılmak için kullanılabilir gruplar , Başkalarının haritalarını kullanmak için de ödeme yapabilirsiniz."

stringMap[strings.Linked_Successfully] = "Başarıyla bağlandı"
stringMap[strings.Cancel_Archiving] = "Arşivlemeyi İptal Et"
stringMap[strings.Please_Select_Archive_Location] = "Lütfen arşiv konumunu seçin"
stringMap[strings.The_Search_Result_Is_Empty] = "Arama sonuçları boş"

stringMap[strings.Customize_A_Style] = "Üye olmayanlar yalnızca bir stili özelleştirebilir"
stringMap[strings.Adds_Current_Guide_Style_Library] = "Geçerli harita stilini stil kitaplığına ekleyin"
stringMap[strings.Trial_Version] = "Deneme Sürümü"
stringMap[strings.Not_Supported_At_The_Moment] = "Şu anda desteklenmiyor"
stringMap[strings.Mind_File] = "Dosya"
stringMap[strings.Mind_Import] = "İçe Aktar"
stringMap[strings.Importing] = "İçe Aktarılıyor"

stringMap[strings.Mind_Edit_Insert_Video] = "Video Ekle"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Videoyu buraya sürükleyip bırakın"
stringMap[strings.Message_Tips_Video_Upload_Time_Limit] = "Yüklenen video süresi 60 saniyeyi aşamaz"
stringMap[strings.Mind_Edit_Node_Menu_View_Video] = "Videoyu Görüntüle"
stringMap[strings.Mind_Edit_Node_Delete_View_Video] = "Videoyu sil"
stringMap[strings.Message_Tips_Share_Video_Does_Not_Exist] = "Yüklenen video 10 MB'ı aşamaz"
stringMap[strings.Mind_Upload_Video_Restrictions] = "Yalnızca 120s ve 10M boyutuna kadar MP4 formatında video yüklemeyi destekler"
stringMap[strings.Mind_Video_Size] = "Video boyutu"
stringMap[strings.Mind_Edit_Left_Menu_Export_Word] = "Kelimeyi Dışa Aktar"
stringMap[strings.Mind_Edit_Left_Menu_Export_TXT] = "TXT'yi Dışa Aktar"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap[strings.Export_Failed] = "Dışarı aktarılamadı"
stringMap[strings.Add_File] = "Dosya ekle"
stringMap[strings.Drag_The_File_Directly_To_Upload] = "Yüklemek için doğrudan dosyaya sürükleyin"
stringMap[strings.Supported_Imported_File_Types] = "İçe aktarma için şu anda desteklenen dosya türleri: Word (yalnızca .docx), XMind, .txt"
stringMap[strings.No_Permission_Do_This] = "Bu işlemi gerçekleştirme izniniz yok"

stringMap[strings.Upgrade_To_Professional] = "Profesyonel Sürüme Yükselt"
stringMap[strings.Non_Members_Insert_Videos] = "Üye olmayanlar kayıt olduktan sadece 24 saat sonra video ekleyebilir"
stringMap[strings.Upload_Failed] = "Yükleme başarısız"
stringMap[strings.Upload_Failed_Title_Message] = "Yükleme başarısız, lütfen onaylamak için tıklayın, yüklenemeyen kalan resimleri silin"

stringMap[strings.Mind_Edit_Members_Use] = "Üyelerin Kullanımı"
stringMap[strings.Invitation_Code] = "Davetiye Kodu"
stringMap[strings.Student_Certification] = "Öğrenci Sertifikasyonu"
stringMap[strings.I_Get_It] = "Yapacağım, kılavuzu atla"
stringMap[strings.Welcome_To_Mind] = "Genç efendi Mind+'a katılmaya hoş geldiniz, düşünme yolculuğuna başlayalım!"
stringMap[strings.Mind_Before] = "İlk"
stringMap[strings.Try_A_Map] = "Bir harita deneyin"
stringMap[strings.Click_Create] = "Oluşturmak için tıklayın"
stringMap[strings.Click_Create_A_Mind_Map] = "Bir zihin haritası oluşturmak için tıklayın"
stringMap[strings.Mind_About] = "Hakkında"
stringMap[strings.See_Checking_Data] = "Kişisel bilgileri görüntüle"
stringMap[strings.Modify_Checking_Data] = "Kişisel bilgileri değiştirin"
stringMap[strings.Mind_Shear] = "Kesme"

stringMap[strings.Mind_Style_Empty] = "Favori veya özel stil yok"
stringMap[strings.Mind_Branch_Free_Layout] = "Şube Serbest Yerleşimi"
stringMap[strings.Mind_Branch_Free_Layout_explain] = "İki yönlü yapı ve kabarcık haritası dalları düzene serbestçe sürüklenebilir"
stringMap[strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Ücretsiz Tema Sürükle Snap"

stringMap[strings.Select_A_Node] = "Bir konu seçin"
stringMap[strings.Create_Child_Node] = "Alt tema oluştur"
stringMap[strings.Add_A_Nice_Image_To_The_Node] = "Ardından alt temaya güzel bir tane ekleyeceğiz"
stringMap[strings.Click_Icon_Panel] = "Simge paneline tıklayın"
stringMap[strings.Select_An_Icon_You_Like] = "Beğendiğiniz bir simge seçin"
stringMap[strings.Replace_The_Mind_Map_With_A_New_One] = "Sonra zihin haritasını yenisiyle değiştireceğiz"
stringMap[strings.Select_The_Central_Theme] = "Ana temayı seçin"
stringMap[strings.Switch_To_Canvas_Panel] = "Tuval Paneline Geç"
stringMap[strings.Click_Layout] = "Düzen için Tıklayın"
stringMap[strings.Choose_A_Layout_You_Like] = "Beğendiğiniz bir düzeni seçin"
stringMap[strings.Mind_Map_Save_Share] = "Tebrikler, başarılı bir şekilde güzel bir zihin haritası oluşturdunuz! Haydi kaydedelim ve arkadaşlarınızla paylaşalım~"
stringMap[strings.Click_Save_Picture] = "Resmi kaydetmek için tıklayın"
stringMap[strings.Click_OK] = "Tamam'ı tıklayın"
stringMap[strings.Congratulations_On_Completing_The_Novice_Guide] = "Acemi kılavuzunu tamamladığınız için tebrikler~"
stringMap[strings.Upload_Succeeded] = "Başarıyla yüklendi"

stringMap[strings.Mind_Edit_Node_Menu_Quick_Style] = "Hızlı Stil"
stringMap[strings.Mind_Extremely_Important] = "Son derece önemli"
stringMap[strings.Mind_Important] = "Önemli"
stringMap[strings.Mind_Commonly] = "Genel"
stringMap[strings.Mind_Ordinary] = "Sıradan"
stringMap[strings.Member_Exclusive] = "Bu özellik üyelere özeldir. Üyeliğinizi yükseltmek ister misiniz?"
stringMap[strings.Member_Exclusive_Color_Scheme] = "Bu renk şeması üyelere özeldir. Üyeliğinizi yükseltmek istiyor musunuz?"
stringMap[strings.Mind_Enlarge] = "Büyüt"
stringMap[strings.Mind_Narrow] = "Dar"
stringMap[strings.Mind_Center] = "Ortalanmış"
stringMap[strings.Mind_Full_Screen] = "Tam Ekran"

stringMap[strings.Mind_Association_Node] = "İlişkilendirme Düğümü"

stringMap[strings.Mind_Resource_See] = "Eki görüntüle"
stringMap[strings.Mind_Resource_Delete] = "Ekleri sil"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Dosyaları buraya sürükleyip bırakın"
stringMap[strings.Mind_Edit_choose_explain] = "Dosya Seç"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "Yüklenen dosya 20 MB'ı aşamaz"

stringMap[strings.Please_Enter_Mind_Password] = "Lütfen 4-8 rakam parolanı girin"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "Lütfen bir parola girin"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "Bu fonksiyon bir üye fonksiyonudur."
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "Dosya parolanı silildi"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "Parola rakamların sayısı yeterli değil. Lütfen 4 çevrili 8 rakamla parolanı girin."
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "Parola çok uzun. Lütfen parolanı 4 çevrili 8 rakamla girin."
stringMap[strings.Please_Enter_Mind_Password_Success] = "Parola başarıyla ayarlandı"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "Parola format ı hatası"
stringMap[strings.Mind_Load_Data_Error] = "Veri yükleme hatası"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Parola hatası"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Only the creator of this map can set the password"

stringMap[strings.Mind_Extremely_DrawOblique] = "Texture"
stringMap[strings.Mind_Extremely_Gradient] = "Gradient"
stringMap[strings.Mind_Extremely_Shadow] = "Text Shadow"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;