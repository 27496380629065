
const { default: strings } = require("./strings");

var stringMap = {};
stringMap[strings.About_App_Name] = "دماغ + دماغ کا نقشہ";
stringMap[strings.TEMPLATE] = "سانچے";
stringMap[strings.Mind_Edit_Main_Topic] = "شاخ کا عنوان";
stringMap[strings.Global_Add_Free_Node_Tips] = "مقام منتخب کرنے کے لئے اسکرین پر کلک کریں";
stringMap[strings.Global_Add_Free_Chart_Node_Tips] = "جگہ شامل کرنے کیلئے منتخب کرنے کیلئے اسکرین پر دو بار تھپتھپائیں";
stringMap[strings.Global_Add_Relationship_Tips] = "وابستہ ہونے کا ہدف منتخب کریں";
stringMap[strings.Global_Add_Target_Tips] = "برائے مہربانی اشیاء کو شامل کرنے کے لئے نوڈ کا انتخاب کریں";
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] = "یہ اعتراض پہلے ہی دوسرے نقشوں سے وابستہ ہے";
stringMap[strings.Mind_Edit_Main_Idea] = "مرکزی خیال ، موضوع";
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = "وابستہ نقشہ موجود نہیں ہے";
stringMap[strings.Mind_Style_Add_Icon_Priority] = "ترجیح";
stringMap[strings.Mind_Style_Add_Icon_Progress] = "شیڈول";
stringMap[strings.Mind_Style_Add_Icon_Stars] = "ستارہ";
stringMap[strings.Mind_Style_Add_Icon_Week] = "ہفتہ";
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] = "اوتار";
stringMap[strings.Establish] = "بنانا";
stringMap[strings.Create_From_Template] = "ٹیمپلیٹ سے بنائیں";
stringMap[strings.Common] = "عالمگیر";
stringMap[strings.Function_List] = "تقریب کی فہرست";
stringMap[strings.Recent_Updates] = "حال ہی میں تازہ کاری کی گئی";
stringMap[strings.The_Latest_To_Delete] = "حال ہی میں حذف ہوگیا";
stringMap[strings.Setting_Up] = "ہم سے رابطہ کریں";
stringMap[strings.Usinghelp] = "مدد کا استعمال کرتے ہوئے";
stringMap[strings.The_Download_Client] = "کلائنٹ / موبائل ورژن ڈاؤن لوڈ کریں";
stringMap[strings.Global_Purchase] = "خریدنے";
stringMap[strings.Global_Login] = "لاگ ان کریں";
stringMap[strings.My_map] = "میرا نقشہ";
stringMap[strings.Create_Map] = "ایک نقشہ بنائیں";
stringMap[strings.Storm] = "طوفان";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = "عام. n خصوصیات";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = "خصوصیات";
stringMap[strings.Mind_Organization_Edit_Main_Idea] = "تنظیمی مرکز";
stringMap[strings.Mind_Edit_Free_Topic] = "مفت تھیم";
stringMap[strings.Mind_Edit_Sub_Topic] = "سبٹوپک";
stringMap[strings.Mind_Organization_Main_Topic] = "شعبہ";
stringMap[strings.Mind_Edit_Main_Topic] = "شاخ کا عنوان";
stringMap[strings.Mind_Time_Edit_Main_Idea] = "منصوبہ بندی کے انتظام";
stringMap[strings.Mind_Time_Edit_Head] = "وقت";
stringMap[strings.Mind_Time_Edit_Title] = "منصوبہ";
stringMap[strings.Mind_Edit_Details] = "تفصیلی وضاحت";
stringMap[strings.Checking_Data] = "ڈیٹا چیک کر رہا ہے";
stringMap[strings.Setting_Information] = "معلومات مرتب کرنا";
stringMap[strings.Sign_Out] = "باہر جائیں";

//تقریب کی فہرست
stringMap[strings.Create_a_Mind_Map] = "ذہن کا نقشہ بنائیں";
stringMap[strings.Create_Mind_Map_1] = " ترتیب کا نقشہ بنائیں (دو طرفہ / دائیں / بائیں / تنظیمی ڈھانچہ / سنگل بلبلا چارٹ / ڈبل بلبلا چارٹ / بائیں درخت کا چارٹ / دائیں درخت کا چارٹ / دو طرفہ درخت کا چارٹ / ٹیبل / افقی ٹائم لائن / عمودی ٹائم لائن / ایس ٹائم لائن / پرامڈ)";
stringMap[strings.Create_Mind_Map_2] = "ٹیمپلیٹس کا استعمال نقشوں کو تیزی سے بنانے کے لئے استعمال کیا جاسکتا ہے (ٹیمپلیٹ مشمولات کی ایک بڑی تعداد)";
stringMap[strings.Create_Mind_Map_3] = "نقشے پر نو قسم کی لائن شیلیوں میں ترمیم کریں";
stringMap[strings.Create_Mind_Map_4] = "نقشے میں نو ترتیب کی تبدیلیوں میں ترمیم کریں";
stringMap[strings.Create_Mind_Map_5] = "چائلڈ نوڈس کو ٹارگٹ نوڈ میں شامل کریں ، اسی سطح پر نوڈس شامل کریں (اوپری اور لوئر)";
stringMap[strings.Create_Mind_Map_6] = "خلاصہ: سنگل نوڈ / ملٹی نوڈ کا اضافہ بیان کریں [خلاصہ]";
stringMap[strings.Create_Mind_Map_7] = "مسدود کرنا: ماڈیولرائزیشن کے ل the نقشہ کے سنگل نوڈ / ایک سے زیادہ نوڈ بکس میں [مسدود کرنا] شامل کریں";
stringMap[strings.Create_Mind_Map_8] = "مفت نوڈ شامل کریں";
stringMap[strings.Create_Mind_Map_9] = "کسی بھی نوڈس کے درمیان چار لائن شیلیوں کی انجمنیں بنائیں";
stringMap[strings.Create_Mind_Map_10] = "تصاویر داخل کریں (مقامی + مادی لائبریری) ، شبیہیں ، نوٹ ، نوڈس کے لنکس";
stringMap[strings.Create_Mind_Map_11] = "کاپی / پیسٹ نوڈ ، کاپی / پیسٹ اسٹائل";
stringMap[strings.Create_Mind_Map_12] = "منسوخ کرنا";
stringMap[strings.Create_Mind_Map_13] = "ملٹی سلیکشن ، متعدد نوڈس کا بیک وقت آپریشن";
stringMap[strings.Create_Mind_Map_14] = "داخل کرنے کے قابل اعدادوشمار کا گراف";
stringMap[strings.Create_Mind_Map_15] = "پورا نقشہ ریکارڈ کیا جاسکتا ہے";
stringMap[strings.Create_Mind_Map_16] = "نقشہ کاپی کریں";
stringMap[strings.Create_Mind_Map_17] = "آپ نقشہ یا پورے نقشہ کو دوسری فائلوں کے نقشے پر کاپی کرسکتے ہیں";
stringMap[strings.Create_Mind_Map_18] = "خلاصہ کے بعد نقشہ تیار کیا جاسکتا ہے";
stringMap[strings.Create_Mind_Map_19] = "ترمیم کرتے وقت ، آپ نقشہ (کمپیوٹر سائیڈ) میں ترمیم کرنے کے ل switch سوئچ کرسکتے ہیں";
stringMap[strings.Create_Mind_Map_20] = "بلوٹوتھ کی بورڈ میں ترمیم کا نقشہ: \ n \ t [کمانڈ] + [a] مرکزی نوڈ منتخب کریں \ n \ t [کمانڈ] + [←] [↑] [→] [↓] منتخب کردہ ہدف کو تبدیل کریں یا نقشہ منتقل کریں \ n Command t [کمانڈ] + [ای] ہدف میں ترمیم کریں \ n \ t [کمانڈ] + [ڈبلیو] چائلڈ نوڈ بنائیں \ n \ t [کمانڈ] + [ے] ٹارگٹ سگنلنگ نوڈ (ٹاپ) بنائیں \ n \ t [کمانڈ] + [d] ہدف بہن بھائی نوڈ (نیچے) بنائیں \ n \ t [کمانڈ] + [ق] منتخب کردہ ہدف سے باہر نکلیں \ n \ t [کمان] + [-] [+] زوم میپ";

stringMap[strings.Beautify_The_Mind_Map] = "ذہن کے نقشے کو خوبصورت بنائیں";
stringMap[strings.Beautify_The_Mind_Map_1] = "دماغ کے نقشے کا پس منظر کا رنگ مرتب کریں";
stringMap[strings.Beautify_The_Mind_Map_2] = "پوری نقشہ لائن کی موٹائی اور رنگ سیٹ کریں ، چاہے وہ ٹائپرڈ ہے یا نہیں";
stringMap[strings.Beautify_The_Mind_Map_3] = "متن بھرنے کا رنگ ، بارڈر رنگ ، بارڈر 13 شکلیں ، بارڈر چوڑائی ، سایہ اثر مرتب کریں";
stringMap[strings.Beautify_The_Mind_Map_4] = "نقشے کے سبھی حصے یا رنگ کی چوڑائی اور سیٹ کریں";
stringMap[strings.Beautify_The_Mind_Map_5] = "ٹیکسٹ فونٹ سائز ، بولڈ ، ترچھا ، حذف کرنے کا انداز طے کریں";
stringMap[strings.Beautify_The_Mind_Map_6] = "تصویر کا سائز ، پوزیشن ، شکل کا انداز مقرر کریں";
stringMap[strings.Beautify_The_Mind_Map_7] = "شبیہیں کی سات اقسام (عام / 3D / مطالعہ / کام / افراد / جانور / کھانا) شامل کریں اور سائز / مقام مرتب کریں";
stringMap[strings.Beautify_The_Mind_Map_8] = "پورے نقشے پر ایک کلک اسٹائل کا حوالہ شامل کریں";
stringMap[strings.Beautify_The_Mind_Map_9] = "گروپ مینجمنٹ کا نقشہ";
stringMap[strings.Beautify_The_Mind_Map_10] = "پورے نقشہ میں ذاتی نوعیت کا پس منظر (میٹری لائبریری) شامل کیا جاسکتا ہے";
stringMap[strings.Beautify_The_Mind_Map_9] = "ایک واحد نوڈ (مادی لائبریری) میں انفرادی حدود یا پس منظر شامل کیے جاسکتے ہیں";
stringMap[strings.Beautify_The_Mind_Map_9] = "نوڈ کی لمبائی کو گھسیٹا جاسکتا ہے";


stringMap[strings.Map_Output] = "نقشہ آؤٹ پٹ";
stringMap[strings.Map_Output_1] = "تصویر کو بچانے کے";
stringMap[strings.Map_Output_2] = "پی ڈی ایف کو محفوظ کریں";
stringMap[strings.Map_Output_3] = "لنک شیئر کریں";
stringMap[strings.Map_Output_4] = "طوفان کو بانٹنا";
stringMap[strings.Map_Output_4] = "پرنٹ کریں";

stringMap[strings.Work_together] = "مل کے کام کرو";
stringMap[strings.Work_together_1] = "مشترکہ گروپ بنائیں (گروپ لیبل ، گروپ اعلان)";
stringMap[strings.Work_together_2] = "مشترکہ گروپ کے ممبران کو شامل کریں";
stringMap[strings.Work_together_3] = "گروپ میں متعدد ممبران ایک ساتھ اسی نقشہ میں ترمیم کریں";
stringMap[strings.Work_together_4] = "گروپ کے اندر مشترکہ فائلیں اپ لوڈ کریں (ورڈ / ایکسل / پی ڈی ایف)";
stringMap[strings.Work_together_5] = "آپ گروپ میں کوئی پیغام چھوڑ سکتے ہیں";
stringMap[strings.Work_together_6] = "مشترکہ گروپ مینجمنٹ کی اجازت کی ترتیبات";
stringMap[strings.Work_together_7] = "گروپ ختم کرو";

stringMap[strings.Work_together] = "علم شیئرنگ (طوفان)";
stringMap[strings.Community_Sharing_1] = "آپ اپنی تخلیق کو طوفان کے ساتھ بانٹ سکتے ہیں ، تاکہ زیادہ سے زیادہ لوگ دیکھ سکیں";
stringMap[strings.Community_Sharing_1] = "آپ طوفان سے متعلقہ مواد دیکھنے کے لئے ٹیگز منتخب کرسکتے ہیں";
stringMap[strings.Community_Sharing_1] = "آپ طوفان کے کاموں پر پسند اور تبصرہ کرسکتے ہیں";

//حال ہی میں تازہ کاری کی گئی
stringMap[strings.Recently_Updated_Title_1] = "ذاتی اکاؤنٹ بنائیں";
stringMap[strings.Recently_Updated_Title_2] = "بڑے پیمانے پر ٹیمپلیٹ علم کی بنیاد";
stringMap[strings.Recently_Updated_Title_3] = "متعدد پلیٹ فارمز کی حمایت کریں";
stringMap[strings.Recently_Updated_Title_4] = "ہر تفصیل کو اپنی مرضی کے مطابق بنائیں";
stringMap[strings.Recently_Updated_Title_5] = "ذاتی نوعیت کی طرز کی لائبریری";
stringMap[strings.Recently_Updated_Title_6] = "نقشہ آؤٹ پٹ";
stringMap[strings.Recently_Updated_Title_7] = "کلاؤڈ اسٹوریج";
stringMap[strings.Recently_Updated_Title_8] = "پندرہ قسم کے نقشے";


stringMap[strings.Recently_Updated_Content_1] = "ایک نجی اکاؤنٹ بنائیں اور اس اکاؤنٹ کے ذریعہ تیار کردہ نقشہ کو محفوظ کریں";
stringMap[strings.Recently_Updated_Content_2] = "بڑے پیمانے پر ٹیمپلیٹ علم کی بنیاد سیکھنے اور کام کی کارکردگی کو بہتر بنانے کے لئے براہ راست حوالہ دیا گیا ہے";
stringMap[strings.Recently_Updated_Content_3] = "سپورٹ ویب ورژن آن لائن پروڈکشن ، آئی او ایس موبائل ٹرمینل ، میک ٹرمینل ، اینڈروئیڈ موبائل ٹرمینل ، اینڈروئیڈ ٹیبلٹ ملٹی پلیٹ فارم استعمال";
stringMap[strings.Recently_Updated_Content_4] = "ذاتی ترجیحات کے مطابق متن ، پس منظر ، لائنز ، تصویر شبیہیں وغیرہ کے ڈیزائن کو خوبصورت بناسکتے ہیں";
stringMap[strings.Recently_Updated_Content_5] = "رنگ کے ملاپ کی پریشانی کو ختم کرتے ہوئے ، انفرادی طرز کے لائبریری کا براہ راست حوالہ دیا گیا ہے";
stringMap[strings.Recently_Updated_Content_6] = "تصاویر ، پی ڈی ایف فارمیٹ کو بچا سکتا ہے ، دوستوں کے ساتھ براہ راست شیئر کرسکتا ہے";
stringMap[strings.Recently_Updated_Content_7] = "مضبوط کلاؤڈ اسٹوریج سپورٹ ، اب ڈیٹا کے نقصان کے بارے میں فکر کرنے کی ضرورت نہیں";
stringMap[strings.Recently_Updated_Content_8] = "دو طرفہ ، دائیں ، بائیں ، تنظیمی ڈھانچہ ، سنگل بلبلا چارٹ ، ڈبل بلبلا چارٹ ، بائیں درخت کا چارٹ ، دائیں درخت کا چارٹ ، دو طرفہ درخت کا چارٹ ، ٹیبل ، افقی ٹائم لائن ، عمودی ٹائم لائن ، ایس ٹائم لائن ، آٹھ ترتیب کو تبدیل کیا جاسکتا ہے کریں گے";

//حال ہی میں حذف ہوگیا
stringMap[strings.Mind_Mindmap_Deleted_Title] = "حال ہی میں حذف ہوگیا";
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = "بحال";
stringMap[strings.Mind_Mindmap_Deleted_Forever] = "حذف کریں";
stringMap[strings.Mind_Mindmap_Deleted_Filename] = "فائل کا نام";
stringMap[strings.Mind_Mindmap_Deleted_Tips] = "یہاں حذف ہوجانے کے بعد ، اسے مستقل طور پر ختم کردیا جائے گا";
stringMap[strings.Mind_Mindmap_Deleted_Operating] = "آپریٹنگ";
stringMap[strings.Mind_Mindmap_Deleted_Selected] = "حالیہ حذف شدہ نقشوں کی تلاش کریں";
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] = "تاریخ حذف کریں";

//ہم سے رابطہ کریں
stringMap[strings.Contact_Us_No_Public] = "عوام";
stringMap[strings.Contact_Us_QQ_Group] = "کیو کیو گروپ";
stringMap[strings.Contact_Us_Message_Board] = "پیغام بورڈ";
stringMap[strings.Contact_Us_Username] = "نام";
stringMap[strings.Contact_Us_Email] = "میل باکس";
stringMap[strings.Contact_Us_Voicemail] = "برائے مہربانی اپنے قیمتی تبصرے بتائیں";
stringMap[strings.Contact_Us_Send_Email] = "ای میل بھیجیں";

//پیغام کی تصدیق کریں
stringMap[strings.Input_Username_isNull] = "نام خالی نہیں ہوسکتا";
stringMap[strings.Input_Email_isNull] = "براہ کرم اپنا ویلڈ ای میل درج کریں";
stringMap[strings.Input_Voicemail_isNull] = "براہ کرم مواد درج کریں";
stringMap[strings.Input_Introduction_isNull] = "تعارف خالی نہیں ہوسکتا";
stringMap[strings.Input_Title_isNull] = "عنوان خالی نہیں ہوسکتا";

stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] = "!ایک درست فون نمبر درج کریں";
stringMap[strings.Input_Please_Enter_Verification_Code] = "!براہ کرم توثیقی کوڈ درج "
stringMap[strings.Input_Please_Enter_The_Password] = "!براہ کرم پاس ورڈ درج کریں";
stringMap[strings.Input_The_Password_Format_Is_Incorrect] = "!پاس ورڈ کی شکل غلط ہے";
stringMap[strings.Input_Please_Enter_A_New_Password] = "!براہ کرم نیا پاس ورڈ درج کریں";
stringMap[strings.Input_Confirm_The_New_Password] = "!براہ کرم تصدیق شدہ پاس ورڈ درج کریں";
stringMap[strings.Input_The_Passwords_Are_Inconsistent] = "!پاس ورڈ متضاد ہیں";
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] = "!براہ کرم درست توثیقی کوڈ درج کریں";
stringMap[strings.Input_The_Verification_Code_Error] = "!توثیقی کوڈ میں غلطی";

//مدد کا استعمال کرتے ہوئے
stringMap[strings.Mind_Usinghelp_1] = "ذہن کا نقشہ بنائیں";
stringMap[strings.Mind_Usinghelp_2] = "نوڈس کو کیسے شامل کریں";
stringMap[strings.Mind_Usinghelp_3] = "مفت نوڈ بنائیں";
stringMap[strings.Mind_Usinghelp_4] = "انجمن بنائیں";
stringMap[strings.Mind_Usinghelp_5] = "کینوس کو خوبصورت بنائیں";
stringMap[strings.Mind_Usinghelp_6] = "متن کو خوبصورت بنائیں";
stringMap[strings.Mind_Usinghelp_7] = "نوٹ ، لنکس داخل کریں";
stringMap[strings.Mind_Usinghelp_8] = "آئیکن داخل کریں";
stringMap[strings.Mind_Usinghelp_9] = "تصویر داخل کریں";
stringMap[strings.Mind_Usinghelp_10] = "حذف کرنے کا طریقہ";
stringMap[strings.Mind_Usinghelp_11] = "دماغ کا نقشہ برآمد کریں";
stringMap[strings.Mind_Usinghelp_12] = "ممبر اپ گریڈ";
stringMap[strings.Mind_Usinghelp_13] = "گرم چابی";
stringMap[strings.Mind_Usinghelp_Understood] = "یہ مل گیا";
stringMap[strings.Mind_Usinghelp_14] = "مادی لائبریری داخل کریں";
//مدد -2 کا استعمال کریں
stringMap[strings.Mind_Usinghelp_15] = "نیا نقشہ";
// stringMap[strings.Mind_Usinghelp_2] = "نوڈ شامل کریں";
// stringMap[strings.Mind_Usinghelp_4] = "انجمن بنائیں";
// stringMap[strings.Mind_Usinghelp_5] = "کینوس کو خوبصورت بنائیں";
// stringMap[strings.Mind_Usinghelp_6] = "متن کو خوبصورت بنائیں";
// stringMap[strings.Mind_Usinghelp_7] = "نوٹ ، لنکس شامل کریں";
stringMap[strings.Mind_Usinghelp_17] = "آپریشن کو کالعدم کریں ، منسوخ کریں";
stringMap[strings.Mind_Usinghelp_18] = "تبدیل کرنے کے لئے نوڈ کو گھسیٹیں";
stringMap[strings.Mind_Usinghelp_19] = "نوڈ سیدھ";
stringMap[strings.Mind_Usinghelp_20] = "نقشہ کی قسم تبدیل کریں";
stringMap[strings.Mind_Usinghelp_21] = "نقشہ لائن انداز";
stringMap[strings.Mind_Usinghelp_22] = "نقشہ کے پس منظر کا رنگ";
stringMap[strings.Mind_Usinghelp_23] = "نوڈ کی شکل سیٹ کریں";
stringMap[strings.Mind_Usinghelp_24] = "نوڈ لائن انداز";
stringMap[strings.Mind_Usinghelp_25] = "نوڈ تصویر کی ترتیبات";
stringMap[strings.Mind_Usinghelp_26] = "نوڈ آئکن کی ترتیبات";
stringMap[strings.Mind_Usinghelp_27] = "نوڈ کے اضافے کا خلاصہ";
stringMap[strings.Mind_Usinghelp_28] = "سنگل نوڈ فریم سلیکشن";
stringMap[strings.Mind_Usinghelp_29] = "ملٹی نوڈ بلاک";
stringMap[strings.Mind_Usinghelp_30] = "نوڈ بارڈر شامل کریں";
stringMap[strings.Mind_Usinghelp_31] = "ایک چارٹ شامل کریں";
stringMap[strings.Mind_Usinghelp_32] = "پوشیدہ نوڈس اور بازیافت";
stringMap[strings.Mind_Usinghelp_33] = "نوڈ متعدد کاروائیاں";
stringMap[strings.Mind_Usinghelp_34] = "انداز بدلیں";
stringMap[strings.Mind_Usinghelp_35] = "تقریر اور وضاحت داخل کریں";
stringMap[strings.Mind_Usinghelp_36] = "نقشہ تصویر کے لئے";
stringMap[strings.Mind_Usinghelp_37] = "نقشہ پی ڈی ایف دستاویز";
stringMap[strings.Mind_Usinghelp_38] = "نقشہ پرنٹ کریں";
stringMap[strings.Mind_Usinghelp_39] = "لنک شیئرنگ اور دیکھنا";
stringMap[strings.Mind_Usinghelp_40] = "طوفان میں نقشہ شیئرنگ";
stringMap[strings.Mind_Usinghelp_41] = "فولڈرز کا استعمال";
stringMap[strings.Mind_Usinghelp_42] = "نقشے کو حذف اور بحال کریں";
//مدد 3 استعمال کریں
stringMap[strings.Mind_Usinghelp_43] = "دوست گروپ میں شامل ہونے کے لئے درخواست دیتے ہیں";
stringMap[strings.Mind_Usinghelp_44] = "کسی گروپ کو تحلیل کرنے کا طریقہ";
stringMap[strings.Mind_Usinghelp_45] = "دستاویزات اپ لوڈ کریں";
stringMap[strings.Mind_Usinghelp_46] = "درخواست میں شامل ہونے پر اتفاق کریں";
stringMap[strings.Mind_Usinghelp_47] = "شریک ترمیم";
stringMap[strings.Mind_Usinghelp_48] = "نیا شیئرنگ گروپ";
stringMap[strings.Mind_Usinghelp_49] = "گروپ ممبران کو مدعو کریں";

//طوفان
stringMap[strings.Mind_Storm_Recommend] = "تجویز کریں";
stringMap[strings.Mind_Storm_Boilingpoint] = "نقطہ کھولاؤ";
stringMap[strings.Mind_Storm_Unfold] = "آشکار";
stringMap[strings.Mind_Storm_Putaway] = "دور رکھو";
stringMap[strings.Mind_Storm_Myclassification] = "میری درجہ بندی";
stringMap[strings.Mind_Storm_Recommended_Classification] = "تجویز کردہ درجہ بندی";
stringMap[strings.Mind_Storm_Click_Add_Category] = "زمرہ شامل کرنے کے لئے کلک کریں";
stringMap[strings.Mind_Storm_No_Classification] = "کوئی درجہ بندی نہیں";
stringMap[strings.Mind_Storm_No_Tinterested] = "دلچسپی نہیں";
stringMap[strings.Mind_Storm_Report] = "رپورٹ کریں";
stringMap[strings.Mind_Storm_Views] = "مناظر";

stringMap[strings.Mind_Storm_Pornographic] = "فحش فحاشی";
stringMap[strings.Mind_Storm_Advertising_Harassment] = "اشتہاری ہراساں کرنا";
stringMap[strings.Mind_Storm_Political_Religion] = "سیاسی مذہب";
stringMap[strings.Mind_Storm_Infringement] = "خلاف ورزی (پورٹریٹ ، بدنامی ، ادبي چوری ، جعلی استعمال)";
stringMap[strings.Mind_Storm_Prohibited_Content] = "ممنوعہ مواد";
stringMap[strings.Mind_Storm_Spread_Rumors] = "افواہیں پھیلائیں";
stringMap[strings.Mind_Storm_Report_Successful] = "رپورٹ کامیاب";

//ترمیم
stringMap[strings.Global_Edit] = "ترمیم";
stringMap[strings.Global_Cancel] = "منسوخ کریں";
stringMap[strings.Global_Ok] = "طے کریں";
stringMap[strings.Global_Name] = "عرفیت";
stringMap[strings.Global_Introduction] = "تعارف";
stringMap[strings.Global_Change_Avatar] = "اوتار تبدیل کریں";
stringMap[strings.Global_Local_Upload] = "مقامی اپ لوڈ";

//ذاتی معلومات
stringMap[strings.User_Personal_information] = "ذاتی معلومات";
stringMap[strings.User_Member_Validity_Period] = "رکنیت کی میعاد کی مدت";
stringMap[strings.User_My_Share] = "میرا حصہ";
stringMap[strings.User_My_Collection] = "میرا مجموعہ";
stringMap[strings.User_More_Message] = "مزید";
stringMap[strings.User_Deleted_Sotrm] = "طوفان کو حذف کریں";
stringMap[strings.User_Folder_Rename] = "نام تبدیل کریں";
stringMap[strings.User_Folder_Rename_Root] = "اجازت میں ترمیم کریں";
stringMap[strings.User_Folder_Rename_Root_Title] = "اجازت میں ترمیم کریں";
stringMap[strings.User_Folder_Root_Public] = "مکمل طور پر عوامی";
stringMap[strings.User_Folder_Root_Viewonly] = "عوامی فولڈر صرف دیکھنے کی اجازت دیتا ہے";
stringMap[strings.User_Folder_Root_Private] = "نجی";
stringMap[strings.User_Folder_Root_Public_Introduction] = "دوسرے آپ کے پروفائل پر آپ کے پسندیدہ صفحے کو دیکھ سکتے ہیں";
stringMap[strings.User_Message_Link] = "لنک";
stringMap[strings.User_New_Group] = "نیا گروپ";
stringMap[strings.User_New_Group_Title] = "ایک نیا گروپ بنائیں";
stringMap[strings.User_New_Group_Style_Title] = "فائل کی قسم";
stringMap[strings.User_Share_Is_Null] = "!ابھی تک کوئی حصہ نہیں";
stringMap[strings.User_Deleted_Storm_Tips] = "کیا آپ واقعی اس طوفان کو حذف کرنا چاہتے ہیں؟";
stringMap[strings.User_Remove_From_List] = "فہرست سے خارج کریں",
    stringMap[strings.User_Move_To] = "پر منتقل",
    stringMap[strings.User_Deleted_Link] = "لنک کو حذف کریں"
stringMap[strings.User_Collction_Is_Null] = "کوئی مجموعہ نہیں"
stringMap[strings.User_Collection] = "پسندیدہ"

//登录框
stringMap[strings.User_Lolgin_Welcome_To_Login] = "دماغ + میں خوش آمدید"
stringMap[strings.User_Lolgin_Free_Registration] = "مفت رجسٹریشن"
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] = "براہ کرم فون نمبر درج کریں"
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] = "براہ کرم توثیقی کوڈ درج کریں"
stringMap[strings.User_Lolgin_Obtain] = "حاصل کریں"
stringMap[strings.User_Lolgin_Password_Login] = "پاس ورڈ لاگ ان"
stringMap[strings.User_Lolgin_Login] = "لاگ ان کریں"
stringMap[strings.User_Lolgin_Register_An_Account] = "ایک اکاؤنٹ رجسٹر کریں"
stringMap[strings.User_Lolgin_Already_Have_An_Account] = "پہلے سے ہی ایک اکاؤنٹ ہے"
stringMap[strings.User_Lolgin_To_Log_In] = "لاگ ان کرنا"
stringMap[strings.User_Lolgin_Please_Enter_Password] = "براہ کرم پاس ورڈ درج کریں"
stringMap[strings.User_Lolgin_Confirm_Password] = "پاس ورڈ کی تصدیق کریں"
stringMap[strings.User_Lolgin_Registered] = "رجسٹرڈ"
stringMap[strings.User_Lolgin_Account_Login] = "اکاؤنٹ لاگ ان"
stringMap[strings.User_Lolgin_Scan_Code_Login] = "اسکین کوڈ لاگ ان"
stringMap[strings.User_Lolgin_Forgot_Password] = "پاسورڈ بھول گے"
stringMap[strings.User_Lolgin_Verification_Code_Login] = "توثیقی کوڈ لاگ ان"
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = "ترتیب انٹرفیس کے اوپری حصے میں مائینڈ + اور سوائپ کریں"
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = "یہ QR کوڈ پانچ منٹ میں ختم ہوجاتا ہے ، براہ کرم کلک کریں"
stringMap[strings.User_Lolgin_Refresh] = "ریفریش"
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = "اسکیننگ کامیاب ہے ، براہ کرم اپنے فون پر تصدیق کریں"
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] = "چاہے لاگ ان کو اختیار دیں"
stringMap[strings.User_Lolgin_Please_Enter_New_Password] = "براہ کرم نیا پاس ورڈ درج کریں"
stringMap[strings.User_Lolgin_Reset] = "ری سیٹ کریں"

//我的导图
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] = "ٹیمپلیٹ کی قسم یا ٹیمپلیٹ تلاش کریں"
stringMap[strings.My_Mind_Date] = "تاریخ"
stringMap[strings.My_Mind_Create_Folder] = "فولڈر بنائیں"
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] = "براہ کرم فولڈر کا نام درج کریں"
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] = "براہ کرم ایک نیا فولڈر نام درج کریں"
stringMap[strings.My_Mind__No_Map_Yet_Go] = "ابھی ابھی کوئی نقشہ نہیں ہے"
stringMap[strings.My_Mind_Create] = "بنانا"
stringMap[strings.My_Mind_Right] = "ٹھیک ہے"
stringMap[strings.My_Mind_Open] = "آن کر دو"
stringMap[strings.My_Mind_Type_Left_Right] = "ڈبل گائیڈ آریھ"
stringMap[strings.My_Mind_Type_Unilateral] = "یکطرفہ نقشہ"
stringMap[strings.My_Mind_Type_Bottom] = "تنظیم چارٹ"
stringMap[strings.My_Mind_Type_Bubble] = "بلبلا چارٹ"
stringMap[strings.My_Mind_Type_Time_Vertical] = "شیڈول چارٹ"
stringMap[strings.My_Mind_Blank_Mind_Map] = "خالی دماغ کا نقشہ"
stringMap[strings.My_Mind_All] = "سب"
stringMap[strings.My_Mind_Views] = "مناظر"

//风暴搜索
stringMap[strings.Storm_Search_How_Title] = "مشہور ٹیگز"
stringMap[strings.Storm_History_Search] = "تاریخی تلاش"

//公共 header
stringMap[strings.Public_Header_Mind] = "نقشہ"
stringMap[strings.Public_Header_Outline] = "خاکہ"
stringMap[strings.Public_Header_Download_Apps] = "ایپس ڈاؤن لوڈ کریں"
stringMap[strings.Public_Header_Mobile_Version] = "موبائل ورژن"
stringMap[strings.Public_Header_Creator] = "خالق"
stringMap[strings.Public_Header_View_Frequency] = "تعدد دیکھیں"
stringMap[strings.Public_Header_Save] = "محفوظ کریں"
stringMap[strings.Header_Mind_Tips_Saved] = "محفوظ"
stringMap[strings.Header_Mind_Tips_Edit] = "ترمیم شدہ"
stringMap[strings.Header_Mind_Tips_Update_Saved] = "تازہ کاری محفوظ ہوگئی"

//导图编辑菜单
stringMap[strings.Mind_Edit_Revoke] = "کالعدم کریں"
stringMap[strings.Mind_Edit_Restore] = "بحال"
stringMap[strings.Mind_Edit_Subtopic] = "سبٹوپک"
stringMap[strings.Mind_Edit_Top_Subtopic] = "سبٹوپک"
stringMap[strings.Mind_Edit_Bottom_Subtopic] = "اگلا عنوان"
stringMap[strings.Mind_Edit_Free_Node] = "مفت نوڈ"
stringMap[strings.Mind_Edit_Summary] = "خلاصہ"
stringMap[strings.Mind_Edit_Block] = "بلاک کریں"
stringMap[strings.Mind_Edit_Insert] = "داخل کریں"
stringMap[strings.Mind_Edit_Related] = "ایسوسی ایٹ"
stringMap[strings.Mind_Edit_Style] = "اسٹائل"
stringMap[strings.Mind_Edit_Recording] = "ریکارڈنگ"
stringMap[strings.Mind_Edit_Insert_Picture] = "تصویر داخل کریں"
stringMap[strings.Mind_Edit_Insert_Icon] = "آئیکن داخل کریں"
stringMap[strings.Mind_Edit_Insert_Remarks] = "نوٹ داخل کریں"
stringMap[strings.Mind_Edit_Insert_Link] = "لنک داخل کریں"
stringMap[strings.Mind_Edit_Insert_Summary_Graph] = "چارٹ داخل کریں"
stringMap[strings.Mind_Edit_Upload] = "اپ لوڈ کریں"
stringMap[strings.Mind_Edit_Right_Icon] = "آئیکن"
stringMap[strings.Mind_Edit_Right_Style] = "اسٹائل"
stringMap[strings.Mind_Edit_Audio_Decoding] = "...ضابطہ کشائی"
stringMap[strings.Mind_Edit_Audio_Duration] = "مدت"
stringMap[strings.Mind_Edit_Delete_Audio_Tips] = "کیا آپ واقعی ریکارڈ شدہ آڈیو کو حذف کرنا چاہتے ہیں؟"
stringMap[strings.Mind_Edit_Join_Membership] = "رکنیت میں شامل ہوں"
stringMap[strings.Mind_Edit_Upgrade] = "اپ گریڈ"
stringMap[strings.Mind_Edit_Upgrade_Item] = "ترقية برو"
stringMap[strings.Mind_Edit_Join_Membership_Tips] = "ممبر کے پاس ریکارڈنگ کا زیادہ وقت ہوسکتا ہے ، اب کسی ممبر میں اپ گریڈ کرنے کے لئے؟"

stringMap[strings.Mind_Edit_Choose_Material] = "مواد کا انتخاب کریں"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = "تصویر یہاں کھینچ کر لائیں"
stringMap[strings.Mind_Edit_Select_locally] = "مقامی طور پر منتخب کریں"
stringMap[strings.Mind_Edit_Choose_From] = "مادی لائبریری میں سے انتخاب کریں"
stringMap[strings.Mind_Edit_Mine] = "میرا"
stringMap[strings.Mind_Edit_My_Material] = "میرا ماد .ہ"
stringMap[strings.Mind_Edit_Recently_Used] = "حال ہی میں استعمال کیا جاتا ہے"
stringMap[strings.Mind_Edit_Use] = "استعمال کریں"
stringMap[strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = "!عارضی طور پر کوئی مواد نہیں ، اسے شامل کریں"
stringMap[strings.Mind_Edit_Select_Chart_Type] = "چارٹ کی قسم منتخب کریں"
stringMap[strings.Mind_Edit_Remarks] = "ریمارکس"
stringMap[strings.Mind_Edit_Remarks_Tips] = "برائے مہربانی ریمارکس درج کریں"
stringMap[strings.Mind_Edit_Add] = "میں شامل کریں"
stringMap[strings.Mind_Edit_Please_Enter_The_Link] = "براہ کرم لنک درج کریں"

//编辑页左侧菜单
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture] = "تصویر کو بچانے کے"
stringMap[strings.Mind_Edit_Left_Menu_Export_Pdf] = "پی ڈی ایف برآمد کریں"
stringMap[strings.Mind_Edit_Left_Menu_Share_Links] = "لنک شیئر کریں"
stringMap[strings.Mind_Edit_Left_Menu_Share_The_Storm] = "طوفان کو بانٹنا"
stringMap[strings.Mind_Edit_Left_Menu_Printing] = "پرنٹ کریں"
stringMap[strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = "مکمل ورژن کو غیر مقفل کریں"
stringMap[strings.Mind_Edit_Left_Menu_Help] = "مدد"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Format] = "فارمیٹ کو محفوظ کریں"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Png] = "png فارمیٹ"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = "jpg کی شکل"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = "ویبپی شکل"
stringMap[strings.Mind_Edit_Left_Menu_Share_Map] = "نقشہ شیئر کریں"
stringMap[strings.Mind_Edit_Left_Menu_Share_Switch] = "شیئر سوئچ"
stringMap[strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = "لنک کو کاپی کرکے بھیجا جاسکتا ہے"
stringMap[strings.Mind_Edit_Left_Menu_Copy_Link] = "لنک کاپی کریں"
stringMap[strings.Mind_Edit_Left_Menu_Follow_The_Link] = "اگر آپ لنک تبدیل کرنا چاہتے ہیں تو ، براہ کرم کلک کریں"
stringMap[strings.Mind_Edit_Left_Menu_Regenerate] = "دوبارہ تخلیق کرنا"
stringMap[strings.Mind_Edit_Left_Menu_Link_Password] = "لنک پاس ورڈ"
stringMap[strings.Mind_Edit_Left_Menu_Allow_Save] = "بچانے کی اجازت دیں"
stringMap[strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = "مناظر کی زیادہ سے زیادہ تعداد"
stringMap[strings.Mind_Edit_Left_Menu_Infinite] = "لامحدود"
stringMap[strings.Mind_Edit_Left_Menu_Limit] = "حد"
stringMap[strings.Mind_Edit_Left_Menu_Second] = "ٹائمز"
stringMap[strings.Mind_Edit_Left_Menu_Link_Validity] = "لنک کی میعاد کی مدت"
stringMap[strings.Mind_Edit_Left_Menu_1_Day] = "1 دن"
stringMap[strings.Mind_Edit_Left_Menu_3_Days] = "3 دن"
stringMap[strings.Mind_Edit_Left_Menu_7_Days] = "7 دن"
stringMap[strings.Mind_Edit_Left_Menu_Permanent] = "مستقل"
stringMap[strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = "مواد اور لنک کاپی کریں "
stringMap[strings.Mind_Edit_Left_Menu_Regenerate_Tips] = "کیا آپ واقعی دوبارہ تخلیق کرنا چاہتے ہیں؟ پچھلی لنک غلط ہو گا"
stringMap[strings.Mind_Edit_Left_Menu_Share] = "اسے بانٹئے"
stringMap[strings.Mind_Edit_Left_Menu_Custom_Category] = "اپنی مرضی کی درجہ بندی"
stringMap[strings.Mind_Edit_Left_Menu_Allow_Comments] = "چاہے تبصرے کی اجازت دی جائے"
stringMap[strings.Mind_Edit_Left_Menu_Edit_Add_Category] = "زمرہ شامل کریں میں ترمیم کریں"
stringMap[strings.Mind_Edit_Left_Menu_Membership_Rights] = "ممبر فوائد"
stringMap[strings.Mind_Edit_Left_Menu_Support_Platform] = "سپورٹ پلیٹ فارم"
stringMap[strings.Mind_Edit_Left_Menu_Open_Membership] = "رکنیت میں شامل ہوں"
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = "ایکٹیویشن کوڈ چھٹکارا"
stringMap[strings.Mind_Edit_Left_Menu_purchase_Tips] = "خریداری کے بعد ممبر کے حقوق"
stringMap[strings.Mind_Edit_Left_Menu_Payment_Type] = "ادائیگی کرنے کا طریقہ منتخب کریں"
stringMap[strings.Mind_Edit_Left_Menu_Activity_Price] = "سرگرمی کی قیمت"
stringMap[strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = "اصل میں ادا کی گئی رقم"
stringMap[strings.Mind_Edit_Left_Menu_Immediate_Renewal] = "ابھی تجدید کریں"
stringMap[strings.Mind_Edit_Left_Menu_Terms_Of_Service] = "سروس کی شرائط"
stringMap[strings.Mind_Edit_Left_Menu_Privacy_Policy] = "رازداری کی پالیسی"
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code] = "فعالیت کا کوڈ"
stringMap[strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = "براہ کرم ایکٹیویشن کوڈ درج کریں"
stringMap[strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = "ایکٹیویشن کوڈ کی تصدیق کریں"
stringMap[strings.Mind_Edit_Left_Menu_Select_Categories] = "زمرہ منتخب کریں"
stringMap[strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = "مرکزی عنوان خالی نہیں ہوسکتا"
stringMap[strings.Mind_Edit_Left_Menu_Coupon] = "کوپن"
stringMap[strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = "براہ کرم کوپن کوڈ درج کریں"
stringMap[strings.Mind_Confirm_Use_Coupons] = "کوپن کے استعمال کی تصدیق کریں"
stringMap[strings.Mind_Edit_Left_Menu_Verification] = "تصدیق"
stringMap[strings.Mind_Edit_Left_Menu_Preferential_Amount] = "رعایتی قیمت"
stringMap[strings.Mind_Edit_Left_Menu_Pay_Immediately] = "فوری طور پر ادائیگی"
stringMap[strings.Mind_Edit_Left_Menu_Wx_Pay] = "WeChat تنخواہ"
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = "چھٹکارے کی معلومات کی تصدیق کریں"
stringMap[strings.Mind_Edit_Left_Menu_Exchange_Code] = "چھٹکارا کوڈ"
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange] = "تبادلہ"

//编辑页右侧菜单
stringMap[strings.Mind_Edit_Right_Menu_canvas] = "کینوس"
stringMap[strings.Mind_Edit_Right_Menu_background_Color] = "پس منظر کا رنگ"
stringMap[strings.Mind_Edit_Right_Menu_layout] = "ترتیب"
stringMap[strings.Mind_Edit_Right_Menu_Line_Style] = "لائن اسٹائل"
stringMap[strings.Mind_Edit_Right_Menu_line_Color] = "لائن کا رنگ"
stringMap[strings.Mind_Edit_Right_Menu_Line_Width] = "لائن کی چوڑائی"
stringMap[strings.Mind_Edit_Right_Menu_Line_Tapering] = "ٹیپنگ"
stringMap[strings.Mind_Edit_Right_Menu_Theme_Font] = "تھیم فونٹ"
stringMap[strings.Mind_Edit_Right_Menu_Child_Font] = "چائلڈ فونٹ"
stringMap[strings.Mind_Edit_Right_Menu_text_Color] = "متن کا رنگ"
stringMap[strings.Mind_Edit_Right_Menu_font_Size] = "حرف کا سائز"
stringMap[strings.Mind_Edit_Right_Menu_Font_Style] = "لکھائی کا انداز"
stringMap[strings.Mind_Edit_Right_Menu_Border_Color] = "بارڈر رنگ"
stringMap[strings.Mind_Edit_Right_Menu_Fill_Color] = "رنگ بھریں"
stringMap[strings.Mind_Edit_Right_Menu_Border_Shape] = "بارڈر شکل"
stringMap[strings.Mind_Edit_Right_Menu_Border_Width] = "بارڈر چوڑائی"
stringMap[strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = "سائے کا اثر دکھائیں (سبسکرپشن فنکشن)"
stringMap[strings.Mind_Edit_Right_Menu_Line_Color] = "لائن کا رنگ"
stringMap[strings.Mind_Edit_Right_Menu_Dotted_Line] = "نقطہ دار لکیر"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Size] = "تصویر کا سائز"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Location] = "تصویر کا مقام"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Shape] = "تصویر کی شکل"
stringMap[strings.Mind_Edit_Right_Menu_Icon_Size] = "شبیہہ کا سائز"
stringMap[strings.Mind_Edit_Right_Menu_Icon_Position] = "شبیہ کی پوزیشن"
stringMap[strings.Mind_Edit_Right_Menu_routine] = "روایتی"
stringMap[strings.Mind_Edit_Right_Menu_priority] = "ترجیح"
stringMap[strings.Mind_Edit_Right_Menu_Progress] = "شیڈول"
stringMap[strings.Mind_Edit_Right_Menu_Stars] = "ستارہ"
stringMap[strings.Mind_Edit_Right_Menu_Week] = "ہفتہ"
stringMap[strings.Mind_Edit_Right_Menu_Head_Portrait] = "اوتار"
stringMap[strings.Mind_Edit_Right_Menu_3D] = "3D"
stringMap[strings.Mind_Edit_Right_Menu_Learning] = "سیکھیں"
stringMap[strings.Mind_Edit_Right_Menu_Work] = "نوکریاں"
stringMap[strings.Mind_Edit_Right_Menu_Characters] = "کردار"
stringMap[strings.Mind_Edit_Right_Menu_Animals] = "جانور"
stringMap[strings.Mind_Edit_Right_Menu_Food] = "کھانا"
stringMap[strings.Mind_Edit_Node_Menu_Copy_Style] = "کاپی اسٹائل"
stringMap[strings.Mind_Edit_Node_Menu_Sticking_Style] = "پیسٹ اسٹائل"
stringMap[strings.Mind_Edit_Node_Menu_Modify_Shape] = "شکل میں ترمیم کریں"
stringMap[strings.Mind_Edit_Node_Menu_View_Picture] = "تصویر دیکھنا"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Picture] = "تصویر حذف کریں"
stringMap[strings.Mind_Edit_Node_Menu_Copy] = "کاپی"
stringMap[strings.Mind_Edit_Node_Menu_Paste] = "چسپاں کریں"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Line] = "لائن کو حذف کریں"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = "حذف کریں"
stringMap[strings.Mind_Edit_Node_Menu_Edit_Remarks] = "نوٹ میں ترمیم کریں"
stringMap[strings.Mind_Edit_Node_Menu_Open_Link] = "لنک کھولیں"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Remarks] = "نوٹ حذف کریں"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Link] = "لنک کو حذف کریں"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Icon] = "آئیکن ڈیلیٹ کریں"
stringMap[strings.Mind_Edit_Node_Menu_Icon_Style] = "شبیہہ انداز"

//统计图编辑面板
stringMap[strings.Echart_Edit_Statistical] = "اعدادوشمار"
stringMap[strings.Echart_Edit_Value] = "قدر"
stringMap[strings.Echart_Edit_Color] = "رنگ"
stringMap[strings.Echart_Edit_Title] = "عنوان"
stringMap[strings.Echart_Edit_Detailed_Description] = "تفصیلی وضاحت"
stringMap[strings.Echart_Edit_Value_Sort] = "ترتیب دیں"
stringMap[strings.Echart_Edit_Value_Display_Type] = "ڈسپلے کی قسم"
stringMap[strings.Echart_Edit_Value_Color_Scheme] = "رنگنے کی منصوبہ بندی"
stringMap[strings.Echart_Edit_Value_Display_Size] = "ڈسپلے سائز"
stringMap[strings.Echart_Edit_Value_Title_Size] = "عنوان سائز"
stringMap[strings.Echart_Edit_Value_Explain_Size] = "سائز کی وضاحت کریں"
stringMap[strings.Echart_Edit_Value_Subscribe] = "رکنیت"

//风暴标签页
stringMap[strings.StormBookTag_Subscribed] = "سبسکرائب"
stringMap[strings.StormBookTag_List] = "فہرست"

//风暴分享页
stringMap[strings.Storm_Share_Comment] = "تبصرہ"
stringMap[strings.Storm_Share_Reply] = "جواب دیں"
stringMap[strings.Storm_Share_Let_Me_Comment_Too] = "مجھے بھی تبصرہ کرنے دیں~"
stringMap[strings.Storm_Share_Release] = "رہائی"
stringMap[strings.Storm_Share_Main_Title] = "مین عنوان"
stringMap[strings.Storm_Share_Subtitle] = "سب ٹائٹل"
stringMap[strings.Storm_Share_Author] = "مصنف"
stringMap[strings.Storm_Share_Replies] = "جوابات"
stringMap[strings.Storm_Share_Comment_Is_Null_Tips] = "ابھی تک کوئی تبصرہ نہیں ، پہلے آئیں"
stringMap[strings.Storm_Share_Comment_Input_Is_Null_Tips] = "براہ کرم ایک تبصرہ درج کریں~"
stringMap[strings.Storm_Share_Delete_Comment_Tips] = "کیا آپ واقعی اس تبصرے کو حذف کرنا چاہتے ہیں؟"

//导图内部使用帮助
stringMap[strings.Mind_Usinghelp_Btn_1] = "شارٹ کٹ کیز (ونڈوز)"
stringMap[strings.Mind_Usinghelp_Btn_2] = "شارٹ کٹ کی (میک)"
stringMap[strings.Mind_Usinghelp_Btn_3] = "تفصیل"
stringMap[strings.Mind_Usinghelp_Btn_4] = "[Ctrl] + [D]"
stringMap[strings.Mind_Usinghelp_Btn_5] = "[Command] + [D]"
stringMap[strings.Mind_Usinghelp_Btn_6] = "چائلڈ نوڈ شامل کریں"
stringMap[strings.Mind_Usinghelp_Btn_7] = "[Delet]"
stringMap[strings.Mind_Usinghelp_Btn_8] = "موجودہ نوڈ کو حذف کریں (نوٹ کریں کہ اگر اسے ترمیم کرنے کی حالت میں ہو تو اسے حذف نہیں کیا جاسکتا ہے)"
stringMap[strings.Mind_Usinghelp_Btn_9] = "[Ctrl] + [E]"
stringMap[strings.Mind_Usinghelp_Btn_10] = "[Command] + [E]"
stringMap[strings.Mind_Usinghelp_Btn_11] = "موجودہ نوڈ میں ترمیم کریں"
stringMap[strings.Mind_Usinghelp_Btn_12] = "[Ctrl] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_13] = "[Command] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_14] = "[Ctrl] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_15] = "[Command] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_16] = "نقشہ کو وسعت دیں"
stringMap[strings.Mind_Usinghelp_Btn_17] = "[Ctrl] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_18] = "[Command] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_19] = "نقشہ کو زوم بنائیں"
stringMap[strings.Mind_Usinghelp_Btn_20] = "[Ctrl] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_21] = "[Command] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_22] = "[Ctrl] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_23] = "[Command] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_24] = "[Ctrl] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_25] = "[Command] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_26] = "[Ctrl] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_27] = "[Command] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_28] = "ترمیم سے باہر نکلیں"

//提示文字
stringMap[strings.Message_Tips_Login_Success] = "لاگ ان کامیاب"
stringMap[strings.Message_Tips_Dlt_Success] = "کامیابی کے ساتھ حذف ہوگیا"
stringMap[strings.Message_Tips_Report_Success] = "رپورٹ کامیاب"
stringMap[strings.Message_Tips_Copy_Success] = "کامیابی کے ساتھ کاپی کریں"
stringMap[strings.Message_Tips_Collction_Success] = "جمع کرنے میں کامیابی"
stringMap[strings.Message_Tips_Avatar_Modification_Success] = "اوتار کامیابی کے ساتھ نظر ثانی کی گئی"
stringMap[strings.Message_Tips_Signature_Modification_Success] = "دستخط کامیابی کے ساتھ نظر ثانی کی گئی"
stringMap[strings.Message_Tips_Name_Modification_Success] = "عرفی نام کامیابی کے ساتھ تبدیل ہوا"
stringMap[strings.Message_Tips_Modification_Success] = "کامیابی کے ساتھ ترمیم کی گئی"
stringMap[strings.Message_Tips_New_Profile_Success] = "کامیابی کے ساتھ نیا فولڈر بنائیں"
stringMap[strings.Message_Tips_Mobile_Success] = "کامیابی کے ساتھ آگے بڑھا"
stringMap[strings.Message_Tips_Submit_Success] = "کامیابی کے ساتھ پیش کیا گیا"
stringMap[strings.Message_Tips_Exchange_Success] = "کامیاب چھٹکارا"
stringMap[strings.Message_Tips_Please_Code] = "براہ کرم ایکٹیویشن کوڈ درج کریں"
stringMap[strings.Message_Tips_Agree_To_Terms] = "براہ کرم رازداری کی پالیسی سے اتفاق کریں"
stringMap[strings.Message_Tips_Please_Sign_In] = "سائن ان کریں"
stringMap[strings.Message_Tips_Share_Success] = "کامیاب شیئرنگ"
stringMap[strings.Message_Tips_Create_Success] = "کامیابی کے ساتھ تشکیل دیا گیا"
stringMap[strings.Message_Tips_Profile_Name_No_Null] = "فولڈر کا نام خالی نہیں ہوسکتا"
stringMap[strings.Message_Tips_Selecte_Success] = "تلاش مکمل"
stringMap[strings.Message_Tips_Subscription_Success] = "کامیابی کے ساتھ سبسکرائب کیا"
stringMap[strings.Message_Tips_Unsubscribe_Success] = "کامیابی سے رکنیت ختم کریں"
stringMap[strings.Message_Tips_Related_Content_Not_Found] = "متعلقہ مواد نہیں ملا"
stringMap[strings.Message_Tips_Max_Add_Class] = "آپ صرف زیادہ سے زیادہ شامل کرسکتے ہیں"
stringMap[strings.Message_Tips_Registered_Success] = "اندراج کامیاب ہوا"
stringMap[strings.Message_Tips_Scan_Code_Login_Failed] = "اسکین کوڈ لاگ ان ناکام ہوگیا"
stringMap[strings.Message_Tips_Share_Is_Null] = "اشتراک کا لنک موجود نہیں ہے"
stringMap[strings.Message_Tips_Share_Link_Does_Not_Exist] = "اپ لوڈ فائل 3MB سے زیادہ نہیں ہوسکتی ہے"
stringMap[strings.Message_Tips_Get_Permission_Successfully] = "اجازت حاصل کرنے میں کامیاب"
stringMap[strings.Message_Tips_Membership_Required] = "رکنیت کی ضرورت ہے"
stringMap[strings.Message_Tips_Save_Success] = "کامیابی کے ساتھ محفوظ کیا گیا"
stringMap[strings.Message_Tips_Scan_Code_Login_Success] = "اسکین کوڈ لاگ ان کامیابی کے ساتھ"
stringMap[strings.Message_Tips_Style] = "درجہ بندی"

//大纲模式
stringMap[strings.Outline_Menu_Indent] = "انڈینٹیشن"
stringMap[strings.Outline_Menu_Decrease_Indent] = "انڈینٹیشن کم کریں"

//帮助提示
stringMap[strings.Help_Background_Content] = "لائن پس منظر سے متعلق,"
stringMap[strings.Help_Mind_Content] = "آپ اپنی ترجیحات کے مطابق مختلف ترتیبوں کے ساتھ نقشے بناسکتے ہیں: ڈبل گائیڈ نقشہ ، بائیں طرف کا نقشہ ، دائیں طرف کا نقشہ ، تنظیم کا چارٹ ، دو طرفہ درخت ، بائیں درخت ، دائیں درخت ، میز"
stringMap[strings.Help_Share_Link_Content] = "لنکس کی شکل میں وی چیٹ ، کیو کیو ، ویبو اور دوسرے چینلز میں اشتراک کے لنکس کا اشتراک کیا جاسکتا ہے ، اور دوست مواد دیکھ سکتے ہیں"
stringMap[strings.Using_Templates] = "ٹیمپلیٹس استعمال کریں"

//邀请码
stringMap[strings.User_Lolgin_Please_Enter_Invitation_Code] = "برائے کرم دعوت نامہ درج کریں"
stringMap[strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = "براہ کرم دعوت نامہ کا صحیح کوڈ درج کریں"
stringMap[strings.User_Lolgin_Optional] = "اختیاری"

//使用素材

stringMap[strings.Cannot_Add_To_Node_Background] = "نوڈ کے پس منظر پر لاگو نہیں ہے"
stringMap[strings.Cannot_Add_To_Img_Background] = "متن کے پس منظر کے لئے موزوں نہیں ہے"
stringMap[strings.Cannot_Add_To_Mind_Background] = "لائن پس منظر لپیٹنے کے لئے موزوں نہیں ہے"
stringMap[strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = "پس منظر کی تصویر شامل کریں"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = "پس منظر کی تصویر ہٹائیں"
stringMap[strings.Mind_Add_Background_Image] = "پس منظر کی تصویر داخل کریں"
stringMap[strings.Mind_Delete_Background_Image] = "پس منظر کی تصویر کو حذف کریں"

stringMap[strings.Permanent_Member] = "مستقل ممبر"
stringMap[strings.Insufficient_Storage_Space] = "ناکافی اسٹوریج اسپیس ، جگہ بڑھانے کے ل members ممبروں کو اپ گریڈ کریں۔"
stringMap[strings.Used_Space] = "اسٹوریج"
stringMap[strings.Current_Storage] = "موجودہ اسٹوریج"
stringMap[strings.Used_Space_Upgrade] = "اسٹوریج اسپیس اپ گریڈ"
stringMap[strings.My_Mind_Is_Null_Tips] = "دماغ + دماغ کا نقشہ اور نوٹ کریں سپر میمونکس ، سوچ کو پنکھ دیں تخلیق کریں ، ڈسپلے کریں ، پلان بنائیں ، نوٹ کریں"
stringMap[strings.Insufficient_Storage_Space_Tempalte_Tips] = "ذخیرہ کرنے کی ناکافی جگہ ، ممبروں کو ٹیمپلیٹ کے استعمال کے ل upgrade اپ گریڈ کریں۔"

stringMap[strings.Group_Management] = "گروپ"
stringMap[strings.Save_Img_Fill_Full] = "ہموار"
stringMap[strings.Save_Img_Center_Tilt] = "مرکز جھکاؤ"
stringMap[strings.Save_Img_File_Full_Dark_Color] = "چھپا ہوا (تاریک)"
stringMap[strings.Save_Img_Mind_Map_Watermarking] = "دماغ + دماغ کا نقشہ واٹر مارک"
stringMap[strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = "علم کی تخلیق کی حفاظت کے لئے واٹر مارک شامل کریں"
stringMap[strings.Save_Img_Watermark_Text] = "واٹر مارک متن"
stringMap[strings.Save_Img_Watermark_Logo] = "واٹر مارک لوگو"
stringMap[strings.Save_Img_Watermark_Style] = "واٹر مارک اسٹائل"
stringMap[strings.Save_Img_Please_Input] = "درج کریں"
stringMap[strings.Save_Img_Chop_Tips] = "واٹر مارک حقوق سے لطف اندوز ہونے کے لئے ممبر کو کھولیں"
stringMap[strings.Public_Header_Mindmap_Mind] = "نقشہ وضع"
stringMap[strings.Copy_Mind_Map] = "نقشہ کاپی کریں"
stringMap[strings.Font_Style_Bold] = "بولڈ"
stringMap[strings.Font_Style_Italics] = "ترچھا"
stringMap[strings.Font_Style_Delete_Line] = "ہڑتال"
stringMap[strings.Remove_Any] = "دور"

stringMap[strings.Mindmap_Review] = "نقشہ جائزہ"
stringMap[strings.Mindmap_Review_Recently_Updated_Content_10] = "تاثر کو بڑھاو اور میموری کو بڑھاو ، خیالات اور معلومات کو موثر انداز میں ترتیب دیں"
stringMap[strings.Mindmap_Review_Content] = "نقشہ جائزہ سے مراد ترمیم کے عمل کے پلے بیک سے ہوتا ہے ، اور مرکزی مرکزی خیال ، موضوع کو ترتیب سے کھیلا جاتا ہے؛ نقشہ جائزہ آپ کو سسٹم کی انوینٹری ، تجزیہ ، اور نظریات اور نقائص تلاش کرنے میں مدد فراہم کرسکتا ہے۔ جائزہ عمل کے دوران ، آپ کو مختلف الہامات مل سکتے ہیں ."
stringMap[strings.Mindmap_Review_Speed] = "رفتار"
stringMap[strings.Mindmap_Review_Word_Speed] = "متن کی رفتار"
stringMap[strings.Mindmap_Review_Background_Ratio] = "پس منظر کا تناسب"
stringMap[strings.Mindmap_Review_Play_Order_Peer] = 'أولوية المحتوى'
stringMap[strings.Mindmap_Review_Current_Playback_Node_Magnification] = "موجودہ پلے بیک نوڈ میں اضافہ"
stringMap[strings.Mindmap_Review_Node_Playback_Order] = "نوڈ پلے بیک آرڈر"
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap[strings.Mind_Group_Picture_Upload] = "اوتار اپ لوڈ"
stringMap[strings.Mind_Group_Upload_From_Local] = "مقامی اپ لوڈ"
stringMap[strings.Mind_Group_Mind_Mapping] = "دماغ پڑھنا"
stringMap[strings.Mind_Group_Information] = "ڈیٹا"

stringMap[strings.Mind_Group_Invite_New_Members] = "نئے ممبروں کو مدعو کریں"
stringMap[strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = "براہ کرم فون نمبر درج کریں"
stringMap[strings.Mind_Group_Cancel] = "منسوخ کریں"
stringMap[strings.Mind_Group_Confirm] = "طے کریں"
stringMap[strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = "فون نمبر خالی نہیں ہوسکتا"
stringMap[strings.Mind_Group_Invite_The_Successful] = "دعوت نامہ کامیاب"


stringMap[strings.Mind_Group_Shared_Group_Membership] = "مشترکہ گروپ ممبران"
stringMap[strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "مشترکہ گروپ ممبروں کی زیادہ سے زیادہ تعداد" 
stringMap[strings.Mind_Group_Membership_Limit] = "ممبر کی حد" 
stringMap[strings.Mind_Group_Current_Member_Limit] = "موجودہ ممبر کی حد" 
stringMap[strings.Mind_Group_Upgrade_Member_Limit] = "ممبر کی حد کو اپ گریڈ کریں"
stringMap[strings.Mind_Group_Members_Of_The_Search] = "ممبروں کی تلاش کریں"
stringMap[strings.Mind_Group_Creator] = "خالق"
stringMap[strings.Mind_Group_Administrator] = "ایڈمنسٹریٹر"
stringMap[strings.Mind_Group_Number_Of_People] = "لوگ"
stringMap[strings.Mind_Group_Selected] = "منتخب کیا"
stringMap[strings.Mind_Group_Member] = "رکن"
stringMap[strings.Mind_Group_Check_All] = "تمام منتخب کریں"
stringMap[strings.Mind_Group_Remove] = "دور"
stringMap[strings.Mind_Group_Invite_New_Members] = "نئے ممبروں کو مدعو کریں"
stringMap[strings.Mind_Group_Delete_Members] = "ممبر کو حذف کریں"
stringMap[strings.Mind_Group_Please_Select_The_Member_To_Delete] = "حذف کرنے کے لئے ممبر منتخب کریں "
stringMap[strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "کیا آپ واقعی صارف کو اس گروپ سے ہٹانا چاہتے ہیں؟"
stringMap[strings.Mind_Group_Add_As_Administrator] = "منیجر کو شامل کریں"
stringMap[strings.Mind_Group_Accomplish] = "انجام دیں"
stringMap[strings.Mind_Group_Compile] = "ترمیم"
stringMap[strings.Mind_Group_Group_Owner] = "رب"
stringMap[strings.Mind_Group_Add_Admin] = "منیجر کو شامل کریں"
stringMap[strings.Mind_Group_Group_ID] = "گروپ آئی ڈی"
stringMap[strings.Mind_Group_Group_Number] = "گروپ نمبر"
stringMap[strings.Mind_Group_Name] = "نام"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "براہ کرم شیئرنگ گروپ کا نام درج کریں"
stringMap[strings.Mind_Group_Introduce] = "تعارف"
stringMap[strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "براہ کرم شیئرنگ گروپ کو مختصر طور پر متعارف کروائیں"
stringMap[strings.Mind_Group_Announcement] = "اعلان"
stringMap[strings.Mind_Group_Shared_Group_Tag] = "مشترکہ گروپ لیبل"
stringMap[strings.Mind_Group_Custom_Tag] = "کسٹم لیبل"
stringMap[strings.Mind_Group_Save] = "محفوظ کریں"
stringMap[strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "گروپ کا نام خالی نہیں ہوسکتا"
stringMap[strings.Mind_Group_Establish] = "بنانا"
stringMap[strings.Mind_Group_Allows_Mapfolder_Creation] = "نقشے / فولڈر بنانے کی اجازت دیں"
stringMap[strings.Mind_Group_Allows_Editing_Of_Maps] = "نقشوں میں ترمیم کی اجازت دیں"
stringMap[strings.Mind_Group_Holder] = "سب"
stringMap[strings.Mind_Group_Only_The_Administrator] = "صرف ایڈمنسٹریٹر"
stringMap[strings.Mind_Group_Not_Allow] = "اجازت نہیں ہے"
stringMap[strings.Mind_Group_Allows_Map_Deletion] = "نقشہ کو حذف کرنے کی اجازت دیں"
stringMap[strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "فورمز کو پیغامات بھیجنے کی اجازت دیں"
stringMap[strings.Mind_Group_Export] = "برآمد کریں"
stringMap[strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "تصاویر / پی ڈی ایف کو محفوظ کرنے کی اجازت دیں"
stringMap[strings.Mind_Group_Open_Group_Watermark] = "گروپ واٹر مارک آن کریں"
stringMap[strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "کھولنے کے بعد ، گروپ کا لوگو دماغ کے نقشے پر پرنٹ ہوگا"
stringMap[strings.Mind_Group_Allow_Saving_Maps] = "نقشے کو بچانے کی اجازت دیں"
stringMap[strings.Mind_Group_Save_It_To_My_Map] = "میرے نقشے پر محفوظ کریں"
stringMap[strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "چاہے طوفان میں اشتراک کی اجازت دی جائے"
stringMap[strings.Mind_Group_Join_The_Way] = "شامل ہونے کا طریقہ"
stringMap[strings.Mind_Group_Whether_To_Invite_New_Members] = "چاہے نئے ممبروں کو مدعو کرنے کی اجازت دی جائے"
stringMap[strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "چاہے تلاش گروپ نمبر کو شامل ہونے دیں"
stringMap[strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "کیا آپ کو شامل ہونے کے وقت پیغام کی تصدیق کرنے کی ضرورت ہے؟"
stringMap[strings.Mind_Group_Shared_Group_Data] = "مشترکہ گروپ کی معلومات"
stringMap[strings.Mind_Group_Authority_Management] = "اتھارٹی مینجمنٹ"
stringMap[strings.Mind_Group_Quit_Sharing_Of_Group] = "مشترکہ گروپ چھوڑیں"
stringMap[strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "کیا آپ واقعی اشتراک گروپ چھوڑنا چاہتے ہیں؟"
stringMap[strings.Mind_Group_The_Dissolution_Of_Group] = "منقسم گروپ"
stringMap[strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "گروپ کو ختم کرنے کے بعد فائلیں حذف ہوجائیں گی۔ تحلیل ہونے کی تصدیق کریں؟"
stringMap[strings.Mind_Group_Return_To_Home_Page] = "ہوم پیج پر واپس جائیں"
stringMap[strings.Mind_Group_Join_The_Group] = "گروپ میں شامل ہوں"
stringMap[strings.Mind_Group_Please_Enter_The_Group_Number] = "برائے مہربانی تلاش کرنے کے لئے گروپ نمبر / گروپ کا نام درج کریں"
stringMap[strings.Mind_Group_Apply_To_Join] = "رکنیت کے لئے درخواستیں"
stringMap[strings.Mind_Group_Join] = "شامل ہوں"
stringMap[strings.Mind_Group_Verification] = "تصدیق"
stringMap[strings.Mind_Group_Please_Enter_A_Validation_Message] = "براہ کرم توثیقی پیغام داخل کریں"
stringMap[strings.Mind_Group_The_Content_Cannot_Be_Empty] = "مواد خالی نہیں ہوسکتا ہے"
stringMap[strings.Mind_Group_Name] = "مشترکہ گروپ کا نام"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "براہ کرم شیئرنگ گروپ کا نام درج کریں"
stringMap[strings.Mind_Group_Introduction] = "شیئرنگ گروپ کا تعارف"
stringMap[strings.Mind_Group_Group_Indication] = "گروپ نمبر"
stringMap[strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "تخلیق کار سست ہے ، میں نے ابھی اس کا تعارف نہیں کرایا ~"
stringMap[strings.Mind_Group_Shared_Group_Announcement] = "شیئرنگ گروپ اعلان"
stringMap[strings.Mind_Group_No_Announcement] = "کوئی اعلان نہیں"
stringMap[strings.Mind_Group_Click_On] = "وسعت کے لئے کلک کریں"
stringMap[strings.Mind_Group_Click_On_The_Hidden] = "چھپانے کے لئے کلک کریں"
stringMap[strings.Mind_Group_Altogether] = "کل"
stringMap[strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "گروپ نمبر کامیابی کے ساتھ کاپی کریں"
stringMap[strings.Mind_Group_Stick] = "اوپر"
stringMap[strings.Mind_Group_There_Is_A_New_Content] = "نیا مواد"
stringMap[strings.Mind_Group_Cancel_The_Top] = "پن کھولیں"
stringMap[strings.Mind_Group_Setting_Up] = "سیٹ اپ کریں"
stringMap[strings.Mind_Group_Usinghelp] = "مدد کا استعمال کرتے ہوئے"
stringMap[strings.Mind_Group_Set_The_Message] = "گروپ میسج"
stringMap[strings.Mind_Group_Apply_To_Join] = "رکنیت کے لئے درخواستیں"
stringMap[strings.Mind_Group_Invite_To_Join] = "شمولیت کی دعوت دیں"
stringMap[strings.Mind_Group_Consent] = "متفق ہوں"
stringMap[strings.Mind_Group_Turn_Down] = "انکار"
stringMap[strings.Mind_Group_Label] = "لیبل"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "براہ کرم مشترکہ گروپ لیبل کا نام درج کریں"
stringMap[strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "دوستوں اور ساتھیوں کو دعوت دیں کہ وہ مشترکہ معلومات تخلیق کریں اور شیئر کریں"
stringMap[strings.Mind_Group_Immediately_Create] = "ابھی بنائیں"
stringMap[strings.Mind_Group_Immediately_To_Join] = "ابھی شامل ہوں"

stringMap[strings.Currently_Selected] = "موجودہ توجہ"
stringMap[strings.LabelManagement] = "لیبل مینجمنٹ"
stringMap[strings.NoAttention] = "کوئی توجہ نہیں"
stringMap[strings.Mindmap_Review_Play] = "کھیلیں"

stringMap[strings.Mind_Group_Joined] = "شامل ہوئے"
stringMap[strings.Mind_Group_Download_App] = "اے پی پی ڈاؤن لوڈ کریں"
stringMap[strings.Mind_Group_No_Content] = "ابھی تک کوئی مواد نہیں ہے"
stringMap[strings.Mind_Group_Individual] = "A"
stringMap[strings.Mind_Group_Message_Area] = "پیغام کا علاقہ"
stringMap[strings.Mind_Group_No_Message] = "ابھی تک کوئی تبصرہ نہیں ، جاؤ"
stringMap[strings.Mind_Group_Leave_Message] = "ایک پیغام چھوڑ دو"
stringMap[strings.Mind_Group_Bar] = "بار"
stringMap[strings.Mind_Group_See_More] = "دیکھیں مزید"
stringMap[strings.Mind_Group_Enter_Invitation_Code] = "دعوت کا کوڈ درج کریں"
stringMap[strings.Mind_Group_Apply_To_Join_Sharing_Group] = "مشترکہ گروپ میں شامل ہونے کے لئے درخواست دیں"
stringMap[strings.Reasons_For_Application_0_30_Words] = "درخواست کی وجہ (0-30 الفاظ)"
stringMap[strings.Mind_Group_Send_Out] = "بھیجیں"
stringMap[strings.Mind_Group_Join_Successfully] = "کامیابی کے ساتھ شامل ہوں"
stringMap[strings.Mind_Group_Application_Sent_Successfully] = "申请发送成功"

stringMap[strings.Mind_Group_Save_The_Mapping] = "نقشہ محفوظ کریں"
stringMap[strings.Mind_Group_Update_Record] = "ریکارڈ میں ترمیم کریں"

stringMap[strings.Mind_Edit_Page_Switch_Mapping] = "نقشہ سوئچ کریں"
stringMap[strings.Mind_Edit_Page_Mind_Retrospect] = "导图回顾"
stringMap[strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "ایک موجودہ صارف نقشہ میں ترمیم کر رہا ہے"
stringMap[strings.Mind_Edit_Page_Password] = "پاس ورڈ"

stringMap[strings.Mind_Edit_Page_Sharing_Group] = "مشترکہ گروپ"
stringMap[strings.Mind_MBean] = "ایم پھلیاں"
stringMap[strings.Mind_My_MBean] = "میری ایم بین"
stringMap[strings.Alipay] = "الپے"
stringMap[strings.Alipay_Payment] = "علی پے کے ساتھ ادائیگی کریں"
stringMap[strings.Please_Pay_On_The_Payment_Page] = "برائے کرم ادائیگی کے صفحے پر جائیں"
stringMap[strings.WeChat] = "ویکیٹ"
stringMap[strings.Mind_Shooping_Total] = "کل"
stringMap[strings.Mind_Shooping_Confirm_Payment] = "ادائیگی کی تصدیق"
stringMap[strings.Mind_Shooping_Redeem_Now] = "اب چھڑا"

stringMap[strings.Mind_Whether_Confirm_Payment_Purchase] = "چاہے ادائیگی اور خریداری کی تصدیق کی جائے"
stringMap[strings.Mind_Tips] = "فوری طور پر"
stringMap[strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "آپ کے ایم پھلیاں کافی نہیں ہیں ، کیا آپ ایم پھلیاں خریدنا چاہتے ہیں؟"

stringMap[strings.Mind_share_scan_code] = "دماغ + اسکین کوڈ"

stringMap[strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "کیا یہ اس جگہ پر بحال ہوا ہے جہاں یہ آخری بار کھیلا گیا تھا؟"
stringMap[strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "باقی رہنے کے قابل اوقات"

stringMap[strings.Group_Mind_Play_The_File_Will_Not_Exist] = "فائل مکمل طور پر حذف ہوجائے گی ، کیا آپ واقعی اسے تحلیل کرنا چاہتے ہیں؟"
stringMap[strings.Group_Mind_Up_One_Level] = "اوپر کا درجہ"
stringMap[strings.Group_Mind_No_Documents] = "فائلیں نہیں"
stringMap[strings.Group_Mind_PaymentSuccessful] = "ادائیگی کامیاب"

stringMap[strings.Mind_Edit_Page_Sharing_Group_introduce] = "لوگوں کے ذریعہ باہمی تعاون کے ساتھ ترمیم ، متعدد ٹرمینلز کی اصل وقت ہم آہنگی ، دستاویزات کا اشتراک ، ٹیم کے تعاون کی کارکردگی کو بہتر بنانا"

stringMap[strings.Mind_Edit_Subscript] = "سبسکرپٹ"
stringMap[strings.Mind_Edit_Text_Location] = "متن کی پوزیشن"
stringMap[strings.Mind_Mind_Import_Into_A_Shared_Group] = "مشترکہ گروپ میں درآمد کریں"

stringMap[strings.Standard_Colors] = "معیاری رنگ"
stringMap[strings.More_Colors] = "زیادہ رنگ"
stringMap[strings.Reset_Password] = "پاس ورڈ ری سیٹ"
stringMap[strings.Account_Password_Login] = "اکاؤنٹ کا پاس ورڈ لاگ ان"
stringMap[strings.Authentication_code_Login_registration] = "توثیقی کوڈ لاگ ان / رجسٹر"
stringMap[strings.One_End_Creation_Multi_End_Synchronization] = "ایک اختتام کی تخلیق ، ملٹی اینڈ سنکرونائزیشن"
stringMap[strings.Rich_And_Diverse_Mapping_Structure] = "امیر اور متنوع نقشے کی ساخت"
stringMap[strings.Massive_And_ExquisiteTemplateLibrary] = "بڑے پیمانے پر شاندار ٹیمپلیٹ لائبریری"
stringMap[strings.Map_Review_Play] = "نقشہ کا جائزہ لیں"
stringMap[strings.CommunityLearningAndSharingPlatform] = "علم سیکھنے اور اشتراک تبادلہ پلیٹ فارم"

stringMap[strings.Mind_Mind_Upgrade_Storage_Space] = "اسٹوریج اسپیس کو اپ گریڈ کریں"
stringMap[strings.Mind_Mind_Let_Me_Comment_Too] = "مجھے بھی تبصرہ کرنے دیں"
stringMap[strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "کوئی خبر نہیں"
stringMap [strings.Failed_To_Get_Recording_Device] = "ریکارڈنگ ڈیوائس حاصل کرنے میں ناکام"

stringMap [strings.Search_Pagination] = "صفحہ تلاش کریں"
stringMap [strings.Please_Enter_A_Paging_Name] = "براہ کرم صفحہ کا نام درج کریں"
stringMap [strings.Operation_Settings] = "آپریشن کی ترتیبات"
stringMap [strings.Shortcut_key_Settings] = "شارٹ کٹ کلیدی ترتیبات"
stringMap [strings.Press_Key_Combination] = "براہ کرم مطلوبہ کلیدی مجموعہ دبائیں"
stringMap [strings.Mind_Reduction] = "کمی"
stringMap [strings.Double_Click_Add_Free_Node] = "مفت نوڈ شامل کرنے کے لیے خالی جگہ پر ڈبل کلک کریں"
stringMap [strings.Shortcut_Conflict] = "شارٹ کٹ کلیدی تنازعہ"
stringMap [strings.Select_Main_Node] = "مین نوڈ منتخب کریں"
stringMap [strings.Telephone] = "فون"
stringMap [strings.Microblog] = "Microblog"
stringMap [strings.Address] = "پتہ"
stringMap [strings.Company_Address] = "بلاک بی ، بلڈنگ 21 ، نمبر 1158 ژونگ زین روڈ ، سونگ جیانگ ضلع ، شنگھائی (سونگ جیانگ لینگ کاؤجنگ)"
stringMap [strings.Mind_Archive] = "محفوظ شدہ دستاویزات"
stringMap [strings.Mind_Bookshelf] = "کتابوں کی الماری"
stringMap [strings.Search_Archive] = "کتابوں کی الماری تلاش کریں"
stringMap [strings.Modify_Archive_Order] = "بک شیلف آرڈر میں ترمیم کریں"
stringMap [strings.Modification_Order] = "ترمیم کا حکم"
stringMap [strings.Please_Enter_Archive_Name] = "براہ کرم بک شیلف کا نام درج کریں"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "بک شیلف کا نام خالی نہیں ہو سکتا"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "کیا آپ واقعی اس کتابوں کی الماری کو حذف کرنا چاہتے ہیں؟"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "کیا آپ واقعی اس کتابوں کی الماری کو حذف کرنا چاہتے ہیں؟"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "کیا آپ واقعی یہ نقشہ حذف کرنا چاہتے ہیں؟"
stringMap [strings.Mind_Edit_Confirm] = "تصدیق"
stringMap [strings.Mind_Import_Map] = "نقشہ درآمد کریں"
stringMap [strings.Mind_Bookcase] = "کتابوں کی الماری"
stringMap [strings.Added_Successfully] = "کامیابی کے ساتھ شامل کیا گیا"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "بک کیس کا نام خالی نہیں ہو سکتا"

stringMap [strings.Mind_Association_Map] = "ایسوسی ایشن کا نقشہ"
stringMap [strings.Mind_confirm_Association_Map] = "کیا آپ اس نقشے کو منسلک کرنے کا یقین رکھتے ہیں؟"
stringMap [strings.Mind_Association_Map_See] = "ایسوسی ایشن کا نقشہ دیکھیں"
stringMap [strings.Mind_Association_Map_Delete] = "ایسوسی ایشن کا نقشہ حذف کریں"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "Correlation Line Structure"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "ارتباط لائن کا آغاز"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "ارتباط لائن کا اختتام"

stringMap [strings.Share_Group_To_Storm] = "گروپ کو طوفان میں بانٹنا ہے"
stringMap [strings.Apply_To_Join_Storm_Platform] = "طوفان کے پلیٹ فارم پر ، دوسرے لوگ دیکھتے ہیں اور شامل ہونے کے لیے درخواست دیتے ہیں ، عام علم کے ساتھ شائقین کے ساتھ ذہن سازی کرتے ہیں"
stringMap [strings.Pay_To_Goin] = "شمولیت کے لیے ادائیگی کریں"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "شمولیت کے وقت M پھلیاں ادا کرنے کی ضرورت ہے"
stringMap [strings.Mbean_Can_Be_Realized] = "Mbean Realized"

stringMap [strings.Mind_Visit] = "وزٹ"
stringMap [strings.Mind_Details] = "تفصیلات"
stringMap [strings.Mind_See] = "دیکھیں"
stringMap [strings.Earn_Mbeans] = "Mbeans کمائیں"
stringMap [strings.Mind_Explain] = "تفصیل"
stringMap [strings.Mind_Parenting] = "والدین"
stringMap [strings.Mind_Internet] = "انٹرنیٹ"
stringMap [strings.Mind_Psychology] = "نفسیات"
stringMap [strings.Mind_literature] = "ادب"
stringMap [strings.Mind_Technology] = "ٹیکنالوجی"
stringMap [strings.Mind_Workplace] = "کام کی جگہ"
stringMap [strings.Mind_Life] = "زندگی"
stringMap [strings.Mind_Subject] = "موضوع"
stringMap [strings.Hot_Group] = "ہاٹ گروپ"
stringMap [strings.Open_Group] = "اوپن گروپ"
stringMap [strings.Mind_Group_Used] = "استعمال شدہ"

stringMap [strings.Mind_Click_Select] = "منتخب کرنے کے لیے کلک کریں"
stringMap [strings.Mind_Double_Click_Enter] = "داخل ہونے کے لیے ڈبل کلک کریں"
stringMap [strings.Search_Shared_Groups] = "مشترکہ گروپ تلاش کریں"
stringMap [strings.Search_Storm] = "تلاش کا طوفان"
stringMap [strings.Members_Save_Free] = "ممبر مفت محفوظ کریں"
stringMap [strings.Bank_Card_Account_Name] = "براہ کرم اپنے بینک کارڈ اکاؤنٹ کا نام درج کریں"
stringMap [strings.Bank_Card_No] = "بینک کارڈ نمبر"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "براہ کرم اپنا بینک کارڈ نمبر درج کریں"
stringMap [strings.Branch_Sub_Branch_Information] = "برانچ برانچ انفارمیشن"
stringMap [strings.Branch_Sub_Branch_Example] = "مثال کے طور پر: ہانگجو برانچ چینگسی ذیلی شاخ"
stringMap [strings.Application_For_Withdrawal] = "واپسی کے لیے درخواست"
stringMap [strings.Withdrawal_Amount] = "واپسی کی رقم"
stringMap [strings.Withdrawal] = "واپسی"
stringMap [strings.Current_Revenue] = "موجودہ آمدنی"
stringMap [strings.Recharge] = "ریچارج"
stringMap [strings.Current_Total_MBeans] = "MBeans کی موجودہ کل تعداد"
stringMap [strings.Withdrawal_Status] = "واپسی کی حیثیت"
stringMap [strings.Insuffic_Withdrawal_Amount] = "ناکافی رقم نکلوانا"
stringMap [strings.Load_More] = "مزید لوڈ کریں"
stringMap [strings.Loading] = "لوڈ ہو رہا ہے"
stringMap [strings.It_Is_Over] = "یہ ختم ہو گیا ہے"
stringMap [strings.Default_Label] = "ڈیفالٹ لیبل"
stringMap [strings.Selected_Label] = "منتخب لیبل"
stringMap [strings.Current_Location] = "موجودہ مقام"
stringMap [strings.MGold_Introduction] = "M سونا آپ نے دماغ کے ذریعے حاصل کیا ہے"
stringMap [strings.MGold_Details] = "آپ کا بامعاوضہ کلاؤڈ گروپ ، گروپ میں علمی نقشے ، سیکھنے کا سامان وغیرہ موجود ہیں۔ اگر صارفین ادائیگی ایم پھلیاں میں شامل ہو جائیں تو ان میں سے 50 فیصد ایم گولڈ میں تبدیل ہو جائیں گے ، اور ایم گولڈ زیادہ 50 سے زائد رقم واپس لی جا سکتی ہے "
stringMap [strings.Cloud_Group_Introduction] = "اپنے گروپ کا اشتراک کریں ، دوسرے اسے طوفان کے صفحے پر دیکھ سکتے ہیں"
stringMap [strings.Cloud_Group_Details] = "آپ کا بامعاوضہ کلاؤڈ گروپ۔ گروپ میں علمی نقشے ، سیکھنے کا سامان وغیرہ موجود ہیں۔ اگر صارفین ادائیگی ایم پھلیاں میں شامل ہو جائیں تو ان میں سے 50 فیصد ایم گولڈ میں تبدیل ہو جائیں گے ، اور ایم گولڈ زیادہ 50 سے زیادہ نکالا جا سکتا ہے "
stringMap [strings.Share_Storm_Introduction] = "اپنے نقشے کو طوفانی کمیونٹی میں بانٹیں ، تاکہ زیادہ سے زیادہ لوگ آپ سے علم سیکھ سکیں اور دریافت کر سکیں"
stringMap [strings.Share_Storm_Details] = "اگر آپ نقشوں کو محفوظ کرنے کے لیے M پھلیاں استعمال کرنے کے قابل بناتے ہیں تو دوسرے صارف آپ کے علمی نقشے کو استعمال کر سکتے ہیں ، اور آپ کو 50 فیصد M پھلیاں بھی ملیں گی۔ گروپ ، آپ دوسرے لوگوں کے نقشے استعمال کرنے کے لیے بھی ادائیگی کر سکتے ہیں۔ "

stringMap [strings.Linked_Successfully] = "کامیابی سے لنک کیا گیا"
stringMap [strings.Cancel_Archiving] = "آرکائیو منسوخ کریں"
stringMap [strings.Please_Select_Archive_Location] = "برائے مہربانی آرکائیو لوکیشن منتخب کریں"
stringMap [strings.The_Search_Result_Is_Empty] = "تلاش کے نتائج خالی ہیں"

stringMap [strings.Customize_A_Style] = "غیر ممبر صرف ایک سٹائل کو اپنی مرضی کے مطابق بنا سکتے ہیں"
stringMap [strings.Adds_Current_Guide_Style_Library] = "سٹائل لائبریری میں موجودہ نقشہ سٹائل شامل کریں"
stringMap [strings.Trial_Version] = "آزمائشی ورژن"
stringMap [strings.Not_Supported_At_The_Moment] = "فی الحال تعاون یافتہ نہیں"
stringMap [strings.Mind_File] = "فائل"
stringMap [strings.Mind_Import] = "درآمد"
stringMap [strings.Importing] = "درآمد"

stringMap [strings.Mind_Edit_Insert_Video] = "ویڈیو داخل کریں"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "ویڈیو کو یہاں گھسیٹیں اور چھوڑیں"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "اپ لوڈ ویڈیو کا دورانیہ 60s سے زیادہ نہیں ہو سکتا"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "ویڈیو دیکھیں"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "ویڈیو حذف کریں"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "اپ لوڈ کردہ ویڈیو 10MB سے زیادہ نہیں ہو سکتی"
stringMap [strings.Mind_Upload_Video_Restrictions] = "صرف MP4 فارمیٹ میں ویڈیوز اپ لوڈ کرنے کی حمایت کرتا ہے ، 120s اور 10M سائز تک"
stringMap [strings.Mind_Video_Size] = "ویڈیو سائز"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "لفظ برآمد کریں"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "TXT برآمد کریں"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap [strings.Export_Failed] = "برآمد ناکام"
stringMap [strings.Add_File] = "فائل شامل کریں"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "اپ لوڈ کرنے کے لیے براہ راست فائل میں گھسیٹیں"
stringMap [strings.Supported_Imported_File_Types] = "درآمد کے لیے فی الحال تعاون یافتہ فائل کی اقسام: ورڈ (صرف .docx) ، XMind ، .txt"
stringMap [strings.No_Permission_Do_This] = "آپ کو یہ آپریشن کرنے کی اجازت نہیں ہے"

stringMap [strings.Upgrade_To_Professional] = "پروفیشنل ایڈیشن میں اپ گریڈ کریں"
stringMap [strings.Non_Members_Insert_Videos] = "غیر ممبران صرف اندراج کے 24 گھنٹے بعد ویڈیو داخل کر سکتے ہیں"
stringMap [strings.Upload_Failed] = "اپ لوڈ ناکام"
stringMap [strings.Upload_Failed_Title_Message] = "اپ لوڈ ناکام ، براہ کرم تصدیق کرنے کے لیے کلک کریں ، باقی تصاویر جو کہ اپ لوڈ کرنے میں ناکام رہیں حذف کریں"

stringMap [strings.Mind_Edit_Members_Use] = "ممبران استعمال کرتے ہیں"
stringMap [strings.Invitation_Code] = "دعوت نامہ"
stringMap [strings.Student_Certification] = "طالب علم سرٹیفیکیشن"
stringMap [strings.I_Get_It] = "میں کروں گا ، گائیڈ چھوڑ دو"
stringMap [strings.Welcome_To_Mind] = "نوجوان ماسٹر کو Mind+میں شامل ہونے پر خوش آمدید"
stringMap [strings.Mind_Before] = "پہلا"
stringMap [strings.Try_A_Map] = "نقشہ آزمائیں"
stringMap [strings.Click_Create] = "بنانے کے لیے کلک کریں"
stringMap [strings.Click_Create_A_Mind_Map] = "ذہن کا نقشہ بنانے کے لیے کلک کریں"
stringMap [strings.Mind_About] = "کے بارے میں"
stringMap [strings.See_Checking_Data] = "ذاتی معلومات دیکھیں"
stringMap [strings.Modify_Checking_Data] = "ذاتی معلومات میں ترمیم کریں"
stringMap [strings.Mind_Shear] = "شیئر"

stringMap [strings.Mind_Style_Empty] = "کوئی پسندیدہ یا اپنی مرضی کا انداز نہیں"
stringMap [strings.Mind_Branch_Free_Layout] = "برانچ فری لے آؤٹ"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "دو طرفہ ڈھانچہ اور بلبلے نقشے کی شاخوں کو آزادانہ طور پر لے آؤٹ میں گھسیٹا جا سکتا ہے"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "مفت تھیم ڈریگ سنیپ"

stringMap [strings.Select_A_Node] = "ایک موضوع منتخب کریں"
stringMap [strings.Create_Child_Node] = "چائلڈ تھیم بنائیں"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "اگلا ہم چائلڈ تھیم میں ایک اچھا شامل کریں گے"
stringMap [strings.Click_Icon_Panel] = "آئیکن پینل پر کلک کریں"
stringMap [strings.Select_An_Icon_You_Like] = "اپنی پسند کا آئیکن منتخب کریں"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "اگلا ہم ذہن کا نقشہ ایک نئے سے تبدیل کریں گے"
stringMap [strings.Select_The_Central_Theme] = "مرکزی موضوع منتخب کریں"
stringMap [strings.Switch_To_Canvas_Panel] = "کینوس پینل پر سوئچ کریں"
stringMap [strings.Click_Layout] = "لے آؤٹ پر کلک کریں"
stringMap [strings.Choose_A_Layout_You_Like] = "اپنی پسند کا لے آؤٹ منتخب کریں"
stringMap [strings.Mind_Map_Save_Share] = "مبارک ہو ، آپ نے کامیابی سے ایک خوبصورت ذہن کا نقشہ بنایا ہے! آئیے اسے محفوظ کریں اور اپنے دوستوں کے ساتھ شیئر کریں ~"
stringMap [strings.Click_Save_Picture] = "تصویر کو محفوظ کرنے کے لیے کلک کریں"
stringMap [strings.Click_OK] = "ٹھیک ہے پر کلک کریں"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "نوسکھئیے گائیڈ مکمل کرنے پر مبارکباد"
stringMap [strings.Upload_Succeeded] = "کامیابی سے اپ لوڈ ہوا"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "فوری انداز"
stringMap [strings.Mind_Extremely_Impferent] = "انتہائی اہم"
stringMap [strings.Mind_Impferent] = "اہم"
stringMap [strings.Mind_Commonly] = "جنرل"
stringMap [strings.Mind_Ordinary] = "عام"
stringMap [strings.Member_Exclusive] = "یہ خصوصیت ممبروں کے لیے خصوصی ہے۔ کیا آپ اپنی رکنیت کو اپ گریڈ کرنا چاہتے ہیں؟"
stringMap [strings.Member_Exclusive_Color_Scheme] = "یہ رنگ سکیم ممبروں کے لیے خصوصی ہے۔ کیا آپ اپنی رکنیت کو اپ گریڈ کرنا چاہتے ہیں؟"
stringMap [strings.Mind_Enlarge] = "بڑا کریں"
stringMap [strings.Mind_Narrow] = "تنگ"
stringMap [strings.Mind_Center] = "مرکز"
stringMap [strings.Mind_Full_Screen] = "فل سکرین"

stringMap [strings.Mind_Association_Node] = "ایسوسی ایشن نوڈ"

stringMap[strings.Mind_Resource_See] = "ملحقہ دیکھیں"
stringMap[strings.Mind_Resource_Delete] = "منسلکات کو حذف کریں"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "فائلوں کو یہاں گھسیٹیں اور چھوڑیں"
stringMap[strings.Mind_Edit_choose_explain] = "فائل کا انتخاب کریں"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "اپ لوڈ کردہ فائل 20MB سے زیادہ نہیں ہو سکتی"

stringMap[strings.Please_Enter_Mind_Password] = "الرجاء إدخال 4-8 بت كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "الرجاء إدخال كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "هذه الوظيفة هي وظيفة الأعضاء"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "ملف كلمة السر قد أزيلت"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "ليس هناك ما يكفي من أرقام كلمة السر ، الرجاء إدخال 4 إلى 8 أرقام كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "كلمة السر طويلة جدا ، الرجاء إدخال 4 عكس 8 بت كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_Success] = "تعيين كلمة المرور بنجاح"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "شكل كلمة السر خطأ"
stringMap[strings.Mind_Load_Data_Error] = "تحميل البيانات خطأ"
stringMap[strings.Please_Enter_Mind_Password_Error] = "كلمة السر خطأ"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "خريطة الخالق فقط يمكن تعيين كلمة السر"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;
