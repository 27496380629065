// package mind.yushu.com.mindmap.viewmodel.core.base;

// import java.util.List;

// import mind.yushu.com.mindmap.utils.Colors;

import Colors from "../../../utils/Colors"
import MindElementFullType from "../../datatype/MindElementFullType";
import MindElementShapeType from "../../datatype/MindElementShapeType";
import OrderType from "../../datatype/OrderType";

/**
 * Created by tony on 2019/12/26
 */

class MindMapStyleColor {
    constructor(selectedIndex = 3) {
        if (selectedIndex == null) {
            selectedIndex = 3
        } else if ((typeof selectedIndex) == "string") {
            if (selectedIndex.length > 0) {
                selectedIndex = parseInt(selectedIndex)
            }
        } else if ((typeof selectedIndex) != "number") {
            selectedIndex = 3
        }
        if (selectedIndex < 0) {
            selectedIndex = 0
        }
        
        this.currentSelectedIndex = selectedIndex;

        this.mindMapFillColors = [ //背景色
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff],

        this.rootNodeFillColors = [ //主节点背景色
            0x11C5A2, 0x4388FF, 0xFD833C,
            0xD31823, 0xEE4241, 0x222222,
            0xEE4242, 0xF41E2C, 0x10C5A1,
            0x888888, 0x16BFCD, 0xD02F48,
            Colors.clear, Colors.clear, Colors.clear,
            0x993281, 0xFFEA9C, 0x888888,
            Colors.clear, Colors.clear, Colors.clear,
            0xFFED00, 0x007243, 0x153AA8,
            0x3A0452, 0x222222, 0x8D7D3F,
            0xE36566, 0x444444, 0x4388FF,
            0xB20000, 0x8D7D3F, 0x8D7D3F,
            0xB20000, 0xEF4343, 0x747FD1,
            0xFF5112, 0x020328, 0xFF3C00,
            0x020328],
        this.rootNodeOrder = [ //主节点背景色
            false, false, true,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            true, false, false,
            false],
        this.rootNodeChildDepth = [ //主节点背景色
            1, 1, 2,
            1, 1, 1,
            2, 1, 1,
            2, 2, 2,
            1, 1, 1,
            1, 1, 1,
            1, 1, 1,
            1, 1, 1,
            1, 1, 1,
            1, 1, 1,
            1, 1, 1,
            1, 1, 1,
            2, 1, 2,
            1],

        this.rootNodeShapeType = [
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Circular, MindElementShapeType.Corner,
                MindElementShapeType.Corner_Right, MindElementShapeType.CornerShadow, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner_Right, MindElementShapeType.Corner, MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle,
                MindElementShapeType.Corner_Right, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Ellipse,
                MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle
            ];

        this.rootNodeTextColors = [//主节点文字色
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0x020202, 0xffffff, 0xC02A23,
            0xffffff, 0x000201, 0x1FED46,
            0x020202, 0xffffff, 0xC02A23,
            0x020202, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff],

        this.rootNodeTextShadowColors =  [
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            0x636363, 0x023E48, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear];

        this.rootNodeBackgorundFullShape =  [
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL, MindElementFullType.FULL, MindElementFullType.FULL,
                MindElementFullType.FULL];

        this.lineColors = [ //主节点线条色
                [0x8ADACD], [0x4388FF], [0x020202],
                [0xD4344F, 0xF8C00A, 0x32AF54, 0x4BADD0, 0x7A5AFF, 0xF23DFD], [0x222222], [0x222222],
                [0x37302D], [0x222222], [0xABABAB],
                [0x222222], [0x0B9CAC], [0xffffff],
                [0x555555], [0x020202], [0xC02A23],
                [0x555555], [0x555556], [0x4EEC6D],
                [0x555555], [0xFFAFB0], [0xC02A23],
                [0xFBFCF2], [0x004D2A], [0x4691F8],
                [0xffffff], [0x222222], [0x222222],
                [0x222222], [0x222222], [0xEEEEEE],
                [0x666666], [0xAAAAAA], [0x777777],
                [0x777777], [0x9A9A9A], [0x555555],
                [0x222222], [0xF43A3F, 0xF4AB6C, 0x4C6DFB, 0xF1D23B], [0xD4344F, 0xF8C00A, 0x32AF54, 0x4BADD0, 0x7A5AFF, 0xF23DFD],
                [0xF43A3F, 0xF4AB6C, 0x4C6DFB, 0xF1D23B]],

            this.subjectViewFillColors = [ //主题节点背景色
                0xffffff, 0xDCE9FF, Colors.clear,
                0xffffff, 0xEEEEEE, 0xffffff,
                0xffffff, 0x9400FF, 0x555555,
                0xC2C2C2, 0x9CF1F1, 0xD02F48,
                Colors.clear, Colors.clear, Colors.clear,
                0x4C3861, 0xFFFFFF, 0x222222,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                0xA434FA, Colors.clear, 0xFFF4CA,
                0xffffff, 0xBBBBBB, 0xEEEEEE,
                0xF4F4F4, 0xF4F4F4, 0xF4F4F4,
                0xAAAAAA, 0xFFB3B3, 0xEEEEEE,
                Colors.clear, 0xF43A3F, Colors.clear,
                0xffffff],

            this.subjectNodeShapeType = [
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Underline,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Two_Underline,
                MindElementShapeType.Corner, MindElementShapeType.Circular, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Two_Underline, MindElementShapeType.Corner, MindElementShapeType.Underline,
                MindElementShapeType.Card
            ];

        this.subjectTextColors = [ //主题节点文字色
            0x020202, 0x020202, 0x020202,
            0x020202, 0x020202, 0x020202,
            0x020202, 0xffffff, 0xffffff,
            0x020202, 0x020202, 0xffffff,
            0x020202, 0xffffff, 0x020202,
            0xffffff, 0x000000, 0x020202,
            0x020202, 0xffffff, 0x020202,
            0xffffff, 0x020202, 0xffffff,
            0xffffff, 0x020202, 0x020202,
            0x020202, 0xffffff, 0x020202,
            0x020202, 0x020202, 0x020202,
            0x020202, 0xffffff, 0x020202,
            0x020202, 0xffffff, 0x020202,
            0x020202],

        this.subjectBorderColors = [ //主题节点边框色
            0x8ADACD, 0x4388FF, 0x222222,
            0x020202, Colors.clear, 0x222222,
            Colors.clear, 0x222222, 0xABABAB,
            0x696969, 0x5EE4DF, 0xffffff,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, 0x54E764,
            Colors.clear, Colors.clear, Colors.clear,
            0xFBFCF2, 0x004D2A, 0xffffff,
            0xffffff, 0x222222, 0xA0A0A0,
            0x9A9A9A, 0x888888, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            0x222222, Colors.clear, 0x020202,
            Colors.clear],

        this.subjectBorderColorFollowLineColor = [ //主题节点边框色
            false, false, false,
            true, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, true,
            true],

        this.subjectNodeFullLine = [
            false, false, true,
            true, false, false,
            false, false, false,
            true,  true,  true,
            false, false, false,
            false, false, false,
            false, false, false,
            true, true, false,
            true, true, true,
            true,  true, false,
            false, false, false,
            false, false, false,
            false, true, false,
            false];

        this.sonSubjectTextColors = [
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0xffffff,
            0x333333, 0x333333, 0xffffff,
            0x333333, 0xffffff, 0x333333,
            0x333333, 0x333333, 0xffffff,
            0x333333, 0xffffff, 0x333333,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x141414, 0x333333,
            0x141414]; 

        this.sonSubjectBorderColors = [
            0x333333, 0x333333, 0x222222,
            Colors.clear, 0x333333, Colors.clear,
            Colors.clear, 0x333333, 0x333333,
            0x717171, Colors.clear, 0xffffff,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0x333333,
            Colors.clear, 0x004D2A, Colors.clear,
            Colors.clear, 0x222222, 0x333333,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0x333333,
            0x333333, 0x333333, 0x333333,
            Colors.clear, Colors.clear, 0xD4344F,
            Colors.clear]; //

        this.sonSubjectFullColors =  [
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, 0xECECEC,
                0xECECEC, Colors.clear, Colors.clear,
                0xE8E8E8, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                0xECECEC, Colors.clear, Colors.clear,
                0xFFFFFF];
        this.sonSubjectFullColorsAlhpa =  [
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, 0.19, 1.0,
                0.19];
        this.sonSubjectFullColorParentColor =  [
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, false, false,
                    false, true, false,
                    false];

        this.sonSubjectNodeShapeType = [
            MindElementShapeType.Underline, MindElementShapeType.Underline, MindElementShapeType.Underline,
            MindElementShapeType.Underline, MindElementShapeType.Underline, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Circular, MindElementShapeType.Underline,
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Corner,
            MindElementShapeType.Underline, MindElementShapeType.Underline, MindElementShapeType.Underline,
            MindElementShapeType.Underline, MindElementShapeType.Underline, MindElementShapeType.Underline,
            MindElementShapeType.Underline, MindElementShapeType.Underline, MindElementShapeType.Underline,
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Underline,
            MindElementShapeType.Underline, MindElementShapeType.Underline, MindElementShapeType.Underline,
            MindElementShapeType.Underline, MindElementShapeType.Underline, MindElementShapeType.Underline,
            MindElementShapeType.Underline, MindElementShapeType.Underline, MindElementShapeType.Underline,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Underline,
            MindElementShapeType.Card
        ];//？？

        this.rootNodeBorderColors = [//主节点边框色
            Colors.clear, Colors.clear, Colors.clear,
            0xD31823, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            0x020202, 0x00544F, 0xffffff,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, 0x34EA51,
            Colors.clear, Colors.clear, Colors.clear,
            0xffffff, 0xD7D7D7, 0xffffff,
            0xffffff, 0x9B9B9B, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear, Colors.clear, Colors.clear,
            Colors.clear],

        this.freeSubjectViewFillColors = [ //自由系欸但背景色
                0x6B79FF, 0xDCE9FF, 0xF1F1F1,
                0xffffff, 0xFFF4CA, 0x4288FF,
                0xffffff, 0xE8D9FF, 0xFFF3C8,
                0xffffff, 0x9CF1F1, 0xD02F48,
                Colors.clear, Colors.clear, Colors.clear,
                0xF4F4F4, 0xFFF0E7, 0xF4F4F4,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, 0xEF4343, 0xffffff,
                0xffffff, 0xFF0000, 0xBBBBBB,
                0xE36566, 0xE36566, 0xBBBBBB,
                0xBBBBBB, 0xBBBBBB, 0xBBBBBB,
                0xBBBBBB, 0xBBBBBB, 0x099F82,
                Colors.clear, 0x020328, 0xfb3f20,
                0x020328],

        this.freeNodeShapeType = [
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Semicircle,
                MindElementShapeType.Semicircle, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Semicircle, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Semicircle, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Semicircle, MindElementShapeType.Corner, MindElementShapeType.Corner,
                MindElementShapeType.Corner
            ];//？？
        this.freeSubjectTextColors = [//自由节点文字色
            0xffffff, 0x333333, 0x010101,
            0x333333, 0x333333, 0xffffff,
            0x333333, 0x333333, 0x555552,
            0x333333, 0x696969, 0xffffff,
            0x555555, 0x4388FF, 0xC02A23,
            0x4C4C4C, 0x333333, 0x333333,
            0x555555, 0x4388FF, 0xC02A23,
            0xffffff, 0xffffff, 0x222222,
            0x222222, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0xffffff, 0xffffff, 0xffffff,
            0x333333, 0xffffff, 0xffffff,
            0xffffff],

            this.freeSubjectBorderColors = [ //自由节点边框色
                Colors.clear, 0x4388FF, 333333,
                0x555555, 0xFC6333, Colors.clear,
                0x733AE1, 0x5C0BA8, Colors.clear,
                0x333333, 0x5EE4DF, 0xffffff,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                0xffffff, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                Colors.clear, Colors.clear, Colors.clear,
                0x333333, 0x020328, 0xfb3f20,
                0x020328],

            this.rootNodeTextFontSizes = [ //主节点文字大小
                30, 30, 30,
                30, 30, 30,
                30, 28, 30,
                30, 30, 30,
                30, 30, 30,
                30, 30, 30,
                30, 30, 30,
                30, 30, 30,
                30, 30, 30,
                30, 30, 30,
                30, 30, 30,
                30, 30, 30,
                30, 30, 30,
                30],
            this.subjectTextFontSizes = [ //主题点文字大小
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18],
            this.lineWidths = [ //？？
                1.5, 1.5, 1.0,
                2.5, 1.5, 4,
                1.5, 3, 1.5,
                1.5, 1.5, 1.0,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.0, 1.0, 2.0,
                2.0, 1.0, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                2.5, 3.0, 3.0,
                3.0],
            this.rootNodeBorderWidths = [//主节点边框宽度
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 2.0,
                2.0, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 2.0, 1.5,
                2.0],
            this.subjectBorderWidths = [//主题边框宽度
                1.5, 1.5, 3.0,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.0,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.0, 3.0, 2.0,
                2.0, 3.0, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 2.0, 1.5,
                2.0],

            this.sonSubjectBorderWidths = [
                1.5, 1.5, 1.0,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.0, 1.5, 1.0,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.0, 1.0, 1.5,
                1.5, 1.0, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 2.0, 1.5,
                2.0],
            this.sonSubjectBorderWidthDot = [
                false, false, false,
                false, false, false,
                false, false, false,
                true, false, false,
                false, false, false,
                false, false, false,
                false, false, false,
                false, false, false,
                false, false, false,
                false, false, false,
                false, false, false,
                false, false, false,
                false, false, false,
                false],
            this.freeSubjectTextFontSizes = [ //自由节点文字大小
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18, 18, 18,
                18],

            this.freeSubjectBorderWidths = [//自由节点边框宽度
                1.5, 1.5, 1.0,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.0, 1.5, 1.0,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.0, 3.0, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 1.5, 1.5,
                1.5, 2.0, 1.5,
                2.0]
    }

    getMindMapFillColor() {
        return this.mindMapFillColors[this.currentSelectedIndex];
    }

    getrootNodeTextFontSize() {
        return this.rootNodeTextFontSizes[this.currentSelectedIndex];
    }

    getRootNodeFillColor() {
        return this.rootNodeFillColors[this.currentSelectedIndex];
    }

    getRootNodeChildDepth() {
        return this.rootNodeChildDepth[this.currentSelectedIndex];
    }
    

    getRootNodeOrder() {
        return this.rootNodeOrder[this.currentSelectedIndex];
    }

    getRootNodeTextColor() {
        return this.rootNodeTextColors[this.currentSelectedIndex];
    }

    getRootNodeBorderColor() {
        return this.rootNodeBorderColors[this.currentSelectedIndex];
    }

    getLineColor() {
        return this.lineColors[this.currentSelectedIndex][0];
    }

    getLineColors() {
        return this.lineColors[this.currentSelectedIndex];
    }

    getSubjectViewFillColor() {
        return this.subjectViewFillColors[this.currentSelectedIndex];
    }

    getSubjectTextColor() {
        return this.subjectTextColors[this.currentSelectedIndex];
    }

    getSubjectTextFontSize() {
        return this.subjectTextFontSizes[this.currentSelectedIndex];
    }

    getSubjectBorderWidth() {
        return this.subjectBorderWidths[this.currentSelectedIndex];
    }

    getSubjectLineWidth() {
        return this.lineWidths[this.currentSelectedIndex];
    }

    getRootNodeBorderWidth() {
        return this.rootNodeBorderWidths[this.currentSelectedIndex];
    }

    getRootShapeType() {
        return this.rootNodeShapeType[this.currentSelectedIndex];
    }

    getSubjectBorderColor() {
        return this.subjectBorderColors[this.currentSelectedIndex];
    }

    getSubjectBorderColorFollowLineColor() {
        return this.subjectBorderColorFollowLineColor[this.currentSelectedIndex];
    }
    

    getSubjectShapeType() {
        return this.subjectNodeShapeType[this.currentSelectedIndex];
    }

    getSonSubjectBorderWidth() {
        return this.sonSubjectBorderWidths[this.currentSelectedIndex];
    }

    getSonSubjectBorderDot() {
        return this.sonSubjectBorderWidthDot[this.currentSelectedIndex];
    }

    getSonSubjectBorderColor() {
        return this.sonSubjectBorderColors[this.currentSelectedIndex];
    }

    getSonSubjectShapeType() {
        return this.sonSubjectNodeShapeType[this.currentSelectedIndex];
    }

    getSonSubjectFullColor() {
        return this.sonSubjectFullColors[this.currentSelectedIndex];
    }

    getSonSubjectFullColorAlhpa() {
        return this.sonSubjectFullColorsAlhpa[this.currentSelectedIndex];
    }

    getSonSubjectFullColorParentColor() {
        return this.sonSubjectFullColorParentColor[this.currentSelectedIndex];
    }

    getFreeSubjectViewFillColor() {
        return this.freeSubjectViewFillColors[this.currentSelectedIndex];
    }

    getFreeSubjectTextColor() {
        return this.freeSubjectTextColors[this.currentSelectedIndex];
    }

    getFreeSubjectTextFontSize() {
        return this.freeSubjectTextFontSizes[this.currentSelectedIndex];
    }

    getFreeSubjectBorderWidth() {
        return this.freeSubjectBorderWidths[this.currentSelectedIndex];
    }

    getFreeSubjectBorderColor() {
        return this.freeSubjectBorderColors[this.currentSelectedIndex];
    }

    getFreeSubjectNodeShapeType() {
        return this.freeNodeShapeType[this.currentSelectedIndex];
    }

    getSubjectNodeLineFull() {
        return this.subjectNodeFullLine[this.currentSelectedIndex];
    }

    getRootNodeTextShadowColor() {
        return this.rootNodeTextShadowColors[this.currentSelectedIndex]
    }

    getRootNodeBackgorundFullShape() {
        return this.rootNodeBackgorundFullShape[this.currentSelectedIndex]
    }
}

export default MindMapStyleColor