/**
 * Created by tony on 2019/12/26
 */
const MindTaskType = {

    NORMAL: 1, //不展示
    PROGRESS: 2, //未选中
    COMPLETE: 3, //打勾
}

export default MindTaskType