const { default: strings } = require("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Mind + Mind Map';
stringMap [strings.TEMPLATE] = 'Template';
stringMap [strings.Mind_Edit_Main_Topic] = 'Isi Alaka';
stringMap [strings.Global_Add_Free_Node_Tips] = 'Pịa na ihuenyo ka ịhọrọ ebe ị ga-agbakwunye';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'Pịa ihuenyo ugboro abụọ iji họrọ ebe ị ga-agbakwunye';
stringMap [strings.Global_Add_Relationship_Tips] = 'Họrọ ebumnuche nke kwesiri ijikọ';
stringMap [strings.Global_Add_Target_Tips] = 'Biko họta ọnụ iji tinye ihe';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Ihe a abanyelarị na map ndị ọzọ';
stringMap [strings.Mind_Edit_Main_Idea] = 'Isi Okwu';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'Maapụ emetụtara adịghị';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'Ibu ụzọ';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'Ọganihu';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'Kpakpando';
stringMap [strings.Mind_Style_Add_Icon_Week] = 'izu';
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'Eserese';
stringMap [strings.Establish] = 'Mepụta';
stringMap [strings.Create_From_Template] = 'Mepụta site na ndebiri';
stringMap [strings.Common] = 'Akara';
stringMap [strings.Function_List] = 'Ndepụta Ọrụ';
stringMap [strings.Recent_Updates] = 'Na nso nso a ka emelitere';
stringMap [strings.The_Latest_To_Delete] = 'Emechara nso nso a';
stringMap [strings.Setting_Up] = 'Kpọtụrụ anyị';
stringMap [strings.Usinghelp] = 'Iji enyemaka';
stringMap [strings.The_Download_Client] = 'Download ahịa / mobile version';
stringMap [strings.Global_Purchase] = 'chazụta';
stringMap [strings.Global_Login] = 'Nbanye';
stringMap [strings.My_map] = 'My Maapụ';
stringMap [strings.Create_Map] = 'Mepụta maapụ';
stringMap [strings.Storm] = 'Oké Ifufe';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'A na-ahụkarị \ nCharacteristic';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'E ji mara';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'Organizationtù Center';
stringMap [strings.Mind_Edit_Free_Topic] = 'Isiokwu efu';
stringMap [strings.Mind_Edit_Sub_Topic] = 'Isiokwu Nkebi';
stringMap [strings.Mind_Organization_Main_Topic] = 'Ngalaba';
stringMap [strings.Mind_Edit_Main_Topic] = 'Isi Alaka';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'Atụmatụ';
stringMap [strings.Mind_Time_Edit_Head] = 'Oge';
stringMap [strings.Mind_Time_Edit_Title] = 'Atụmatụ';
stringMap [strings.Mind_Edit_Details] = 'Nkọwa zuru ezu';
stringMap [strings.Checking_Data] = 'Chelele data';
stringMap [strings.Setting_Information] = 'Isetịpụ Ozi';
stringMap [strings.Sign_Out] = 'Pụọ';

// ọrụ ndepụta
stringMap [strings.Create_a_Mind_Map] = 'Mepụta Mind Map';
stringMap [strings.Create_Mind_Map_1] = '[1] Mepụta maapụ nwere nhazi iri na anọ (ụzọ abụọ / aka nri / aka ekpe / nhazi usoro / otu eserese afụ / mpịakọta afụ abụọ / map aka ekpe / map aka nri / eserese aka nri / Osisi osisi ụzọ abụọ / okpokoro / usoro ihe ntanetị / usoro iheomume / usoro iheomume / pyramid) ';
stringMap [strings.Create_Mind_Map_2] = '[2] ndebiri nwere ike iji mepụta maapụ ngwa ngwa (ọnụ ọgụgụ dị ukwuu nke ederede ndebiri)';
stringMap [strings.Create_Mind_Map_3] = '[3] Dezie maapụ ahụ na ụdị usoro usoro itoolu';
stringMap [strings.Create_Mind_Map_4] = '[4] Mee usoro mgbanwe iteghete na ndezi na eserese ngosi';
stringMap [strings.Create_Mind_Map_5] = '[5] Tinye node umuntakiri na onu ogugu ma tinye onu na otu larịị (elu na ala)';
stringMap [strings.Create_Mind_Map_6] = '[6] Nchịkọta: Tinye [Nchịkọta] iji kọwaa otu ọnụ / ọtụtụ ọnụ';
stringMap [strings.Create_Mind_Map_7] = '[7] Gbochie: tinye [ngọngọ] na otu ọnụ / ọtụtụ ọnụ igbe nke maapụ ka emezie';
stringMap [strings.Create_Mind_Map_8] = '[8] Tinye uzo efu';
stringMap [strings.Create_Mind_Map_9] = "[9] Mepụta njikọ nke ụzọ akara anọ n'etiti mpaghara ọ bụla";
stringMap [strings.Create_Mind_Map_10] = '[10] Fanye foto (mpaghara + ọbá akwụkwọ ihe onwunwe), akara ngosi, ndetu, njikọ ọnụ';
stringMap [strings.Create_Mind_Map_11] = '[11] Detuo / mado ọnụ, ụdị / mado ụzọ';
stringMap [strings.Create_Mind_Map_12] = '[12] Kagbuo, weghachi ma kagbuo';
stringMap [strings.Create_Mind_Map_13] = "[13] Ọtụtụ nhọrọ dị, ọtụtụ ọnụ nwere ike rụọ ọrụ n'otu oge";
stringMap [strings.Create_Mind_Map_14] = '[14] Statistical eserese nwere ike etinyere';
stringMap [strings.Create_Mind_Map_15] = '[15] Enwere ike idekọ map ahụ dum';
stringMap [strings.Create_Mind_Map_16] = '[16] Detuo maapụ';
stringMap [strings.Create_Mind_Map_17] = '[17] can nwere ike idetuo maapụ ma ọ bụ dum map na map nke faịlụ ndị ọzọ';
stringMap [strings.Create_Mind_Map_18] = '[18] Enwere ike ịmepụta maapụ mgbe nchịkọta';
stringMap [strings.Create_Mind_Map_19] = "[19] Nhazi mmezi nwere ike gbanwee n'oge edezi (akụkụ kọmputa)";
stringMap [strings.Create_Mind_Map_20] = "[20] Bluetooth edezi map: \ n \ t [Iwu] + [a] Họrọ nnabata ọnụ \ n \ T [Iwu] + [←] [↑] [→] [↓ ] Gbanwee ebumnuche nhọrọ ma ọ bụ bugharịa map \ n \ t [Iwu] + [e] Dezie ebumnuche \ n \ T [Iwu] + [w] Mepụta ọnụ nwa \ n \ T [Iwu] + [s] Mepụta ebe nwanne na-atụghị anya ya ( Nke dị elu) \ n \ t [Iwu] + [d] Mepụta ọnụ nwanne na-atụghị anya ya (n'ala ala) \ n \ t [Iwu] + [q] Wepu ebumnuche ahọrọ \ n \ Iwu [+ -] [+] bia maapụ ";

stringMap [strings.Beautify_The_Mind_Map] = 'Mara Mma Ebe Uche';
stringMap [strings.Beautify_The_Mind_Map_1] = '[1] Nịm ndabere agba nke uche map';
stringMap [strings.Beautify_The_Mind_Map_2] = '[2] Nịm ọkpụrụkpụ na agba nke dum map ahịrị, ma ọ na-tapawa ma ọ bụ na';
stringMap [strings.Beautify_The_Mind_Map_3] = '[3] Tọọ ederede jupụta agba, agba ókèala, ókè 13 ụdị, ókè obosara, onyinyo mmetụta';
stringMap [strings.Beautify_The_Mind_Map_4] = '[4] Nịm ma ọ bụ akụkụ nke agba ahịrị na obosara nke maapụ ahụ';
stringMap [strings.Beautify_The_Mind_Map_5] = '[5] Tọọ nha ederede ederede, nwee nkwuwa okwu, italic, hichapụ ụdị';
stringMap [strings.Beautify_The_Mind_Map_6] = '[6] Tọọ nha eserese, ọnọdụ, na ụdị udi';
stringMap [strings.Beautify_The_Mind_Map_7] = '[7] Tinye akara ngosi nke edemede asaa (nkịtị / 3D / ọmụmụ / ọrụ / mmadụ / anụmanụ / nri) wee tọọ nha / ọnọdụ';
stringMap [strings.Beautify_The_Mind_Map_8] = '[8] Tinye otu ụdị ịpị ịkọwa otu map';
stringMap [strings.Beautify_The_Mind_Map_9] = '[9] Nchịkwa Njikwa Group';
stringMap [strings.Beautify_The_Mind_Map_10] = '[10] Mgbakwunye ahaziri iche (ọba akwụkwọ) nwere ike ịgbakwunye na map niile';
stringMap [strings.Beautify_The_Mind_Map_9] = '[11] Onwe onye ma ọ bụ nzụlite (ọbá akwụkwọ ihe onwunwe) nwere ike ịgbakwunye na otu ọnụ';
stringMap [strings.Beautify_The_Mind_Map_9] = '[12] Ogologo ọnụ ọnụ nwere ike ịdọrọ';


stringMap [strings.Map_Output] = 'Mmepụta map';
stringMap [strings.Map_Output_1] = '[1] Chekwaa eserese';
stringMap [strings.Map_Output_2] = '[2] Zọpụta PDF';
stringMap [strings.Map_Output_3] = '[3] Kekọrịta njikọ';
stringMap [strings.Map_Output_4] = '[4] Kesaa oke ebili mmiri ahụ';
stringMap [strings.Map_Output_4] = '[5] Bipute';

stringMap [strings.Work_together] = 'Ọrụ na mmekota';
stringMap [strings.Work_together_1] = '[1] Mepụta otu ekekọrịta (akara otu, ọkwa otu)';
stringMap [strings.Work_together_2] = "[2] Ndị ọhụrụ so n'otu";
stringMap [strings.Work_together_3] = '【3】 Otutu ndi otu di na otu dezie otu map ahu';
stringMap [strings.Work_together_4] = "[4] Bulite faịlụ ndị kesara n'etiti otu (okwu / excle / pdf)";
stringMap [strings.Work_together_5] = '[5] can nwere ike ịhapụ ozi na otu';
stringMap [strings.Work_together_6] = '[6] Ikekọrịta otu njikwa ikike njikwa ntọala';
stringMap [strings.Work_together_7] = '[7] Gbasaa Group';

stringMap [strings.Work_together] = 'Creative Sharing (Storm)';
stringMap [strings.Community_Sharing_1] = '[1] can nwere ike ịkekọrịta ihe okike gị na oke mmiri ozuzo ka ọtụtụ ndị mmadụ wee hụ ya';
stringMap [strings.Community_Sharing_1] = '[2] can nwere ike ịhọrọ mkpado iji lelee ọdịnaya mmiri ozuzo metụtara';
stringMap [strings.Community_Sharing_1] = '[3] I nwere ike na-amasị na-ekwu okwu banyere oké ifufe ọrụ';

// Emechara nso nso a
stringMap [strings.Recently_Updated_Title_1] = 'Mepụta akaụntụ nkeonwe';
stringMap [strings.Recently_Updated_Title_2] = 'Massive Template Community Base';
stringMap [strings.Recently_Updated_Title_3] = 'Kwado otutu nyiwe';
stringMap [strings.Recently_Updated_Title_4] = 'Hazie ihe niile';
stringMap [strings.Recently_Updated_Title_5] = 'Ọdụ akwụkwọ ụdị ụdị nke mmadụ';
stringMap [strings.Recently_Updated_Title_6] = 'Mmepụta map';
stringMap [strings.Recently_Updated_Title_7] = 'Cloudgwé ojii Nchekwa';
stringMap [strings.Recently_Updated_Title_8] = 'Fdị map iri na ise';


stringMap [strings.Recently_Updated_Content_1] = 'Mepụta akaụntụ nkeonwe ma chekwaa map mepụtara na akaụntụ ahụ';
stringMap [strings.Recently_Updated_Content_2] = 'A na-ekwuputa otutu ihe omuma nke otutu ihe omuma iji meziwanye ihe omumu';
stringMap [strings.Recently_Updated_Content_3] = 'Nkwado web version online mmepụta, IOS mobile ọnụ, Mac ọnụ, Android mobile ọnụ, Android mbadamba multi-ikpo okwu iji';
stringMap [strings.Recently_Updated_Content_4] = 'can nwere ike ịchọ nhazi nke ederede, ndabere, ahịrị, akara ngosi eserese, wdg dabere na mmasị onwe gị';
stringMap [strings.Recently_Updated_Content_5] = 'A na-ekwu kpọmkwem ụdị ọbá akwụkwọ ụdị ụdị, na-ewepu nsogbu nke agba agba';
stringMap [strings.Recently_Updated_Content_6] = 'can nwere ike ịchekwa foto, PDF usoro, ma kesaa ndị enyi ozugbo';
stringMap [strings.Recently_Updated_Content_7] = 'cloudgwé ojii na-akwado nchekwa nchekwa, agakwaghị echegbu onwe gị maka ọnwụ data';
stringMap [strings.Recently_Updated_Content_8] = 'Uzo abuo, aka nri, aka ekpe, nhazi nhazi, otu eserese afụ, okpukpu abuo abuo, eserese osisi aka ekpe, eserese aka nri, eserese osisi uzo abuo, okpokoro, usoro iheomume uzo, usoro iheomume doro anya, S usoro iheomume, asatọ layout nwere ike gbanwere na uche ';

// Na-adịbeghị anya ehichapụ
stringMap [strings.Mind_Mindmap_Deleted_Title] = "Ehichapụ n'oge na-adịbeghị anya";
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = 'Iweghachite';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'Hichapụ';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'Aha faịlụ';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'Mgbe ihichapụchara ebe a, a ga-ewepụ ya kpamkpam';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'Ọrụ';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = "Chọọ maapụ ehichapụ n'oge na-adịbeghị anya";
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = 'Hichapụ ụbọchị';

//kpọtụrụ anyị
stringMap [strings.Contact_Us_No_Public] = 'Akaụntụ Ọha';
stringMap [strings.Contact_Us_QQ_Group] = 'Otu QQ';
stringMap [strings.Contact_Us_Message_Board] = 'Ozi Ozi';
stringMap [strings.Contact_Us_Username] = 'Aha';
stringMap [strings.Contact_Us_Email] = 'Email';
stringMap [strings.Contact_Us_Voicemail] = 'Biko hapụ okwu gị bara uru';
stringMap [strings.Contact_Us_Send_Email] = 'Zipu Email';

// nyochaa ozi
stringMap [strings.Input_Username_isNull] = 'Aha enweghị ike ịbụ ihe efu';
stringMap [strings.Input_Email_isNull] = 'Biko tinye adreesị ozi-e ziri ezi';
stringMap [strings.Input_Voicemail_isNull] = 'Biko tinye ọdịnaya ezigara';
stringMap [strings.Input_Introduction_isNull] = 'Okwu mmalite enweghị ike ịbụ efu';
stringMap [strings.Input_Title_isNull] = 'Aha mbu apughi ibu efu';

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'Biko tinye akara ekwentị ziri ezi';
stringMap [strings.Input_Please_Enter_Verification_Code] = 'Biko tinye koodu nkwenye! ';
stringMap [strings.Input_Please_Enter_The_Password] = 'Biko tinye okwuntughe! ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = 'Usoro okwuntughe ezighi ezi! ';
stringMap [strings.Input_Please_Enter_A_New_Password] = 'Biko tinye okwuntughe ohuru! ';
stringMap [strings.Input_Confirm_The_New_Password] = 'Biko tinye okwuntughe nkwenye! ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = 'Okwuntughe ekwesighi! ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = 'Biko tinye akara nkwenye ziri ezi! ';
stringMap [strings.Input_The_Verification_Code_Error] = 'Nyocha koodu mperi! ';

// Iji enyemaka
stringMap [strings.Mind_Usinghelp_1] = 'Mepụta a uche map';
stringMap [strings.Mind_Usinghelp_2] = 'Otu esi etinye onu';
stringMap [strings.Mind_Usinghelp_3] = 'Mepụta ọnụ efu';
stringMap [strings.Mind_Usinghelp_4] = 'Mepụta mkpakọrịta';
stringMap [strings.Mind_Usinghelp_5] = 'chọọ kwaaji';
stringMap [strings.Mind_Usinghelp_6] = 'chọọ ederede mma';
stringMap [strings.Mind_Usinghelp_7] = 'Fanye ndetu, njikọ';
stringMap [strings.Mind_Usinghelp_8] = 'Fanye akara ngosi';
stringMap [strings.Mind_Usinghelp_9] = 'Fanye Foto';
stringMap [strings.Mind_Usinghelp_10] = 'Otu esi eweghachi nhichapụ';
stringMap [strings.Mind_Usinghelp_11] = 'Mbupụ Mind Map';
stringMap [strings.Mind_Usinghelp_12] = 'Nkwalite ndị otu';
stringMap [strings.Mind_Usinghelp_13] = 'cutzọ mkpirisi';
stringMap [strings.Mind_Usinghelp_Understood] = 'Enwetara m ya';
stringMap [strings.Mind_Usinghelp_14] = "Fanye ihe n'ọbá akwụkwọ ihe onwunwe";
// Jiri enyemaka-2
stringMap [strings.Mind_Usinghelp_15] = 'Map ohuru';
// stringMap [strings.Mind_Usinghelp_2] = 'Tinye node';
// stringMap [strings.Mind_Usinghelp_4] = 'Mepụta mkpakọrịta';
// stringMap [strings.Mind_Usinghelp_5] = 'Mara mma kwaaji';
// stringMap [strings.Mind_Usinghelp_6] = 'Mara ederede mma';
// stringMap [strings.Mind_Usinghelp_7] = 'Tinye ndetu, njikọ';
stringMap [strings.Mind_Usinghelp_17] = 'Wepu ọrụ, kagbuo';
stringMap [strings.Mind_Usinghelp_18] = 'Dọrọ ọnụ iji gbanye';
stringMap [strings.Mind_Usinghelp_19] = 'Nhazi nhazi';
stringMap [strings.Mind_Usinghelp_20] = 'Gbanwee ụdị map';
stringMap [strings.Mind_Usinghelp_21] = 'linedị akara map';
stringMap [strings.Mind_Usinghelp_22] = 'Agba mgbada agba';
stringMap [strings.Mind_Usinghelp_23] = 'Nịm udi ihu';
stringMap [strings.Mind_Usinghelp_24] = 'odedị akara ọnụ';
stringMap [strings.Mind_Usinghelp_25] = 'Node Foto Ntọala';
stringMap [strings.Mind_Usinghelp_26] ='Ntọala akara ngosi ọnụ';
stringMap [strings.Mind_Usinghelp_27] = 'Ntinye Tinye Nchịkọta';
stringMap [strings.Mind_Usinghelp_28] = 'Otu igbe nhọrọ otu';
stringMap [strings.Mind_Usinghelp_29] = 'Otutu uzo di na mgbochi';
stringMap [strings.Mind_Usinghelp_30] = 'Ntinye tinye ókèala';
stringMap [strings.Mind_Usinghelp_31] = 'Tinye akwukwo onu ogugu';
stringMap [strings.Mind_Usinghelp_32] = 'Zoo ọnụ ma weghachi';
stringMap [strings.Mind_Usinghelp_33] = 'Ọnụ ọtụtụ ọrụ';
stringMap [strings.Mind_Usinghelp_34] = 'Gbanwee ụdị';
stringMap [strings.Mind_Usinghelp_35] = 'Fanye olu na nkowa';
stringMap [strings.Mind_Usinghelp_36] = "Nduzi Map n'ime Ndise";
stringMap [strings.Mind_Usinghelp_37] = 'Akwụkwọ PDF akwụkwọ';
stringMap [strings.Mind_Usinghelp_38] = 'Bipụta map';
stringMap [strings.Mind_Usinghelp_39] = 'Njikọ njikọ na ilele';
stringMap [strings.Mind_Usinghelp_40] = 'Maapụ ịkọrọ oke mmiri ozuzo';
stringMap [strings.Mind_Usinghelp_41] = 'Iji nchekwa';
stringMap [strings.Mind_Usinghelp_42] = 'Hichapụ ma weghachite maapụ';
// Jiri enyemaka 3
stringMap [strings.Mind_Usinghelp_43] = "Ndị enyị tinyekwara n'òtù ahụ";
stringMap [strings.Mind_Usinghelp_44] = 'Otu esi agbari ndi otu';
stringMap [strings.Mind_Usinghelp_45] = 'Bulite ozi faịlụ';
stringMap [strings.Mind_Usinghelp_46] = 'Kwere isonyere ngwa ahụ';
stringMap [strings.Mind_Usinghelp_47] = 'Ndekọ Njikọ';
stringMap [strings.Mind_Usinghelp_48] = 'Otu Shakekọrịta Ọhụrụ';
stringMap [strings.Mind_Usinghelp_49] = 'Kpoo ndi otu';

// oke ifufe
stringMap [strings.Mind_Storm_Recommend] = 'Nkwanye';
stringMap [strings.Mind_Storm_Boilingpoint] = 'Ebe esi';
stringMap [strings.Mind_Storm_Unfold] = 'Emeghe';
stringMap [strings.Mind_Storm_Putaway] = 'Putaway';
stringMap [strings.Mind_Storm_Myclassification] = 'Nhazi ọkwa m';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'Ntuziaka ka akwadoro';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'Pịa ka ịgbakwunye otu';
stringMap [strings.Mind_Storm_No_Classification] = 'Ọ nweghị nhazi ọkwa';
stringMap [strings.Mind_Storm_No_Tinterested] = 'Enweghị m mmasị';
stringMap [strings.Mind_Storm_Report] = 'Akuko';
stringMap [strings.Mind_Storm_Views] = 'Echiche';

stringMap [strings.Mind_Storm_Pornographic] = 'Okwu rere ure';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'Mgbasa Mgbasa Ozi';
stringMap [strings.Mind_Storm_Political_Religion] = 'Ọchịchị na Okpukpe';
stringMap [strings.Mind_Storm_Infringement] = 'ringda iwu (eserese, nkwulu, ịkpa arụ, iji aghụghọ)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'Mmachibido ọdịnaya';
stringMap [strings.Mind_Storm_Spread_Rumors] = 'Gbasaa asịrị ma gbasaa asịrị';
stringMap [strings.Mind_Storm_Report_Successful] = 'Kpesa ihe ịga nke ọma';

// dezie
stringMap [strings.Global_Edit] = 'Dezie';
stringMap [strings.Global_Cancel] = 'Kagbuo';
stringMap [strings.Global_Ok] = 'OK';
stringMap [strings.Global_Name] = 'Aha otutu';
stringMap [strings.Global_Introduction] = 'Okwu Mmalite';
stringMap [strings.Global_Change_Avatar] = 'Gbanwee Avatar';
stringMap [strings.Global_Local_Upload] = 'Mpaghara bulite';

//ozi nkeonwe
stringMap [strings.User_Personal_information] = 'Ozi Nkeonwe';
stringMap [strings.User_Member_Validity_Period] = 'Otu Ndaba Oge';
stringMap [strings.User_My_Share] = 'Ekekọrịta M';
stringMap [strings.User_My_Collection] = 'Nchịkọta m';
stringMap [strings.User_More_Message] = 'Ọzọ';
stringMap [strings.User_Deleted_Sotrm] = 'Hichapụ Oké Ifufe';
stringMap [strings.User_Folder_Rename] = 'Kpọgharia aha';
stringMap [strings.User_Folder_Rename_Root] = 'Gbanwee ikikere';
stringMap [strings.User_Folder_Rename_Root_Title] = 'Gbanwee ikikere';
stringMap [strings.User_Folder_Root_Public] = 'Ofụri Esịt ọha';
stringMap [strings.User_Folder_Root_Viewonly] = 'A na-ekwe ka folda ọhaneze ka a hụ ya';
stringMap [strings.User_Folder_Root_Private] = 'Nkeonwe';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'Ndị ọzọ nwere ike ịlele ọkacha mmasị gị na ibe profaịlụ gị';
stringMap [strings.User_Message_Link] = 'Njikọ';
stringMap [strings.User_New_Group] = 'Otu ohuru';
stringMap [strings.User_New_Group_Title] = 'Mepụta otu ohuru';
stringMap [strings.User_New_Group_Style_Title] = 'Typedị faịlụ';
stringMap [strings.User_Share_Is_Null] = 'Ọ dịghị nkesa ọzọ! ';
stringMap [strings.User_Deleted_Storm_Tips] = 'O doro gị anya na ị chọrọ ihichapụ oké ifufe a? ';
stringMap [strings.User_Remove_From_List] = 'Wepu na ndepụta',
    stringMap [strings.User_Move_To] = 'Paghaa na',
    stringMap [strings.User_Deleted_Link] = 'Hichapụ njikọ'
stringMap [strings.User_Collction_Is_Null] = 'Ọ nwebeghị nchịkọta'
stringMap [strings.User_Collection] = 'gbasara mkpokọta'

// Igbe nbanye
stringMap [strings.User_Lolgin_Welcome_To_Login] = 'Nnabata na Uche +'
stringMap [strings.User_Lolgin_Free_Registration] = 'Ndebanye aha efu'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'Biko tinye akara ekwentị gị'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'Biko tinye koodu nkwenye'
stringMap [strings.User_Lolgin_Obtain] = 'Nweta'
stringMap [strings.User_Lolgin_Password_Login] = 'Nbanye okwuntughe'
stringMap [strings.User_Lolgin_Login] = 'Banye'
stringMap [strings.User_Lolgin_Register_An_Account] = 'Ndebanye aha akaụntụ'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'Ugbua nwere akaụntụ'
stringMap [strings.User_Lolgin_To_Log_In] = 'Gaa banye'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'Biko tinye okwuntughe'
stringMap [strings.User_Lolgin_Confirm_Password] = 'Kwenye okwuntughe'
stringMap [strings.User_Lolgin_Registered] = 'Aha'
stringMap [strings.User_Lolgin_Account_Login] = 'Nbanye akaụntụ'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'Nyocha usoro nbanye'
stringMap [strings.User_Lolgin_Forgot_Password] = 'Chefuru okwuntughe'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'Usoro nkwenye abanye'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Mepee Uche + nyocha na elu ntọala ntọala'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'Nke a QR koodu expires na nkeji ise, biko pịa mgbe ọ na-agwụ'
stringMap [strings.User_Lolgin_Refresh] = 'Ume'
stringMap [strings.User_Lolgin_Scanning_Is_Sufulful_Please_Confirm_On_Your_Phone] = 'Nyocha nyocha gara nke ọma, biko gosi na ekwentị gị'
stringMap [strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'Ma iji nye ikike nbanye'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'Biko tinye okwuntughe ohuru'
stringMap [strings.User_Lolgin_Reset] = 'Tọgharia'

// Map m
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'Chọọ ụdị ndebiri ma ọ bụ ndebiri'
stringMap [strings.My_Mind_Date] = "bọchị"
stringMap [strings.My_Mind_Create_Folder] = 'Mepụta folda'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'Biko tinye aha folda ahụ'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'Biko tinye aha folda ọhụrụ'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'Enweghị map ma, gaa ọsọ ọsọ'
stringMap [strings.My_Mind_Create] = 'Mepụta'
stringMap [strings.My_Mind_Right] = 'Ogwe!'
stringMap [strings.My_Mind_Open] = 'Mepee'
stringMap [strings.My_Mind_Type_Left_Right] = 'Uzo ndu abuo'
stringMap [strings.My_Mind_Type_Unilateral] = 'Unilateral map'
stringMap [strings.My_Mind_Type_Bottom] = 'Chart Chaatị'
stringMap [strings.My_Mind_Type_Bubble] = 'Afụ Chart'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'Schetọ oge map'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'Blank Mind Map'
stringMap [strings.My_Mind_All] = 'All'
stringMap [strings.My_Mind_Views] = 'Echiche:'

// Oké Ifufe ọchụchọ
stringMap [strings.Storm_Search_How_Title] = 'Top Tags'
stringMap [strings.Storm_History_Search] = 'Historychọ akụkọ ihe mere eme'

// Isi okwu oha
stringMap [strings.Public_Header_Mind] = 'Maapụ'
stringMap [strings.Public_Header_Outline] = 'Ndepụta'
stringMap [strings.Public_Header_Download_Apps] = 'Budata onye ahịa'
stringMap [strings.Public_Header_Mobile_Version] = 'ụdị mkpanaka'
stringMap [strings.Public_Header_Creator] = 'Onye Okike:'
stringMap [strings.Public_Header_View_Frequency] = 'Echiche:'
stringMap [strings.Public_Header_Save] = 'Chekwa'
stringMap [strings.Header_Mind_Tips_Saved] = 'Zọpụta'
stringMap [strings.Header_Mind_Tips_Edit] = 'Emeziiri'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'Echekwala mmelite'

// Nhazi ederede menu
stringMap [strings.Mind_Edit_Revoc] = 'Kagbuo'
stringMap [strings.Mind_Edit_Restore] = 'Weghachi'
stringMap [strings.Mind_Edit_Subtopic] = 'Ndekọ Okwu'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'Top Ndekọ Okwu'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'Gburugburu Subtopic'
stringMap [strings.Mind_Edit_Free_Node] = 'Ọnọdụ efu'
stringMap [strings.Mind_Edit_Summary] = 'Nchịkọta'
stringMap [strings.Mind_Edit_Block] = 'obanye na ngọngọ'
stringMap [strings.Mind_Edit_Insert] = 'Fanye'
stringMap [strings.Mind_Edit_Related] = 'njikọ'
stringMap [strings.Mind_Edit_Style] = 'Style'
stringMap [strings.Mind_Edit_Recording] = 'ordingdekọ'
stringMap [strings.Mind_Edit_Insert_Picture] = 'Fanye foto'
stringMap [strings.Mind_Edit_Insert_Icon] = 'Fanye akara ngosi'
stringMap [strings.Mind_Edit_Insert_Remarks] = 'Fanye Okwu'
stringMap [strings.Mind_Edit_Insert_Link] = 'Fanye njikọ'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'Fanye ederede onyonyo'
stringMap [strings.Mind_Edit_Upload] = 'Bulite'
stringMap [strings.Mind_Edit_Right_Icon] = 'Ihe ngosi'
stringMap [strings.Mind_Edit_Right_Style] = 'Style'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'Nkọwapụta ...'
stringMap [strings.Mind_Edit_Audio_Duration] = 'Oge'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = "Ì ji n'aka ihichapụ ọdịyo e dere ede? "
stringMap [strings.Mind_Edit_Join_Membership] = 'Mepee ndị otu'
stringMap [strings.Mind_Edit_Upgrade] = 'Nweta nkwalite'
stringMap [strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = 'Ndị otu nwere ike ịnwekwu ndekọ oge, ugbu a gaa nweta nkwalite ịbụ ndị otu? '

stringMap [strings.Mind_Edit_Choose_Material] = 'Họrọ ihe'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'Dọrọ na dobe foto ebe a'
stringMap [strings.Mind_Edit_Select_locally] = 'Họrọ site na mpaghara'
stringMap [strings.Mind_Edit_Choose_From] = "Họrọ site n'ọbá akwụkwọ ihe onwunwe"
stringMap [strings.Mind_Edit_Mine] = 'My'
stringMap [strings.Mind_Edit_My_Material] = 'Ihe M'
stringMap [strings.Mind_Edit_Recently_Used] = "Ejiri mee ya n'oge na-adịbeghị anya"
stringMap [strings.Mind_Edit_Use] = 'Jiri'
stringMap [strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = "Enweghị ihe ọ bụla n'oge a, gaa tinye ya! '"
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'Họrọ ụdị eserese'
stringMap [strings.Mind_Edit_Remarks] = 'Okwu'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'Biko tinye okwu ndị a'
stringMap [strings.Mind_Edit_Add] = 'Tinye'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'Biko tinye njikọ'

// Dezie menu n'akụkụ aka ekpe nke ibe ahụ
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'Chekwaa foto'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = 'Mbupụ PDF'
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'Kekọrịta njikọ'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'Kesaa oke ifufe ahụ'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'Bipute'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'Mepee ụdị zuru ezu'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'Enyemaka'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'Chekwaa usoro'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'usoro png'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'usoro jpg'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'ụdị webp'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'Kekọrịta maapụ'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'Share ngbanwe'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'can nwere ike i copyomi njikọ ahụ wee zipu ya'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'Detuo njikọ'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'Ọ bụrụ na ịchọrọ ịgbanwe njikọ ahụ, biko pịa'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'Ntughari'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'Njikọ paswọọdụ:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ="Nye ohere ịchekwa"
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Ọnụ ọgụgụ kachasị elu nke echiche'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'Ebighebi'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'itgba'
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'Nke abụọ'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'Njikọ ndaba oge'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = 'daybọchị 1'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = 'daysbọchị 3'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = 'daysbọchị 7'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'Na-adịgide Adịgide'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'Detuo ọdịnaya & njikọ'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = "Ì ji n'aka na ị chọrọ regenerate? Njikọ gara aga ga-abụ nke enweghị isi! "
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'Kekọrịta'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'Omenala udi:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'Ma ọ ga-ekwe ka nkwupụta'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'Dezie iji tinye udi'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'Ikike ndi otu'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'Nkwado nkwado'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'Mepee Otu'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'Ebugharị koodu mgbanwe'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = 'Otu ndị otu bara uru mgbe ịzụrụ'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'Họrọ usoro ịkwụ ụgwọ'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'Ọnụ ego ọrụ'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = "Ọnụ ego akwụgoro n'ezie"
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Emegharia ozugbo'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《Usoro Ọrụ》'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《amụma nzuzo》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'Ebighị koodu:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Biko tinye koodu mmeghe'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = "Nyochaa ntinye n'ọrụ"
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'Họrọ otu'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'Isi aha apughi ibu efu'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'dere:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Biko tinye koodu dere'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'Kwenye na ojiji nke Kupọns'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'nkwenye'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'Ikike ego'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'Lezienụ ozugbo'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat kwuo'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Kwenye na mgbanwe ozi'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'Exchange koodu:'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Exchange'

// Dezie menu n'akụkụ aka nri nke ibe ahụ
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'Kanvas'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'Agba okirikiri'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'Okirikiri nhọrọ ukwuu'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'Usoro akara'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'Agba agba'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'Ogologo ahịrị'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = 'A kara ahịrị'
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'Okwu ederede'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'fmụaka font'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'Agba ederede'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'Font size'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'Font Style'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'Agba oke'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'Jupụta agba'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'Oke pekè'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'Oke obosara'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Gosi onyinyo mmetụta (ọrụ ndenye aha)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'Agba agba'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'Edere akara'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'Foto dị na ya'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'Ebe Foto'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'Foto dị na peeji nke'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'Icon size'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'Ọnọdụ akara ngosi'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'General'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'Ibu ụzọ'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'Ọganihu'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'Kpakpando'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'izu'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Eserese'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'Learningmụta'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'Ọrụ'
stringMap [strings.Mind_Edit_Right_Menu_Characters] = 'Ihe Odide'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'immụ anụmanụ'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'Nri'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'Detuo ụdị'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'ickingkegide ụdị'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'Gbanwee udi'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'Lelee foto'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'Hichapụ foto'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'Detuo'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'Tapawa'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'Hichapụ ahịrị'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Hichapụ'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'Dezie Okwu'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'Mepee njikọ'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'Hichapụ ikwu'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'Hichapụ njikọ'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'Hichapụ akara ngosi'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'Akara ngosi'

// Nkọwapụta eserese eserese
stringMap [strings.Echart_Edit_Statistical] = 'Statistics'
stringMap [strings.Echart_Edit_Value] = 'Uru'
stringMap [strings.Echart_Edit_Color] = 'Agba'
stringMap [strings.Echart_Edit_Title] = 'Aha'
stringMap [strings.Echart_Edit_Detailed_Description] = 'Nkọwa zuru ezu'
stringMap [strings.Echart_Edit_Value_Sort] = 'ortdị'
stringMap [strings.Echart_Edit_Value_Display_Type] = "Ngosipụta'dị"
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'Agba agba'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'Ngosipụta ngosi'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'Nha Mbụ'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'Kọwaa nha'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'Idenye aha'

// Oké ifufe taabụ
stringMap [strings.StormBookTag_Subscribed] = 'Ndenye aha'
stringMap [strings.StormBookTag_List] = 'Ndepụta'

// Okporo peeji nkekọrịta
stringMap [strings.Storm_Share_Comment] = 'Ikwu'
stringMap [strings.Storm_Share_Reply] = 'Zaghachi'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'Ka m kwuo okwu na ya ~'
stringMap [strings.Storm_Share_Release] = 'Hapụ'
stringMap [strings.Storm_Share_Main_Title] = 'Isi Aha'
stringMap [strings.Storm_Share_Subtitle] = 'Ndepụta Okwu'
stringMap [strings.Storm_Share_Author] = 'Onye edemede'
stringMap [strings.Storm_Share_Replies] = 'Zaghachi'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'Onweghi ikwu okwu. Buru onye mbu buru onye mbu.'
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'Biko tinye okwu ~'
stringMap [strings.Storm_Share_Delete_Comment_Tips] = 'O doro gị anya na ị chọrọ ihichapụ okwu a? '

// Enyemaka maka iji ihe dị n'ime maapụ mee ihe
stringMap [strings.Mind_Usinghelp_Btn_1] = 'Uzo mkpirisi igodo (windo)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'Igodo mkpirisi (mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'Nkọwa'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Iwu] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'Tinye nwa ọnụ'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[Delet]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'Hichapụ ọnụ nke ugbu a (mara na enweghị ike ihichapụ ya ma ọ bụrụ na ọ nọ na steeti edezi)'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Iwu] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'Gbanwee uzo di ugbu a'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Iwu] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Iwu] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'Gbasawanye map'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Iwu] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'Chee maapụ'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Iwu] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Iwu] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Mgbanwe] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Iwu] + Mgbanwe + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Iwu] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'Wepu edezi'

// Ozugbo ederede
stringMap [strings.Message_Tips_Login_Success] = 'Nbanye ịga nke ọma'
stringMap [strings.Message_Tips_Dlt_Success] = 'Hichapụ nke ọma'
stringMap [strings.Message_Tips_Report_Success] = 'Kpesa ihe ịga nke ọma'
stringMap [strings.Message_Tips_Copy_Success] = 'Detuo ihe ịga nke ọma'
stringMap [strings.Message_Tips_Collction_Success] = 'Mkpokọta ịga nke ọma'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'Avatar ịga nke ọma mgbanwe'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'Mbinye aka mgbanwe nke ọma'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'aha otutu gbanwere nke oma'
stringMap [strings.Message_Tips_Modification_Success] = 'Mgbanwe gara nke ọma'
stringMap [strings.Message_Tips_New_Profile_Success] = 'Ọfụma folda ọhụrụ'
stringMap [strings.Message_Tips_Mobile_Success] = 'Ihe ịga nke ọma nke ekwentị'
stringMap [strings.Message_Tips_Submit_Success] = 'Nyefee nke ọma'
stringMap [strings.Message_Tips_Exchange_Success] = 'Mgbanwe gara nke ọma'
stringMap [strings.Message_Tips_Please_Code] = 'Biko tinye koodu mmeghe'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'Biko kwenye na iwu nzuzo'
stringMap [strings.Message_Tips_Please_Sign_In] = 'Biko banye'
stringMap [strings.Message_Tips_Share_Success] = 'Kesaa ịga nke ọma'
stringMap [strings.Message_Tips_Create_Success] = 'Emepụtara ya nke ọma'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'Aha nchekwa ahụ enweghị ike ịbụ ihe efu'
stringMap [strings.Message_Tips_Selecte_Success] = 'Chọọ agwụla'
stringMap [strings.Message_Tips_Subscription_Success] = 'Ndenye aha na-aga nke ọma'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'wepu aha na ihe ịga nke ọma'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'Ahụghị ọdịnaya metụtara'
stringMap [strings.Message_Tips_Max_Add_Class] = 'Nwere ike ịgbakwunye na ọtụtụ'
stringMap [strings.Message_Tips_Registered_Success] = 'Edere nke ọma'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'Ntinye koodu mbanye kụrụ'
stringMap [strings.Message_Tips_Share_Is_Null] = 'Njikọ njikọ adịghị'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = 'Faịlụ ebugharị enweghị ike ịgafe 3MB'
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'Nweta ikike nke ọma'
stringMap [strings.Message_Tips_Membership_Required] = 'Ndị otu achọrọ'
stringMap [strings.Message_Tips_Save_Success] = 'Chekwaa nke oma'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = "Nyochaa koodu mbanye n'ụzọ gara nke ọma"
stringMap [strings.Message_Tips_Style] = 'A Atiya?'

// Ọnọdụ ndepụta
stringMap [strings.Outline_Menu_Indent] = 'Indent'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'Mbelata indentation'

// Enyemaka
stringMap [strings.Help_Background_Content] = 'Line ndabere metụtara, akara ndabere metụtara, akara ndabere metụtara, akara ndabere metụtara, akara ndabere metụtara, akara ndabere metụtara, akara ndabere metụtara, akara ndabere metụtara, akara ndabere metụtara, akara ndabere metụtara,'
stringMap [strings.Help_Mind_Content] = 'Maapụ nwere nhazi dị iche iche nwere ike ime dị ka mmasị: ụzọ ndu abụọ, map aka ekpe, maapụ aka nri, eserese nzukọ, ụzọ ụzọ abụọ, osisi aka ekpe, osisi aka nri, tebụl'
stringMap [strings.Help_Share_Link_Content] = "Enwere ike ịkekọrịta njikọ njikọ na WeChat, QQ, Weibo na ọwa ndị ọzọ n'ụdị njikọ, ndị enyi nwere ike ịlele ọdịnaya"
stringMap [strings.Using_Templates] = 'Iji ndebiri'

// vkù òkù
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'Biko tinye akara ọkpụkpọ'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Biko tinye akara òkù ziri ezi'
stringMap [strings.User_Lolgin_Optional] = 'Nhọrọ'

// Jiri ihe

stringMap [strings.Cannot_Add_To_Node_Background] = 'Ọ metụtaghị node ndabere'
stringMap [strings.Cannot_Add_To_Img_Background] = 'Ọ metụtaghị ederede ederede'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'Ọ metụtaghị ngwugwu usoro'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Tinye ndabere foto'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Hichapụ ihe oyiyi'
stringMap [strings.Mind_Add_Background_Image] = 'Fanye ndabere oyiyi'
stringMap [strings.Mind_Delete_Background_Image] = 'Hichapụ ihe oyiyi'

stringMap [strings.Permanent_Member] = 'Onye Na-adịgide Adịgide'
stringMap [strings.Insufficient_Storage_Space] = 'Ọnọdụ nchekwa zuru ezu, kwalite ndị otu iji mụbaa ohere. '
stringMap [strings.Used_Space] = 'Ebe nchekwa'
stringMap [strings.Current_Storage] = 'Nchekwa dị ugbu a:'
stringMap [strings.Used_Space_Upgrade] = 'Nchekwa oghere nweta nkwalite'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Mind + Mind Map & Ndetu Super mnemonics, gbakwunye nku na iche echiche, mepụta, gosipụta, atụmatụ, na ndetu'
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = 'Oghere nchekwa adịghị ezu. can nwere ike iji ndebiri mgbe ị kwalite ndị otu gị. '

stringMap [strings.Group_Management] = 'Otu'
stringMap [strings.Save_Img_Fill_Full] = 'kpuchie zuru'
stringMap [strings.Save_Img_Center_Tilt] = 'Center Tilt'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'Ofụri Esịt (ọchịchịrị)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'Uche + Mind Map Watermarking'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'Tinye akara mmiri iji kpuchido ihe okike:'
stringMap [strings.Save_Img_Watermark_Text] = 'Watermark ederede:'
stringMap [strings.Save_Img_Watermark_Logo] = 'Watermark LOGO:'
stringMap [strings.Save_Img_Watermark_Style] = 'markdị mmiri mmiri:'
stringMap [strings.Save_Img_Please_Input] = 'Biko tinye'
stringMap [strings.Save_Img_Chop_Tips] = 'can nwere ike ịnụ ụtọ ikike mmiri mmiri mgbe ị mepere otu'
stringMap [strings.Public_Header_Mindmap_Mind] = 'Ọnọdụ eserese'
stringMap [strings.Copy_Mind_Map] = 'Detuo Mind Map'
stringMap [strings.Font_Style_Bold] = 'Bold'
stringMap [strings.Font_Style_Italics] = 'Akwụkwọ edemede'
stringMap [strings.Font_Style_Delete_Line] = "Strikethrough eriri igwe"
stringMap [strings.Remove_Any] = 'Wepụ'

stringMap [strings.Mindmap_Review] = 'Map nyochaa'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'Mee ka echiche di omimi ma bulie ebe nchekwa, dozie echiche na ozi nke ọma'
stringMap [strings.Mindmap_Review_Content] = "Nyocha nyocha na-ezo aka na playback nke usoro mmezi, a na-egwu ọdịnaya ahụ n'usoro malite na isiokwu bụ isi; nyochaa map nwere ike inyere gị aka mepụta usoro nyocha, nyocha, ma chọta echiche na oghere N'oge usoro nyocha, ị nwere ike ịchọta mkpali dị iche iche "
stringMap [strings.Mindmap_Review_Speed] = 'Ọsọ'
stringMap [strings.Mindmap_Review_Word_Speed] = 'Okwu ọsọ'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'Ndabere ndabere'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'Ugbu playback ọnụ ọnụ magnification'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'Node Playback Order'
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap [strings.Mind_Group_Picture_Upload] = 'Bulite foto'
stringMap [strings.Mind_Group_Upload_From_Local] = 'Mpaghara bulite'
stringMap [strings.Mind_Group_Mind_Mapping] = 'Mind Map'
stringMap [strings.Mind_Group_Information] = 'Data'

stringMap [strings.Mind_Group_Invite_New_Members] = 'Kpoo ndi otu ohuru'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Biko tinye akara ekwentị gị'
stringMap [strings.Mind_Group_Cancel] = 'Kagbuo'
stringMap [strings.Mind_Group_Confirm] = 'OK'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'Nọmba ekwentị ekwenti enweghị ike ịtọgbue'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'Ọkpụkpọ òkù gara nke ọma'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "Ndị otu otu"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Onu ogugu kacha elu nke ndi otu otu"
stringMap [strings.Mind_Group_Membership_Limit] = "Oke Ndị otu"
stringMap [strings.Mind_Group_Current_Member_Limit] = "Ugbu a Member Oke gba"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "Nweta nkwalite onye otu oke"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "Chọọ ndị otu"
stringMap [strings.Mind_Group_Creator] = "Onye Okike"
stringMap [strings.Mind_Group_Administrator] = "Onye nchịkwa"
stringMap [strings.Mind_Group_Number_Of_People] = "Ndị mmadụ"
stringMap [strings.Mind_Group_Selected] = "Ahọrọ ya"
stringMap [strings.Mind_Group_Member] = "Otu"
stringMap [strings.Mind_Group_Check_All] = "Họrọ ha niile"
stringMap [strings.Mind_Group_Remove] = "Wepu"
stringMap [strings.Mind_Group_Invite_New_Members] = "Kpọọ ndị ọhụrụ"
stringMap [strings.Mind_Group_Delete_Members] = "Hichapụ ndị otu"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Biko họrọ onye otu ka ihichapụ"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "O doro gị anya na ịchọrọ iwepụ onye ọrụ na otu a?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "Tinye Nchịkwa"
stringMap [strings.Mind_Group_Accomplish] = "Emechara"
stringMap [strings.Mind_Group_Compile] = "Dezie"
stringMap [strings.Mind_Group_Group_Owner] = "Onye Nwe Otu"
stringMap [strings.Mind_Group_Add_Admin] = "Tinye Nchịkwa"
stringMap [strings.Mind_Group_Group_ID] = "Otu id"
stringMap [strings.Mind_Group_Group_Number] = "Nọmba Otu"
stringMap [strings.Mind_Group_Name] = "Aha"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Biko tinye aha otu a kesara"
stringMap [strings.Mind_Group_Introduce] = "Webata"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Biko webata nkenke otu"
stringMap [strings.Mind_Group_Announcement] = "Ọkwa"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "Amalite Ijekọ Otu Tag"
stringMap [strings.Mind_Group_Custom_Tag] = "Mkpado Ndị Ahịa"
stringMap [strings.Mind_Group_Save] = "Chekwa"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "Aha otu enweghị ike tọgbọrọ"
stringMap [strings.Mind_Group_Establish] = "Mepụta"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "Nye ohere ịmepụta maapụ / folda"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "Nye ohere edezi maapụ"
stringMap [strings.Mind_Group_Holder] = "Onye ọ bụla"
stringMap [strings.Mind_Group_Only_The_Administrator] = "Onye nchịkwa naanị"
stringMap [strings.Mind_Group_Not_Allow] = "A gaghị anabata ya"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "Nye ohere ka ihichapụ maapụ"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Hapụ ọgbakọ ka ha biputere ozi"
stringMap [strings.Mind_Group_Export] = "Mbupụ"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Nye ohere iji chekwaa onyonyo / PDF"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "Mepee akara mmiri otu"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Mgbe emechara, a ga-ebipụta akara ngosi otu na map n'uche"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "Nye ohere ịzọpụta map"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "Zọpụta na Maapụ M"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Ma ọ ga-ekwe ka ikesa oke mmiri ahụ"
stringMap [strings.Mind_Group_Join_The_Way] = "Otu esi esonye"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "Ma a ga-ekwe ka ndị ọhụụ ọhụrụ kpọọ"
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Ma a ga-ekwe ka agbakwunye nọmba otu ọchụchọ"
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Ma ozi a kwesiri ka eme ka ọ dị mma mgbe ọ na-esonye"
stringMap [strings.Mind_Group_Shared_Group_Data] = "Ikekọrịta otu data"
stringMap [strings.Mind_Group_Authority_Management] = "Njikwa ikike"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "Hapụ otu nkekọrịta"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "you ji n'aka ịpụ na otu ndị ọzọ?"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Otu Mkpebi"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "A ga-ehichapụ faịlụ ndị ahụ mgbe etisasịchara otu ahụ. Kwenye iji kesaa?"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "Laghachi na ibe obibi"
stringMap [strings.Mind_Group_Join_The_Group] = "Jikọọ Otu"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "Biko tinye nọmba otu / aha otu iji chọọ"
stringMap [strings.Mind_Group_Apply_To_Join] = "Tinye iji sonyere"
stringMap [strings.Mind_Group_Join] = "Jikọọ"
stringMap [strings.Mind_Group_Verification] = "Nyocha"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "Biko tinye ozi nkwado"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "Ọdịnaya enweghị ike ịbụ ihe efu"
stringMap [strings.Mind_Group_Name] = "Ekekọrịta otu aha"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Biko tinye aha otu a kesara"
stringMap [strings.Mind_Group_Introduction] = "Kekọrịta otu okwu mmeghe"
stringMap [strings.Mind_Group_Group_Indication] = "Nọmba Otu"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "Onye okike dị umengwụ, ewebeghị m ya ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "Amara otu otu"
stringMap [strings.Mind_Group_No_Announcement] = "Enweghị ọkwa ọ bụla"
stringMap [strings.Mind_Group_Click_On] = "Pịa ka ịgbatị"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "Pịa zoo"
stringMap [strings.Mind_Group_Altogether] = "Total"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Ipomi onu ogugu nke oma"
stringMap [strings.Mind_Group_Stick] = "Nọgide n'elu"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "Enwere ọdịnaya ọhụrụ"
stringMap [strings.Mind_Group_Cancel_The_Top] = "Kagbuo Top"
stringMap [strings.Mind_Group_Setting_Up] = "Ntọala"
stringMap [strings.Mind_Group_Usinghelp] = "Iji enyemaka"
stringMap [strings.Mind_Group_Set_The_Message] = "Ozi Ozi"
stringMap [strings.Mind_Group_Apply_To_Join] = "Tinye iji sonyere"
stringMap [strings.Mind_Group_Invite_To_Join] = "Kpọọ isonyere"
stringMap [strings.Mind_Group_Consent] = "Kwere"
stringMap [strings.Mind_Group_Turn_Down] = "Jụ"
stringMap [strings.Mind_Group_Label] = "Label"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Biko tinye aha otu aha otu"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Kpọọ ndị enyi na ndị ọrụ ibe ka ha mekọrịta ma kekọrịta ọdịnaya ihe ọmụma"
stringMap [strings.Mind_Group_Immediately_Create] = "Mepụta ozugbo"
stringMap [strings.Mind_Group_Immediately_To_Join] = "Sonye Ugbu a"

stringMap [strings.Currently_Selected] = "Ugbu a na-eso"
stringMap [strings.LabelManagement] ="Label Management"
stringMap [strings.NoAttention] = "Enweghị Ntị"
stringMap [strings.Mindmap_Review_Play] = 'Gwuo'

stringMap [strings.Mind_Group_Joined] = "esonyere"
stringMap [strings.Mind_Group_Download_App] = "Budata APP"
stringMap [strings.Mind_Group_No_Content] = "Ọ nweghị ọdịnaya ugbu a"
stringMap [strings.Mind_Group_Individual] = "iberibe"
stringMap [strings.Mind_Group_Message_Area] = "Mpaghara Ozi"
stringMap [strings.Mind_Group_No_Message] = "Enweghị ozi, ga ugbu a"
stringMap [strings.Mind_Group_Leave_Message] = "Hapụ Ozi"
stringMap [strings.Mind_Group_Bar] = "Ogwe"
stringMap [strings.Mind_Group_See_More] = "Hụ ihe ọzọ"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "Tinye koodu ọkpụkpọ"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Tinye iji sonye otu nkekọrịta"
stringMap [strings.Reasons_For_Application_0_30_Words] = "Ihe kpatara ntinye (okwu 0-30)"
stringMap [strings.Mind_Group_Send_Out] = "Zipu"
stringMap [strings.Mind_Group_Join_Successfully] = "Soro nke ọma"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "Ezitere ngwa ahụ nke ọma"

stringMap [strings.Mind_Group_Save_The_Mapping] = "Chekwa Maapụ"
stringMap [strings.Mind_Group_Update_Record] = "Gbanwee ndekọ"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "Gbanwee Maapụ"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "Nyocha Nyocha"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Onye ọrụ dị ugbu a na-edezi maapụ ahụ"
stringMap [strings.Mind_Edit_Page_Password] = "Paswọdu"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "Shakekọrịta Otu"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "My MBean"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "ipkwụ Algwọ Alipay"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "Biko gaa na peeji ugwo iji kwuo ugwo"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "Total"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "Kwenye ịkwụ ụgwọ"
stringMap [strings.Mind_Shooping_Redeem_Now] = "Gbapụta ugbu a"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "Ma iji kwenye ịkwụ ụgwọ maka nzụta"
stringMap [strings.Mind_Tips] = "Atụmatụ"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "M Beans gị ezughi oke, ị chọrọ ịzụta M Agwa"

stringMap [strings.Mind_share_scan_code] = "Mind + nyocha koodu"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "Ma iji weghachite ebe ikpeazụ egwuru egwu?"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Fọdụrụ oge egwuregwu"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "A ga-ehichapụ faịlụ ahụ kpamkpam, ị ji n'aka na ọ ga-ekposa?"
stringMap [strings.Group_Mind_Up_One_Level] = "Elu otu ọkwa"
stringMap [strings.Group_Mind_No_Documents] = "Ọ nwebeghị faịlụ"
stringMap [strings.Group_Mind_PaymentSuccessful] = "mentkwụ successfulgwọ ahụ gara nke ọma"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "Ndozi mmekorita ndi mmadu, mmekorita oge di otutu, imekọrịta akwukwo, imeziwanye mmekorita ndi otu"

stringMap [strings.Mind_Edit_Subscript] = "Ndebanye aha"
stringMap [strings.Mind_Edit_Text_Location] = "Ebe Ederede"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "Bubata na Otu nnwekọrịta"

stringMap [strings.Standard_Colors] = "Agba agba"
stringMap [strings.More_Colors] = "Agba ndị ọzọ"
stringMap [strings.Reset_Password] = "Tọgharia Paswọdu"
stringMap [strings.Account_Password_Login] = "Nbanye Paswọdu Akaụntụ"
stringMap [strings.Authentication_code_Login_registration] = "Usoro nkwenye nbanye / ndebanye aha"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "Otu okike okike, otutu ngakọrịta mmekọrịta"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "Ọdịdị map dịgasị iche iche"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "Nnukwu ọmarịcha ọba akwụkwọ ndebiri"
stringMap [strings.Map_Review_Play] = "Map Review-Play"
stringMap [strings.CommunityLearningAndSharingPlatform] = "Ihe omuma ihe omuma na ringkesa Exchange Platform"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "Nweta nkwalite ohere nchekwa"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "Ka m kwupụtakwa na ya"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "O nweghi ozi okwa"
stringMap [strings.Failed_To_Get_Recording_Device] = "Enwetaghị ngwaọrụ ndekọ"

stringMap [strings.Search_Pagination] = "Chọọ Pagination"
stringMap [strings.Please_Enter_A_Paging_Name] = "Biko tinye aha ibe"
stringMap [strings.Operation_Settings] = "Ntọala ọrụ"
stringMap [strings.Shortcut_key_Settings] = "Ntọala igodo ụzọ mkpirisi"
stringMap [strings.Press_Key_Combination] = "Biko pịa nchikota igodo achọrọ"
stringMap [strings.Mind_Reduction] = "Mbelata"
stringMap [strings.Double_Click_Add_Free_Node] = "Pịa oghere oghere ugboro abụọ ka ịgbakwunye ọnụ efu"
stringMap [strings.Shortcut_Conflict] = "Esemokwu igodo ụzọ mkpirisi"
stringMap [strings.Select_Main_Node] = "Họrọ isi ọnụ"
stringMap [strings.Telephone] = "Ekwentị"
stringMap [strings.Microblog] = "Microblog"
stringMap [strings.Address] = "Adreesị"
stringMap [strings.Company_Address] = "Block B, Building 21, No. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "Archive"
stringMap [strings.Mind_Bookshelf] = "akwụkwọ nchekwa"
stringMap [strings.Search_Archive] = "Chọọ ebe nchekwa akwụkwọ"
stringMap [strings.Modify_Archive_Order] = "Gbanwee usoro akwụkwọ ntuziaka"
stringMap [strings.Modification_Order] = "Iwu mgbanwe"
stringMap [strings.Please_Enter_Archive_Name] = "Biko tinye aha ebe a na -edebe akwụkwọ"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "Aha shelf agaghị enwe ike tọgbọ chakoo"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "O doro gị anya na ị chọrọ hichapụ ebe a na -edebe akwụkwọ?"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "O doro gị anya na ị chọrọ kagbuo igbe akwụkwọ a?"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "O doro gị anya na ị ga -ehichapụ maapụ a?"
stringMap [strings.Mind_Edit_Confirm] = "Kwenye"
stringMap [strings.Mind_Import_Map] = "Bubata maapụ"
stringMap [strings.Mind_Bookcase] = "Igbe akwụkwọ"
stringMap [strings.Added_Successfully] = "agbakwunyere nke ọma"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "Aha akpa akwụkwọ enweghị ike ịbụ ihe efu"

stringMap [strings.Mind_Association_Map] = "Maapụ Njikọ"
stringMap [strings.Mind_confirm_Association_Map] = "O doro gị anya na ị ga -ejikọ maapụ a?"
stringMap [strings.Mind_Association_Map_See] = "Lelee Maapụ Njikọ"
stringMap [strings.Mind_Association_Map_Delete] = "Hichapụ maapụ ndị otu"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "Nhazi Nhazi Nhazi"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Mmalite nke Nweta Mmekọrịta"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "Njedebe nke njikọ njikọta"

stringMap [strings.Share_Group_To_Storm] = "Ma ọ bụ ịkekọrịta otu ahụ n'oké ifufe"
stringMap [strings.Apply_To_Join_Storm_Platform] = "N'elu ikpo okwu, ndị ọzọ na -ahụ ma tinye n'ọrụ isonye, ​​jiri ndị na -anụ ọkụ n'obi nwere ihe ọmụma na -atụgharị uche"
stringMap [strings.Pay_To_Goin] = "kwụọ ụgwọ iji sonyere"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "Ekwesịrị ịkwụ ụgwọ agwa mgbe ọ na -esonye"
stringMap [strings.Mbean_Can_Be_Realized] = "Mbean mezuru"

stringMap [strings.Mind_Visit] = "Nleta"
stringMap [strings.Mind_Details] = "Nkọwa"
stringMap [strings.Mind_See] = "Lelee"
stringMap [strings.Earn_Mbeans] = "Nweta mbeans"
stringMap [strings.Mind_Explain] = "Nkọwa"
stringMap [strings.Mind_Parenting] = "Ịzụ nwa"
stringMap [strings.Mind_Internet] = "Internet"
stringMap [strings.Mind_Psychology] = "Psychology"
stringMap [strings.Mind_literature] = "Akwụkwọ"
stringMap [strings.Mind_Technology] = "Teknụzụ"
stringMap [strings.Mind_Workplace] = "Ebe ọrụ"
stringMap [strings.Mind_Life] = "Ndụ"
stringMap [strings.Mind_Subject] = "Isiokwu"
stringMap [strings.Hot_Group] = "Otu na -ekpo ọkụ"
stringMap [strings.Open_Group] = "Mepee Otu"
stringMap [strings.Mind_Group_Used] = "Eji ya"

stringMap [strings.Mind_Click_Select] = "Pịa ka ịhọrọ"
stringMap [strings.Mind_Double_Click_Enter] = "Pịa ugboro abụọ ịbanye"
stringMap [strings.Search_Shared_Groups] = "Chọọ maka otu ekekọrịtara"
stringMap [strings.Search_Storm] = "Oké ifufe"
stringMap [strings.Members_Save_Free] = "Ndị otu na -echekwa n'efu"
stringMap [strings.Bank_Card_Account_Name] = "Biko tinye aha akaụntụ kaadị akụ gị"
stringMap [strings.Bank_Card_No] = "Nọmba Kaadị Bank"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "Biko tinye nọmba kaadị akụ gị"
stringMap [strings.Branch_Sub_Branch_Information] = "ozi ngalaba ngalaba"
stringMap [strings.Branch_Sub_Branch_Example] = "Dịka ọmụmaatụ: Ngalaba Hangzhou Alaka Chengxi"
stringMap [strings.Application_For_Withdrawal] = "Ngwa maka ịwepụ"
stringMap [strings.Withdrawal_Amount] = "ego mwepu"
stringMap [strings.Withdrawal] = "dọrọ"
stringMap [strings.Current_Revenue] = "Ego mbata ugbu a"
stringMap [strings.Recharge] = "Rụgharịa"
stringMap [strings.Current_Total_MBeans] = "ọnụọgụ MBeans dị ugbu a"
stringMap [strings.Withdrawal_Status] = "Ọnọdụ ndọrọ ego"
stringMap [strings.Insufficient_Withdrawal_Amount] = "Ego mwepụ ezughi oke"
stringMap [strings.Load_More] = "Bukwuo ibu"
stringMap [strings.Loading] = "na -ebugo ihe"
stringMap [strings.It_Is_Over] = "Ọ gwụla"
stringMap [strings.Default_Label] = "aha njirimara"
stringMap [strings.Selected_Label] = "akara ahọrọ"
stringMap [strings.Current_Location] = "Ọnọdụ dị ugbu a"
stringMap [strings.MGold_Introduction] = "M na -enweta ọla edo site n'aka Mind+"
stringMap [strings.MGold_Details] = "Otu igwe ojii akwụgoro ụgwọ, enwere maapụ ihe ọmụma, ihe mmụta, wdg n'ime otu. Ọ bụrụ na ndị ọrụ sonye na agwa M, 50% n'ime ha ga -atụgharị ka ọ bụrụ ọla edo M, na ọla edo M ka ukwuu enwere ike wepu 50 "
stringMap [strings.Cloud_Group_Introduction] = "Kekọrịta otu gị, ndị ọzọ nwere ike ịlele ya na ihu oke mmiri ozuzo"
stringMap [strings.Cloud_Group_Details] = "Otu igwe ojii akwụ ụgwọ gị enwere ike wepụta ihe karịrị 50 "
stringMap [strings.Share_Storm_Introduction] = "Kekọrịta obodo gị n'oké ifufe, ka ọtụtụ mmadụ wee mụta ma chọpụta ihe ọmụma n'aka gị"
stringMap [strings.Share_Storm_Details] = "Ọ bụrụ na ị mee ka iji agwa M chekwaa maapụ, ndị ọrụ ndị ọzọ nwere ike iji maapụ ihe ọmụma gị, ị ga -enwetakwa 50% agwa M. Enwere ike iji agwa M zụta ndị otu wee sonye na -akwụ ụgwọ. otu, ị nwekwara ike ịkwụ ụgwọ iji maapụ ndị ọzọ. "

stringMap [strings.Linked_Successfully] = "Ejikọtara nke ọma"
stringMap [strings.Cancel_Archiving] = "Kagbuo ịchekwa akwụkwọ"
stringMap [strings.Please_Select_Archive_Location] = "Biko họrọ ebe ebe a na -edebe akwụkwọ"
stringMap [strings.The_Search_Result_Is_Empty] = "nsonaazụ ọchụchọ efu"

stringMap [strings.Customize_A_Style] = "Ndị na-abụghị ndị otu nwere ike hazie otu ụdị"
stringMap [strings.Adds_Current_Guide_Style_Library] = "Tinye ụdị maapụ ugbu a n'ọba akwụkwọ"
stringMap [strings.Trial_Version] = "Ụdị nnwale"
stringMap [strings.Not_Supported_At_The_Moment] = "Akwadoghi ya ugbu a"
stringMap [strings.Mind_File] = "faịlụ"
stringMap [strings.Mind_Import] = "Bubata"
stringMap [strings.Importing] = "Mbubata"

stringMap [strings.Mind_Edit_Insert_Video] = "Tinye vidiyo"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Dọrọ na dobe vidiyo ebe a"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "Ogologo vidiyo ebugo agaghị agafe 60s"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "Lelee vidiyo"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "Hichapụ vidiyo"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "Vidiyo ebugo agaghị agafe 10MB"
stringMap [strings.Mind_Upload_Video_Restrictions] = "Naanị na -akwado ibugo vidiyo n'ụdị MP4, ruo ogo 120 na 10M n'ogo"
stringMap [strings.Mind_Video_Size] = "Ogo vidiyo"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "Mbupụ Okwu"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "Mbupụ TXT"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap [strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap [strings.Export_Failed] = "Mbupụ dara"
stringMap [strings.Add_File] = "Tinye faịlụ"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "Dọrọ na faịlụ ozugbo iji bulite"
stringMap [strings.Supported_Imported_File_Types] = "Ụdị faịlụ akwadoro ugbu a maka mbubata: Okwu (naanị .docx), XMind, .txt"
stringMap [strings.No_Permission_Do_This] = "Ị nweghị ikike ịrụ ọrụ a"

stringMap [strings.Upgrade_To_Professional] = "Kwalite gaa na Ọkachamara Ọkachamara"
stringMap [strings.Non_Members_Insert_Videos] = "Ndị na-abụghị ndị otu nwere ike tinye vidiyo naanị awa 24 ka ndebanye aha"
stringMap [strings.Upload_Failed] = "Mbugo kụrụ afọ n'ala"
stringMap [strings.Upload_Failed_Title_Message] = "Mbupụ emezughị, biko pịa iji gosi, hichapụ foto ndị fọdụrụ na -ebugaghị"

stringMap [strings.Mind_Edit_Members_Use] = "Ndị otu na -eji"
stringMap [strings.Invitation_Code] = "Koodu vkù"
stringMap [strings.Student_Certification] = "Asambodo ụmụ akwụkwọ"
stringMap [strings.I_Get_It] = "M ga -atụpụ ntuziaka"
stringMap [strings.Welcome_To_Mind] = "Nabata nna ukwu ahụ ka ọ sonyere Mind+, ka anyị malite njem echiche!"
stringMap [strings.Mind_Before] = "Nke mbụ"
stringMap [strings.Try_A_Map] = "Gbalịa maapụ"
stringMap [strings.Click_Create] = "Pịa imepụta"
stringMap [strings.Click_Create_A_Mind_Map] = "Pịa iji mepụta maapụ uche"
stringMap [strings.Mind_About] = "Banyere"
stringMap [strings.See_Checking_Data] = "Lelee ozi nkeonwe"
stringMap [strings.Modify_Checking_Data] = "Gbanwee ozi nkeonwe"
stringMap [strings.Mind_Shear] = "ịkwa osisi"

stringMap [strings.Mind_Style_Empty] = "Enweghị mmasị ma ọ bụ ụdị omenala"
stringMap [strings.Mind_Branch_Free_Layout] = "Nhazi okirikiri ngalaba"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "Enwere ike ịdọkpụrụ usoro ụzọ abụọ na afụ map ka enweghị ike ịdọrọ ya na nhazi"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Isiokwu Drag Snap"

stringMap [strings.Select_A_Node] = "Họrọ isiokwu"
stringMap [strings.Create_Child_Node] = "Mepụta isiokwu nwata"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "Ọzọ anyị ga -etinye nke mara mma na isiokwu nwata"
stringMap [strings.Click_Icon_Panel] = "Pịa akara ngosi akara ngosi"
stringMap [strings.Select_An_Icon_You_Like] = "Họrọ akara ngosi masịrị gị"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "Ọzọ anyị ga -eji nke ọhụrụ dochie maapụ uche"
stringMap [strings.Select_The_Central_Theme] = "Họrọ isi okwu"
stringMap [strings.Switch_To_Canvas_Panel] = "Gbanwee na Ogwe osisi Kanvas"
stringMap [strings.Click_Layout] = "Pịa na Nhazi"
stringMap [strings.Choose_A_Layout_You_Like] = "Họrọ nhazi nke masịrị gị"
stringMap [strings.Mind_Map_Save_Share] = "Ekele, ị meela ọmarịcha maapụ uche nke ọma! Ka anyị chekwaa ya ma soro ndị enyi gị kerịta ya ~"
stringMap [strings.Click_Save_Picture] = "Pịa iji chekwaa foto a"
stringMap [strings.Click_OK] = "Pịa OK"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "Ekele gị imecha ntuziaka novice ~"
stringMap [strings.Upload_Succeeded] = "Ebugo nke ọma"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "Ụdị ọsọ ọsọ"
stringMap [strings.Mind_Extremely_Important] = "dị oke mkpa"
stringMap [strings.Mind_Important] = "Dị mkpa"
stringMap [strings.Mind_Commonly] = "Izugbe"
stringMap [strings.Mind_Ordinary] = "Nkịtị"
stringMap [strings.Member_Exclusive] = "Njirimara a bụ naanị maka ndị otu. Ị chọrọ ịkwalite ndị otu gị?"
stringMap [strings.Member_Exclusive_Color_Scheme] = "Atụmatụ agba a bụ naanị maka ndị otu. Ị chọrọ ịkwalite ndị otu gị?"
stringMap [strings.Mind_Enlarge] = "gbasaa"
stringMap [strings.Mind_Narrow] = "dị warara"
stringMap [strings.Mind_Center] = "gbadoro anya"
stringMap [strings.Mind_Full_Screen] = "Ihuenyo zuru oke"

stringMap [strings.Mind_Association_Node] = "Njikọ ọnụ"

stringMap[strings.Mind_Resource_See] = "Lee mgbakwunye"
stringMap[strings.Mind_Resource_Delete] = "Hichapụ mgbakwunye"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Dọrọ wee dobe faịlụ ebe a"
stringMap[strings.Mind_Edit_choose_explain] = "Họrọ faịlụ"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "Faịlụ ebugoro enweghị ike gafere 20MB"

stringMap[strings.Please_Enter_Mind_Password] = "Hãy nhập mật khẩu 4-8 số."
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "Nhập mật khẩu đi."
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "Chức năng này là chức năng thành viên"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "đã gỡ bỏ mật khẩu tập tin"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "Số của mật khẩu không đủ. Hãy nhập mật khẩu với bốn con số đảo số 8."
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "Mật khẩu quá dài. Xin hãy nhập mật khẩu với 4 tính số 8."
stringMap[strings.Please_Enter_Mind_Password_Success] = "Đặt mật khẩu thành công"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "Lỗi định dạng mật khẩu"
stringMap[strings.Mind_Load_Data_Error] = "Lỗi tải dữ liệu"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Lỗi mật khẩu"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Chỉ có người tạo bản đồ này mới đặt được mật khẩu"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;