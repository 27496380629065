let MindElementShapeType = {
    Unknown:-1,
    Corner:0,
    Semicircle:1,
    Diamond:2,
    Cloud:3,
    Underline:4,
    Circular:5,
    Hexagon:6,
    Left_Right_Three_Side:7, //左右各三条边
    Left_Straight_Right_Arrow:8, //左直右箭头
    Right_Straight_Left_Arrow:9, //右直左箭头
    RightArrow:10, //右箭头
    LeftArrow:11, //左箭头
    Left_Right_Three_Circular:12,       //左右三小圆
    Left_Right_Corner_Top_Bottom_Semicircle:13,      //左右小圆角顶部底部拱起
    Triangle:14,       //三角形
    CircularAndRight:15,       //圆角直角对称型
    InvertedTriangle:16,
    RightTriangle:17,
    LeftTriangle:18,
    CornerShadow:19,
    Ring:20,
    Ring2:21,
    Ring3:22,
    Ring2_UP:23,
    Corner_Right:24,
    RightArrow2:25, //右箭头
    LeftArrow2:26, //左箭头
    Circular_Right_Top:27,
    Three_Circular_Cloud:28,
    Ellipse:29,
    Ellipse_3D:30,
    Cube:31,
    Form:32,
    Oblique_corner:33, //斜角60度矩形
    Star:34, //五角星
    Gemstone:35, // //宝石
    Heart:36, // //心型
    Octagonal:37, //八边形
    Large_braces:38, //大扣号{}
    Angle_braces:39, //尖括号<>
    Middle_brackets:40, //扣号[]
    Brackets:41, //扣号:
    Regular_hexagon:42, //正六边形
    Shield:43, //盾牌
    DrawCorner: 44,  //手绘矩形
    DrawCircular: 45,       //手绘圆
    DrawEllipse: 46,    //手绘椭圆
    Two_Underline: 47,  //双下划线
    Book: 48,  //书本
    Card: 49,  //卡片
    Left_Right_Corner_Top_Bottom_Corner: 50,  //卡片

    // Left_Right_Corner_Top_Bottom_Corner: 51,  //卡片

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.Unknown;
    }

}

export default MindElementShapeType