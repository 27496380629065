// import mind.yushu.com.mindmap.main.MindMapApplication;

import EdgeInsets from "../../basemode/EdgeInsets"
import Config from "../Config"
import Colors from "../../../../utils/Colors"
import UiUtil from "../../../../utils/UiUtil"
import MindElementData from "../../../../viewmodel/mindelementdata/MindElementData"
import IconElementContent from "../../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import ImageContent from "../../../../viewmodel/mindelementdata/mindcontent/ImageContent"
import MindElementContent from "../../../../viewmodel/mindelementdata/mindcontent/MindElementContent"
import Size from "../../../../viewmodel/core/base/Size"
import TextCalculation from './TextCalculation'
import TextAndIconCalculation from "./TextAndIconCalculation"
import TextIAndmageCalculation from "./TextIAndmageCalculation"
import TextAndRemarksCalculation from "./TextAndRemarksCalculation"
import TextAndIconAndRemarksCalculation from "./TextAndIconAndRemarksCalculation"
import TextAndLinkCalculation from "./TextAndLinkCalculation"
import TextAndLinkAndRemarksCalculation from "./TextAndLinkAndRemarksCalculation"
import TextAndIconAndLinkCalculation from "./TextAndIconAndLinkCalculation"
import TextAndIconAndLinkAndRemarksCalculation from "./TextAndIconAndLinkAndRemarksCalculation"
import ImageCalculation from "./ImageCalculation"
import TextAndImageAndIconCalculation from "./TextAndImageAndIconCalculation"
import TextAndImageAndRemarksCalculation from "./TextAndImageAndRemarksCalculation"
import TextAndImageAndLinkAndRemarksCalculation from "./TextAndImageAndLinkAndRemarksCalculation"
import TextAndConnectMapCalculation from "./TextAndConnectMapCalculation"
import StatisticsCalculation from "./StatisticsCalculation"
import TextAndImageAndLinkCalculation from "./TextAndImageAndLinkCalculation"
import Util from "../../../../utils/Util"
import InnerHTMLParse from "../../../../components/complieComponents/tools/InnerHTMLParse"
import TextAndImageAndConnectMap from "./TextAndImageAndConnectMap"

import TextAndRemarksAndConnectMap from "./TextAndRemarksAndConnectMap"
import TextAndIconAndConnectMap from "./TextAndIconAndConnectMap"
import TextAndLinkAndConnectMap from "./TextAndLinkAndConnectMap"
import TextAndIconAndLinkAndConnectMap from "./TextAndIconAndLinkAndConnectMap"
import TextAndImageAndLinkAndConnectMap from "./TextAndImageAndLinkAndConnectMap"
import TextAndIconAndRemarksAndConnectMap from "./TextAndIconAndRemarksAndConnectMap"
import TextAndImageAndRemarksAndConnectMap from "./TextAndImageAndRemarksAndConnectMap"
import Strings from "../../../../utils/Strings"
import TextAndTaskCalculation from "./TextAndTaskCalculation"
import TextAndImageAndTaskCalculation from "./TextAndImageAndTaskCalculation"
import TextAndIconAndTaskCalculation from "./TextAndIconAndTaskCalculation"
import TextAndImageAndIconAndTaskCalculation from "./TextAndImageAndIconAndTaskCalculation"
import TextAndLinkAndTaskCalculation from "./TextAndLinkAndTaskCalculation"
import TextAndIconAndLinkAndTaskCalculation from "./TextAndIconAndLinkAndTaskCalculation"
import TextAndRemarksAndTaskCalculation from "./TextAndRemarksAndTaskCalculation"
import TextAndIconAndRemarksAndTaskCalculation from "./TextAndIconAndRemarksAndTaskCalculation"
import TextAndImageAndRemarksAndTaskCalculation from "./TextAndImageAndRemarksAndTaskCalculation"
import TextAndLinkAndRemarksAndTaskCalculation from "./TextAndLinkAndRemarksAndTaskCalculation"
import TextAndIconAndLinkAndRemarksAndTaskCalculation from "./TextAndIconAndLinkAndRemarksAndTaskCalculation"
import TextAndImageAndLinkAndRemarksAndTaskCalculation from "./TextAndImageAndLinkAndRemarksAndTaskCalculation"
import TextAndConnectMapAndTaskCalculation from "./TextAndConnectMapAndTaskCalculation"
import TextAndImageAndConnectMapAndTask from "./TextAndImageAndConnectMapAndTask"
import TextAndIconAndConnectMapAndTask from "./TextAndIconAndConnectMapAndTask"
import TextAndLinkAndConnectMapAndTask from "./TextAndLinkAndConnectMapAndTask"
import TaskContent from "../../../../viewmodel/mindelementdata/mindcontent/TaskContent"
import TextAndLatexCalculation from "./TextAndLatexCalculation"
import TextAndTaskAndLatexCalculation from "./TextAndTaskAndLatexCalculation"
import TextAndImageAndLatexCalculation from "./TextAndImageAndLatexCalculation"
import TextAndImageAndTaskAndLatexCalculation from "./TextAndImageAndTaskAndLatexCalculation"
import TextAndIconAndLatexCalculation from "./TextAndIconAndLatexCalculation"
import TextAndIconAndTaskAndLatexCalculation from "./TextAndIconAndTaskAndLatexCalculation"
import TextAndLinkAndLatexCalculation from "./TextAndLinkAndLatexCalculation"
import TextAndLinkAndTaskAndLatexCalculation from "./TextAndLinkAndTaskAndLatexCalculation"
import TextAndIconAndLinkAndLatexCalculation from "./TextAndIconAndLinkAndLatexCalculation"
import TextAndRemarksAndLatexCalculation from "./TextAndRemarksAndLatexCalculation"
import TextAndRemarksAndTaskAndLatexCalculation from "./TextAndRemarksAndTaskAndLatexCalculation"
import TextAndIconAndRemarksAndLatexCalculation from "./TextAndIconAndRemarksAndLatexCalculation"
import TextAndIconAndRemarksAndTaskAndLatexCalculation from "./TextAndIconAndRemarksAndTaskAndLatexCalculation"
import TextAndConnectMapAndLatexCalculation from "./TextAndConnectMapAndLatexCalculation"
import TextAndConnectMapAndTaskAndLatexCalculation from "./TextAndConnectMapAndTaskAndLatexCalculation"
import TextAndImageAndConnectMapAndLatex from "./TextAndImageAndConnectMapAndLatex"
import TextAndIconAndConnectMapAndLatex from "./TextAndIconAndConnectMapAndLatex"
import TextAndLinkAndConnectMapAndLatex from "./TextAndLinkAndConnectMapAndLatex"
import TextAndIconAndLinkAndConnectMapAndLatex from "./TextAndIconAndLinkAndConnectMapAndLatex"
import TextAndRemarksAndConnectMapAndLatex from "./TextAndRemarksAndConnectMapAndLatex"
import TextAndIconAndRemarksAndConnectMapAndLatex from "./TextAndIconAndRemarksAndConnectMapAndLatex"
import TextAndImageAndRemarksAndConnectMapAndLatex from "./TextAndImageAndRemarksAndConnectMapAndLatex"
import TextAndLinkAndRemarksAndLatexCalculation from "./TextAndLinkAndRemarksAndLatexCalculation"
// import EditMindmapVM from "../../../../viewmodel/mindelementdata/vm/EditMindmapVM"
import katex from "katex"
import NodeLayoutType from "../../../../viewmodel/datatype/NodeLayoutType"
import TextAndResourceCalculation from "./TextAndResourceCalculation"
import TextAndResourceAndLatexCalculation from "./TextAndResourceAndLatexCalculation"
import TextAndResourceAndTaskCalculation from "./TextAndResourceAndTaskCalculation"
import TextAndResourceAndTaskAndLatexCalculation from "./TextAndResourceAndTaskAndLatexCalculation"
import TextAndIconAndResourceAndLatexCalculation from "./TextAndIconAndResourceAndLatexCalculation"
import TextAndIconAndResourceAndTaskCalculation from "./TextAndIconAndResourceAndTaskCalculation"
import TextAndIconAndResourceCalculation from "./TextAndIconAndResourceCalculation"
import TextAndImageAndResourceCalculation from "./TextAndImageAndResourceCalculation"
import TextAndMoreCalculation from "./TextAndMoreCalculation"
import TextAndMoreAndLatexCalculation from "./TextAndMoreAndLatexCalculation"
import TextAndMoreAndTaskCalculation from "./TextAndMoreAndTaskCalculation"
import TextAndMoreAndTaskAndLatexCalculation from "./TextAndMoreAndTaskAndLatexCalculation"
import TextAndIconAndMoreAndLatexCalculation from "./TextAndIconAndMoreAndLatexCalculation"
import TextAndIconAndMoreAndTaskCalculation from "./TextAndIconAndMoreAndTaskCalculation"
import TextAndIconAndMoreCalculation from "./TextAndIconAndMoreCalculation"
import TextAndImageAndMoreCalculation from "./TextAndImageAndMoreCalculation"
import MoreContent from "../../../../viewmodel/mindelementdata/mindcontent/MoreContent"
import MindElementAlignmentType from "../../../../viewmodel/datatype/MindElementAlignmentType"
import MindElementShapeType from "../../../../viewmodel/datatype/MindElementShapeType"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import HandwritingContentCalculation from "./HandwritingContentCalculation"

// import EditMindmapVM from "../../../../viewmodel/mindelementdata/vm/EditMindmapVM"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class MindElementCalculation {

    constructor() {
        this.data = null;
        this.nodeLayoutType = NodeLayoutType.LAYOUT_LEFT;
        this.layoutRefreshCalculeDataList = [];
    }

    set(data,nodeLayoutType = NodeLayoutType.LAYOUT_LEFT) {
        this.data = data;
        this.nodeLayoutType = nodeLayoutType;
        return this
    }

    calcule(checkLayoutRefresh = true) {
        if (this.data == null || this.data.isEmpty() || 
            (checkLayoutRefresh && this.layoutRefreshCalculeDataList.includes(this.data.id))) {
            return
        }
        this.layoutRefreshCalculeDataList.push(this.data.id);
        var iconElementContents = this.data.iconElementContents;
        var imageContent = this.data.imageContent;
        var isIncludeLink = this.data.isContainLinkContent();
        var isTitleContent = this.data.isCardContent();
        var isIncludeRemarks = this.data.isContainRemarksContent();
        var isIncludeConnectMap = this.data.isContainConnectMapContent();
        var isIncludeIcon = iconElementContents != null && iconElementContents.length > 0;
        var isIncludeImage = imageContent != null;
        var isStatisticsContent = this.data.isStatisticsContent();
        var isTaskContent = this.data.taskContent != null && !(new Strings().isEmpty(this.data.taskContent.getContentText()));
        var isLatextContent = this.data.isContainLatextContent();
        var isResourceContent = this.data.isContainResourceContent();
        var isHandwritingContent = this.data.isHandwritingContent();
        var moreButtonCount = 0;
        var width = 10;
        var height = 10;
        if (isIncludeLink) {
            moreButtonCount += 1;
            width = this.data.linkElementContent.width;
            height = this.data.linkElementContent.height;
        }
        if (isIncludeRemarks) {
            moreButtonCount += 1;
            width = this.data.remarksElementContent.width;
            height = this.data.remarksElementContent.height;
        }
        if (isIncludeConnectMap) {
            moreButtonCount += 1;
            width = this.data.connectMapElementContent.width;
            height = this.data.connectMapElementContent.height;
        }
        if (isResourceContent) {
            moreButtonCount += 1;
            width = this.data.resourceContent.width;
            height = this.data.resourceContent.height;
        }
        let isMoreContent = moreButtonCount > 1;

        if (isMoreContent) {
            isIncludeRemarks = false;
            isIncludeLink = false;
            isIncludeConnectMap = false;
            isResourceContent = false;
            this.data.moreContent = new MoreContent(width, height);
        } else {
            this.data.moreContent = null;
        }
        var textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        var isIncludeText = textMindElementContent != null;

        if (isStatisticsContent) {
            new StatisticsCalculation(this.data).calculate();
        } else if (isHandwritingContent) {
            new HandwritingContentCalculation(this.data).calculate();
        } else if (!isIncludeImage &&
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeText &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeImage &&
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeText &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeImage &&
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeText &&
            isTaskContent &&
            !isLatextContent &&
                !isMoreContent) {
            new TextAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeImage &&
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeText &&
            isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndTaskAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeText &&
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new ImageCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeIcon &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextIAndmageCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeIcon &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndImageAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if
            (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeIcon &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndImageAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeIcon &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndImageAndTaskAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if
            (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndIconCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
                !isIncludeConnectMap &&
                !isResourceContent &&
                !isIncludeImage &&
                !isIncludeLink &&
                isIncludeText &&
                isIncludeIcon &&
                !isTaskContent &&
                isLatextContent &&
                !isMoreContent) {
            new TextAndIconAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if
            (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndIconAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndIconAndTaskAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if
            (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isMoreContent) {
            new TextAndImageAndIconCalculation(this.data, this.nodeLayoutType).calculate();
        } else if
            (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            isTaskContent &&
            !isMoreContent) {
            new TextAndImageAndIconAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeImage &&   //Resource start
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            !isIncludeConnectMap &&
            isResourceContent &&
            isIncludeText &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndResourceCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndResourceAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndResourceAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndResourceAndTaskAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndIconAndResourceAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            isTaskContent &&
            !isMoreContent) {
            new TextAndIconAndResourceAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndIconAndResourceCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            isResourceContent &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isMoreContent) {
            new TextAndImageAndResourceCalculation(this.data, this.nodeLayoutType).calculate(); //Resource end
        } else if (!isIncludeImage &&   //More start
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeText &&
            !isTaskContent &&
            !isLatextContent &&
            isMoreContent) {
            new TextAndMoreCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            isMoreContent) {
            new TextAndMoreAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            !isLatextContent &&
            isMoreContent) {
            new TextAndMoreAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            isLatextContent &&
            isMoreContent) {
            new TextAndMoreAndTaskAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            isMoreContent) {
            new TextAndIconAndMoreAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            isTaskContent &&
            isMoreContent) {
            new TextAndIconAndMoreAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            isMoreContent) {
            new TextAndIconAndMoreCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            isMoreContent) {
            new TextAndImageAndMoreCalculation(this.data, this.nodeLayoutType).calculate(); //More end
        } else if
            (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndLinkCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndLinkAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if
            (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndLinkAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndLinkAndTaskAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
    } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndIconAndLinkCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndIconAndLinkAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            isTaskContent &&
            !isMoreContent) {
            new TextAndIconAndLinkAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
        } else if
            (!isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isMoreContent) {
            new TextAndImageAndLinkCalculation(this.data, this.nodeLayoutType).calculate();
        } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndRemarksCalculation(this.data, this.nodeLayoutType).calculate();
        } else if (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndRemarksAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
        } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndRemarksAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
    } else if (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndRemarksAndTaskAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndIconAndRemarksCalculation(this.data, this.nodeLayoutType).calculate();
    } else if (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndIconAndRemarksAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndIconAndRemarksAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
    } else if (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndIconAndRemarksAndTaskAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndImageAndRemarksCalculation(this.data, this.nodeLayoutType).calculate();
    } else if (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndImageAndRemarksAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
    } else if(isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            !isMoreContent) {
        new TextAndImageAndRemarksAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndLinkAndRemarksCalculation(this.data, this.nodeLayoutType).calculate();
    } else if (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndLinkAndRemarksAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            !isMoreContent) {
        new TextAndLinkAndRemarksAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isMoreContent) {
        new TextAndIconAndLinkAndRemarksCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
                (isIncludeRemarks &&
                !isIncludeConnectMap &&
                !isResourceContent &&
                !isIncludeImage &&
                isIncludeLink &&
                isIncludeText &&
                isIncludeIcon &&
                isTaskContent &&
                !isMoreContent) {
        new TextAndIconAndLinkAndRemarksAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isMoreContent) {
        new TextAndImageAndLinkAndRemarksCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            !isMoreContent) {
        new TextAndImageAndLinkAndRemarksAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (!isIncludeImage && // ConnectMap
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeText &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndConnectMapCalculation(this.data, this.nodeLayoutType).calculate();
    } else if (!isIncludeImage && // ConnectMap
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeText &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndConnectMapAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
    }  else if (!isIncludeImage && // ConnectMap
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeText &&
            isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndConnectMapAndTaskCalculation(this.data, this.nodeLayoutType).calculate();
    } else if (!isIncludeImage && // ConnectMap
            !isIncludeIcon &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeText &&
            isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndConnectMapAndTaskAndLatexCalculation(this.data, this.nodeLayoutType).calculate();
    } else if
            (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeIcon &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndImageAndConnectMap(this.data, this.nodeLayoutType).calculate();
    } else if (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeIcon &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndImageAndConnectMapAndLatex(this.data, this.nodeLayoutType).calculate();
    }  else if (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeIcon &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isTaskContent &&
            !isMoreContent) {
        new TextAndImageAndConnectMapAndTask(this.data, this.nodeLayoutType).calculate();
    } else if
            (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndIconAndConnectMap(this.data, this.nodeLayoutType).calculate();
    } else if (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndIconAndConnectMapAndLatex(this.data, this.nodeLayoutType).calculate();
    } else if
            (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            isTaskContent &&
            !isMoreContent) {
        new TextAndIconAndConnectMapAndTask(this.data, this.nodeLayoutType).calculate();
    } else if
            (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndLinkAndConnectMap(this.data, this.nodeLayoutType).calculate();
    } else if (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndLinkAndConnectMapAndLatex(this.data, this.nodeLayoutType).calculate();
    } else if
            (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            isTaskContent &&
            !isMoreContent) {
        new TextAndLinkAndConnectMapAndTask(this.data, this.nodeLayoutType).calculate();
    } else if (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndIconAndLinkAndConnectMap(this.data, this.nodeLayoutType).calculate();
    } else if (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
        new TextAndIconAndLinkAndConnectMapAndLatex(this.data, this.nodeLayoutType).calculate();
    } else if
            (!isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isMoreContent) {
        new TextAndImageAndLinkAndConnectMap(this.data, this.nodeLayoutType).calculate();
    } else if
            (isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
        new TextAndRemarksAndConnectMap(this.data, this.nodeLayoutType).calculate();
    } else if (isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndRemarksAndConnectMapAndLatex(this.data, this.nodeLayoutType).calculate();
        } else if
            (isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndIconAndRemarksAndConnectMap(this.data, this.nodeLayoutType).calculate();
        } else if (isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            !isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            isIncludeIcon &&
            !isTaskContent &&
            isLatextContent &&
            !isMoreContent) {
            new TextAndIconAndRemarksAndConnectMapAndLatex(this.data, this.nodeLayoutType).calculate();
        } else if
            (isIncludeRemarks &&
            isIncludeConnectMap &&
            !isResourceContent &&
            isIncludeImage &&
            !isIncludeLink &&
            isIncludeText &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isMoreContent) {
            new TextAndImageAndRemarksAndConnectMap(this.data, this.nodeLayoutType).calculate();
        } else if (isIncludeRemarks &&
                isIncludeConnectMap &&
                !isResourceContent &&
                isIncludeImage &&
                !isIncludeLink &&
                isIncludeText &&
                !isIncludeIcon &&
                !isTaskContent &&
                isLatextContent &&
                !isMoreContent) {
            new TextAndImageAndRemarksAndConnectMapAndLatex(this.data, this.nodeLayoutType).calculate();
        }

        if (isTitleContent) {
            let titleContent = this.data.titleContent
            if (this.data.width < titleContent.width) {
                this.setDataContentMoveLeft(titleContent.width - this.data.width, true)
            }
            this.setDataContentMoveTop(titleContent.height)
            titleContent.x = (this.data.width - titleContent.width) / 2
            titleContent.y = 0
        }
    }

    getTextUIEdgeInsets() {
        return (new TextCalculation(this.data,this.nodeLayoutType)).getTextEdgeInsets();
    }


    // 原来的caluleText()
    caluleTextForData() {
        if (this.data.customWidth > new UiUtil().dip2px(5)) {            
            if (this.data.customWidth > new UiUtil().dip2px(12)) {
                if (this.data.isCardContent()) {
                    this.caluleTitleMaxWidth(this.data.customWidth)
                }
                return this.caluleTextMaxWidth(this.data.customWidth);
            } else {
                if (this.data.isCardContent()) {
                    this.caluleTitleMaxWidth(new UiUtil().dip2px(12))
                }
                return this.caluleTextMaxWidth(new UiUtil().dip2px(12));
            }
        } else {
            if (this.data.isCardContent()) {
                this.caluleTitleMaxWidth(0)
            }
            return this.caluleTextMaxWidth(0);
        }
    }

    caluleTextMaxWidth(maxWidth) {
        let text = "";
        let textBold = false;
        let textFontSize = 18;
        let textItalics = false;
        let styleCells = new Array();
        let isBgColor = false;
        let fontFamily = "";

        let textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
            textBold = this.data.textContent.textBold;
            textFontSize = this.data.textContent.textFontSize;
            textItalics = this.data.textContent.textItalics;
            isBgColor = this.data.textContent.isBackgorunColor();
            styleCells = this.data.textContent.styleCells;
            fontFamily = this.data.textContent.fontFamily;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
            textBold = this.data.generalizationContent.textBold;
            textFontSize = this.data.generalizationContent.textFontSize;
            textItalics = this.data.generalizationContent.textItalics;
            isBgColor = this.data.generalizationContent.isBackgorunColor();
            styleCells = this.data.generalizationContent.styleCells;
            fontFamily = this.data.generalizationContent.fontFamily;
        }
        if (textMindElementContent == null) {
            return this;
        }
        if (maxWidth <= 0) {
            maxWidth = Config.contentMaxWidth
        }
        let box;
        if (styleCells == null || styleCells.length == 0) {
            box = this.publicCaluleText(text, textFontSize, textBold, textItalics, fontFamily,maxWidth)
        } else {
            box = this.publicCaluleTextByTextStyles(Util.getContentText(this.data), textFontSize, textBold, textItalics, fontFamily, styleCells,maxWidth)
        }
        let edgeInsets = this.getTextUIEdgeInsets();
        if (text.length == 0) {
            let width = box.getWidth() + edgeInsets.left/2 + edgeInsets.right/2 + (isBgColor ? 16 : 0);
            let height = box.getHeight() + edgeInsets.top/2 + edgeInsets.bottom/2;
            textMindElementContent.width = width;
            textMindElementContent.height = height;
        } else {
            let width = box.getWidth() + edgeInsets.left + edgeInsets.right + (isBgColor ? 16 : 0);
            let height = box.getHeight() + edgeInsets.top + edgeInsets.bottom;
            textMindElementContent.width = width;
            textMindElementContent.height = height;
        }       
        return this;
    }

    caluleTitleMaxWidth(maxWidth) {
        if (this.data.titleContent == null) {
            return
        }
        let text = "";
        let textBold = false;
        let textFontSize = 18;
        let textItalics = false;
        let styleCells = new Array();
        let isBgColor = false;
        let fontFamily = "";

        let textMindElementContent = this.data.titleContent;
        text = this.data.titleContent.text;
        textBold = this.data.titleContent.textBold;
        textFontSize = this.data.titleContent.textFontSize;
        textItalics = this.data.titleContent.textItalics;
        isBgColor = this.data.titleContent.isBackgorunColor();
        styleCells = this.data.titleContent.styleCells;
        fontFamily = this.data.titleContent.fontFamily;
        if (text == null || text.length == 0) {
            text = " "
        }
        if (textMindElementContent == null) {
            return this;
        }
        if (maxWidth <= 0) {
            maxWidth = Config.contentMaxWidth
        }
        let box;
        if (styleCells == null || styleCells.length == 0) {
            box = this.publicCaluleText(text, textFontSize, textBold, textItalics, fontFamily,maxWidth)
        } else {
            box = this.publicCaluleTextByTextStyles(Util.getContentText(this.data), textFontSize, textBold, textItalics, fontFamily, styleCells,maxWidth)
        }
        let edgeInsets = this.getTextUIEdgeInsets();
        if (text.length == 0) {
            let width = box.getWidth() + edgeInsets.left/2 + edgeInsets.right/2 + (isBgColor ? 16 : 0);
            let height = box.getHeight() + edgeInsets.top/2 + edgeInsets.bottom/2;
            textMindElementContent.width = width;
            textMindElementContent.height = height;
        } else {
            let width = box.getWidth() + edgeInsets.left + edgeInsets.right + (isBgColor ? 16 : 0);
            let height = box.getHeight() + edgeInsets.top + edgeInsets.bottom;
            textMindElementContent.width = width;
            textMindElementContent.height = height;
        }       
        return this;
    }

    caluleTextForLimitLength( maxWidth, textCount) {
        let text = "";
        let textBold = false;
        let textFontSize = 18;
        let textItalics = false;
        let isBgColor = false;
        let styleCells = new Array();

        let textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
            textBold = this.data.textContent.textBold;
            textFontSize = this.data.textContent.textFontSize;
            textItalics = this.data.textContent.textItalics;
            isBgColor = this.data.textContent.isBackgorunColor();
            styleCells = this.data.textContent.styleCells;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
            textBold = this.data.generalizationContent.textBold;
            textFontSize = this.data.generalizationContent.textFontSize;
            textItalics = this.data.generalizationContent.textItalics;
            isBgColor = this.data.generalizationContent.isBackgorunColor();
            styleCells = this.data.generalizationContent.styleCells;
        }

        if (textMindElementContent == null) {
            return this;
        }
        if (text.length > textCount) {
            text = text.substr(0, textCount)
        }
        
        let box;
        if (styleCells == null || styleCells.length == 0) {
            box = this.publicCaluleText(text, textFontSize, textBold, textItalics, Config.textFontFamily,maxWidth)
        } else {
            text = Util.getContentText(this.data)
            box = this.publicCaluleTextByTextStyles(text, textFontSize, textBold, textItalics, Config.textFontFamily, styleCells,maxWidth)
        }
        let edgeInsets = this.getTextUIEdgeInsets();
        let width = box.getWidth() + edgeInsets.left + edgeInsets.right + (isBgColor ? 16 : 0);
        let height = box.getHeight() + edgeInsets.top + edgeInsets.bottom;
        textMindElementContent.width = width;
        textMindElementContent.height = height;
        return this;
    }

    getLatexSize(text, textSize, bool = false) {
        if (text == null || text.length == 0) {  //如果没有文字设置一个最小宽高
            return new Size(textSize, textSize)
        }
        // EditMindmapVM.delegate.latexKatex = text;
        var span;
        if (document.getElementById("latex_katex")) {
            span = document.getElementById("latex_katex");
        } else {

            span = document.createElement("div")
            span.setAttribute('id','latex_katex')
            document.body.appendChild(span)
            span.style.position = 'absolute';
            span.style.whiteSpace = "pre-wrap"
            span.style.wordBreak = "break-word"
            span.style.opacity = 0
            span.style.maxWidth = Config.contentMaxWidth + 'px'
            span.style.width = "auto"
            span.style.top = "0"
            span.style.lineHeight = Config.textLineHeight
            
            //换行必须设置为行内块
            span.style.display = "block"
        }
        span.innerHTML = '';
        span.style.fontSize = textSize + 'px'
        let html = katex.renderToString(text, {
            throwOnError: false
        });
        span.innerHTML = html;
        let rect = span.getBoundingClientRect();

        return new Size(rect.width, rect.height)
        // return new Size(span.clientWidth,span.clientHeight)
    }

    publicCaluleTextByTextStyles(text, textSize = 18, bold = false, textItalics = false, fontFamily = Config.textFontFamily ,textStyles,maxWidth = Config.contentMaxWidth) {  //文字、字体大小、粗体、斜体、字体样式 计算节点的大小（这里节点有标签），也只用于节点的计算
        let fontSize = text.indexOf('font-size:') > -1 ? 1 : textSize;
        if (text == null || text.length == 0) {  //如果没有文字设置一个最小宽高
            return new Size(textSize, textSize)
        }
        var span
        if (document.getElementById("computed-text-width-height-span1")) {
            span = document.getElementById("computed-text-width-height-span1")
        } else {
            span = document.createElement("div")
            span.setAttribute('id','computed-text-width-height-span1')
            document.body.appendChild(span)
        }
        if (fontFamily == "" || fontFamily == null) {
            fontFamily = Config.textFontFamily;
        }
        span.style.position = 'absolute';
        span.style.zIndex = -999;
        span.style.whiteSpace = "pre-wrap"
        span.style.wordBreak = "break-word"
        span.style.opacity = 0
        span.style.maxWidth = maxWidth + 'px'
        span.style.width = "auto"
        span.style.top = "0"
        span.style.fontSize = fontSize + 'px'
        if (bold) {   //为粗体
            span.style.fontWeight = Config.textBoldFontWeight
        } else {
            span.style.fontWeight = Config.textNormalFontWeight
        }
        if (textItalics) {    //设置为斜体
            span.style.fontStyle = 'italic'
        } else {
            span.style.fontStyle = 'normal'
        }
        span.style.fontFamily = fontFamily
        span.style.lineHeight = Config.textLineHeight
        //换行必须设置为行内块
        span.style.display = "block"
        if (this.data == null || this.data.isEmpty()) {
            span.innerHTML = text
        } else {
            span.innerHTML = Util.getContentText(this.data)
        }
        
        let rect = span.getBoundingClientRect()
        return new Size(rect.width, rect.height)
    }

    //自己写的计算宽高方法
    publicCaluleText(text, textSize = 18, bold = false, 
        textItalics = false, fontFamily = Config.textFontFamily,
        maxWidth = Config.contentMaxWidth) {  //文字、字体大小、粗体、斜体、字体样式  计算节点的大小（这里节点没有标签），也只用于节点的计算
        if (text == null || text.length == 0) {  //如果没有文字设置一个最小宽高
            return new Size(textSize, textSize)
        }
        if (fontFamily == "" || fontFamily == null) {
            fontFamily = Config.textFontFamily;
        }
        let key = text + textSize + "" + bold + "" + textItalics + "" + fontFamily + "" + maxWidth;
        if (Config.TextSizeCaluleMap.containsKey(key)) {
            return Config.TextSizeCaluleMap.get(key)
        }
        var span
        if (document.getElementById("computed-text-width-height-span1")) {
            span = document.getElementById("computed-text-width-height-span1")
        } else {
            span = document.createElement("div")
            span.setAttribute('id','computed-text-width-height-span1')
            document.body.appendChild(span)
        }
        
        span.style.position = 'absolute';
        span.style.zIndex = -999;
        span.style.whiteSpace = "pre-wrap"
        span.style.letterSpacing = "1px"//letter-spacing
        span.style.wordBreak = "break-word"
        span.style.opacity = 0
        span.style.maxWidth = maxWidth + 'px'
        span.style.width = "auto"
        span.style.top = "0"
        span.style.fontSize = textSize + 'px'
        // span.style.writingMode = "vertical-lr" //(horizontal-tb 默认 \ vertical-lr 垂直)
        // span.style.textOrientation = "upright"

        if (bold) {   //为粗体
            span.style.fontWeight = Config.textBoldFontWeight
        } else {
            span.style.fontWeight = Config.textNormalFontWeight
        }
        if (textItalics) {    //设置为斜体
            span.style.fontStyle = 'italic'
        } else {
            span.style.fontStyle = 'normal'
        }
        span.style.fontFamily = fontFamily
        span.style.lineHeight = Config.textLineHeight
        //换行必须设置为行内块
        span.style.display = "block"
        span.innerHTML = text
        
        let rect = span.getBoundingClientRect()
        let size = new Size(rect.width + 2, rect.height);
        // console.log("- ", text, size, bold);
        Config.TextSizeCaluleMap.put(key, size)
        return size
    }

    caluleText(text, textSize = 14, bold, maxWidth = Config.contentMaxWidth) {   //计算文字的宽高，这里的文字没有标签，只是纯文字，
        if (text == null || text.length == 0) {  //如果没有文字设置一个最小宽高
            return new Size(textSize, textSize)
        }
        var span
        if (document.getElementById("computed-text-width-height-span1")) {
            span = document.getElementById("computed-text-width-height-span1")
        } else {
            span = document.createElement("div")
            span.setAttribute('id','computed-text-width-height-span1')
            document.body.appendChild(span)
        }
        span.style.position = 'absolute';
        span.style.zIndex = -999;
        span.style.whiteSpace = "pre-wrap"
        span.style.wordBreak = "break-word"
        span.style.letterSpacing = "1px"
        span.style.opacity = 0
        span.style.maxWidth = maxWidth + 'px'
        span.style.width = "auto"
        span.style.top = "0"
        span.style.fontSize = textSize + 'px'
        
        if (bold) {   //为粗体
            span.style.fontWeight = Config.textBoldFontWeight
        } else {
            span.style.fontWeight = Config.textNormalFontWeight
        }
        span.style.fontFamily = Config.textFontFamily  //"SimSun"

        span.style.lineHeight = Config.textLineHeight
        //换行必须设置为行内块
        span.style.display = "block"
        span.innerHTML = text
        let rect = span.getBoundingClientRect()
        return new Size(Math.ceil(rect.width), Math.ceil(rect.height))
    }

    setWidthCaluleText (text, textSize = 14, bold, maxWidth = 500) {  //下标框的宽高计算。让下标在一行显示。
        if (text == null || text.length == 0) {  //如果没有文字设置一个最小宽高
            return new Size(textSize, textSize)
        }
        var span
        if (document.getElementById("computed-text-width-height-span1")) {
            span = document.getElementById("computed-text-width-height-span1")
        } else {
            span = document.createElement("div")
            span.setAttribute('id','computed-text-width-height-span1')
            document.body.appendChild(span)
        }
        span.style.position = 'absolute';
        span.style.zIndex = -999;
        span.style.whiteSpace = "nowrap"
        span.style.wordBreak = "break-word"
        span.style.opacity = 0
        span.style.maxWidth = maxWidth + 'px'
        span.style.width = "auto"
        span.style.top = "0"
        span.style.overflow = "hidden"
        span.style.fontSize = textSize + 'px'
        if (bold) {   //为粗体
            span.style.fontWeight = Config.textBoldFontWeight
        } else {
            span.style.fontWeight = Config.textNormalFontWeight
        }
        span.style.fontFamily = Config.textFontFamily  //"SimSun"
        span.style.lineHeight = Config.textLineHeight
        //换行必须设置为行内块
        span.style.display = "block"
        span.innerHTML = text
        //用whiteSpace = "nowrap"好像无法解决换行的问题，只能用下面这个方法。
        span.innerHTML = new InnerHTMLParse(span.innerHTML, span.innerText).getInnerText(false);

        let rect = span.getBoundingClientRect()
        return new Size(rect.width, rect.height)
    }

    setCustomSize(customWidth) {
        if (customWidth <= this.data.width) {
            return;
        }

        let newAddWidth = customWidth - this.data.width;
        // let newAddHeight = this.data.customHeight - this.data.height;
        switch (this.data.alignmentType) {
            case MindElementAlignmentType.RIGHT:
                newAddWidth = (customWidth - this.data.width) * 2;
                break;
            case MindElementAlignmentType.MIDDLE:
            case MindElementAlignmentType.UNKNOWN:
                newAddWidth = (customWidth - this.data.width);
                break;
            case MindElementAlignmentType.LEFT:
                newAddWidth = 0;
                break;
            default:
                newAddWidth = (customWidth - this.data.width);
        }
        if (this.data.mindElementShape == MindElementShapeType.Circular ||
            this.data.mindElementShape == MindElementShapeType.Ring ||
            this.data.mindElementShape == MindElementShapeType.Ring2 ||
            this.data.mindElementShape == MindElementShapeType.Ring3 ||
            this.data.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.data.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.data.mindElementShape == MindElementShapeType.Book ||
            this.data.mindElementShape == MindElementShapeType.Star ||
            this.data.mindElementShape == MindElementShapeType.Octagonal ||
            (this.data.layout == NodeLayoutType.LAYOUT_CIRCLE && this.data.type != MindElementType.EXPLAIN)) {
            newAddWidth = (customWidth - this.data.width)
        }

        this.data.width = customWidth;
        if (this.data.imageContent != null) {
            this.data.imageContent.x = this.data.imageContent.x + newAddWidth / 2;
        }
        if (this.data.textContent != null) {
            this.data.textContent.x = this.data.textContent.x + newAddWidth / 2;
        }
        if (this.data.titleContent != null) {
            this.data.titleContent.x = this.data.titleContent.x + newAddWidth / 2;
        }
        if (this.data.generalizationContent != null) {
            this.data.generalizationContent.x = this.data.generalizationContent.x + newAddWidth / 2;
        }
        if (this.data.iconElementContents != null) {
            let iconCount = this.data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    this.data.iconElementContents[index].x = this.data.iconElementContents[index].x + newAddWidth / 2;
                }
            }
        }
        if (this.data.linkElementContent != null) {
            this.data.linkElementContent.x = this.data.linkElementContent.x + newAddWidth / 2;
        }
        if (this.data.remarksElementContent != null) {
            this.data.remarksElementContent.x = this.data.remarksElementContent.x + newAddWidth / 2;
        }
        if (this.data.moreContent != null) {
            this.data.moreContent.x = this.data.moreContent.x + newAddWidth / 2;
        }
        if (this.data.connectMapElementContent != null) {
            this.data.connectMapElementContent.x = this.data.connectMapElementContent.x + newAddWidth / 2;
        }

        if (this.data.taskContent != null) {
            this.data.taskContent.x = this.data.taskContent.x + newAddWidth / 2;
        }
        if (this.data.latexContent != null) {
            this.data.latexContent.x = this.data.latexContent.x + newAddWidth / 2;
        }
        //lxz add 附件
        if (this.data.resourceContent !=null){
            this.data.resourceContent.x = this.data.resourceContent.x + newAddWidth / 2;
        }

        if (this.data.mindElementShape == MindElementShapeType.Circular ||
            this.data.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.data.mindElementShape == MindElementShapeType.Ring ||
            this.data.mindElementShape == MindElementShapeType.Ring3 ||
            this.data.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.data.mindElementShape == MindElementShapeType.Book ||
            this.data.mindElementShape == MindElementShapeType.Star ||
            this.data.mindElementShape == MindElementShapeType.Octagonal ||
            (this.data.layout == NodeLayoutType.LAYOUT_CIRCLE && this.data.type != MindElementType.EXPLAIN)) {
            newAddWidth = customWidth - this.data.height;
            this.data.height = customWidth;

            if (this.data.imageContent != null) {
                this.data.imageContent.y = this.data.imageContent.y + newAddWidth / 2;
            }
            if (this.data.textContent != null) {
                this.data.textContent.y = this.data.textContent.y + newAddWidth / 2;
            }
            if (this.data.generalizationContent != null) {
                this.data.generalizationContent.y = this.data.generalizationContent.y + newAddWidth / 2;
            }
            if (this.data.iconElementContents != null) {
                let iconCount = this.data.iconElementContents.length
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + newAddWidth / 2;
                    }
                }
            }
            if (this.data.linkElementContent != null) {
                this.data.linkElementContent.y = this.data.linkElementContent.y + newAddWidth / 2;
            }
            if (this.data.remarksElementContent != null) {
                this.data.remarksElementContent.y = this.data.remarksElementContent.y + newAddWidth / 2;
            }
            if (this.data.moreContent != null) {
                this.data.moreContent.y = this.data.moreContent.y + newAddWidth / 2;
            }
            if (this.data.connectMapElementContent != null) {
                this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + newAddWidth / 2;
            }
            if (this.data.taskContent != null) {
                this.data.taskContent.y = this.data.taskContent.y + newAddWidth / 2;
            }
            if (this.data.latexContent != null) {
                this.data.latexContent.y = this.data.latexContent.y + newAddWidth / 2;
            }
            //lxz add 附件
            if (this.data.resourceContent !=null){
                this.data.resourceContent.y = this.data.resourceContent.y + newAddWidth / 2;
            }
        } else if (this.data.mindElementShape == MindElementShapeType.Heart ||
                    this.data.mindElementShape == MindElementShapeType.Shield ||
                    this.data.mindElementShape == MindElementShapeType.Gemstone) {
            var whScale = 1.0
            if (this.data.mindElementShape == MindElementShapeType.Heart) {
                whScale = 1.125
            } else if (this.data.mindElementShape == MindElementShapeType.Shield) {
                whScale = 0.81
            } else if (this.data.mindElementShape == MindElementShapeType.Gemstone) {
                whScale = 1.206
            }
            newAddWidth = customWidth - this.data.height;
            let customHeiht = customWidth / whScale
            newAddWidth = customHeiht - this.data.height;
            this.data.height = customHeiht;

            if (this.data.imageContent != null) {
                this.data.imageContent.y = this.data.imageContent.y + newAddWidth / 2;
            }
            if (this.data.textContent != null) {
                this.data.textContent.y = this.data.textContent.y + newAddWidth / 2;
            }
            if (this.data.generalizationContent != null) {
                this.data.generalizationContent.y = this.data.generalizationContent.y + newAddWidth / 2;
            }
            if (this.data.iconElementContents != null) {
                let iconCount = this.data.iconElementContents.length
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + newAddWidth / 2;
                    }
                }
            }
            if (this.data.linkElementContent != null) {
                this.data.linkElementContent.y = this.data.linkElementContent.y + newAddWidth / 2;
            }
            if (this.data.remarksElementContent != null) {
                this.data.remarksElementContent.y = this.data.remarksElementContent.y + newAddWidth / 2;
            }
            if (this.data.connectMapElementContent != null) {
                this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + newAddWidth / 2;
            }
            if (this.data.moreContent != null) {
                this.data.moreContent.y = this.data.moreContent.y + newAddWidth / 2;
            }
            if (this.data.taskContent != null) {
                this.data.taskContent.y = this.data.taskContent.y + newAddWidth / 2;
            }
            if (this.data.latexContent != null) {
                this.data.latexContent.y = this.data.latexContent.y + newAddWidth / 2;
            }
            //lxz add 附件
            if (this.data.resourceContent !=null){
                this.data.resourceContent.y = this.data.resourceContent.y + newAddWidth / 2;
            }
        }
    }

    setDataContentMoveLeft(size, isCenter = false, isCardTitle = true, isNegativeSize = false) {
        if (!isNegativeSize && size <= 0) {
            return
        }

        let newAddWidth = size;

        this.data.width = size + this.data.width;
        if (this.data.imageContent != null) {
            this.data.imageContent.x = this.data.imageContent.x + (isCenter ? size / 2 : size);
        }
        if (this.data.textContent != null) {
            this.data.textContent.x = this.data.textContent.x + (isCenter ? size / 2 : size);
        }
        if (isCardTitle && this.data.titleContent != null) {
            this.data.titleContent.x = this.data.titleContent.x + (isCenter ? size / 2 : size);
        }
        if (this.data.generalizationContent != null) {
            this.data.generalizationContent.x = this.data.generalizationContent.x + (isCenter ? size / 2 : size);
        }
        if (this.data.iconElementContents != null) {
            let iconCount = this.data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    this.data.iconElementContents[index].x = this.data.iconElementContents[index].x + (isCenter ? size / 2 : size);
                }
            }
        }
        if (this.data.linkElementContent != null) {
            this.data.linkElementContent.x = this.data.linkElementContent.x + (isCenter ? size / 2 : size);
        }
        if (this.data.remarksElementContent != null) {
            this.data.remarksElementContent.x = this.data.remarksElementContent.x + (isCenter ? size / 2 : size);
        }
        if (this.data.moreContent != null) {
            this.data.moreContent.x = this.data.moreContent.x + (isCenter ? size / 2 : size);
        }
        if (this.data.connectMapElementContent != null) {
            this.data.connectMapElementContent.x = this.data.connectMapElementContent.x + (isCenter ? size / 2 : size);
        }

        if (this.data.taskContent != null) {
            this.data.taskContent.x = this.data.taskContent.x + (isCenter ? size / 2 : size);
        }
        if (this.data.latexContent != null) {
            this.data.latexContent.x = this.data.latexContent.x + (isCenter ? size / 2 : size);
        }
        //lxz add 附件
        if (this.data.resourceContent !=null){
            this.data.resourceContent.x = this.data.resourceContent.x + (isCenter ? size / 2 : size);
        }
        if (this.data.orderContent !=null){
            this.data.orderContent.x = this.data.orderContent.x + (isCenter ? size / 2 : size);
        }
        if (this.data.mindElementShape == MindElementShapeType.Circular ||
            this.data.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.data.mindElementShape == MindElementShapeType.Ring ||
            this.data.mindElementShape == MindElementShapeType.Ring3 ||
            this.data.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.data.mindElementShape == MindElementShapeType.Book ||
            this.data.mindElementShape == MindElementShapeType.Star ||
            this.data.mindElementShape == MindElementShapeType.Octagonal ||
            (this.data.layout == NodeLayoutType.LAYOUT_CIRCLE && this.data.type != MindElementType.EXPLAIN)) {
            this.data.height = size + this.data.height;

            if (this.data.imageContent != null) {
                this.data.imageContent.y = this.data.imageContent.y + size / 2;
            }
            if (this.data.textContent != null) {
                this.data.textContent.y = this.data.textContent.y + size / 2;
            }
            if (this.data.generalizationContent != null) {
                this.data.generalizationContent.y = this.data.generalizationContent.y + size / 2;
            }
            if (this.data.iconElementContents != null) {
                let iconCount = this.data.iconElementContents.length
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + size / 2;
                    }
                }
            }
            if (this.data.linkElementContent != null) {
                this.data.linkElementContent.y = this.data.linkElementContent.y + size / 2;
            }
            if (this.data.remarksElementContent != null) {
                this.data.remarksElementContent.y = this.data.remarksElementContent.y + size / 2;
            }
            if (this.data.moreContent != null) {
                this.data.moreContent.y = this.data.moreContent.y + size / 2;
            }
            if (this.data.connectMapElementContent != null) {
                this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + size / 2;
            }
            if (this.data.taskContent != null) {
                this.data.taskContent.y = this.data.taskContent.y + size / 2;
            }
            if (this.data.latexContent != null) {
                this.data.latexContent.y = this.data.latexContent.y + size / 2;
            }
            //lxz add 附件
            if (this.data.resourceContent !=null){
                this.data.resourceContent.y = this.data.resourceContent.y + size / 2;
            }
            if (this.data.orderContent !=null){
                this.data.orderContent.y = this.data.orderContent.y + size / 2;
            }
        } else if (this.data.mindElementShape == MindElementShapeType.Heart ||
                    this.data.mindElementShape == MindElementShapeType.Shield ||
                    this.data.mindElementShape == MindElementShapeType.Gemstone) {
            var whScale = 1.0
            if (this.data.mindElementShape == MindElementShapeType.Heart) {
                whScale = 1.125
            } else if (this.data.mindElementShape == MindElementShapeType.Shield) {
                whScale = 0.81
            } else if (this.data.mindElementShape == MindElementShapeType.Gemstone) {
                whScale = 1.206
            }
            newAddWidth = size;
            let customHeiht = (size + this.data.width) / whScale
            newAddWidth = customHeiht - this.data.height;
            this.data.height = customHeiht;

            if (this.data.imageContent != null) {
                this.data.imageContent.y = this.data.imageContent.y + newAddWidth / 2;
            }
            if (this.data.textContent != null) {
                this.data.textContent.y = this.data.textContent.y + newAddWidth / 2;
            }
            if (this.data.generalizationContent != null) {
                this.data.generalizationContent.y = this.data.generalizationContent.y + newAddWidth / 2;
            }
            if (this.data.iconElementContents != null) {
                let iconCount = this.data.iconElementContents.length
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + newAddWidth / 2;
                    }
                }
            }
            if (this.data.linkElementContent != null) {
                this.data.linkElementContent.y = this.data.linkElementContent.y + newAddWidth / 2;
            }
            if (this.data.remarksElementContent != null) {
                this.data.remarksElementContent.y = this.data.remarksElementContent.y + newAddWidth / 2;
            }
            if (this.data.connectMapElementContent != null) {
                this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + newAddWidth / 2;
            }
            if (this.data.moreContent != null) {
                this.data.moreContent.y = this.data.moreContent.y + newAddWidth / 2;
            }
            if (this.data.taskContent != null) {
                this.data.taskContent.y = this.data.taskContent.y + newAddWidth / 2;
            }
            if (this.data.latexContent != null) {
                this.data.latexContent.y = this.data.latexContent.y + newAddWidth / 2;
            }
            //lxz add 附件
            if (this.data.resourceContent !=null){
                this.data.resourceContent.y = this.data.resourceContent.y + newAddWidth / 2;
            }
            if (this.data.orderContent !=null){
                this.data.orderContent.y = this.data.orderContent.y + newAddWidth / 2;
            }
        }
    }

    setDataContentMoveTop(size) {
        if (size <= 0) {
            return
        }

        let newAddHeight = size;

        this.data.height = size + this.data.height;
        if (this.data.imageContent != null) {
            this.data.imageContent.y = this.data.imageContent.y + size;
        }
        if (this.data.textContent != null) {
            this.data.textContent.y = this.data.textContent.y + size;
        }
        if (this.data.generalizationContent != null) {
            this.data.generalizationContent.y = this.data.generalizationContent.y + size;
        }
        if (this.data.iconElementContents != null) {
            let iconCount = this.data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + size;
                }
            }
        }
        if (this.data.linkElementContent != null) {
            this.data.linkElementContent.y = this.data.linkElementContent.y + size;
        }
        if (this.data.remarksElementContent != null) {
            this.data.remarksElementContent.y = this.data.remarksElementContent.y + size;
        }
        if (this.data.moreContent != null) {
            this.data.moreContent.y = this.data.moreContent.y + size;
        }
        if (this.data.connectMapElementContent != null) {
            this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + size;
        }

        if (this.data.taskContent != null) {
            this.data.taskContent.y = this.data.taskContent.y + size;
        }
        if (this.data.latexContent != null) {
            this.data.latexContent.y = this.data.latexContent.y + size;
        }
        //lxz add 附件
        if (this.data.resourceContent !=null){
            this.data.resourceContent.y = this.data.resourceContent.y + size;
        }
        if (this.data.orderContent !=null){
            this.data.orderContent.y = this.data.orderContent.y + size;
        }
        if (this.data.mindElementShape == MindElementShapeType.Circular ||
            this.data.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.data.mindElementShape == MindElementShapeType.Ring ||
            this.data.mindElementShape == MindElementShapeType.Ring3 ||
            this.data.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.data.mindElementShape == MindElementShapeType.Book ||
            this.data.mindElementShape == MindElementShapeType.Star ||
            this.data.mindElementShape == MindElementShapeType.Octagonal ||
            (this.data.layout == NodeLayoutType.LAYOUT_CIRCLE && this.data.type != MindElementType.EXPLAIN)) {
            this.data.height = size + this.data.height;

            if (this.data.imageContent != null) {
                this.data.imageContent.y = this.data.imageContent.y + size / 2;
            }
            if (this.data.textContent != null) {
                this.data.textContent.y = this.data.textContent.y + size / 2;
            }
            if (this.data.generalizationContent != null) {
                this.data.generalizationContent.y = this.data.generalizationContent.y + size / 2;
            }
            if (this.data.iconElementContents != null) {
                let iconCount = this.data.iconElementContents.length
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + size / 2;
                    }
                }
            }
            if (this.data.linkElementContent != null) {
                this.data.linkElementContent.y = this.data.linkElementContent.y + size / 2;
            }
            if (this.data.remarksElementContent != null) {
                this.data.remarksElementContent.y = this.data.remarksElementContent.y + size / 2;
            }
            if (this.data.moreContent != null) {
                this.data.moreContent.y = this.data.moreContent.y + size / 2;
            }
            if (this.data.connectMapElementContent != null) {
                this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + size / 2;
            }
            if (this.data.taskContent != null) {
                this.data.taskContent.y = this.data.taskContent.y + size / 2;
            }
            if (this.data.latexContent != null) {
                this.data.latexContent.y = this.data.latexContent.y + size / 2;
            }
            //lxz add 附件
            if (this.data.resourceContent !=null){
                this.data.resourceContent.y = this.data.resourceContent.y + size / 2;
            }
            if (this.data.orderContent !=null){
                this.data.orderContent.y = this.data.orderContent.y + size / 2;
            }
        } else if (this.data.mindElementShape == MindElementShapeType.Heart ||
                    this.data.mindElementShape == MindElementShapeType.Shield ||
                    this.data.mindElementShape == MindElementShapeType.Gemstone) {
            var whScale = 1.0
            if (this.data.mindElementShape == MindElementShapeType.Heart) {
                whScale = 1.125
            } else if (this.data.mindElementShape == MindElementShapeType.Shield) {
                whScale = 0.81
            } else if (this.data.mindElementShape == MindElementShapeType.Gemstone) {
                whScale = 1.206
            }
            newAddWidth = size;
            let customHeiht = (size + this.data.width) / whScale
            newAddWidth = customHeiht - this.data.height;
            this.data.height = customHeiht;

            if (this.data.imageContent != null) {
                this.data.imageContent.y = this.data.imageContent.y + newAddWidth / 2;
            }
            if (this.data.textContent != null) {
                this.data.textContent.y = this.data.textContent.y + newAddWidth / 2;
            }
            if (this.data.generalizationContent != null) {
                this.data.generalizationContent.y = this.data.generalizationContent.y + newAddWidth / 2;
            }
            if (this.data.iconElementContents != null) {
                let iconCount = this.data.iconElementContents.length
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + newAddWidth / 2;
                    }
                }
            }
            if (this.data.linkElementContent != null) {
                this.data.linkElementContent.y = this.data.linkElementContent.y + newAddWidth / 2;
            }
            if (this.data.remarksElementContent != null) {
                this.data.remarksElementContent.y = this.data.remarksElementContent.y + newAddWidth / 2;
            }
            if (this.data.connectMapElementContent != null) {
                this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + newAddWidth / 2;
            }
            if (this.data.moreContent != null) {
                this.data.moreContent.y = this.data.moreContent.y + newAddWidth / 2;
            }
            if (this.data.taskContent != null) {
                this.data.taskContent.y = this.data.taskContent.y + newAddWidth / 2;
            }
            if (this.data.latexContent != null) {
                this.data.latexContent.y = this.data.latexContent.y + newAddWidth / 2;
            }
            //lxz add 附件
            if (this.data.resourceContent !=null){
                this.data.resourceContent.y = this.data.resourceContent.y + newAddWidth / 2;
            }
            if (this.data.orderContent !=null){
                this.data.orderContent.y = this.data.orderContent.y + newAddWidth / 2;
            }
        }
    }
}
export default new MindElementCalculation()
