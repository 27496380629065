import UserCalendarData from "./UserCalendarData";

class UserCalendarDatas {
    constructor(userId = "") {
        this.list = [];
        this.userId = userId;
    }  
    
    add(data) {
        if (data == null) {
            return
        }
        this.list.push(data)
    }

    toJSONString() {
        let that = this
        let nodeStr = JSON.stringify(this, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            return that.formatNumber(val)
        });
        return nodeStr;
    }

    formatNumber(value) {
        if (typeof value === "number") {
            let newValue = Math.round(value*100)/100;
            if (newValue == value) {
                return value
            } else {
                return newValue
            }
        } else {
            return value
        }
    }

    format(vaule) {
        let data = new UserCalendarDatas()
        if (vaule == null || vaule.length == 0) {
            return data;
        }
        let json = JSON.parse(vaule)
        if (json == null) {
            return data;
        }        
        for (var key in json) {
            let keyValue = json[key]
            if (key == "list") {
                let list = new Array();
                let valueSize = keyValue.length
                for (let index = 0; index < valueSize; index++) {
                    let userCalendarData = new UserCalendarData()
                    for (var userCalendarDataKey in keyValue[index]) {
                        let userCalendarDataValue = keyValue[index][userCalendarDataKey]
                        userCalendarData[userCalendarDataKey] = userCalendarDataValue
                    }
                    list.push(userCalendarData)
                }
                data[key] = list
            } else {
                data[key] = keyValue
            }
        }
        return data;
    }

 }
 export default UserCalendarDatas