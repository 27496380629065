const { default: strings } = require("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = "Mind + Mind Map";
stringMap[strings.TEMPLATE] = "awoṣe";
stringMap [strings.Mind_Edit_Main_Topic] = "Koko Ẹka";
stringMap [strings.Global_Add_Free_Node_Tips] = "Tẹ lori iboju lati yan ipo lati fikun";
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = "Tẹ iboju lẹẹmeji lati yan ipo lati fikun";
stringMap [strings.Global_Add_Relationship_Tips] = "Yan ibi-afẹde ti o nilo lati ni nkan";
stringMap [strings.Global_Add_Target_Tips] = "Jọwọ yan oju ipade lati ṣafikun awọn nkan";
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = "Nkan yi ti ni nkan ṣe pẹlu awọn maapu miiran";
stringMap [strings.Mind_Edit_Main_Idea] = "Ero Akọkọ";
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = "Maapu ti o somọ ko si";
stringMap [strings.Mind_Style_Add_Icon_Priority] = "Ohun pataki";
stringMap [strings.Mind_Style_Add_Icon_Progress] = "Ilọsiwaju";
stringMap [strings.Mind_Style_Add_Icon_Stars] = "Awọn irawọ";
stringMap[strings.Mind_Style_Add_Icon_Week] = "ọsẹ";
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = "Aworan";
stringMap [strings.Establish] = "Ṣẹda";
stringMap[strings.Create_From_Template] = "Ṣẹda lati awoṣe";
stringMap[strings.Common] = "Agbaye";
stringMap[strings.Function_List] = "iṣẹ akojọ";
stringMap[strings.Recent_Updates] = "Laipe ti ni imudojuiwọn";
stringMap[strings.The_Latest_To_Delete] = "Laipẹ paarẹ";
stringMap[strings.Setting_Up] = "pe wa";
stringMap[strings.Usinghelp] = "Lilo iranlọwọ";
stringMap[strings.The_Download_Client] = "Ṣe igbasilẹ alabara / ẹya alagbeka";
stringMap[strings.Global_Purchase] = "ra";
stringMap[strings.Global_Login] = "wo ile";
stringMap[strings.My_map] = "Maapu mi";
stringMap[strings.Create_Map] = "Ṣẹda maapu kan";
stringMap[strings.Storm] = "iji";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = "Wọpọ \ n Awọn ẹya";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = "Awọn ẹya ara ẹrọ";
stringMap[strings.Mind_Organization_Edit_Main_Idea] = "Ile-iṣẹ agbari";
stringMap[strings.Mind_Edit_Free_Topic] = "Akori ọfẹ";
stringMap[strings.Mind_Edit_Sub_Topic] = "Koko-ọrọ";
stringMap[strings.Mind_Organization_Main_Topic] = "ẹka";
stringMap[strings.Mind_Edit_Main_Topic] = "Koko-ọrọ ti eka";
stringMap[strings.Mind_Time_Edit_Main_Idea] = "isakoso ètò";
stringMap[strings.Mind_Time_Edit_Head] = "aago";
stringMap[strings.Mind_Time_Edit_Title] = "gbero";
stringMap[strings.Mind_Edit_Details] = "Apejuwe alaye";
stringMap[strings.Checking_Data] = "yiyewo data";
stringMap[strings.Setting_Information] = "Eto alaye";
stringMap[strings.Sign_Out] = "ifowosi jada";

//功能列表
stringMap[strings.Create_a_Mind_Map] = "Ṣẹda maapu ọkan";
stringMap[strings.Create_Mind_Map_1] = "【1】Ṣẹda awọn maapu akọkọ mẹrinla (ọna meji / ọtun / osi / eto agbari / iwe atokọ nikan / iwe afọwọya ti ilọpo meji / iwe igi igi osi / iwe igi ọtun / iwe igi ọna meji / tabili / Ago petele / Ago inaro / S Ago / Pyramid) )";
stringMap[strings.Create_Mind_Map_2] = "【2】Awọn awoṣe le ṣee lo lati ṣẹda awọn maapu ni kiakia (nọmba nla ti awọn itọkasi akoonu awoṣe);";
stringMap[strings.Create_Mind_Map_3] = "【3】Ṣatunkọ awọn oriṣi mẹsan ti awọn aza laini lori maapu naa";
stringMap[strings.Create_Mind_Map_4] = "【4】Satunkọ awọn ayipada akọkọ mẹsan si maapu naa";
stringMap[strings.Create_Mind_Map_5] = "【5】Ṣafikun awọn apa ọmọ si oju-ọna ibi-afẹde, ṣafikun awọn apa ni ipele kanna (oke ati isalẹ)";
stringMap[strings.Create_Mind_Map_6] = "【6】Akopọ: Ṣe alaye afikun ti oju-eepo / oju ipade pupọ [Lakotan]";
stringMap[strings.Create_Mind_Map_7] = "【7】Ìdènà: Ṣafikun [Ìdènà] si oju ipade kan / awọn apoti oju opo pupọ ti maapu fun imularada";
stringMap[strings.Create_Mind_Map_8] = "【8】Ṣafikun ipade ọfẹ";
stringMap[strings.Create_Mind_Map_9] = "【9】Ṣẹda awọn ẹgbẹ ti awọn aza laini mẹrin laarin eyikeyi awọn apa";
stringMap[strings.Create_Mind_Map_10] = "【10】Fi awọn aworan sii (agbegbe + ile-ikawe ohun elo), awọn aami, awọn akọsilẹ, awọn ọna asopọ si awọn apa";
stringMap[strings.Create_Mind_Map_11] = "【11】Daakọ / lẹẹ ipade, daakọ / lẹẹ ara";
stringMap[strings.Create_Mind_Map_12] = "【12】Fagilee";
stringMap[strings.Create_Mind_Map_13] = "【13】Aṣayan pupọ, iṣẹ igbakanna ti awọn apa lọpọlọpọ";
stringMap[strings.Create_Mind_Map_14] = "【14】Atọka iṣiro iṣiro";
stringMap[strings.Create_Mind_Map_15] = "【15】Gbogbo map le gba silẹ";
stringMap[strings.Create_Mind_Map_16] = "【16】Daakọ maapu";
stringMap[strings.Create_Mind_Map_17] = "【17】O le daakọ maapu naa tabi gbogbo maapu si maapu awọn faili miiran";
stringMap[strings.Create_Mind_Map_18] = "【18】A le ṣẹda maapu kan lẹhin akopọ naa";
stringMap[strings.Create_Mind_Map_19] = "【19】Nigbati o ba n ṣatunkọ, o le yipada lati satunkọ maapu (ẹgbẹ kọnputa)";
stringMap[strings.Create_Mind_Map_20] = "【20】Maapu ṣiṣatunkọ keyboard Bluetooth: \ n \ t [Aṣẹ] + [a] Yan oju ipade akọkọ \ n \ t [Commandfin] + [←] [↑] [→] [↓] Yi ibi-afẹde ti o yan tabi gbe maapu \ n \ t [Commandfin] + [e] Ṣatunkọ ibi-afẹde \ n \ t []fin] + [w] Ṣẹda ipade ọmọ \ n \ t [Aṣẹ] + [s] Ṣẹda ipade arakunrin arakunrin kan (oke) \ n \ t [Ofin] + [d] Ṣẹda oju opo arakunrin ti o fojusi (ni isalẹ) \ n \ t [Aṣẹ] + [q] Jade kuro ni ibi-afẹde ti a yan \ n \ t [Command] + [-] [+] Maapu Sún ";

stringMap[strings.Beautify_The_Mind_Map] = "Ṣe ẹwà maapu okan";
stringMap[strings.Beautify_The_Mind_Map_1] = "【1】Ṣeto awọ isale ti maapu ọkan";
stringMap[strings.Beautify_The_Mind_Map_2] = "【2】Ṣeto sisanra ati awọ ti gbogbo laini maapu, boya o tẹ tabi rara";
stringMap[strings.Beautify_The_Mind_Map_3] = "【3】Ṣeto awọ ti o kun ọrọ, awọ aala, awọn aala 13 aala, iwọn aala, ipa ojiji";
stringMap[strings.Beautify_The_Mind_Map_4] = "【4】Ṣeto awọ ati iwọn ti gbogbo tabi apakan maapu naa";
stringMap[strings.Beautify_The_Mind_Map_5] = "【5】Ṣeto iwọn font ọrọ, igboya, italic, paarẹ aṣa";
stringMap[strings.Beautify_The_Mind_Map_6] = "【6】Ṣeto iwọn aworan, ipo, aṣa apẹrẹ";
stringMap[strings.Beautify_The_Mind_Map_7] = "【7】Ṣafikun awọn ẹka meje ti awọn aami (wọpọ / 3D / iwadi / iṣẹ / eniyan / eniyan / ounjẹ) ati ṣeto iwọn / ipo";
stringMap[strings.Beautify_The_Mind_Map_8] = "【8】Ṣafikun itọkasi ara-ẹẹkan si gbogbo maapu naa";
stringMap[strings.Beautify_The_Mind_Map_9] = "【9】Maapu iṣakoso ẹgbẹ";
stringMap[strings.Beautify_The_Mind_Map_10] = "【10】Atilẹyin ti ara ẹni (ile-ikawe ohun elo) ni a le fi kun si gbogbo maapu naa";
stringMap[strings.Beautify_The_Mind_Map_9] = "【11】A le fi awọn aala kọọkan tabi awọn isale kun si oju ipade kan (ile-ikawe ohun elo)";
stringMap[strings.Beautify_The_Mind_Map_9] = "【12】Gigun oju ipade le fa";


stringMap[strings.Map_Output] = "Ṣiṣejade maapu";
stringMap[strings.Map_Output_1] = "【1】fi aworan pamọ";
stringMap[strings.Map_Output_2] = "【2】Fipamọ PDF";
stringMap[strings.Map_Output_3] = "【3】Pin ọna asopọ";
stringMap[strings.Map_Output_4] = "【4】Pin si iji";
stringMap[strings.Map_Output_4] = "【5】tẹjade";

stringMap[strings.Work_together] = "sise papo";
stringMap[strings.Work_together_1] = "【1】Ṣẹda ẹgbẹ ti o pin (aami ẹgbẹ, ikede ẹgbẹ)";
stringMap[strings.Work_together_2] = "【2】Ṣafikun awọn ọmọ ẹgbẹ ti ẹgbẹ pipin";
stringMap[strings.Work_together_3] = "【3】Awọn ọmọ ẹgbẹ lọpọlọpọ ninu ẹgbẹ ṣatunkọ maapu kanna papọ";
stringMap[strings.Work_together_4] = "【4】Ṣe ikojọpọ awọn faili ti a pin laarin ẹgbẹ (ọrọ / tayo / pdf)";
stringMap[strings.Work_together_5] = "【5】O le fi ifiranṣẹ silẹ ninu ẹgbẹ naa";
stringMap[strings.Work_together_6] = "【6】Awọn eto awọn igbanilaaye iṣakoso ẹgbẹ ti pin";
stringMap[strings.Work_together_7] = "【7】Ẹgbẹ tuka";

stringMap[strings.Work_together] = "Pinpin Imọ (Iji)";
stringMap[strings.Community_Sharing_1] = "【1】O le pin ẹda rẹ si iji, ki eniyan diẹ sii le rii";
stringMap[strings.Community_Sharing_1] = "【2】O le yan awọn afi lati wo akoonu iji ti o jọmọ";
stringMap[strings.Community_Sharing_1] = "【3】O le fẹran ati ṣe asọye lori awọn iṣẹ iji";

//最近更新
stringMap[strings.Recently_Updated_Title_1] = "Ṣẹda iroyin ti ara ẹni";
stringMap[strings.Recently_Updated_Title_2] = "Ipilẹ oye awoṣe awoṣe";
stringMap[strings.Recently_Updated_Title_3] = "Ṣe atilẹyin awọn iru ẹrọ lọpọlọpọ";
stringMap[strings.Recently_Updated_Title_4] = "Ṣe akanṣe gbogbo alaye";
stringMap[strings.Recently_Updated_Title_5] = "Ikawe ara ti ara ẹni ti ara ẹni";
stringMap[strings.Recently_Updated_Title_6] = "Ṣiṣejade maapu";
stringMap[strings.Recently_Updated_Title_7] = "Ibi ipamọ awọsanma";
stringMap[strings.Recently_Updated_Title_8] = "Awọn maapu meedogun";


stringMap[strings.Recently_Updated_Content_1] = "Ṣẹda iroyin ti ara ẹni kan ki o fipamọ maapu ti o ṣẹda pẹlu akọọlẹ naa";
stringMap[strings.Recently_Updated_Content_2] = "Ipilẹ imoye awoṣe awoṣe ti sọ taara lati mu ilọsiwaju ṣiṣe ti ẹkọ ati iṣẹ ṣiṣẹ";
stringMap[strings.Recently_Updated_Content_3] = "Ṣe atilẹyin irufẹ wẹẹbu lori ayelujara, ebute IOS alagbeka, ebute Mac, ebute alagbeka alagbeka Android, lilo tabulẹti pupọ ti Android";
stringMap[strings.Recently_Updated_Content_4] = "Le ṣe ẹwa apẹrẹ ti ọrọ, lẹhin, awọn ila, awọn aami aworan, ati bẹbẹ lọ gẹgẹbi awọn ayanfẹ ti ara ẹni";
stringMap[strings.Recently_Updated_Content_5] = "Ile-ikawe ara ti ara ẹni kọọkan ni a sọ taara, yiyo wahala ti ibaramu awọ";
stringMap[strings.Recently_Updated_Content_6] = "Le fi awọn aworan pamọ, ọna kika PDF, taara le pin pẹlu awọn ọrẹ";
stringMap[strings.Recently_Updated_Content_7] = "Atilẹyin ibi ipamọ awọsanma ti o lagbara, ko ni lati ni wahala nipa pipadanu data";
stringMap[strings.Recently_Updated_Content_8] = "Ọna meji, ọtun, osi, eto eto eto, iwe atokọ ẹyọkan, chart ti nkuta ilọpo meji, apẹrẹ igi apa osi, apẹrẹ igi ọtun, iwe igi ọna meji, tabili, Ago petele, Ago inaro, Agogo S, Awọn ipilẹ mẹjọ le yipada ni yoo";

//最近删除
stringMap[strings.Mind_Mindmap_Deleted_Title] = "Laipẹ paarẹ";
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = "pada sipo";
stringMap[strings.Mind_Mindmap_Deleted_Forever] = "paarẹ";
stringMap[strings.Mind_Mindmap_Deleted_Filename] = "orukọ faili";
stringMap[strings.Mind_Mindmap_Deleted_Tips] = "Lọgan ti paarẹ nibi, yoo yọkuro patapata";
stringMap[strings.Mind_Mindmap_Deleted_Operating] = "ṣiṣẹ";
stringMap[strings.Mind_Mindmap_Deleted_Selected] = "Wa fun awọn maapu ti o paarẹ laipe";
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] = "Paarẹ ọjọ";

//联系我们
stringMap[strings.Contact_Us_No_Public] ="gbogbo eniyan";
stringMap[strings.Contact_Us_QQ_Group] = "Ẹgbẹ QQ";
stringMap[strings.Contact_Us_Message_Board] =  "igbimọ ifiranṣẹ";
stringMap[strings.Contact_Us_Username] = "Orukọ";
stringMap[strings.Contact_Us_Email] =  "apoti leta";
stringMap[strings.Contact_Us_Voicemail] = "Jọwọ fi awọn ọrọ iyebiye rẹ silẹ";
stringMap[strings.Contact_Us_Send_Email] = "Firanṣẹ Imeeli";


//验证信息
stringMap[strings.Input_Username_isNull] ="Orukọ ko le ṣofo";
stringMap[strings.Input_Email_isNull] ="jọwọ tẹ imeeli rẹ ti o lagbara";
stringMap[strings.Input_Voicemail_isNull] ="Jọwọ tẹ akoonu sii lati firanṣẹ";
stringMap[strings.Input_Introduction_isNull] ="Ifihan ko le ṣofo";
stringMap[strings.Input_Title_isNull] ="Akọle naa ko le ṣofo";


stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] ="jọwọ tẹ nọmba foonu to wulo";
stringMap[strings.Input_Please_Enter_Verification_Code] ="jọwọ tẹ koodu ijerisi sii!";
stringMap[strings.Input_Please_Enter_The_Password] ="Jọwọ tẹ ọrọ igbaniwọle rẹ sii!";
stringMap[strings.Input_The_Password_Format_Is_Incorrect] ="Ọna ọrọ igbaniwọle ko tọ!";
stringMap[strings.Input_Please_Enter_A_New_Password] ="Jọwọ tẹ ọrọ igbaniwọle titun sii!";
stringMap[strings.Input_Confirm_The_New_Password] ="Jọwọ tẹ ọrọ igbaniwọle ijẹrisi sii!";
stringMap[strings.Input_The_Passwords_Are_Inconsistent] ="Awọn ọrọ igbaniwọle ko ni ibamu!";
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] ="Jọwọ tẹ koodu ijerisi ti o tọ sii!";
stringMap[strings.Input_The_Verification_Code_Error] ="Aṣiṣe koodu ijẹrisi!";


//使用帮助
stringMap[strings.Mind_Usinghelp_1] ="Ṣẹda maapu ọkan";
stringMap[strings.Mind_Usinghelp_2] ="Bii o ṣe le ṣe afikun oju ipade tuntun";
stringMap[strings.Mind_Usinghelp_3] ="Ṣẹda ipade ọfẹ";
stringMap[strings.Mind_Usinghelp_4] ="Ṣeto ajọṣepọ";
stringMap[strings.Mind_Usinghelp_5] ="Ṣe ẹṣọ kanfasi naa";
stringMap[strings.Mind_Usinghelp_6] ="Ṣe ẹwà ọrọ naa";
stringMap[strings.Mind_Usinghelp_7] ="Fi awọn akọsilẹ sii, awọn ọna asopọ";
stringMap[strings.Mind_Usinghelp_8] ="Fi aami sii";
stringMap[strings.Mind_Usinghelp_9] ="Fi aworan sii";
stringMap[strings.Mind_Usinghelp_10] ="Bii o ṣe le mu pada paarẹ";
stringMap[strings.Mind_Usinghelp_11] ="Si ilẹ okeere maapu lokan";
stringMap[strings.Mind_Usinghelp_12] ="Igbesoke Ẹgbẹ";
stringMap[strings.Mind_Usinghelp_13] ="bọtini gbigbona";
stringMap[strings.Mind_Usinghelp_Understood] ="Ṣe o ri";
stringMap[strings.Mind_Usinghelp_14] ="Fi sii ile-ikawe ohun elo";

//使用帮助-2
stringMap[strings.Mind_Usinghelp_15] ="Maapu Tuntun";
// stringMap[strings.Mind_Usinghelp_2] ="Ṣafikun Node";
// stringMap[strings.Mind_Usinghelp_4] ="Ṣeto Ẹgbẹ";
// stringMap[strings.Mind_Usinghelp_5] ="Ṣe ẹṣọ kanfasi naa";
// stringMap[strings.Mind_Usinghelp_6] ="Ṣe ẹwà ọrọ naa";
// stringMap[strings.Mind_Usinghelp_7] ="Ṣafikun awọn akọsilẹ, awọn ọna asopọ";
stringMap[strings.Mind_Usinghelp_17] ="Fagilee iṣẹ, ifagile";
stringMap[strings.Mind_Usinghelp_18] ="Fa oju ipade si transposition";
stringMap[strings.Mind_Usinghelp_19] ="Iṣeto Node";
stringMap[strings.Mind_Usinghelp_20] ="Yi iru Map pada";
stringMap[strings.Mind_Usinghelp_21] ="Style Line Map";
stringMap[strings.Mind_Usinghelp_22] ="Awọ abẹlẹ map";
stringMap[strings.Mind_Usinghelp_23] ="Ṣeto Apẹrẹ Node";
stringMap[strings.Mind_Usinghelp_24] ="Style Line Node";
stringMap[strings.Mind_Usinghelp_25] ="Awọn Eto Aworan Node";
stringMap[strings.Mind_Usinghelp_26] ="Awọn Eto Aami Aami";
stringMap[strings.Mind_Usinghelp_27] ="Akopọ Ṣafikun Node";
stringMap[strings.Mind_Usinghelp_28] ="Aṣayan fireemu oju ipade ọkan";
stringMap[strings.Mind_Usinghelp_29] ="Awọn apa lọpọlọpọ sinu awọn bulọọki";
stringMap[strings.Mind_Usinghelp_30] ="Ṣafikun Aala si Node";
stringMap[strings.Mind_Usinghelp_31] ="Ṣafikun apẹrẹ";
stringMap[strings.Mind_Usinghelp_32] ="Hode Node ati Imularada";
stringMap[strings.Mind_Usinghelp_33] ="Awọn iṣẹ Pupo Node";
stringMap[strings.Mind_Usinghelp_34] ="Iyipada ara";
stringMap[strings.Mind_Usinghelp_35] ="Fi ohun ati Alaye sii";
stringMap[strings.Mind_Usinghelp_36] ="Map Itọsọna si Aworan";
stringMap[strings.Mind_Usinghelp_37] ="Iwe Iwe-aṣẹ PDF Map";
stringMap[strings.Mind_Usinghelp_38] ="Tẹjade Maapu";
stringMap[strings.Mind_Usinghelp_39] ="Pinpin Ọna asopọ ati Wiwo";
stringMap[strings.Mind_Usinghelp_40] ="Pin maapu si iji";
stringMap[strings.Mind_Usinghelp_41] ="Lilo folda";
stringMap[strings.Mind_Usinghelp_42] ="Paarẹ ati mu pada maapu";

//使用帮助3
stringMap[strings.Mind_Usinghelp_43] ="Awọn ọrẹ beere lati darapọ mọ ẹgbẹ naa";
stringMap[strings.Mind_Usinghelp_44] ="Bii o ṣe le tu ẹgbẹ kan";
stringMap[strings.Mind_Usinghelp_45] ="Po si alaye faili";
stringMap[strings.Mind_Usinghelp_46] ="Gba lati darapọ mọ ohun elo naa";
stringMap[strings.Mind_Usinghelp_47] ="Ṣatunkọ Ifọwọsowọpọ";
stringMap[strings.Mind_Usinghelp_48] ="Ẹgbẹ Pinpin Tuntun";
stringMap[strings.Mind_Usinghelp_49] ="Pe awọn ọmọ ẹgbẹ ẹgbẹ";


//风暴
stringMap[strings.Mind_Storm_Recommend] ="ṣeduro";
stringMap[strings.Mind_Storm_Boilingpoint] ="Oju Ibun";
stringMap[strings.Mind_Storm_Unfold] ="Faagun";
stringMap[strings.Mind_Storm_Putaway] ="Subu";
stringMap[strings.Mind_Storm_Myclassification] ="Sọri mi";
stringMap[strings.Mind_Storm_Recommended_Classification] ="Ẹka ti a Ṣeduro";
stringMap[strings.Mind_Storm_Click_Add_Category] ="Tẹ lati ṣafikun ẹka";
stringMap[strings.Mind_Storm_No_Classification] ="Ko si iyasọtọ sibẹsibẹ";
stringMap[strings.Mind_Storm_No_Tinterested] ="ko wunmi";
stringMap[strings.Mind_Storm_Report] ="Iroyin";
stringMap[strings.Mind_Storm_Views] ="Awọn iwo"


stringMap[strings.Mind_Storm_Pornographic] ="Awọn iwa iwokuwo ati Vulgar";
stringMap[strings.Mind_Storm_Advertising_Harassment] ="Ipabapa Ipolowo";
stringMap[strings.Mind_Storm_Political_Religion] ="Iṣelu ati Esin";
stringMap[strings.Mind_Storm_Infringement] ="Ofin";
stringMap[strings.Mind_Storm_Prohibited_Content] ="Akoonu ti a eewọ";
stringMap[strings.Mind_Storm_Spread_Rumors] ="Awọn agbasọ ti ntan";
stringMap[strings.Mind_Storm_Report_Successful] ="Ṣe ijabọ aṣeyọri";


//编辑
stringMap[strings.Global_Edit] ="satunkọ";
stringMap[strings.Global_Cancel] ="fagilee";
stringMap[strings.Global_Ok] ="pinnu";
stringMap[strings.Global_Name] ="inagije";
stringMap[strings.Global_Introduction] ="Ifihan";
stringMap[strings.Global_Change_Avatar] ="Yi Afata pada";
stringMap[strings.Global_Local_Upload] ="Ikojọpọ Agbegbe";

//个人资料
stringMap[strings.User_Personal_information] ="oro iroyin nipa re";
stringMap[strings.User_Member_Validity_Period] ="Akoko Wiwulo Awọn ọmọ ẹgbẹ";
stringMap[strings.User_My_Share] ="Pin Mi";
stringMap[strings.User_My_Collection] ="gbigba mi";
stringMap[strings.User_More_Message] ="Diẹ sii";
stringMap[strings.User_Deleted_Sotrm] ="Paarẹ Iji";
stringMap[strings.User_Folder_Rename] ="Tun lorukọ mii";
stringMap[strings.User_Folder_Rename_Root] ="Ṣatunṣe awọn igbanilaaye";
stringMap[strings.User_Folder_Rename_Root_Title] ="Ṣatunṣe awọn igbanilaaye";
stringMap[strings.User_Folder_Root_Public] ="Ifihan ni kikun";
stringMap[strings.User_Folder_Root_Viewonly] ="Folda ti gbogbo eniyan ngbanilaaye wiwo nikan";
stringMap[strings.User_Folder_Root_Private] ="Ikọkọ";
stringMap[strings.User_Folder_Root_Public_Introduction] ="Awọn eniyan miiran le wo awọn ayanfẹ rẹ lori oju-iwe profaili rẹ";
stringMap[strings.User_Message_Link] ="ọna asopọ";
stringMap[strings.User_New_Group] ="Ẹgbẹ Tuntun";
stringMap[strings.User_New_Group_Title] ="Ṣẹda ẹgbẹ tuntun kan";
stringMap[strings.User_New_Group_Style_Title] ="Iru faili";
stringMap[strings.User_Share_Is_Null] ="Ko si pinpin sibẹsibẹ!";
stringMap[strings.User_Deleted_Storm_Tips] ="Ṣe o da ọ loju pe o fẹ paarẹ iji yii?";
stringMap[strings.User_Remove_From_List] ="Yọ kuro ninu atokọ",
stringMap[strings.User_Move_To] ="gbe si",
stringMap[strings.User_Deleted_Link] ="Yọ ọna asopọ"
stringMap[strings.User_Collction_Is_Null] ="Ko si Awọn ayanfẹ"
stringMap[strings.User_Collection] ="Gbigba"


//登录框
stringMap[strings.User_Lolgin_Welcome_To_Login] ="Kaabo si Mind +"
stringMap[strings.User_Lolgin_Free_Registration] ="iforukọsilẹ ọfẹ"
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] ="Jọwọ tẹ nọmba foonu sii"
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] ="jọwọ tẹ koodu ijerisi sii"
stringMap[strings.User_Lolgin_Obtain] ="Gba"
stringMap[strings.User_Lolgin_Password_Login] ="Wiwọle Wiwọle"
stringMap[strings.User_Lolgin_Login] ="wo ile"
stringMap[strings.User_Lolgin_Register_An_Account] ="Forukọsilẹ iroyin kan"
stringMap[strings.User_Lolgin_Already_Have_An_Account] ="Ti ni akọọlẹ kan"
stringMap[strings.User_Lolgin_To_Log_In] ="Lọ lati buwolu wọle"
stringMap[strings.User_Lolgin_Please_Enter_Password] ="Jọwọ tẹ ọrọ igbaniwọle kan sii"
stringMap[strings.User_Lolgin_Confirm_Password] ="So ni pato orukoabawole re"
stringMap[strings.User_Lolgin_Registered] ="forukọsilẹ"
stringMap[strings.User_Lolgin_Account_Login] ="Wiwọle iroyin"
stringMap[strings.User_Lolgin_Scan_Code_Login] ="Wiwọle Wiwọle Kooduwo"
stringMap[strings.User_Lolgin_Forgot_Password] ="Gbagbe ọrọ aṣina bi"
stringMap[strings.User_Lolgin_Verification_Code_Login] ="Wiwọle Koodu Ijeri"
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] ="Ṣii Mind + ki o ra loke ti wiwo eto naa"
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] ="Koodu QR yii dopin ni iṣẹju marun, jọwọ tẹ nigbati o pari"
stringMap[strings.User_Lolgin_Refresh] ="Sọ"
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] ="Ṣiṣayẹwo jẹ aṣeyọri, jọwọ jẹrisi lori foonu rẹ"
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] ="Ṣe o fun ni aṣẹ lati wọle"
stringMap[strings.User_Lolgin_Please_Enter_New_Password] ="Jọwọ tẹ ọrọ igbaniwọle titun sii"
stringMap[strings.User_Lolgin_Reset] ="Tun"


//我的导图
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] ="Wa iru awoṣe tabi awoṣe";
stringMap[strings.My_Mind_Date] ="ọjọ";
stringMap[strings.My_Mind_Create_Folder] ="Ṣẹda folda";
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] ="Jọwọ tẹ orukọ folda kan sii";
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] ="Jọwọ tẹ orukọ folda tuntun kan sii";
stringMap[strings.My_Mind__No_Map_Yet_Go] ="Ko si maapu sibẹsibẹ, lọ siwaju";
stringMap[strings.My_Mind_Create] ="ṣẹda";
stringMap[strings.My_Mind_Right] ="Bayi!";
stringMap[strings.My_Mind_Open] ="tan-an";
stringMap[strings.My_Mind_Type_Left_Right] ="Aworan Onimọ Meji";
stringMap[strings.My_Mind_Type_Unilateral] ="Aworan oso Kan";
stringMap[strings.My_Mind_Type_Bottom] ="Iwe apẹrẹ agbari";
stringMap[strings.My_Mind_Type_Bubble] ="Atoka Bubble";
stringMap[strings.My_Mind_Type_Time_Vertical] ="Eto iṣeto";
stringMap[strings.My_Mind_Blank_Mind_Map] ="Aworan Okan ofo";
stringMap[strings.My_Mind_All] ="Gbogbo";
stringMap[strings.My_Mind_Views] ="Awọn iwo:";

//风暴搜索
stringMap[strings.Storm_Search_How_Title] ="Awọn aami olokiki";
stringMap[strings.Storm_History_Search] ="Iwadi Itan";

//公共 header
stringMap[strings.Public_Header_Mind] ="Maapu";
stringMap[strings.Public_Header_Outline] ="Ìla";
stringMap[strings.Public_Header_Download_Apps] ="ṣe igbasilẹ awọn ohun elo";
stringMap[strings.Public_Header_Mobile_Version] ="Ẹya alagbeka";
stringMap[strings.Public_Header_Creator] ="Eleda:";
stringMap[strings.Public_Header_View_Frequency] ="wo igbohunsafẹfẹ:";
stringMap[strings.Public_Header_Save] ="fipamọ";
stringMap[strings.Header_Mind_Tips_Saved] ="Ti fipamọ";
stringMap[strings.Header_Mind_Tips_Edit] ="Ṣatunkọ";
stringMap[strings.Header_Mind_Tips_Update_Saved] ="Ti ṣe imudojuiwọn imudojuiwọn";

//导图编辑菜单
stringMap[strings.Mind_Edit_Revoke] ="Fagilee";
stringMap[strings.Mind_Edit_Restore] ="mu pada";
stringMap[strings.Mind_Edit_Subtopic] ="Koko-ọrọ";
stringMap[strings.Mind_Edit_Top_Subtopic] ="Koko-ọrọ Oke";
stringMap[strings.Mind_Edit_Bottom_Subtopic] ="Koko-ọrọ atẹle";
stringMap[strings.Mind_Edit_Free_Node] ="Node Ọfẹ";
stringMap[strings.Mind_Edit_Summary] ="Lakotan";
stringMap[strings.Mind_Edit_Block] ="Oyinbo";
stringMap[strings.Mind_Edit_Insert] ="fi sii";
stringMap[strings.Mind_Edit_Related] ="Ẹgbẹ";
stringMap[strings.Mind_Edit_Style] ="ara";
stringMap[strings.Mind_Edit_Recording] ="gbigbasilẹ";
stringMap[strings.Mind_Edit_Insert_Picture] ="Fi aworan sii";
stringMap[strings.Mind_Edit_Insert_Icon] ="Fi Aami sii";
stringMap[strings.Mind_Edit_Insert_Remarks] ="Fi akọsilẹ sii";
stringMap[strings.Mind_Edit_Insert_Link] ="Fi ọna asopọ sii";
stringMap[strings.Mind_Edit_Insert_Summary_Graph] ="Fi apẹrẹ sii";
stringMap[strings.Mind_Edit_Upload] ="Po si";
stringMap[strings.Mind_Edit_Right_Icon] ="aami";
stringMap[strings.Mind_Edit_Right_Style] ="ara";
stringMap[strings.Mind_Edit_Audio_Decoding] ="Ṣiṣe iyipada ...";
stringMap[strings.Mind_Edit_Audio_Duration] ="iye";
stringMap[strings.Mind_Edit_Delete_Audio_Tips] ="Ṣe o da ọ loju pe o fẹ paarẹ ohun afetigbọ ti o gbasilẹ?";
stringMap[strings.Mind_Edit_Join_Membership] ="darapọ mọ ẹgbẹ";
stringMap[strings.Mind_Edit_Upgrade] ="igbesoke";
stringMap[strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap[strings.Mind_Edit_Join_Membership_Tips] ="Awọn ọmọ ẹgbẹ le ni akoko gbigbasilẹ diẹ sii, ni bayi lọ lati ṣe igbesoke si ọmọ ẹgbẹ kan?";

stringMap[strings.Mind_Edit_Choose_Material] ="Yan ohun elo";
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] ="Fa ati ju aworan silẹ nibi";
stringMap[strings.Mind_Edit_Select_locally] ="Yan lati agbegbe";
stringMap[strings.Mind_Edit_Choose_From] ="Yan lati inu ile-ikawe ohun elo";
stringMap[strings.Mind_Edit_Mine] ="temi";
stringMap[strings.Mind_Edit_My_Material] ="Ohun elo mi";
stringMap[strings.Mind_Edit_Recently_Used] ="Laipẹ Ti Lo";
stringMap[strings.Mind_Edit_Use] ="lo";
stringMap[strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] ="Ko si ohun elo sibẹsibẹ, jẹ ki a ṣafikun rẹ!";
stringMap[strings.Mind_Edit_Select_Chart_Type] ="Yan Iru apẹrẹ";
stringMap[strings.Mind_Edit_Remarks] ="Awọn ifiyesi";
stringMap[strings.Mind_Edit_Remarks_Tips] ="Jọwọ tẹ akoonu ti awọn akiyesi naa sii";
stringMap[strings.Mind_Edit_Add] ="Fi kun un";
stringMap[strings.Mind_Edit_Please_Enter_The_Link] ="Jọwọ tẹ ọna asopọ kan";

//编辑页左侧菜单
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture] ="fi aworan pamọ";
stringMap[strings.Mind_Edit_Left_Menu_Export_Pdf] ="Si ilẹ okeere PDF";
stringMap[strings.Mind_Edit_Left_Menu_Share_Links] ="Pin Ọna asopọ";
stringMap[strings.Mind_Edit_Left_Menu_Share_The_Storm] ="Pin si Iji naa";
stringMap[strings.Mind_Edit_Left_Menu_Printing] ="tẹjade";
stringMap[strings.Mind_Edit_Left_Menu_Unlock_Full_Version] ="Ṣii ẹya kikun";
stringMap[strings.Mind_Edit_Left_Menu_Help] ="Egba Mi O";
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Format] ="Fipamọ ọna kika";
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Png] ="ọna kika png";
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] ="ọna kika jpg";
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Webp] ="ọna kika wẹẹbu";
stringMap[strings.Mind_Edit_Left_Menu_Share_Map] ="Pin maapu";
stringMap[strings.Mind_Edit_Left_Menu_Share_Switch] ="Pin Yi pada";
stringMap[strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] ="O le daakọ ọna asopọ ki o firanṣẹ";
stringMap[strings.Mind_Edit_Left_Menu_Copy_Link] ="daakọ Ọna asopọ";
stringMap[strings.Mind_Edit_Left_Menu_Follow_The_Link] ="Ti o ba fẹ yi ọna asopọ naa pada, jọwọ tẹ";
stringMap[strings.Mind_Edit_Left_Menu_Regenerate] ="tunse";
stringMap[strings.Mind_Edit_Left_Menu_Link_Password] ="Ọrọ igbaniwọle ọna asopọ:";
stringMap[strings.Mind_Edit_Left_Menu_Allow_Save] ="Gba fifipamọ";
stringMap[strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] ="Awọn iwo ti o pọ julọ";
stringMap[strings.Mind_Edit_Left_Menu_Infinite] ="Kolopin";
stringMap[strings.Mind_Edit_Left_Menu_Limit] ="opin";
stringMap[strings.Mind_Edit_Left_Menu_Second] ="Igba";
stringMap[strings.Mind_Edit_Left_Menu_Link_Validity] ="Akoko akoko iwulo ọna asopọ";
stringMap[strings.Mind_Edit_Left_Menu_1_Day] ="Ọjọ 1";
stringMap[strings.Mind_Edit_Left_Menu_3_Days] ="Awọn ọjọ 3";
stringMap[strings.Mind_Edit_Left_Menu_7_Days] ="Awọn ọjọ 7";
stringMap[strings.Mind_Edit_Left_Menu_Permanent] ="titilai";
stringMap[strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] ="Daakọ Akoonu & Ọna asopọ";
stringMap[strings.Mind_Edit_Left_Menu_Regenerate_Tips] ="Ṣe o da ọ loju pe o fẹ sọtun? Ọna asopọ ti tẹlẹ yoo jẹ asan!";
stringMap[strings.Mind_Edit_Left_Menu_Share] ="pin";
stringMap[strings.Mind_Edit_Left_Menu_Custom_Category] ="Isọri Aṣa:";
stringMap[strings.Mind_Edit_Left_Menu_Allow_Comments] ="Ṣe o gba awọn asọye laaye";
stringMap[strings.Mind_Edit_Left_Menu_Edit_Add_Category] ="Ṣatunkọ lati ṣafikun ẹka";
stringMap[strings.Mind_Edit_Left_Menu_Membership_Rights] ="awọn anfani ẹgbẹ";
stringMap[strings.Mind_Edit_Left_Menu_Support_Platform] ="Syeed Atilẹyin";
stringMap[strings.Mind_Edit_Left_Menu_Open_Membership] ="darapọ mọ ẹgbẹ";
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] ="Ṣiṣe koodu Koodu ṣiṣẹ";
stringMap[strings.Mind_Edit_Left_Menu_purchase_Tips] ="Awọn anfani ẹgbẹ lẹhin rira";
stringMap[strings.Mind_Edit_Left_Menu_Payment_Type] ="Yan Ọna isanwo";
stringMap[strings.Mind_Edit_Left_Menu_Activity_Price] ="Iye Iṣẹ-ṣiṣe";
stringMap[strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] ="Iye ti a san niti gidi ”";
stringMap[strings.Mind_Edit_Left_Menu_Immediate_Renewal] ="Tunse Bayi";
stringMap[strings.Mind_Edit_Left_Menu_Terms_Of_Service] ="Awọn ofin ti iṣẹ";
stringMap[strings.Mind_Edit_Left_Menu_Privacy_Policy] =" Afihan Asiri";
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code] ="Koodu Ibere ​​ise:";
stringMap[strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] ="Jọwọ tẹ koodu ifilọlẹ sii";
stringMap[strings.Mind_Edit_Left_Menu_Verify_Activation_Code] ="Ṣayẹwo koodu ifisilẹ";
stringMap[strings.Mind_Edit_Left_Menu_Select_Categories] ="Yan ẹka";
stringMap[strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] ="Akọle akọle ko le ṣofo";
stringMap[strings.Mind_Edit_Left_Menu_Coupon] ="kupọọnu:";
stringMap[strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] ="Jọwọ tẹ koodu kupọọnu sii";
stringMap[strings.Mind_Confirm_Use_Coupons] ="Jẹrisi lilo awọn kuponu";
stringMap[strings.Mind_Edit_Left_Menu_Verification] ="ijerisi";
stringMap[strings.Mind_Edit_Left_Menu_Preferential_Amount] ="Owo ẹdinwo";
stringMap[strings.Mind_Edit_Left_Menu_Pay_Immediately] ="san lẹsẹkẹsẹ";
stringMap[strings.Mind_Edit_Left_Menu_Wx_Pay] ="WeChat sanwo";
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] ="Jẹrisi Alaye Idande";
stringMap[strings.Mind_Edit_Left_Menu_Exchange_Code] ="Koodu Idande:";
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange] ="paṣipaarọ";

//编辑页右侧菜单
stringMap[strings.Mind_Edit_Right_Menu_canvas] ="kanfasi";
stringMap[strings.Mind_Edit_Right_Menu_background_Color] ="awọ lẹhin";
stringMap[strings.Mind_Edit_Right_Menu_layout] ="akọkọ";
stringMap[strings.Mind_Edit_Right_Menu_Line_Style] ="Style Line";
stringMap[strings.Mind_Edit_Right_Menu_line_Color] ="Awọ Ila";
stringMap[strings.Mind_Edit_Right_Menu_Line_Width] ="Iwọn Line";
stringMap[strings.Mind_Edit_Right_Menu_Line_Tapering] ="Awọn ila ti wa ni teepu";
stringMap[strings.Mind_Edit_Right_Menu_Theme_Font] ="Akori Akori";
stringMap[strings.Mind_Edit_Right_Menu_Child_Font] ="Ipele-Font";
stringMap[strings.Mind_Edit_Right_Menu_text_Color] ="Awọ Ọrọ";
stringMap[strings.Mind_Edit_Right_Menu_font_Size] ="iwọn iwọn";
stringMap[strings.Mind_Edit_Right_Menu_Font_Style] ="Ara Font";
stringMap[strings.Mind_Edit_Right_Menu_Border_Color] ="Awọ Aala";
stringMap[strings.Mind_Edit_Right_Menu_Fill_Color] ="Kun awọ";
stringMap[strings.Mind_Edit_Right_Menu_Border_Shape] ="Aala Apẹrẹ";
stringMap[strings.Mind_Edit_Right_Menu_Border_Width] ="Iwọn Aala";
stringMap[strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] ="Ṣafihan ipa ojiji (iṣẹ ṣiṣe ṣiṣe alabapin)";
stringMap[strings.Mind_Edit_Right_Menu_Line_Color] ="Awọ Ila";
stringMap[strings.Mind_Edit_Right_Menu_Dotted_Line] ="ila ti sami";
stringMap[strings.Mind_Edit_Right_Menu_Picture_Size] ="Iwọn aworan";
stringMap[strings.Mind_Edit_Right_Menu_Picture_Location] ="Ipo Aworan";
stringMap[strings.Mind_Edit_Right_Menu_Picture_Shape] ="Aworan apẹrẹ";
stringMap[strings.Mind_Edit_Right_Menu_Icon_Size] ="Iwọn aami";
stringMap[strings.Mind_Edit_Right_Menu_Icon_Position] ="Ipo aami";
stringMap[strings.Mind_Edit_Right_Menu_routine] ="mora";
stringMap[strings.Mind_Edit_Right_Menu_priority] ="ayo";
stringMap[strings.Mind_Edit_Right_Menu_Progress] ="iṣeto";
stringMap[strings.Mind_Edit_Right_Menu_Stars] ="irawọ";
stringMap[strings.Mind_Edit_Right_Menu_Week] ="ọsẹ";
stringMap[strings.Mind_Edit_Right_Menu_Head_Portrait] ="Afata";
stringMap[strings.Mind_Edit_Right_Menu_3D] ="3D";
stringMap[strings.Mind_Edit_Right_Menu_Learning] ="Kọ ẹkọ";
stringMap[strings.Mind_Edit_Right_Menu_Work] ="awọn iṣẹ";
stringMap[strings.Mind_Edit_Right_Menu_Characters] ="ohun kikọ";
stringMap[strings.Mind_Edit_Right_Menu_Animals] ="ẹranko";
stringMap[strings.Mind_Edit_Right_Menu_Food] ="ounjẹ";
stringMap[strings.Mind_Edit_Node_Menu_Copy_Style] ="Daakọ Ara";
stringMap[strings.Mind_Edit_Node_Menu_Sticking_Style] ="Lẹẹ Style";
stringMap[strings.Mind_Edit_Node_Menu_Modify_Shape] ="Ṣatunṣe apẹrẹ";
stringMap[strings.Mind_Edit_Node_Menu_View_Picture] ="wo aworan";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Picture] ="Paarẹ Aworan";
stringMap[strings.Mind_Edit_Node_Menu_Copy] ="daakọ";
stringMap[strings.Mind_Edit_Node_Menu_Paste] ="Lẹẹ mọ";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Line] ="Paarẹ laini";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] ="paarẹ";
stringMap[strings.Mind_Edit_Node_Menu_Edit_Remarks] ="Ṣatunkọ Awọn akọsilẹ";
stringMap[strings.Mind_Edit_Node_Menu_Open_Link] ="Ṣii ọna asopọ";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Remarks] ="Paarẹ akọsilẹ";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Link] ="Yọ ọna asopọ";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Icon] ="Mu aami kuro";
stringMap[strings.Mind_Edit_Node_Menu_Icon_Style] ="Ara aami";

//统计图编辑面板
stringMap[strings.Echart_Edit_Statistical] ="awọn iṣiro";
stringMap[strings.Echart_Edit_Value] ="iye";
stringMap[strings.Echart_Edit_Color] ="awọ";
stringMap[strings.Echart_Edit_Title] ="akọle";
stringMap[strings.Echart_Edit_Detailed_Description] ="Apejuwe Alaye";
stringMap[strings.Echart_Edit_Value_Sort] ="Too";
stringMap[strings.Echart_Edit_Value_Display_Type] ="Iru Ifihan";
stringMap[strings.Echart_Edit_Value_Color_Scheme] ="ilana awọ";
stringMap[strings.Echart_Edit_Value_Display_Size] ="Iwọn ifihan";
stringMap[strings.Echart_Edit_Value_Title_Size] ="Iwọn akọle";
stringMap[strings.Echart_Edit_Value_Explain_Size] ="Iwọn itumọ";
stringMap[strings.Echart_Edit_Value_Subscribe] ="ṣiṣe alabapin";

//风暴标签页
stringMap[strings.StormBookTag_Subscribed] ="Ti ṣe alabapin";
stringMap[strings.StormBookTag_List] ="Akojọ";

//风暴分享页
stringMap[strings.Storm_Share_Comment] ="asọye";
stringMap[strings.Storm_Share_Reply] ="Fesi";
stringMap[strings.Storm_Share_Let_Me_Comment_Too] ="Jẹ ki n sọ asọye ju ~";
stringMap[strings.Storm_Share_Release] ="tu silẹ";
stringMap[strings.Storm_Share_Main_Title] ="Akọle akọle";
stringMap[strings.Storm_Share_Subtitle] ="atunkọ";
stringMap[strings.Storm_Share_Author] ="Onkọwe";
stringMap[strings.Storm_Share_Replies] ="Fesi";
stringMap[strings.Storm_Share_Comment_Is_Null_Tips] ="Ko si asọye, wa lori ki o jẹ akọkọ";
stringMap[strings.Storm_Share_Comment_Input_Is_Null_Tips] ="Jọwọ tẹ asọye kan sii ~";
stringMap[strings.Storm_Share_Delete_Comment_Tips] ="Ṣe o da ọ loju pe o fẹ paarẹ asọye yii?";

//导图内部使用帮助
stringMap[strings.Mind_Usinghelp_Btn_1] ="Awọn bọtini abuja (windows)";
stringMap[strings.Mind_Usinghelp_Btn_2] ="Bọtini ọna abuja (mac)";
stringMap[strings.Mind_Usinghelp_Btn_3] ="apejuwe";
stringMap[strings.Mind_Usinghelp_Btn_4] ="[Konturolu] + [D]";
stringMap[strings.Mind_Usinghelp_Btn_5] ="[Aṣẹ] + [D]";
stringMap[strings.Mind_Usinghelp_Btn_6] ="Ṣafikun ipade ọmọ";
stringMap[strings.Mind_Usinghelp_Btn_7] ="[Delet]";
stringMap[strings.Mind_Usinghelp_Btn_8] ="Pa oju ipade lọwọlọwọ (akiyesi pe ko le paarẹ ti o ba wa ni ipo ṣiṣatunkọ)";
stringMap[strings.Mind_Usinghelp_Btn_9] ="[Konturolu] + [E]";
stringMap[strings.Mind_Usinghelp_Btn_10] ="[Commandfin] + [E]";
stringMap[strings.Mind_Usinghelp_Btn_11] ="Ṣatunṣe oju ipade lọwọlọwọ";
stringMap[strings.Mind_Usinghelp_Btn_12] ="[Konturolu] + [↑] [↓] [←] [→]";
stringMap[strings.Mind_Usinghelp_Btn_13] ="[Aṣẹ] + [↑] [↓] [←] [→]";
stringMap[strings.Mind_Usinghelp_Btn_14] ="[Konturolu] + [+]";
stringMap[strings.Mind_Usinghelp_Btn_15] ="[Commandfin] + [+ +]";
stringMap[strings.Mind_Usinghelp_Btn_16] ="Ṣe afikun Maapu";
stringMap[strings.Mind_Usinghelp_Btn_17] ="[Konturolu] + [-]";
stringMap[strings.Mind_Usinghelp_Btn_18] ="[Commandfin] + [- -]";
stringMap[strings.Mind_Usinghelp_Btn_19] ="Sun maapu sita";
stringMap[strings.Mind_Usinghelp_Btn_20] ="[Konturolu] + [S]";
stringMap[strings.Mind_Usinghelp_Btn_21] ="[Commandfin] + [S]";
stringMap[strings.Mind_Usinghelp_Btn_22] ="[Konturolu] + [Z]";
stringMap[strings.Mind_Usinghelp_Btn_23] ="[Aṣẹ] + [Z]";
stringMap[strings.Mind_Usinghelp_Btn_24] ="[Konturolu] + [Yi lọ yi bọ] + [Z]";
stringMap[strings.Mind_Usinghelp_Btn_25] ="[Commandfin] + [Yi lọ yi bọ] + [Z]";
stringMap[strings.Mind_Usinghelp_Btn_26] ="[Konturolu] + [B]";
stringMap[strings.Mind_Usinghelp_Btn_27] ="[Aṣẹ] + [B]";
stringMap[strings.Mind_Usinghelp_Btn_28] ="Jade ṣiṣatunkọ";

//提示文字
stringMap[strings.Message_Tips_Login_Success] ="Wiwọle wọle aṣeyọri";
stringMap[strings.Message_Tips_Dlt_Success] ="ti paarẹ ni ifijišẹ";
stringMap[strings.Message_Tips_Report_Success] ="Ṣe ijabọ aṣeyọri";
stringMap[strings.Message_Tips_Copy_Success] ="Daakọ ṣaṣeyọri";
stringMap[strings.Message_Tips_Collction_Success] ="Ajọpọ ṣaṣeyọri";
stringMap[strings.Message_Tips_Avatar_Modification_Success] ="A ti yi avatar pada ni aṣeyọri";
stringMap[strings.Message_Tips_Signature_Modification_Success] ="Ibuwọlu ti yipada ni aṣeyọri";
stringMap[strings.Message_Tips_Name_Modification_Success] ="Orukọ apeso ti a tunṣe ni aṣeyọri";
stringMap[strings.Message_Tips_Modification_Success] ="Ti yipada ni aṣeyọri";
stringMap[strings.Message_Tips_New_Profile_Success] ="Ṣẹda folda tuntun ni aṣeyọri";
stringMap[strings.Message_Tips_Mobile_Success] ="Gbe ni aṣeyọri";
stringMap[strings.Message_Tips_Submit_Success] ="Ti firanṣẹ ni aṣeyọri";
stringMap[strings.Message_Tips_Exchange_Success] ="Aṣeyọri paṣipaarọ";
stringMap[strings.Message_Tips_Please_Code] ="Jọwọ tẹ koodu ifilọlẹ sii";
stringMap[strings.Message_Tips_Agree_To_Terms] ="Jọwọ gba si eto imulo ipamọ";
stringMap[strings.Message_Tips_Please_Sign_In] ="jowo wole";
stringMap[strings.Message_Tips_Share_Success] ="Pin aṣeyọri";
stringMap[strings.Message_Tips_Create_Success] ="Ti ṣẹda ni aṣeyọri";
stringMap[strings.Message_Tips_Profile_Name_No_Null] ="Orukọ folda ko le ṣofo";
stringMap[strings.Message_Tips_Selecte_Success] ="Wa pari";
stringMap[strings.Message_Tips_Subscription_Success] ="Ṣiṣe alabapin ṣiṣe ṣaṣeyọri";
stringMap[strings.Message_Tips_Unsubscribe_Success] ="Ṣe alabapin kuro ni aṣeyọri";
stringMap[strings.Message_Tips_Related_Content_Not_Found] ="Ko ri akoonu ti o jọmọ";
stringMap[strings.Message_Tips_Max_Add_Class] ="Le nikan ṣafikun ni pupọ julọ";
stringMap[strings.Message_Tips_Registered_Success] ="Aṣeyọri iforukọsilẹ";
stringMap[strings.Message_Tips_Scan_Code_Login_Failed] ="Wiwọle koodu iwole kuna";
stringMap[strings.Message_Tips_Share_Is_Null] ="Pin ọna asopọ ko si tẹlẹ";
stringMap[strings.Message_Tips_Share_Link_Does_Not_Exist] ="Awọn faili ikojọpọ ko le kọja 3MB";
stringMap[strings.Message_Tips_Get_Permission_Successfully] ="Gbigba igbanilaaye ni aṣeyọri";
stringMap[strings.Message_Tips_Membership_Required] ="O nilo fun ẹgbẹ";
stringMap[strings.Message_Tips_Save_Success] ="Ti fipamọ ni aṣeyọri";
stringMap[strings.Message_Tips_Scan_Code_Login_Success] ="Ṣayẹwo iwoye koodu wọle ni aṣeyọri";
stringMap[strings.Message_Tips_Style] ="Sọri kan?";

//大纲模式
stringMap[strings.Outline_Menu_Indent] ="ifunni";
stringMap[strings.Outline_Menu_Decrease_Indent] ="Din ifunni silẹ";

//帮助提示
stringMap[strings.Help_Background_Content] ="Ipilẹṣẹ ẹhin laini, ibatan ibatan laini, ibatan ibatan laini, ipilẹ ti o jọmọ laini, ibatan ti ila, ipilẹ ti o jọmọ laini, ipilẹ ti o jọmọ, ipilẹ ti o jọmọ laini, ibatan ibatan laini,";
stringMap[strings.Help_Mind_Content] ="O le ṣe awọn maapu pẹlu awọn ipilẹ oriṣiriṣi ni ibamu si awọn ayanfẹ rẹ: maapu itọsọna meji, maapu apa osi, maapu apa ọtun, chart agbari, igi ọna meji, igi apa osi, igi apa ọtun, ati tabili";
stringMap[strings.Help_Share_Link_Content] ="Awọn ọna asopọ pinpin ni a le pin ni WeChat, QQ, Weibo ati awọn ikanni miiran ni ọna awọn ọna asopọ, ati awọn ọrẹ le wo akoonu naa";
stringMap[strings.Using_Templates] ="Lo awoṣe";

//邀请码
stringMap[strings.User_Lolgin_Please_Enter_Invitation_Code] ="Jọwọ tẹ koodu ifiwepe sii";
stringMap[strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] ="Jọwọ tẹ koodu ifiwepe to pe sii";
stringMap[strings.User_Lolgin_Optional] ="Yiyan";

//使用素材

stringMap[strings.Cannot_Add_To_Node_Background] ="Ko wulo fun isale oju ipade";
stringMap[strings.Cannot_Add_To_Img_Background] ="Ko wulo fun abẹlẹ ọrọ";
stringMap[strings.Cannot_Add_To_Mind_Background] ="Ko wulo fun isale ila murasilẹ";
stringMap[strings.Mind_Edit_Node_Menu_Add_Encircle_Background] ="Ṣafikun aworan isale kan";
stringMap[strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] ="Yọ aworan abẹlẹ kuro";
stringMap[strings.Mind_Add_Background_Image] ="Fi aworan isale sii";
stringMap[strings.Mind_Delete_Background_Image] ="Yọ aworan abẹlẹ kuro";

stringMap[strings.Permanent_Member] ="Egbe Yẹ";
stringMap[strings.Insufficient_Storage_Space] ="Aaye ibi ipamọ ti ko to, ṣe igbesoke awọn ọmọ ẹgbẹ lati mu aaye kun.";
stringMap[strings.Used_Space] ="ibi ipamọ";
stringMap[strings.Current_Storage] ="Ibi ipamọ lọwọlọwọ:";
stringMap[strings.Used_Space_Upgrade] ="Igbesoke Aaye Ifipamọ";
stringMap[strings.My_Mind_Is_Null_Tips] ="Mind + maapu ọpọlọ & akọsilẹ-gbigba Super mnemonics, lati ṣafikun awọn iyẹ si ọkan lati ṣẹda, iṣafihan, gbero, ati akọsilẹ";
stringMap[strings.Insufficient_Storage_Space_Tempalte_Tips] ="Aaye ibi ipamọ ti ko to, ṣe igbesoke awọn ọmọ ẹgbẹ lati lo awoṣe.";

stringMap[strings.Group_Management] ="Ẹgbẹ";
stringMap[strings.Save_Img_Fill_Full] ="Pave ti kun";
stringMap[strings.Save_Img_Center_Tilt] ="Ti dojukọ ati ti tẹ";
stringMap[strings.Save_Img_File_Full_Dark_Color] ="Ti pa (dudu)";
stringMap[strings.Save_Img_Mind_Map_Watermarking] ="Omi-omi Maapu Mind + Mind";
stringMap[strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] ="Ṣafikun ami omi lati daabobo ẹda ẹda:";
stringMap[strings.Save_Img_Watermark_Text] ="Watermark ọrọ:";
stringMap[strings.Save_Img_Watermark_Logo] ="Watermark LOGO:";
stringMap[strings.Save_Img_Watermark_Style] ="Omi Watermark:";
stringMap[strings.Save_Img_Please_Input] ="jọwọ tẹ";
stringMap[strings.Save_Img_Chop_Tips] ="Ṣii ẹgbẹ lati gbadun awọn ẹtọ omi";
stringMap[strings.Public_Header_Mindmap_Mind] ="Ipo Maapu";
stringMap[strings.Copy_Mind_Map] ="Daakọ maapu";
stringMap[strings.Font_Style_Bold] ="Alaifoya";
stringMap[strings.Font_Style_Italics] ="Italiki";
stringMap[strings.Font_Style_Delete_Line] ="Strikethrough";
stringMap[strings.Remove_Any] ="Yọ";

stringMap[strings.Mindmap_Review] ="Atunyẹwo maapu";
stringMap[strings.Mindmap_Review_Recently_Updated_Content_10] ="Ṣe iwunilori jinlẹ ki o mu iranti pọ si, to awọn imọran ati alaye jade daradara";
stringMap[strings.Mindmap_Review_Content] ="Atunyẹwo maapu n tọka si ṣiṣiṣẹsẹhin ti ilana ṣiṣatunkọ, ati pe akoonu naa dun ni itẹlera lati akori aringbungbun; Atunwo maapu le ṣe iranlọwọ fun ọ lati ṣe akojopo eto, onínọmbà, ki o wa awọn abawọn ninu imọran. Lakoko ilana atunyẹwo, o le wa oriṣiriṣi awọn awokose. ";
stringMap[strings.Mindmap_Review_Speed] ="iyara";
stringMap[strings.Mindmap_Review_Word_Speed] ="Iyara Text";
stringMap[strings.Mindmap_Review_Background_Ratio] ="“Ipilẹṣẹ Lẹhin”";
stringMap[strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap[strings.Mindmap_Review_Current_Playback_Node_Magnification] ="Igbega ti oju ipade sẹhin sẹhin lọwọlọwọ";
stringMap[strings.Mindmap_Review_Node_Playback_Order] ="Node Play Order";
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap[strings.Mind_Group_Picture_Upload] ="Fifuye aworan";
stringMap[strings.Mind_Group_Upload_From_Local] ="Ikojọpọ Agbegbe";
stringMap[strings.Mind_Group_Mind_Mapping] ="Mapping okan";
stringMap[strings.Mind_Group_Information] ="data";

stringMap[strings.Mind_Group_Invite_New_Members] ="Pe awọn ọmọ ẹgbẹ tuntun";
stringMap[strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] ="Jọwọ tẹ nọmba foonu sii";
stringMap[strings.Mind_Group_Cancel] ="fagilee";
stringMap[strings.Mind_Group_Confirm] ="pinnu";
stringMap[strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] ="Nọmba foonu alagbeka ko le ṣofo";
stringMap[strings.Mind_Group_Invite_The_Successful] ="Pipe si ni aṣeyọri";


stringMap[strings.Mind_Group_Shared_Group_Membership] ="Awọn ọmọ ẹgbẹ ẹgbẹ pinpin";
stringMap[strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] ="Nọmba ti o pọ julọ ti awọn ẹgbẹ ẹgbẹ pinpin";
stringMap[strings.Mind_Group_Membership_Limit] ="Idiwọn ẹgbẹ";
stringMap[strings.Mind_Group_Current_Member_Limit] ="Ifilelẹ Ẹgbẹ lọwọlọwọ";
stringMap[strings.Mind_Group_Upgrade_Member_Limit] ="Igbesoke iye Ọmọ ẹgbẹ";
stringMap[strings.Mind_Group_Members_Of_The_Search] ="Wa awọn ọmọ ẹgbẹ";
stringMap[strings.Mind_Group_Creator] ="Eleda";
stringMap[strings.Mind_Group_Administrator] ="alakoso";
stringMap[strings.Mind_Group_Number_Of_People] ="eniyan";
stringMap[strings.Mind_Group_Selected] ="yàn";
stringMap[strings.Mind_Group_Member] ="omo egbe";
stringMap[strings.Mind_Group_Check_All] ="sa gbogbo re";
stringMap[strings.Mind_Group_Remove] ="Yọ";
stringMap[strings.Mind_Group_Invite_New_Members] ="Pe awọn ọmọ ẹgbẹ tuntun";
stringMap[strings.Mind_Group_Delete_Members] ="Mu egbe kuro";
stringMap[strings.Mind_Group_Please_Select_The_Member_To_Delete] ="Jọwọ yan ẹgbẹ lati paarẹ";
stringMap[strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] ="Ṣe o da ọ loju pe o fẹ yọ olumulo kuro ninu ẹgbẹ yii?";
stringMap[strings.Mind_Group_Add_As_Administrator] ="Ṣafikun Alabojuto";
stringMap[strings.Mind_Group_Accomplish] ="gbe jade";
stringMap[strings.Mind_Group_Compile] ="satunkọ";
stringMap[strings.Mind_Group_Group_Owner] ="Oluwa";
stringMap[strings.Mind_Group_Add_Admin] ="Ṣafikun Alabojuto";
stringMap[strings.Mind_Group_Group_ID] ="Id ẹgbẹ";
stringMap[strings.Mind_Group_Group_Number] ="Ẹgbẹ Bẹẹkọ";
stringMap[strings.Mind_Group_Name] ="orukọ";
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] ="Jọwọ tẹ orukọ ti ẹgbẹ pinpin";
stringMap[strings.Mind_Group_Introduce] ="Ifihan";
stringMap[strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] ="Jọwọ ṣafihan ni ṣoki ẹgbẹ";
stringMap[strings.Mind_Group_Announcement] ="ikede";
stringMap[strings.Mind_Group_Shared_Group_Tag] ="Aami Aami Ẹgbẹ";
stringMap[strings.Mind_Group_Custom_Tag] ="Aami Aami";
stringMap[strings.Mind_Group_Save] ="fipamọ";
stringMap[strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] ="Orukọ ẹgbẹ ko le ṣofo";
stringMap[strings.Mind_Group_Establish] ="ṣẹda";
stringMap[strings.Mind_Group_Allows_Mapfolder_Creation] ="Gba laaye lati ṣẹda awọn maapu / awọn folda";
stringMap[strings.Mind_Group_Allows_Editing_Of_Maps] ="Gba ṣiṣatunkọ ti maapu";
stringMap[strings.Mind_Group_Holder] ="Gbogbo eniyan";
stringMap[strings.Mind_Group_Only_The_Administrator] ="Alakoso nikan";
stringMap[strings.Mind_Group_Not_Allow] ="Ko si aaye";
stringMap[strings.Mind_Group_Allows_Map_Deletion] ="Gba laaye paarẹ maapu";
stringMap[strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] ="Gba apejọ ijiroro laaye lati firanṣẹ awọn ifiranṣẹ";
stringMap[strings.Mind_Group_Export] ="Si ilẹ okeere";
stringMap[strings.Mind_Group_Allows_You_To_Save_Images_PDF] ="Gba laaye fifipamọ awọn aworan / PDF";
stringMap[strings.Mind_Group_Open_Group_Watermark] ="Tan ami-ami omi ẹgbẹ";
stringMap[strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] ="Lẹhin ti nsii, aami ẹgbẹ yoo tẹjade lori maapu lokan";
stringMap[strings.Mind_Group_Allow_Saving_Maps] ="Gba laaye lati fipamọ maapu";
stringMap[strings.Mind_Group_Save_It_To_My_Map] ="Fipamọ si maapu mi";
stringMap[strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] ="Boya lati gba laaye pinpin si iji naa";
stringMap[strings.Mind_Group_Join_The_Way] ="Bii o ṣe le darapọ mọ";
stringMap[strings.Mind_Group_Whether_To_Invite_New_Members] ="Boya lati gba awọn ọmọ ẹgbẹ tuntun laaye lati pe";
stringMap[strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] ="Boya lati gba nọmba ẹgbẹ wiwa laaye lati darapọ mọ";
stringMap[strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] ="Ṣe o nilo lati ṣayẹwo ijẹrisi naa nigbati o ba n darapọ mọ";
stringMap[strings.Mind_Group_Shared_Group_Data] ="Pin Alaye Ẹgbẹ";
stringMap[strings.Mind_Group_Authority_Management] ="iṣakoso aṣẹ";
stringMap[strings.Mind_Group_Quit_Sharing_Of_Group] ="Fi Ẹgbẹ Pinpin silẹ";
stringMap[strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] ="Ṣe o da ọ loju lati jade kuro ninu ẹgbẹ pinpin?";
stringMap[strings.Mind_Group_The_Dissolution_Of_Group] ="Ẹgbẹ Tuka";
stringMap[strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] ="Awọn faili naa yoo paarẹ lẹhin ti a ti pin ẹgbẹ naa. Ṣe o da ọ loju lati tuka?";
stringMap[strings.Mind_Group_Return_To_Home_Page] ="Pada si oju-iwe ile";
stringMap[strings.Mind_Group_Join_The_Group] ="Darapọ mọ Ẹgbẹ";
stringMap[strings.Mind_Group_Please_Enter_The_Group_Number] ="Jọwọ tẹ nọmba ẹgbẹ / orukọ ẹgbẹ lati wa";
stringMap[strings.Mind_Group_Apply_To_Join] ="Awọn ohun elo fun Ọmọ ẹgbẹ";
stringMap[strings.Mind_Group_Join] ="Darapọ";
stringMap[strings.Mind_Group_Verification] ="ijerisi";
stringMap[strings.Mind_Group_Please_Enter_A_Validation_Message] ="Jọwọ tẹ ifiranṣẹ ijẹrisi kan";
stringMap[strings.Mind_Group_The_Content_Cannot_Be_Empty] ="akoonu naa ko le ṣofo";
stringMap[strings.Mind_Group_Name] ="Orukọ ẹgbẹ pinpin";
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] ="Jọwọ tẹ orukọ ti ẹgbẹ pinpin";
stringMap[strings.Mind_Group_Introduction] ="Ifihan si Awọn ẹgbẹ Pinpin";
stringMap[strings.Mind_Group_Group_Indication] ="Ẹgbẹ Bẹẹkọ";
stringMap[strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] ="Ẹlẹda naa di ọlẹ, Emi ko ṣafihan rẹ sibẹsibẹ ~";
stringMap[strings.Mind_Group_Shared_Group_Announcement] ="Ikede Ẹgbẹ Pinpin";
stringMap[strings.Mind_Group_No_Announcement] ="Ko si ikede sibẹsibẹ";
stringMap[strings.Mind_Group_Click_On] ="Tẹ lati faagun";
stringMap[strings.Mind_Group_Click_On_The_Hidden] ="Tẹ lati fi pamọ";
stringMap[strings.Mind_Group_Altogether] ="Papọ";
stringMap[strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] ="Daakọ nọmba ẹgbẹ ni aṣeyọri";
stringMap[strings.Mind_Group_Stick] ="Top";
stringMap[strings.Mind_Group_There_Is_A_New_Content] ="Akoonu tuntun wa";
stringMap[strings.Mind_Group_Cancel_The_Top] ="Yọọ oke";
stringMap[strings.Mind_Group_Setting_Up] ="Ètò";
stringMap[strings.Mind_Group_Usinghelp] ="Lilo iranlọwọ";
stringMap[strings.Mind_Group_Set_The_Message] ="Ifiranṣẹ Ẹgbẹ";
stringMap[strings.Mind_Group_Apply_To_Join] ="Awọn ohun elo fun Ọmọ ẹgbẹ";
stringMap[strings.Mind_Group_Invite_To_Join] ="Pe si darapọ";
stringMap[strings.Mind_Group_Consent] ="gba";
stringMap[strings.Mind_Group_Turn_Down] ="Kọ";
stringMap[strings.Mind_Group_Label] ="aami";
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] ="Jọwọ tẹ orukọ aami ẹgbẹ ẹgbẹ ti o pin";
stringMap[strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] ="Pe awọn ọrẹ ati awọn ẹlẹgbẹ lati ṣepọ-ati pinpin akoonu imọ";
stringMap[strings.Mind_Group_Immediately_Create] ="Ṣẹda Bayi";
stringMap[strings.Mind_Group_Immediately_To_Join] ="Darapọ Bayi";

stringMap[strings.Currently_Selected] ="Lọwọlọwọ atẹle";
stringMap[strings.LabelManagement] ="Isakoso Isamisi";
stringMap[strings.NoAttention] ="Ko si Tẹle";
stringMap[strings.Mindmap_Review_Play] ="Mu ṣiṣẹ";

stringMap[strings.Mind_Group_Joined] ="darapo";
stringMap[strings.Mind_Group_Download_App] ="Ṣe igbasilẹ APP";
stringMap[strings.Mind_Group_No_Content] ="ko si akoonu sibẹsibẹ";
stringMap[strings.Mind_Group_Individual] ="A";
stringMap[strings.Mind_Group_Message_Area] ="Agbegbe ifiranṣẹ";
stringMap[strings.Mind_Group_No_Message] ="Ko si awọn asọye sibẹsibẹ, lọ bayi";
stringMap[strings.Mind_Group_Leave_Message] ="fi ifiranṣẹ silẹ";
stringMap[strings.Mind_Group_Bar] ="Pẹpẹ";
stringMap[strings.Mind_Group_See_More] ="wo diẹ sii";
stringMap[strings.Mind_Group_Enter_Invitation_Code] ="Tẹ koodu ifiwepe sii";
stringMap[strings.Mind_Group_Apply_To_Join_Sharing_Group] ="Waye lati darapọ mọ ẹgbẹ ti o pin";
stringMap[strings.Reasons_For_Application_0_30_Words] ="Idi fun ohun elo (awọn ọrọ 0-30)";
stringMap[strings.Mind_Group_Send_Out] ="firanṣẹ";
stringMap[strings.Mind_Group_Join_Successfully] ="Darapọ mọ ni aṣeyọri";
stringMap[strings.Mind_Group_Application_Sent_Successfully] ="Ohun elo ti firanṣẹ ni aṣeyọri";

stringMap[strings.Mind_Group_Save_The_Mapping] ="Fipamọ maapu";
stringMap[strings.Mind_Group_Update_Record] ="Ṣatunṣe Igbasilẹ";

stringMap[strings.Mind_Edit_Page_Switch_Mapping] ="Yipada maapu";
stringMap[strings.Mind_Edit_Page_Mind_Retrospect] ="Atunyẹwo maapu";
stringMap[strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] ="Olumulo ti o wa tẹlẹ n ṣatunṣe maapu naa";
stringMap[strings.Mind_Edit_Page_Password] ="ọrọigbaniwọle";

stringMap[strings.Mind_Edit_Page_Sharing_Group] ="Pin Ẹgbẹ";
stringMap[strings.Mind_MBean] ="M ewa";
stringMap[strings.Mind_My_MBean] ="Ewa mi M";
stringMap[strings.Alipay] ="Alipay";
stringMap[strings.Alipay_Payment] ="San pẹlu Ali-Pay";
stringMap[strings.Please_Pay_On_The_Payment_Page] ="Jọwọ lọ si oju-iwe isanwo lati sanwo";
stringMap[strings.WeChat] ="WeChat";
stringMap[strings.Mind_Shooping_Total] ="lapapọ";
stringMap[strings.Mind_Shooping_Confirm_Payment] ="jẹrisi isanwo";
stringMap[strings.Mind_Shooping_Redeem_Now] ="Rà bayi";

stringMap[strings.Mind_Whether_Confirm_Payment_Purchase] ="Ṣe o da ọ loju lati sanwo fun rira naa?";
stringMap[strings.Mind_Tips] ="yara";
stringMap[strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] ="Awọn ewa M rẹ ko to, ṣe o fẹ ra awọn ewa M?";

stringMap[strings.Mind_share_scan_code] ="Mind + Scan Code";

stringMap[strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] ="Boya lati pada si ibi ti o ti dun kẹhin?";
stringMap[strings.Mind_Play_Number_Of_Playable_Times_Remaining] ="Awọn akoko ṣiṣere to ku";

stringMap[strings.Group_Mind_Play_The_File_Will_Not_Exist] ="Faili naa yoo parẹ patapata, ṣe o da ọ loju lati tuka?";
stringMap[strings.Group_Mind_Up_One_Level] ="ipele oke";
stringMap[strings.Group_Mind_No_Documents] ="Ko si awọn faili sibẹsibẹ";
stringMap[strings.Group_Mind_PaymentSuccessful] ="isanwo ṣaṣeyọri";

stringMap[strings.Mind_Edit_Page_Sharing_Group_introduce] ="Ṣiṣatunṣe ifowosowopo nipasẹ awọn eniyan, amuṣiṣẹpọ akoko gidi ti awọn ebute pupọ, pinpin awọn iwe aṣẹ, imudarasi ṣiṣe ifowosowopo ẹgbẹ";

stringMap[strings.Mind_Edit_Subscript] ="Akọsilẹ";
stringMap[strings.Mind_Edit_Text_Location] ="Ipo Ipo";
stringMap[strings.Mind_Mind_Import_Into_A_Shared_Group] ="Gbe wọle si Ẹgbẹ Pipin";

stringMap[strings.Standard_Colors] ="Awọ boṣewa";
stringMap[strings.More_Colors] ="awọn awọ diẹ sii";
stringMap[strings.Reset_Password] ="Tun Ọrọ igbaniwọle pada";
stringMap[strings.Account_Password_Login] ="Wọle iwọle Ọrọigbaniwọle";
stringMap[strings.Authentication_code_Login_registration] ="Wiwọle koodu / ijẹrisi koodu ijẹrisi";
stringMap[strings.One_End_Creation_Multi_End_Synchronization] ="Ṣẹda ni opin kan ki o muuṣiṣẹpọ ni awọn opin pupọ";
stringMap[strings.Rich_And_Diverse_Mapping_Structure] ="“Ifilelẹ maapu ọlọrọ ati Oniruuru”";
stringMap[strings.Massive_And_ExquisiteTemplateLibrary] ="Ikawe ati awoṣe olorinrin awoṣe";
stringMap[strings.Map_Review_Play] ="Ṣiṣẹ Atunwo Maapu";
stringMap[strings.CommunityLearningAndSharingPlatform] ="Ẹkọ Imọ ati Syeed Pipin paṣipaarọ";

stringMap[strings.Mind_Mind_Upgrade_Storage_Space] ="Igbesoke Ibi Ifipamọ";
stringMap[strings.Mind_Mind_Let_Me_Comment_Too] ="Jẹ ki n sọrọ lori rẹ paapaa";
stringMap[strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] ="Ko si ifitonileti iroyin sibẹsibẹ";
stringMap [strings.Failed_To_Get_Recording_Device] = "O kuna lati gba ẹrọ gbigbasilẹ"

stringMap [strings.Search_Pagination] = "Pagination Ṣawari"
stringMap [strings.Please_Enter_A_Paging_Name] = "Jọwọ tẹ orukọ oju -iwe sii"
stringMap [strings.Operation_Settings] = "Eto Eto isẹ"
stringMap [strings.Shortcut_key_Settings] = "Awọn Eto Bọtini Ọna abuja"
stringMap [strings.Press_Key_Combination] = "Jọwọ tẹ apapo bọtini ti o nilo"
stringMap [strings.Mind_Reduction] = "Idinku"
stringMap[strings.Double_Click_Add_Free_Node] = "Tẹ aaye ṣofo lẹẹmeji lati ṣafikun aaye ọfẹ kan"
stringMap [strings.Shortcut_Conflict] = "Rogbodiyan bọtini ọna abuja"
stringMap [strings.Select_Main_Node] = "Yan oju ipade akọkọ"
stringMap [strings.Telephone] = "Foonu"
stringMap [strings.Microblog] = "Microblog"
stringMap [strings.Address] = "Adirẹsi"
stringMap [strings.Company_Address] = "Dina B, Ilé 21, Rara. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "Ile ifi nkan pamosi"
stringMap [strings.Mind_Bookshelf] = "Bookshelf"
stringMap [strings.Search_Archive] = "Ṣawari ibi ipamọ iwe"
stringMap [strings.Modify_Archive_Order] = "Ṣe atunṣe Ibere ​​Iwe -ipamọ"
stringMap [strings.Modification_Order] = "Bere fun Iyipada"
stringMap [strings.Please_Enter_Archive_Name] = "Jọwọ tẹ orukọ ibi ipamọ iwe"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "Orukọ selifu iwe ko le ṣofo"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "Ṣe o da ọ loju pe o fẹ pa ibi ipamọ iwe yii bi?"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "Ṣe o da ọ loju pe o fẹ pa iwe -ipamọ yii bi?"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "Ṣe o da ọ loju pe o pa maapu yii bi?"
stringMap [strings.Mind_Edit_Confirm] = "Jẹrisi"
stringMap [strings.Mind_Import_Map] = "Maapu gbe wọle"
stringMap [strings.Mind_Bookcase] = "Apoti iwe"
stringMap [strings.Added_Successfully] = "Ti ṣafikun ni aṣeyọri"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "Orukọ apoti ko le ṣofo"

stringMap [strings.Mind_Association_Map] = "Maapu Ẹgbẹ"
stringMap [strings.Mind_confirm_Association_Map] = "Ṣe o da ọ lẹgbẹ maapu yii bi?"
stringMap [strings.Mind_Association_Map_See] = "Wo maapu Ẹgbẹ"
stringMap [strings.Mind_Association_Map_Delete] = "Pa Maapu Ẹgbẹ Rẹ"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "Ipele Laini ibamu"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Ibẹrẹ ti Laini ibamu"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "Opin laini ibamu"

stringMap [strings.Share_Group_To_Storm] = "Boya lati pin ẹgbẹ naa si iji"
stringMap [strings.Apply_To_Join_Storm_Platform] = "Lori pẹpẹ iji, awọn eniyan miiran rii ati lo lati darapọ mọ, iṣaroye pẹlu awọn ololufẹ pẹlu imọ ti o wọpọ"
stringMap [strings.Pay_To_Goin] = "Sanwo lati darapọ mọ"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "Awọn ewa M nilo lati sanwo nigbati o darapọ mọ"
stringMap[strings.Mbean_Can_Be_Realized] = "Awọn ewa Realizable M"

stringMap [strings.Mind_Visit] = "Ṣabẹwo"
stringMap [strings.Mind_Details] = "Awọn alaye"
stringMap [strings.Mind_See] = "Wo"
stringMap [strings.Earn_Mbeans] = "Jo'gun Mbeans"
stringMap [strings.Mind_Explain] = "Apejuwe"
stringMap [strings.Mind_Parenting] = "Ṣiṣe obi"
stringMap [strings.Mind_Internet] = "Intanẹẹti"
stringMap [strings.Mind_Psychology] = "Psychology"
stringMap [strings.Mind_literature] = "Litireso"
stringMap [strings.Mind_Technology] = "Imọ -ẹrọ"
stringMap [strings.Mind_Workplace] = "Ibi iṣẹ"
stringMap [strings.Mind_Life] = "Igbesi aye"
stringMap [strings.Mind_Subject] = "Koko -ọrọ"
stringMap [strings.Hot_Group] = "Ẹgbẹ Gbona"
stringMap [strings.Open_Group] = "Ṣii Ẹgbẹ"
stringMap [strings.Mind_Group_Used] = "Ti lo"

stringMap [strings.Mind_Click_Select] = "Tẹ lati yan"
stringMap [strings.Mind_Double_Click_Enter] = "Tẹ lẹẹmeji lati tẹ sii"
stringMap [strings.Search_Shared_Groups] = "Wa fun awọn ẹgbẹ ti a pin"
stringMap [strings.Search_Storm] = "iji iji"
stringMap [strings.Members_Save_Free] = "Awọn ọmọ ẹgbẹ Fipamọ Ọfẹ"
stringMap [strings.Bank_Card_Account_Name] = "Jọwọ tẹ orukọ akọọlẹ kaadi banki rẹ sii"
stringMap [strings.Bank_Card_No] = "Nọmba Kaadi Bank"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "Jọwọ tẹ nọmba kaadi banki rẹ sii"
stringMap [strings.Branch_Sub_Branch_Information] = "Alaye ti ẹka"
stringMap [strings.Branch_Sub_Branch_Example] = "Fun apẹẹrẹ: Hangzhou Branch Chengxi Sub-branch"
stringMap [strings.Application_For_Withdrawal] = "Ohun elo fun yiyọ kuro"
stringMap [strings.Withdrawal_Amount] = "Iye yiyọ kuro"
stringMap [strings.Withdrawal] = "Yọ kuro"
stringMap [strings.Current_Revenue] = "Owo -wiwọle lọwọlọwọ"
stringMap [strings.Recharge] = "Gbigba agbara"
stringMap [strings.Current_Total_MBeans] = "Nọmba apapọ ti MBeans lọwọlọwọ"
stringMap [strings.Withdrawal_Status] = "Ipo yiyọ kuro"
stringMap [strings.Insufficient_Withdrawal_Amount] = "Iye yiyọ kuro ti ko to"
stringMap [strings.Load_More] = "Ẹru diẹ sii"
stringMap [strings.Loading] = "Ikojọpọ"
stringMap [strings.It_Is_Over] = "O ti pari"
stringMap [strings.Default_Label] = "Aami aiyipada"
stringMap [strings.Selected_Label] = "Aami ti a yan"
stringMap [strings.Current_Location] = "Ipo lọwọlọwọ"
stringMap [strings.MGold_Introduction] = "M goolu jẹ nipasẹ rẹ nipasẹ Mind+"
stringMap [strings.MGold_Details] = "Ẹgbẹ awọsanma ti o sanwo rẹ, awọn maapu imọ wa, awọn ohun elo ẹkọ, ati bẹbẹ lọ ninu ẹgbẹ naa. Ti awọn olumulo ba darapọ mọ awọn ewa M sisan, 50% ninu wọn yoo yipada si goolu M, ati goolu M tobi ju 50 le yọkuro "
stringMap [strings.Cloud_Group_Introduction] = "Pin ẹgbẹ rẹ, awọn miiran le wo o ni oju -iwe iji"
stringMap [strings.Cloud_Group_Details] = "Ẹgbẹ awọsanma ti o san. Awọn maapu imọ, awọn ohun elo ẹkọ, ati bẹbẹ lọ wa ninu ẹgbẹ naa. Ti awọn olumulo ba darapọ mọ awọn ewa M owo sisan, 50% ninu wọn yoo yipada si goolu M, ati goolu M tobi ju 50 lọ ni a le fa jade "
stringMap [strings.Share_Storm_Introduction] = "Pin maapu rẹ si agbegbe iji, ki eniyan diẹ sii le kọ ẹkọ ati ṣawari imọ lati ọdọ rẹ"
stringMap [strings.Share_Storm_Details] = "Ti o ba mu lilo awọn ewa M lati fipamọ awọn maapu, awọn olumulo miiran le lo maapu imọ rẹ, ati pe iwọ yoo tun gba 50% ti awọn ewa M. Awọn ewa M le ṣee lo lati ra awọn ọmọ ẹgbẹ ati darapọ mọ isanwo awọn ẹgbẹ, O tun le sanwo lati lo awọn maapu eniyan miiran. "

stringMap [strings.Linked_Successfully] = "Ti sopọ ni ifijišẹ"
stringMap [strings.Cancel_Archiving] = "Fagilee ibi ipamọ"
stringMap [strings.Please_Select_Archive_Location] = "Jọwọ yan ipo ibi ipamọ"
stringMap [strings ._Search_Result_Is_Empty] = "Awọn abajade wiwa ti ṣofo"

stringMap [strings.Customize_A_Style] = "Awọn ti kii ṣe ọmọ ẹgbẹ le ṣe akanṣe ara kan nikan"
stringMap [strings.Adds_Current_Guide_Style_Library] = "Ṣafikun ara maapu lọwọlọwọ si ibi ikawe ara"
stringMap [strings.Trial_Version] = "Ẹya idanwo"
stringMap [strings.Not_Supported_At_The_Moment] = "Ko ṣe atilẹyin lọwọlọwọ"
stringMap [strings.Mind_File] = "Faili"
stringMap [strings.Mind_Import] = "Gbe wọle"
stringMap [strings.Importing] = "Gbigbe wọle"

stringMap [strings.Mind_Edit_Insert_Video] = "Fi fidio sii"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Fa ati ju fidio silẹ nibi"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "Iye akoko fifuye ko le kọja 60s"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "Wo Fidio"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "Pa fidio rẹ kuro"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "Fidio ti a kojọpọ ko le kọja 10MB"
stringMap [strings.Mind_Upload_Video_Restrictions] = "Nikan ṣe atilẹyin gbigba awọn fidio ni ọna kika MP4, to 120s ati 10M ni iwọn"
stringMap [strings.Mind_Video_Size] = "Iwọn fidio"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "Ọrọ okeere"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "TXT si ilẹ okeere"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap [strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap [strings.Export_Failed] = "Iṣilọ si ilẹ okeere kuna"
stringMap [strings.Add_File] = "Fi faili kun"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "Fa taara sinu faili lati gbejade"
stringMap [strings.Supported_Imported_File_Types] = "Awọn iru faili lọwọlọwọ ni atilẹyin fun gbigbe wọle: Ọrọ (nikan .docx), XMind, .txt"
stringMap [strings.No_Permission_Do_This] = "O ko ni igbanilaaye lati ṣe isẹ yii"

stringMap [strings.Upgrade_To_Professional] = "Igbesoke si Ẹya Ọjọgbọn"
stringMap [strings.Non_Members_Insert_Videos] = "Awọn ti kii ṣe ọmọ ẹgbẹ le fi awọn fidio sii ni awọn wakati 24 lẹhin iforukọsilẹ"
stringMap [strings.Upload_Failed] = "Ikojọpọ ko kuna"
stringMap [strings.Upload_Failed_Title_Message] = "Ikojọpọ ko kuna, jọwọ tẹ lati jẹrisi, pa awọn aworan to ku ti o kuna lati gbe si"

stringMap [strings.Mind_Edit_Members_Use] = "Awọn ọmọ ẹgbẹ Lo"
stringMap [strings.Invitation_Code] = "Koodu ifiwepe"
stringMap [strings.Student_Certification] = "Ijẹrisi ọmọ ile -iwe"
stringMap [strings.I_Get_It] = "Emi yoo, foju itọsọna naa"
stringMap [strings.Welcome_To_Mind] = "Kaabọ oluwa ọdọ lati darapọ mọ Mind+, jẹ ki a bẹrẹ irin -ajo ironu!"
stringMap [strings.Mind_Before] = "Akọkọ"
stringMap [strings.Try_A_Map] = "Gbiyanju maapu kan"
stringMap [strings.Click_Create] = "Tẹ lati ṣẹda"
stringMap [strings.Click_Create_A_Mind_Map] = "Tẹ lati ṣẹda maapu ọkan"
stringMap [strings.Mind_About] = "Nipa"
stringMap [strings.See_Checking_Data] = "Wo alaye ti ara ẹni"
stringMap [strings.Modify_Checking_Data] = "Ṣe atunṣe alaye ti ara ẹni"
stringMap [strings.Mind_Shear] = "Rirọ"

stringMap [strings.Mind_Style_Empty] = "Ko si ayanfẹ tabi aṣa aṣa"
stringMap [strings.Mind_Branch_Free_Layout] = "Ìfilọlẹ Ọfẹ Ẹka"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "Eto ọna meji ati awọn ẹka maapu ti nkuta le fa larọwọto si ipilẹ"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Akori Fa Fa Ọfẹ"

stringMap [strings.Select_A_Node] = "Yan koko -ọrọ kan"
stringMap [strings.Create_Child_Node] = "Ṣẹda akori ọmọ"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "Nigbamii a yoo ṣafikun ọkan ti o wuyi si akori ọmọ"
stringMap [strings.Click_Icon_Panel] = "Tẹ nronu aami"
stringMap [strings.Select_An_Icon_You_Like] = "Yan aami ti o fẹran"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "Nigbamii a yoo rọpo maapu ọkan pẹlu tuntun kan"
stringMap [strings.Select_The_Central_Theme] = "Yan akori aringbungbun"
stringMap [strings.Switch_To_Canvas_Panel] = "Yipada si Igbimọ Kanfasi"
stringMap [strings.Click_Layout] = "Tẹ lati Ìfilélẹ̀"
stringMap [strings.Choose_A_Layout_You_Like] = "Yan ipilẹ ti o fẹran"
stringMap [strings.Mind_Map_Save_Share] = "Oriire, o ti ṣaṣeyọri maapu ọkan ti o lẹwa! Jẹ ki a fipamọ ati pin pẹlu awọn ọrẹ rẹ ~"
stringMap [strings.Click_Save_Picture] = "Tẹ lati fi aworan pamọ"
stringMap [strings.Click_OK] = "Tẹ O DARA"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "Oriire lori ipari itọsọna alakobere ~"
stringMap [strings.Upload_Succeeded] = "Ti gbejade ni aṣeyọri"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "Ara iyara"
stringMap [strings.Mind_Extremely_Important] = "Pataki pupọ"
stringMap [strings.Mind_Important] = "Pataki"
stringMap [strings.Mind_Commonly] = "Gbogbogbo"
stringMap [strings.Mind_Ordinary] = "Arinrin"
stringMap [strings.Member_Exclusive] = "Ẹya ara ẹrọ yi jẹ iyasọtọ fun awọn ọmọ ẹgbẹ. Ṣe o fẹ igbesoke ẹgbẹ rẹ bi?"
stringMap [strings.Member_Exclusive_Color_Scheme] = "Eto awọ yi jẹ iyasọtọ fun awọn ọmọ ẹgbẹ. Ṣe o fẹ igbesoke ẹgbẹ rẹ bi?"
stringMap [strings.Mind_Enlarge] = "Tobi"
stringMap [strings.Mind_Narrow] = "dín"
stringMap [strings.Mind_Center] = "Ti dojukọ"
stringMap [strings.Mind_Full_Screen] = "Iboju kikun"

stringMap [strings.Mind_Association_Node] = "Ipade Ẹgbẹ"

stringMap[strings.Mind_Resource_See] = "Wo asomọ"
stringMap[strings.Mind_Resource_Delete] = "Pa awọn asomọ rẹ"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Fa ati ju awọn faili silẹ nibi"
stringMap[strings.Mind_Edit_choose_explain] = "Yan Faili"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "Fáìlì ìrùsókè kò le kọjá 20MB"

stringMap[strings.Mind_Extremely_DrawOblique] = "Texture"
stringMap[strings.Mind_Extremely_Gradient] = "Gradient"
stringMap[strings.Mind_Extremely_Shadow] = "Text Shadow"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;
