<template>
  <div id="app" style="overflow: -Scroll; overflow-y: hidden">
    <!-- 出口 -->
    <router-view v-if="isRouterShow"></router-view>
    <div class="bg-color" v-if="ShowBg"></div>
    <Setting v-if="setting" @HideSettingModel="HideSettingModel" />
    <div v-show="shipping" class="loading-common-box">
      <a-spin
        class="loading-common-child"
        tip="Loading..."
        :spinning="shipping"
        :delay="200"
      >
      </a-spin>
    </div>
  </div>
</template>

<script>
import Setting from "@/components/universalFile/setting/Setting";

export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
      showSettingModel: this.ShowSettingModel,
      refreshComponent: this.refreshComponent,
      commonShipping: this.setShipping,
    };
  },
  data() {
    return {
      isRouterShow: true,
      ShowBg: false,
      setting: false,
      shipping: false,
    };
  },
  methods: {
    async reload() {
      this.isRouterShow = this.shipping = false;
      await this.$nextTick();
      this.isRouterShow = true;
    },
    //设置模块
    ShowSettingModel() {
      this.setting = this.ShowBg = true;
    },
    HideSettingModel() {
      this.setting = this.ShowBg = false;
    },
    setShipping(val) {
      this.shipping = val;
    },
    refreshComponent() {
      const params = this.$route.query;
      const newParams = { ...params };
      newParams["refresh"] = parseInt(new Date().getTime() / 1000);
      this.$router.push({
        path: this.$route.path,
        query: newParams,
      });
    },
    // 全局快捷键
    globalShortcut() {
      const keydownFun = (e) => {
        //事件对象兼容
        let keydown = e || window.event || arguments.callee.caller.arguments[0];
        let keyNum = keydown.keyCode;
        let controlKey = false;
        if (this.$tools.isMacComputed()) {
          controlKey = e.metaKey;
        } else {
          controlKey = e.ctrlKey;
        }
        if (keyNum == 116 || (keyNum == 82 && controlKey == true)) {
          // [F5] || [Ctrl || Command]+[R]  刷新
          location.reload();
        }
      };
      document.addEventListener("keydown", keydownFun);
    },
  },
  created() {
    window["messageDialog"] = this.$message; //全局ant $message
    // setTimeout(() => {//本地测试用的
    //   {
    //     let meMessage = JSON.parse( localStorage.getItem("meMessage"));
    //     meMessage.isMember=true;
    //     localStorage.setItem("meMessage",JSON.stringify(meMessage) );
    //   }
    // }, 10000);
  },
  mounted() {
    this.globalShortcut();
  },
  components: {
    Setting,
  },
};
</script>

<style lang="less">
@import "./../static/global.css";
@import "./assets/font/mindfont.css";
/* :root {
  --bg: #ffffff; /* 背景色 */
/*--textColor1: #333333; /* 主体文字 */
/*--textColor2: #666666; /* 副标题文字 */
/*--textColor3: #999999; /* 提示文字 */
/*--borderColor: rgba(208, 208, 208, 0.5); /* 分割线 */
/*--exportMenuBorder: rgba(255, 255, 255, 0.2); /* 导出菜单边框色 */
/*} */
:root {
  --exportMenuDashedColor: #ccc; /* 导出菜单虚线,边框颜色 */
  --exportMenuBg: #eee; /* 导出菜单悬浮背景色 */
  --exportMenuColor: #333333; /* 导出菜单悬浮文字色 */
  --rightClickMenuBg: #e0e0e0; /* 右键菜单悬浮背景色 */
  --rightClickMenuColor: #000; /* 右键菜单悬浮文字色 */
  --antSwitchBg: rgba(0, 0, 0, 0.25); /* 右侧菜单开关背景色 */
  --antInputNumberBg: #fff; /* 右侧菜单数字框背景色 */
  --antInputBorderColor: #d9d9d9; /* 右侧菜单数字框边框颜色 */
  --antInputNumberColor: rgba(0, 0, 0, 0.65); /* 右键菜单数字框文字色 */
  --rightMenuTextStyleBg: #cccccc; /* 右侧菜单文字样式背景色 */
  --rightMenuIconHoverBg: #e0e0e0; /* 右侧菜单图标悬浮背景色 */
  --rightMenuBorderColor: #e4e4e4; /* 右侧菜单边框颜色 */
  --rMDropDownListHoverColor: #409eff; /* 右侧菜单下拉列表悬浮文字色 */
  --rMDropDownListBorderColor: #e6e6e6; /* 右侧菜单下拉列表边框颜色 */
  --styleHeadActiveBgColor: #ffffff; /* 一键配色选中Tab背景色 */

  --styleScrollbarThumbColor: #E1E1E1; /* 滚动条滑块 */
  --styleScrollbarTackColor: #FAFAFB; /* 滚动条背景 */
  --styleScrollbarTackBorderColor: #E8E8E8; /* 滚动条背景 */
  --rightClickMenuLineColor: #ddd8d8; //菜单栏分割线颜色
  --rightClickMenuBgHover: #4C4C4C8; //菜单栏鼠标悬浮颜色
  --rightClickMenuHoverColor: #ffffff; //菜单栏鼠标悬浮文字颜色
  --rightClickMenuBorderColor: #4C4C4C8; //菜单边线颜色
  --rightClickMenuInsetBorderColor: #6e6e6e; //菜单栏内边框线颜色
}

.clearfix:after {
  content: "."; /**/ /*内容为“.”就是一个英文的句号而已。也可以不写。*/
  display: block; /**/ /*加入的这个元素转换为块级元素。*/
  clear: both; /**/ /*清除左右两边浮动。*/
  visibility: hidden; /**/ /*可见度设为隐藏。注意它和display:none;是有区别的。visibility:hidden;仍然占据空间，只是看不到而已；*/
  line-height: 0; /**/ /*行高为0；*/
  height: 0; /**/ /*高度为0；*/
  font-size: 0; /**/ /*字体大小为0；*/
}
.clearfix {
  *zoom: 1;
}

html,
body {
  width: 100%;
  height: 100%;
  /* user-select:none; */
  -webkit-font-smoothing: antialiased; /*chrome、safari*/
  -moz-osx-font-smoothing: grayscale; /*firefox*/
}
html,
body::-webkit-scrollbar {
  width: 6px;
  height: 9px;
}
html,
body::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
html,
body::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 5px;
}
html,
body::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}
#app {
  width: 100%;
  height: auto;
  background-color: #f5f7fa !important;
}
.beyond-hidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.null-data {
  height: 100%;
  width: 100%;
  min-height: 200px;
  /* background-color: #eee; */
  margin: 0 auto;
  margin-top: 100px;
  background-image: url("assets/images/null-data.png");
  background-size: 20%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.null-data-title {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
.bg-color {
  background-color: black;
  opacity: 0.5;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}
/* 加载动画开始 */
.loading-common-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1001;
  .loading-common-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
  }
  .ant-spin-dot-item {
    background-color: #fd492b;
  }
  .ant-spin {
    color: #fd492b;
  }
  .ant-spin .ant-spin-dot-item {
    background-color: #fd492b !important;
  }
}
/* 加载动画结束 */
</style>
